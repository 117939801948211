"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobType = void 0;
const enums_1 = require("@bemlo/enums");
exports.jobType = {
    [enums_1.JobType.IRREGULAR_PARTTIME]: 'Uregelmessig deltid',
    [enums_1.JobType.JOUR]: 'Jour',
    [enums_1.JobType.PERMANENT]: 'Fast',
    [enums_1.JobType.STAFFING]: 'Bemanning',
    [enums_1.JobType.TEMPORARY]: 'Midlertidig',
};
