"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.specialization = void 0;
const specialization_1 = require("../nb/specialization");
const specialization_2 = require("../sv/specialization");
exports.specialization = {
    ...specialization_1.specialization,
    ...specialization_2.specialization,
    NURSE_AKUTSJUKVARD: {
        education: 'Emergency Care',
        title: {
            '*': 'Emergency Care Nurses',
            1: 'Emergency Care Nurse',
        },
    },
    NURSE_NONE: {
        education: 'Nursing',
        title: { '*': 'Registered Murses', '1': 'Registered Nurse' },
    },
};
