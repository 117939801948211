"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Experience = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../../../elements");
const NumberInput_1 = require("../../../../elements/Form/NumberInput");
const translate_1 = require("../../../../translate");
const Detail_1 = require("../../components/Detail");
const useProfessionalView_1 = require("../../hooks/useProfessionalView");
const useProfessionalViewAlternatives_1 = require("../../hooks/useProfessionalViewAlternatives");
const scopes_1 = require("../../scopes");
const Experience = () => {
    const { actor, data, isEditing, update } = (0, useProfessionalView_1.useProfessionalView)();
    const { professionsForMarketAlternatives, specializationsForMarketAndProfessionAlternatives, } = (0, useProfessionalViewAlternatives_1.useProfessionalViewAlternatives)();
    const t = (0, translate_1.useTranslations)();
    const { experience, profession, specializations } = data;
    const experienceScope = scopes_1.scopes.biography.experience();
    const professionScope = scopes_1.scopes.biography.profession();
    const specializationsScope = scopes_1.scopes.biography.qualifications.specializations();
    const currentScopes = [experienceScope, professionScope, specializationsScope];
    if (currentScopes.every((scope) => !actor.can('read', scope))) {
        return null;
    }
    if (currentScopes.some((scope) => isEditing(scope))) {
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 3.5, wrap: true, children: [actor.can('read', professionScope) && ((0, jsx_runtime_1.jsx)(elements_1.Combobox, { disabled: !actor.can('update', professionScope), label: t.professional_view.biography.profession(), onChange: update('profession'), options: professionsForMarketAlternatives, value: profession })), actor.can('read', specializationsScope) &&
                            specializationsForMarketAndProfessionAlternatives.length >= 1 && ((0, jsx_runtime_1.jsx)(elements_1.MultiSelect, { disabled: !actor.can('update', specializationsScope), label: t.professional_view.biography.specialization(), onChange: update('specializations'), options: specializationsForMarketAndProfessionAlternatives, value: specializations }))] }), actor.can('read', experienceScope) && ((0, jsx_runtime_1.jsx)(NumberInput_1.NumberInput, { disabled: !actor.can('update', experienceScope), label: t.professional_view.biography.years_of_experience(), onChange: update('experience'), value: experience }))] }));
    }
    const specializationText = specializations && specializations.length >= 1
        ? specializations
            .map((specialization) => t.$specialization(specialization))
            .join(', ')
        : '';
    if (!specializationText) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(Detail_1.Detail, { icon: "AcademicCapIcon", heading: specializationText, content: experience && actor.can('read', experienceScope)
            ? t.professional_view.biography.n_years_of_experience(experience)
            : undefined }));
};
exports.Experience = Experience;
