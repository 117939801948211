"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Day = void 0;
var Day;
(function (Day) {
    Day["MONDAY"] = "MONDAY";
    Day["TUESDAY"] = "TUESDAY";
    Day["WEDNESDAY"] = "WEDNESDAY";
    Day["THURSDAY"] = "THURSDAY";
    Day["FRIDAY"] = "FRIDAY";
    Day["SATURDAY"] = "SATURDAY";
    Day["SUNDAY"] = "SUNDAY";
})(Day || (exports.Day = Day = {}));
