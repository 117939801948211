"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploadType = void 0;
var FileUploadType;
(function (FileUploadType) {
    FileUploadType["CV"] = "CV";
    FileUploadType["HOSP"] = "HOSP";
    FileUploadType["IVO"] = "IVO";
    FileUploadType["LICENSE"] = "LICENSE";
    FileUploadType["REFERENCE"] = "REFERENCE";
    FileUploadType["OTHER"] = "OTHER";
    FileUploadType["SPECIALIZATION"] = "SPECIALIZATION";
})(FileUploadType || (exports.FileUploadType = FileUploadType = {}));
