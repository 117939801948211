"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.whichDepartmentToWorkInAlternative = void 0;
const enums_1 = require("@bemlo/enums");
const $ = enums_1.WhichDepartmentToWorkInAlternative;
const jobb = (preposition, title) => ({ preposition, title });
exports.whichDepartmentToWorkInAlternative = {
    [$.AKUTSJUKVARD]: jobb('inom', 'Akutsjukvård'),
    [$.AMBULANS]: jobb('inom', 'Ambulans'),
    [$.ASIH]: jobb('inom', 'ASIH'),
    [$.BARN_OCH_UNGDOM]: jobb('på', 'Barn- och ungdomsmottagning'),
    [$.BARNMEDICIN]: jobb('på', 'Barnavdelning'),
    [$.BARNONKOLOGEN]: jobb('på', 'Barnonkologen'),
    [$.BAVA]: jobb('på', 'BAVA'),
    [$.CENTER]: jobb('på', 'Mottagning'),
    [$.DEMENS]: jobb('på', 'Demensboende'),
    [$.DEPARTMENT]: jobb('på', 'Avdelning'),
    [$.DIALYSMOTTAGNING]: jobb('på', 'Dialysmottagning'),
    [$.FORLOSSNING]: jobb('inom', 'Förlossning'),
    [$.GASTROAVDELNING]: jobb('på', 'Gastrologen'),
    [$.GERIATRIK]: jobb('på', 'Geriatrisk avdelning'),
    [$.GYNEKOLOGDEPARTMENT]: jobb('på', 'Gynekologisk avdelning'),
    [$.HALSOCENTRAL]: jobb('på', 'Hälsocentral'),
    [$.HEMSJUKVARD]: jobb('inom', 'Hemsjukvård'),
    [$.HIA]: jobb('på', 'HIA'),
    [$.HJARTAVDELNING]: jobb('på', 'Hjärtavdelning'),
    [$.INFEKTION]: jobb('på', 'Infektionsavdelning'),
    [$.IVA]: jobb('på', 'IVA'),
    [$.JOURCENTRAL]: jobb('på', 'Jourcentral'),
    [$.KIRURGI]: jobb('inom', 'Kirurgen'),
    [$.KVINNOKLINIK]: jobb('på', 'Gynekologisk mottagning'),
    [$.LUNGAVDELNING]: jobb('på', 'Lungavdelning'),
    [$.MAMMOGRAFI]: jobb('inom', 'Mammografi'),
    [$.MAVA]: jobb('på', 'MAVA'),
    [$.MEDICIN]: jobb('på', 'Medicin'),
    [$.MUNICIPAL]: jobb('inom', 'Kommunal vård'),
    [$.NARAKUT]: jobb('på', 'Närakuten'),
    [$.NEONATAL]: jobb('på', 'Neonatalavdelning'),
    [$.NEUROLOGI]: jobb('på', 'Neurologi'),
    [$.NJURAVDELNING]: jobb('på', 'Njurmedicin'),
    [$.ONH_MOTTAGNING]: jobb('på', 'ÖNH-mottagning'),
    [$.ONKOLOGEN]: jobb('på', 'Onkologen'),
    [$.OPEN_FOR_ANYTHING]: jobb('inom', 'Öppen för olika'),
    [$.OPERATION]: jobb('inom', 'Operation'),
    [$.ORTOPEDEN]: jobb('på', 'Ortopeden'),
    [$.POSTOPERATION]: jobb('på', 'Postoperation'),
    [$.PSYKIATRI]: jobb('inom', 'Psykiatri'),
    [$.RADIOLOGEN]: jobb('på', 'Radiologen'),
    [$.REHABILITERING]: jobb('inom', 'Rehabilitering'),
    [$.REUMATOLOGI]: jobb('på', 'Reumatologen'),
    [$.SABO]: jobb('på', 'SÄBO'),
    [$.SJUKSTUGA]: jobb('på', 'Sjukstuga'),
    [$.SKOLSJUKVARD]: jobb('på', 'Skolsjukvård'),
    [$.TELEFONRADGIVNING]: jobb('inom', 'Telefonrådgivning'),
    [$.THORAXKIRURGEN]: jobb('på', 'Thoraxkirurgen'),
    [$.VACCINERING]: jobb('inom', 'Vaccinering'),
    [$.VARDCENTRAL]: jobb('på', 'Vårdcentral'),
};
