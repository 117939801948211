"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenderScheduleType = void 0;
var TenderScheduleType;
(function (TenderScheduleType) {
    TenderScheduleType["AVAILABILITY"] = "AVAILABILITY";
    TenderScheduleType["NOT_SET"] = "NOT_SET";
    TenderScheduleType["SHIFTS"] = "SHIFTS";
    TenderScheduleType["TEXT"] = "TEXT";
})(TenderScheduleType || (exports.TenderScheduleType = TenderScheduleType = {}));
