"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.group = exports.safeIdentify = exports.identify = exports.trackViewed = exports.track = exports.useTrackViewed = exports.useTracking = void 0;
const react_1 = require("react");
const utils_1 = require("@bemlo/utils");
const filterPersonalData_1 = require("./filterPersonalData");
const useTracking = (event, properties, dependencies = []) => {
    (0, react_1.useEffect)(() => {
        (0, exports.track)(event, properties);
    }, dependencies);
};
exports.useTracking = useTracking;
const useTrackViewed = (event, properties, dependencies = []) => {
    (0, react_1.useEffect)(() => {
        (0, exports.trackViewed)(event, properties);
    }, dependencies);
};
exports.useTrackViewed = useTrackViewed;
const track = (event, properties) => {
    window?.analytics?.track(event, (0, filterPersonalData_1.filterPersonalData)(properties));
};
exports.track = track;
const trackViewed = (event, properties) => {
    (0, exports.track)(event, { ...properties, nonInteraction: 1 });
};
exports.trackViewed = trackViewed;
const identify = (userId, traits) => {
    window?.analytics?.identify(userId, (0, filterPersonalData_1.filterPersonalData)(traits));
};
exports.identify = identify;
const safeIdentify = (personalDataId, traits) => {
    (0, exports.identify)((0, utils_1.stringHashCode)(personalDataId).toString(), {
        ...traits,
        identifiedWithHash: true,
    });
};
exports.safeIdentify = safeIdentify;
const group = (groupId, traits) => {
    window?.analytics?.group(groupId, (0, filterPersonalData_1.filterPersonalData)(traits));
};
exports.group = group;
