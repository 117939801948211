"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateTimeShiftsToWorkHours = exports.dateTimeShiftToWorkHours = exports.workHoursByShifts = exports.shiftHoursByShift = exports.workHoursByShift = exports.splitShift = exports.shiftToClockHours = void 0;
const date_1 = require("@bemlo/date");
const enums_1 = require("@bemlo/enums");
const constants_1 = require("../constants");
const { DAY, EVENING, NIGHT, WEEKEND, WEEKEND_NIGHT, HOLIDAY } = enums_1.WorkHours;
const _isHourDay = ({ hour }) => {
    return hour >= constants_1.DAY_START_HOUR && hour < constants_1.EVENING_START_HOUR;
};
const isHourEvening = ({ hour }) => {
    return hour >= constants_1.EVENING_START_HOUR && hour < constants_1.NIGHT_START_HOUR;
};
const isHourNight = ({ hour }) => {
    return hour >= constants_1.NIGHT_START_HOUR || hour < constants_1.DAY_START_HOUR;
};
const isHourWeekend = ({ day }) => {
    return [constants_1.DAY_SATURDAY, constants_1.DAY_SUNDAY].includes(day.getDay());
};
const isHourWeekendNight = (shift) => {
    return isHourWeekend(shift) && isHourNight(shift);
};
const isHourHoliday = (_shift) => {
    // TODO(ef): use npm swedish-holidays
    return false;
};
const shiftToClockHours = (shift) => {
    const startTime = (0, date_1.parseTime)(shift.startTime);
    const endTime = (0, date_1.parseTime)(shift.endTime);
    const startingClockHour = startTime.hour;
    const endingClockHour = endTime.hour;
    const totalClockHours = startTime.hour >= endTime.hour
        ? endTime.hour + 24 - startTime.hour
        : endTime.hour - startTime.hour;
    return {
        startingClockHour,
        endingClockHour,
        totalClockHours: endTime.minute ? totalClockHours + 1 : totalClockHours,
    };
};
exports.shiftToClockHours = shiftToClockHours;
const lengthOfHour = (hour, shift, startingClockHour, endingClockHour) => {
    const startTime = (0, date_1.parseTime)(shift.startTime);
    const endTime = (0, date_1.parseTime)(shift.endTime);
    if (hour === startingClockHour && hour === endingClockHour)
        return 60;
    if (hour === endingClockHour)
        return endTime.minute;
    if (hour === startingClockHour)
        return 60 - startTime.minute;
    return 60;
};
const splitShift = (shift) => {
    const { startingClockHour, endingClockHour, totalClockHours } = (0, exports.shiftToClockHours)(shift);
    const shiftHours = [];
    let hour = startingClockHour;
    for (let i = 0; i < totalClockHours; i++) {
        const day = hour < startingClockHour
            ? (0, date_1.parseDate)(shift.date).add(1, 'day').toDate()
            : (0, date_1.parseDate)(shift.date).toDate();
        const length = lengthOfHour(hour, shift, startingClockHour, endingClockHour);
        shiftHours.push({ day, hour, length });
        hour = hour === 23 ? 0 : hour + 1;
    }
    return shiftHours.filter(({ length }) => length);
};
exports.splitShift = splitShift;
const toShiftHourWithType = (shift) => {
    if (isHourHoliday(shift))
        return { ...shift, type: HOLIDAY };
    if (isHourWeekendNight(shift))
        return { ...shift, type: WEEKEND_NIGHT };
    if (isHourWeekend(shift))
        return { ...shift, type: WEEKEND };
    if (isHourNight(shift))
        return { ...shift, type: NIGHT };
    if (isHourEvening(shift))
        return { ...shift, type: EVENING };
    return { ...shift, type: DAY };
};
const getLengthByType = (shiftHours, type) => {
    return shiftHours
        .filter((hour) => hour.type === type)
        .reduce((acc, { length }) => acc + length, 0);
};
const workHoursByShift = (shift, cheapestWorkHour = DAY) => {
    const shiftHours = (0, exports.splitShift)(shift);
    const shiftHoursWithType = shiftHours.map(toShiftHourWithType);
    const workHours = {
        [HOLIDAY]: getLengthByType(shiftHoursWithType, HOLIDAY),
        [WEEKEND_NIGHT]: getLengthByType(shiftHoursWithType, WEEKEND_NIGHT),
        [WEEKEND]: getLengthByType(shiftHoursWithType, WEEKEND),
        [NIGHT]: getLengthByType(shiftHoursWithType, NIGHT),
        [EVENING]: getLengthByType(shiftHoursWithType, EVENING),
        [DAY]: getLengthByType(shiftHoursWithType, DAY),
    };
    workHours[cheapestWorkHour] = Math.max(0, workHours[cheapestWorkHour] - shift.breakTime);
    return workHours;
};
exports.workHoursByShift = workHoursByShift;
const shiftHoursByShift = (shift) => {
    const { DAY, EVENING, NIGHT, WEEKEND, WEEKEND_NIGHT } = (0, exports.workHoursByShift)(shift);
    const dayHours = DAY + WEEKEND;
    const eveningHours = EVENING;
    const nightHours = NIGHT + WEEKEND_NIGHT;
    const { value } = [
        {
            value: enums_1.ShiftHours.DAY,
            hours: dayHours,
        },
        {
            value: enums_1.ShiftHours.EVENING,
            hours: eveningHours,
        },
        {
            value: enums_1.ShiftHours.NIGHT,
            hours: nightHours,
        },
    ].sort((a, b) => b.hours - a.hours)[0];
    return value;
};
exports.shiftHoursByShift = shiftHoursByShift;
const workHoursByShifts = (shifts) => {
    return shifts.reduce((acc, shift) => {
        const shiftHours = (0, exports.workHoursByShift)(shift);
        return {
            [HOLIDAY]: acc[HOLIDAY] + shiftHours[HOLIDAY],
            [WEEKEND_NIGHT]: acc[WEEKEND_NIGHT] + shiftHours[WEEKEND_NIGHT],
            [WEEKEND]: acc[WEEKEND] + shiftHours[WEEKEND],
            [NIGHT]: acc[NIGHT] + shiftHours[NIGHT],
            [EVENING]: acc[EVENING] + shiftHours[EVENING],
            [DAY]: acc[DAY] + shiftHours[DAY],
        };
    }, {
        [HOLIDAY]: 0,
        [WEEKEND_NIGHT]: 0,
        [WEEKEND]: 0,
        [NIGHT]: 0,
        [EVENING]: 0,
        [DAY]: 0,
    });
};
exports.workHoursByShifts = workHoursByShifts;
const dateTimeShiftToWorkHours = (shift) => {
    const startMoment = shift.startDateTime instanceof date_1.dayjsEn
        ? shift.startDateTime
        : (0, date_1.dayjsEn)(shift.startDateTime);
    const endMoment = shift.endDateTime instanceof date_1.dayjsEn
        ? shift.endDateTime
        : (0, date_1.dayjsEn)(shift.endDateTime);
    const shiftDuration = date_1.dayjsEn.duration(endMoment.diff(startMoment));
    const breakDuration = date_1.dayjsEn.duration(shift.breakTime, 'minutes');
    return shiftDuration.subtract(breakDuration).asHours();
};
exports.dateTimeShiftToWorkHours = dateTimeShiftToWorkHours;
const dateTimeShiftsToWorkHours = (shifts) => {
    return shifts.reduce((acc, shift) => acc + (0, exports.dateTimeShiftToWorkHours)(shift), 0);
};
exports.dateTimeShiftsToWorkHours = dateTimeShiftsToWorkHours;
