"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListboxContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Button_1 = require("./Button");
const Option_1 = require("./Option");
const Options_1 = require("./Options");
const useValidate_1 = require("../../hooks/useValidate");
const translate_1 = require("../../translate");
const Column_1 = require("../Column");
const ErrorMessage_1 = require("../ErrorMessage");
const Label_1 = require("../Form/Label");
const Row_1 = require("../Row");
const ListboxContent = (props) => {
    const [touched, setTouched] = (0, react_1.useState)(false);
    const validateError = (0, useValidate_1.useValidate)(props.schema, props.required, props.value);
    const [hasBeenOpened, setHasBeenOpened] = (0, react_1.useState)(false);
    const t = (0, translate_1.useTranslation)();
    (0, react_1.useEffect)(() => {
        if (!hasBeenOpened && props.open) {
            setHasBeenOpened(true);
        }
        else if (hasBeenOpened && !props.open) {
            setTouched(true);
        }
    }, [props.open]);
    const isPlaceholder = typeof props.value !== 'number' && !props.value;
    const placeholder = props.placeholder || `${t.select_a_value()}...`;
    const small = props.size === 'sm';
    const errorMessage = props.errorMessage ?? validateError;
    const showError = (touched || props.forceShowError) && !!errorMessage;
    const buttonText = props.buttonText ||
        (isPlaceholder
            ? placeholder
            : (props.options.find((option) => option.value === props.value)?.label ??
                String(props.value)));
    const button = ((0, jsx_runtime_1.jsx)(Button_1.ListboxButton, { className: props.buttonClassName, compact: props.compact, design: props.design, icon: props.buttonIcon, invalid: showError, isPlaceholder: isPlaceholder, size: props.size, children: buttonText }));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [props.children, props.label ? ((0, jsx_runtime_1.jsx)(Label_1.Label, { fullWidth: !props.compact, hideRequiredIndicator: props.hideRequiredIndicator, icon: props.labelIcon, required: props.required, text: props.label, children: button })) : (button)] }), (0, jsx_runtime_1.jsxs)(Column_1.Column, { position: "relative", children: [(0, jsx_runtime_1.jsx)(ErrorMessage_1.ErrorMessage, { errorMessage: showError ? errorMessage : undefined, reserveSpace: props.reserveErrorSpace }), (0, jsx_runtime_1.jsxs)(Options_1.ListboxOptions, { children: [!props.required && ((0, jsx_runtime_1.jsx)(Option_1.ListboxOption, { placeholder: true, small: small, value: null, children: placeholder })), props.options.map((option) => ((0, jsx_runtime_1.jsx)(Option_1.ListboxOption, { disabled: option.disabled, small: small, value: option.value, children: option.label ?? String(option.value) }, String(option.key ?? option.value))))] })] })] }));
};
exports.ListboxContent = ListboxContent;
