"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Specialization = exports.DoctorSpecialization = exports.NurseSpecialization = void 0;
var NurseSpecialization;
(function (NurseSpecialization) {
    NurseSpecialization["NURSE_NONE"] = "NURSE_NONE";
    NurseSpecialization["NURSE_GENERIC_SPECIALIZATION"] = "NURSE_GENERIC_SPECIALIZATION";
    NurseSpecialization["NURSE_AKUTSJUKVARD"] = "NURSE_AKUTSJUKVARD";
    NurseSpecialization["NURSE_AMBULANSSJUKVARD"] = "NURSE_AMBULANSSJUKVARD";
    NurseSpecialization["NURSE_ANESTESTISJUKVARD"] = "NURSE_ANESTESTISJUKVARD";
    NurseSpecialization["NURSE_BARNMORSKA"] = "NURSE_BARNMORSKA";
    NurseSpecialization["NURSE_BARNOCHUNGDOM"] = "NURSE_BARNOCHUNGDOM";
    NurseSpecialization["NURSE_DISTRIKTSKOTERSKA"] = "NURSE_DISTRIKTSKOTERSKA";
    NurseSpecialization["NURSE_DIABETES"] = "NURSE_DIABETES";
    NurseSpecialization["NURSE_INTENSIVVARD"] = "NURSE_INTENSIVVARD";
    NurseSpecialization["NURSE_KIRURGISKVARD"] = "NURSE_KIRURGISKVARD";
    NurseSpecialization["NURSE_MEDICINSKVARD"] = "NURSE_MEDICINSKVARD";
    NurseSpecialization["NURSE_ONKOLOGISKVARD"] = "NURSE_ONKOLOGISKVARD";
    NurseSpecialization["NURSE_OPERATIONSSJUKVARD"] = "NURSE_OPERATIONSSJUKVARD";
    NurseSpecialization["NURSE_PSYKIATRISKVARD"] = "NURSE_PSYKIATRISKVARD";
    NurseSpecialization["NURSE_SILVIASJUKSKOTERSKA"] = "NURSE_SILVIASJUKSKOTERSKA";
    NurseSpecialization["NURSE_SKOLSJUKVARD"] = "NURSE_SKOLSJUKVARD";
    NurseSpecialization["NURSE_VARDAVALDRE"] = "NURSE_VARDAVALDRE";
    NurseSpecialization["NURSE_GERIATRIK"] = "NURSE_GERIATRIK";
    NurseSpecialization["NURSE_RONTGEN"] = "NURSE_RONTGEN";
    NurseSpecialization["NURSE_ASTMA_KOL"] = "NURSE_ASTMA_KOL";
    NurseSpecialization["NURSE_DIALYS"] = "NURSE_DIALYS";
    NurseSpecialization["NURSE_ORTOPTIST"] = "NURSE_ORTOPTIST";
    NurseSpecialization["NURSE_DEMENSVARD"] = "NURSE_DEMENSVARD";
    NurseSpecialization["NURSE_OGONSJUKDOMAR"] = "NURSE_OGONSJUKDOMAR";
    NurseSpecialization["NURSE_SJUKGYMNAST"] = "NURSE_SJUKGYMNAST";
    NurseSpecialization["NURSE_AVANSERTKLINISKALLMENNSKYEPLEIE"] = "NURSE_AVANSERTKLINISKALLMENNSKYEPLEIE";
    NurseSpecialization["NURSE_AVANSERTKLINISKNYFODTSYKEPLEIE"] = "NURSE_AVANSERTKLINISKNYFODTSYKEPLEIE";
    NurseSpecialization["NURSE_AVANSERTSYKEPLEIE"] = "NURSE_AVANSERTSYKEPLEIE";
    NurseSpecialization["NURSE_DERMATOLOGISKSYKEPLEIE"] = "NURSE_DERMATOLOGISKSYKEPLEIE";
    NurseSpecialization["NURSE_HELSESYKEPLEIE"] = "NURSE_HELSESYKEPLEIE";
    NurseSpecialization["NURSE_KARDIOLOGISKSYKEPLEIE"] = "NURSE_KARDIOLOGISKSYKEPLEIE";
    NurseSpecialization["NURSE_KLINISKSYKEPLEIE"] = "NURSE_KLINISKSYKEPLEIE";
    NurseSpecialization["NURSE_MASTERIHELSEOGSOSIALFAG"] = "NURSE_MASTERIHELSEOGSOSIALFAG";
    NurseSpecialization["NURSE_MASTERIHELSEVITENSKAP"] = "NURSE_MASTERIHELSEVITENSKAP";
    NurseSpecialization["NURSE_PALLIATIVSYKEPLEIE"] = "NURSE_PALLIATIVSYKEPLEIE";
})(NurseSpecialization || (exports.NurseSpecialization = NurseSpecialization = {}));
var DoctorSpecialization;
(function (DoctorSpecialization) {
    DoctorSpecialization["DOCTOR_NONE"] = "DOCTOR_NONE";
    DoctorSpecialization["DOCTOR_GENERIC_SPECIALIZATION"] = "DOCTOR_GENERIC_SPECIALIZATION";
    DoctorSpecialization["DOCTOR_AKUTSJUKVARD"] = "DOCTOR_AKUTSJUKVARD";
    DoctorSpecialization["DOCTOR_ALLMANMEDICIN"] = "DOCTOR_ALLMANMEDICIN";
    DoctorSpecialization["DOCTOR_ANESTESIOCHINTENSIVVARD"] = "DOCTOR_ANESTESIOCHINTENSIVVARD";
    DoctorSpecialization["DOCTOR_ARBETSOCHMILJOMEDICIN"] = "DOCTOR_ARBETSOCHMILJOMEDICIN";
    DoctorSpecialization["DOCTOR_BARNOCHUNGDOMSKARDIOLOGI"] = "DOCTOR_BARNOCHUNGDOMSKARDIOLOGI";
    DoctorSpecialization["DOCTOR_BARNOCHUNGDOMSKIRURGI"] = "DOCTOR_BARNOCHUNGDOMSKIRURGI";
    DoctorSpecialization["DOCTOR_BARNOCHUNGDOMSMEDICIN"] = "DOCTOR_BARNOCHUNGDOMSMEDICIN";
    DoctorSpecialization["DOCTOR_BARNOCHUNGDOMSNEUROLOGI"] = "DOCTOR_BARNOCHUNGDOMSNEUROLOGI";
    DoctorSpecialization["DOCTOR_BARNOCHUNGDOMSPSYKIATRI"] = "DOCTOR_BARNOCHUNGDOMSPSYKIATRI";
    DoctorSpecialization["DOCTOR_ENDOKRINOLOGIOCHDIABETOLOGI"] = "DOCTOR_ENDOKRINOLOGIOCHDIABETOLOGI";
    DoctorSpecialization["DOCTOR_GASTROENTEROLOGI"] = "DOCTOR_GASTROENTEROLOGI";
    DoctorSpecialization["DOCTOR_GERIATRIK"] = "DOCTOR_GERIATRIK";
    DoctorSpecialization["DOCTOR_HANDKIRURGI"] = "DOCTOR_HANDKIRURGI";
    DoctorSpecialization["DOCTOR_HEMATOLOGI"] = "DOCTOR_HEMATOLOGI";
    DoctorSpecialization["DOCTOR_HORSELOCHBALANSRUBBNINGAR"] = "DOCTOR_HORSELOCHBALANSRUBBNINGAR";
    DoctorSpecialization["DOCTOR_HUDOCHKONSSJUKDOMAR"] = "DOCTOR_HUDOCHKONSSJUKDOMAR";
    DoctorSpecialization["DOCTOR_INFEKTIONSSJUKDOMAR"] = "DOCTOR_INFEKTIONSSJUKDOMAR";
    DoctorSpecialization["DOCTOR_INTERNMEDICIN"] = "DOCTOR_INTERNMEDICIN";
    DoctorSpecialization["DOCTOR_KARDIOLOGI"] = "DOCTOR_KARDIOLOGI";
    DoctorSpecialization["DOCTOR_KARLKIRURGI"] = "DOCTOR_KARLKIRURGI";
    DoctorSpecialization["DOCTOR_KIRURGI"] = "DOCTOR_KIRURGI";
    DoctorSpecialization["DOCTOR_KLINISKFARMAKOLOGI"] = "DOCTOR_KLINISKFARMAKOLOGI";
    DoctorSpecialization["DOCTOR_KLINISKFYSIOLOGI"] = "DOCTOR_KLINISKFYSIOLOGI";
    DoctorSpecialization["DOCTOR_KLINISKGENETIK"] = "DOCTOR_KLINISKGENETIK";
    DoctorSpecialization["DOCTOR_KLINISKKEMI"] = "DOCTOR_KLINISKKEMI";
    DoctorSpecialization["DOCTOR_KLINISKMIKROBIOLOGI"] = "DOCTOR_KLINISKMIKROBIOLOGI";
    DoctorSpecialization["DOCTOR_KLINISKNEUROFYSIOLOGI"] = "DOCTOR_KLINISKNEUROFYSIOLOGI";
    DoctorSpecialization["DOCTOR_LUNGSJUKDOMAR"] = "DOCTOR_LUNGSJUKDOMAR";
    DoctorSpecialization["DOCTOR_NEONATOLOGI"] = "DOCTOR_NEONATOLOGI";
    DoctorSpecialization["DOCTOR_NEUROKIRURGI"] = "DOCTOR_NEUROKIRURGI";
    DoctorSpecialization["DOCTOR_NEUROLOGI"] = "DOCTOR_NEUROLOGI";
    DoctorSpecialization["DOCTOR_NEURORADIOLOGI"] = "DOCTOR_NEURORADIOLOGI";
    DoctorSpecialization["DOCTOR_NJURMEDICIN"] = "DOCTOR_NJURMEDICIN";
    DoctorSpecialization["DOCTOR_NUKLEARMEDICIN"] = "DOCTOR_NUKLEARMEDICIN";
    DoctorSpecialization["DOCTOR_OBSTETRIKOCHGYNEKOLOGI"] = "DOCTOR_OBSTETRIKOCHGYNEKOLOGI";
    DoctorSpecialization["DOCTOR_OGONSJUKDOMAR"] = "DOCTOR_OGONSJUKDOMAR";
    DoctorSpecialization["DOCTOR_ONKOLOGI"] = "DOCTOR_ONKOLOGI";
    DoctorSpecialization["DOCTOR_ORONNASAHALSSJUKDOMAR"] = "DOCTOR_ORONNASAHALSSJUKDOMAR";
    DoctorSpecialization["DOCTOR_ORTOPEDI"] = "DOCTOR_ORTOPEDI";
    DoctorSpecialization["DOCTOR_PALLIATIVMEDICIN"] = "DOCTOR_PALLIATIVMEDICIN";
    DoctorSpecialization["DOCTOR_PATOLOGI"] = "DOCTOR_PATOLOGI";
    DoctorSpecialization["DOCTOR_PLASTIKKIRURGI"] = "DOCTOR_PLASTIKKIRURGI";
    DoctorSpecialization["DOCTOR_PSYKIATRI"] = "DOCTOR_PSYKIATRI";
    DoctorSpecialization["DOCTOR_RADIOLOGI"] = "DOCTOR_RADIOLOGI";
    DoctorSpecialization["DOCTOR_RATTSMEDICIN"] = "DOCTOR_RATTSMEDICIN";
    DoctorSpecialization["DOCTOR_REHABILITERINGSMEDICIN"] = "DOCTOR_REHABILITERINGSMEDICIN";
    DoctorSpecialization["DOCTOR_REUMATOLOGI"] = "DOCTOR_REUMATOLOGI";
    DoctorSpecialization["DOCTOR_ROSTOCHTALRUBBNINGAR"] = "DOCTOR_ROSTOCHTALRUBBNINGAR";
    DoctorSpecialization["DOCTOR_SOCIALMEDICIN"] = "DOCTOR_SOCIALMEDICIN";
    DoctorSpecialization["DOCTOR_THORAXKIRURGI"] = "DOCTOR_THORAXKIRURGI";
    DoctorSpecialization["DOCTOR_UROLOGI"] = "DOCTOR_UROLOGI";
    DoctorSpecialization["DOCTOR_ALLERGISJUKDOMAR"] = "DOCTOR_ALLERGISJUKDOMAR";
    DoctorSpecialization["DOCTOR_BARNOCHUNGDOMSHEMATOLOGI"] = "DOCTOR_BARNOCHUNGDOMSHEMATOLOGI";
    DoctorSpecialization["DOCTOR_IMMUNOLOGI"] = "DOCTOR_IMMUNOLOGI";
    DoctorSpecialization["DOCTOR_UTLANDSKUTBILDNING"] = "DOCTOR_UTLANDSKUTBILDNING";
    DoctorSpecialization["DOCTOR_BRYSTOGENDOKRINKIRURGI"] = "DOCTOR_BRYSTOGENDOKRINKIRURGI";
    DoctorSpecialization["DOCTOR_MAXILLOFACIALKIRURGI"] = "DOCTOR_MAXILLOFACIALKIRURGI";
    DoctorSpecialization["DOCTOR_RUSOGAVHENGIGHETSMEDISIN"] = "DOCTOR_RUSOGAVHENGIGHETSMEDISIN";
})(DoctorSpecialization || (exports.DoctorSpecialization = DoctorSpecialization = {}));
exports.Specialization = {
    ...NurseSpecialization,
    ...DoctorSpecialization,
};
