"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Responsive = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Box_1 = require("./Box");
/**
 * @deprecated
 */
const Responsive = (props) => {
    return ((0, jsx_runtime_1.jsx)(Box_1.Box, { display: [
            props.mobile ? 'flex' : 'none',
            props.desktop ? 'flex' : 'none',
        ], children: props.children }));
};
exports.Responsive = Responsive;
