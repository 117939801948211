"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.journalSystem = void 0;
const enums_1 = require("@bemlo/enums");
exports.journalSystem = {
    [enums_1.JournalSystem.ASYNJAVISPH]: 'AsynjaVisph',
    [enums_1.JournalSystem.BMS]: 'BMS',
    [enums_1.JournalSystem.COMBINE]: 'Combine',
    [enums_1.JournalSystem.COSMIC]: 'Cosmic',
    [enums_1.JournalSystem.IMX]: 'IMX',
    [enums_1.JournalSystem.MELIOR]: 'Melior',
    [enums_1.JournalSystem.MILLENNIUM]: 'Millennium',
    [enums_1.JournalSystem.NCSCROSS]: 'NCS Cross',
    [enums_1.JournalSystem.OBSTERIX]: 'Obstetrix',
    [enums_1.JournalSystem.PMO]: 'Profdoc Medical Office (PMO)',
    [enums_1.JournalSystem.PRATOR]: 'Prator',
    [enums_1.JournalSystem.PROCAPITA]: 'Procapita',
    [enums_1.JournalSystem.TAKECARE]: 'TakeCare',
    [enums_1.JournalSystem.VAS]: 'VårdAdministrativt System (VAS)',
};
