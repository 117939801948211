"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withDialog = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Dialog_1 = require("./Dialog");
/**
 * A Higher order component that wraps the Dialog. By using it, your state will be clean every time
 * the dialog opens.
 */
const withDialog = (Component) => {
    return function WithDialogComponent(props) {
        // Getting "could be instantiated with a different subtype of constraint" error otherwise
        const TSHappyComponent = Component;
        const isOpen = !!(props.isOpen || props.openData);
        const [dialogIsOpen, setDialogIsOpen] = (0, react_1.useState)(isOpen);
        const [internalOpenData, setInternalOpenData] = (0, react_1.useState)(props.openData);
        const initialFocus = (0, react_1.useRef)(null);
        (0, react_1.useEffect)(() => {
            // So that dialogIsOpen and componentIsOpen are set in the same render cycle
            setDialogIsOpen(isOpen);
            if (isOpen) {
                // Need to keep old value when dialog is fading out
                setInternalOpenData(props.openData);
            }
        }, [isOpen, props.dialogIsOpen]);
        return ((0, jsx_runtime_1.jsx)(Dialog_1.Dialog, { isOpen: dialogIsOpen, onClose: props.onClose, initialFocus: initialFocus, children: (0, jsx_runtime_1.jsx)(TSHappyComponent, { ...props, isOpen: isOpen, openData: internalOpenData, initialFocus: initialFocus }) }));
    };
};
exports.withDialog = withDialog;
