"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Detail = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const utils_1 = require("@bemlo/utils");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const Flex_1 = require("../../../elements/Flex");
const Detail = ({ content, heading, icon }) => {
    const contents = content ? (0, utils_1.toArray)(content) : [];
    return ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { gap: 2.5, items: "start", styles: { w: 'full' }, children: [icon && (0, jsx_runtime_1.jsx)(elements_1.Icon, { color: constants_1.Color.BLUE, name: icon, size: "sm" }), (0, jsx_runtime_1.jsxs)(Flex_1.Flex, { col: true, gap: 0.5, styles: { fontSize: constants_1.theme.font.size[6] }, children: [(0, jsx_runtime_1.jsx)(Flex_1.Flex, { gap: 2, items: "center", children: (0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, color: constants_1.Color.BLUE, size: 6, children: heading }) }), contents.map((content, index) => {
                        return typeof content === 'string' ? ((0, jsx_runtime_1.jsx)(elements_1.Text, { children: content }, index)) : (content);
                    })] })] }));
};
exports.Detail = Detail;
