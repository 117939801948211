"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedVacanciesTitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const schedule_1 = require("@bemlo/schedule");
const constants_1 = require("../../../../constants");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const useDesign = (scheduledTime) => {
    if (!scheduledTime)
        return { color: constants_1.Color.DARK_MEDIUM_GREY, icon: undefined };
    return scheduledTime <= 40
        ? {
            name: 'CheckCircleIcon',
            color: constants_1.Color.GREEN,
        }
        : {
            name: 'ExclamationIcon',
            color: constants_1.Color.RED,
        };
};
// TODO: add checkboxes here
const FixedVacanciesTitle = (props) => {
    const t = (0, translate_1.useTranslations)();
    const scheduledTime = (0, schedule_1.shiftsToWorkHours)(props.shifts ?? []);
    const { icon, color } = useDesign(scheduledTime);
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 1.5, styles: {
            px: 3,
            py: 1,
            height: '100%',
            position: 'relative',
            justifyContent: props.shifts ? undefined : 'center',
        }, children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { color: constants_1.Color.DARK_GREY, fontSize: constants_1.theme.font.size[6.5], fontWeight: constants_1.theme.font.weight.book, children: t.shifts.row_n(props.rowKey + 1) }), props.shifts && ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 1, children: [icon && (0, jsx_runtime_1.jsx)(elements_1.Icon, { name: icon, size: "sm", color: color, design: "solid" }), (0, jsx_runtime_1.jsx)(elements_1.Text, { color: color, fontSize: constants_1.theme.font.size['6.5'], children: props.shifts.length
                            ? t.$date.formatDuration({ hours: scheduledTime })
                            : `0 ${t.shifts.shift_other().toLocaleLowerCase()}` })] }))] }));
};
exports.FixedVacanciesTitle = FixedVacanciesTitle;
