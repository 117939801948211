"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionHeading = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const EditIcon_1 = require("./EditIcon");
const elements_1 = require("../../../elements");
const SectionHeading = ({ children, permissionScope, styles, }) => {
    return ((0, jsx_runtime_1.jsx)(elements_1.Subtitle, { styles: { mb: 2.5, userSelect: 'none', ...styles }, children: (0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 2, items: "center", children: [children, permissionScope && ((0, jsx_runtime_1.jsx)(EditIcon_1.EditIcon, { small: true, permissionScope: permissionScope }))] }) }));
};
exports.SectionHeading = SectionHeading;
