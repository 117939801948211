"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FullName = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const useProfessionalView_1 = require("../../hooks/useProfessionalView");
const scopes_1 = require("../../scopes");
const FullName = () => {
    const { actor, data, trimAndUpdate, update } = (0, useProfessionalView_1.useProfessionalView)();
    const t = (0, translate_1.useTranslations)();
    const { fullName } = data;
    const scope = scopes_1.scopes.biography.fullName();
    if (!actor.can('read', scope)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(elements_1.Form.Input, { disabled: !actor.can('update', scope), label: t.professional_view.biography.name(), onBlur: trimAndUpdate('fullName'), onChange: update('fullName'), value: fullName }));
};
exports.FullName = FullName;
