"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profession = void 0;
const enums_1 = require("@bemlo/enums");
const profession_1 = require("../nb/profession");
const profession_2 = require("../sv/profession");
exports.profession = {
    ...profession_1.profession,
    ...profession_2.profession,
    [enums_1.Profession.ASSISTANT_NURSE]: {
        short: 'NA',
        title: {
            '*': 'Assistant nurses',
            '1': 'Assistant nurse',
        },
    },
    [enums_1.Profession.AUDIOLOGIST]: {
        short: 'AUD',
        title: {
            '*': 'Audiologists',
            '1': 'Audiologist',
        },
    },
    [enums_1.Profession.CARER]: {
        short: 'CAR',
        title: {
            '*': 'Carers',
            '1': 'Carer',
        },
    },
    [enums_1.Profession.DOCTOR]: {
        short: 'MD',
        title: {
            '*': 'Doctors',
            '1': 'Doctor',
        },
    },
    [enums_1.Profession.NURSE]: {
        short: 'RN',
        title: {
            '*': 'Nurses',
            '1': 'Nurse',
        },
    },
    [enums_1.Profession.SENIOR_CARER]: {
        short: 'SCAR',
        title: {
            '*': 'Senior carers',
            '1': 'Senior carer',
        },
    },
    [enums_1.Profession.PSYCHOTHERAPIST]: {
        short: 'PSTH',
        title: {
            '*': 'Psychotherapists',
            '1': 'Psychotherapist',
        },
    },
};
