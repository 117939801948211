"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EducationBadge = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const Badge_1 = require("./Badge");
const Circle_1 = require("./Circle");
const Div_1 = require("./Div");
const Flex_1 = require("./Flex");
const Tooltip_1 = require("./Tooltip");
const constants_1 = require("../constants");
const translate_1 = require("../translate");
const PROFESSION_COLOR_MAP = {
    [enums_1.Profession.ASSISTANT_NURSE]: constants_1.Color.GREEN,
    [enums_1.Profession.AUDIOLOGIST]: constants_1.Color.YELLOW,
    [enums_1.Profession.CARER]: constants_1.Color.VIOLET,
    [enums_1.Profession.DOCTOR]: constants_1.Color.RED,
    [enums_1.Profession.NURSE]: constants_1.Color.BLUE,
    [enums_1.Profession.PSYCHOTHERAPIST]: constants_1.Color.PINK,
    [enums_1.Profession.SENIOR_CARER]: constants_1.Color.DARK_PURPLE,
};
const EducationBadge = (props) => {
    const t = (0, translate_1.useTranslations)();
    const specializations = [...props.specializations]
        .filter((specialization) => !(0, utils_1.isNoneSpecialization)(specialization))
        .sort((a, b) => {
        if (a === props.preferredSpecialization) {
            return -1;
        }
        else if (b === props.preferredSpecialization) {
            return 1;
        }
        else {
            return 0;
        }
    });
    const specializationLabel = specializations.length
        ? `${t.$specializationEducation(specializations[0])}${specializations.length > 1 ? `, +${specializations.length - 1}` : ''}`
        : '';
    return ((0, jsx_runtime_1.jsx)(Flex_1.Flex, { gap: 2, children: (0, jsx_runtime_1.jsxs)(Tooltip_1.Tooltip, { children: [(0, jsx_runtime_1.jsxs)(Tooltip_1.Tooltip.Content, { intent: "info", side: "left", children: [t.$profession(props.profession), specializations.length > 0 && ', ', specializations
                            .map((specialization) => t.$specializationEducation(specialization))
                            .join(', ')] }), (0, jsx_runtime_1.jsx)(Tooltip_1.Tooltip.Trigger, { asChild: true, children: (0, jsx_runtime_1.jsxs)(Flex_1.Flex, { gap: 2, styles: { cursor: 'pointer' }, children: [(0, jsx_runtime_1.jsxs)(Badge_1.Badge, { color: "transparent", px: "10px", pill: true, size: props.size, children: [(0, jsx_runtime_1.jsx)(Circle_1.Circle, { size: 9, backgroundColor: PROFESSION_COLOR_MAP[props.profession] ?? constants_1.Color.GREY }), t.$professionShort(props.profession)] }), specializations.length > 0 && ((0, jsx_runtime_1.jsx)(Div_1.Div, { styles: { cursor: 'pointer' }, children: (0, jsx_runtime_1.jsx)(Badge_1.Badge, { color: "transparent", px: "10px", pill: true, children: specializationLabel }) }))] }) })] }) }));
};
exports.EducationBadge = EducationBadge;
