"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDropdownProps = exports.DropdownPropsContextProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const DropdownPropsContext = (0, react_1.createContext)(null);
const DropdownPropsContextProvider = (props) => {
    return ((0, jsx_runtime_1.jsx)(DropdownPropsContext.Provider, { value: props, children: props.children }));
};
exports.DropdownPropsContextProvider = DropdownPropsContextProvider;
const useDropdownProps = () => {
    const context = (0, react_1.useContext)(DropdownPropsContext);
    if (!context) {
        throw new Error('useDropdownProps must be used within the DropdownPropsContext.Provider');
    }
    return context;
};
exports.useDropdownProps = useDropdownProps;
