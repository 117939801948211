"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.swedenWhichRegionAlternative = exports.specialWhichRegionAlternative = void 0;
const enums_1 = require("@bemlo/enums");
exports.specialWhichRegionAlternative = {
    [enums_1.SpecialWhichRegionAlternative.ALL]: 'Alla',
    [enums_1.SpecialWhichRegionAlternative.NONE]: 'Ingen',
};
exports.swedenWhichRegionAlternative = {
    [enums_1.SwedenWhichRegionAlternative.BLEKINGE]: 'Blekinge',
    [enums_1.SwedenWhichRegionAlternative.DALARNA]: 'Dalarna',
    [enums_1.SwedenWhichRegionAlternative.GAVLEBORG]: 'Gävleborg',
    [enums_1.SwedenWhichRegionAlternative.GOTLAND]: 'Gotland',
    [enums_1.SwedenWhichRegionAlternative.HALLAND]: 'Halland',
    [enums_1.SwedenWhichRegionAlternative.JAMTLAND_HARJEDALEN]: 'Jämtland Härjedalen',
    [enums_1.SwedenWhichRegionAlternative.JONKOPING]: 'Jönköping',
    [enums_1.SwedenWhichRegionAlternative.KALMAR]: 'Kalmar',
    [enums_1.SwedenWhichRegionAlternative.KRONOBERG]: 'Kronoberg',
    [enums_1.SwedenWhichRegionAlternative.NORRBOTTEN]: 'Norrbotten',
    [enums_1.SwedenWhichRegionAlternative.OREBRO]: 'Örebro',
    [enums_1.SwedenWhichRegionAlternative.OSTERGOTLAND]: 'Östergötland',
    [enums_1.SwedenWhichRegionAlternative.SKANE]: 'Skåne',
    [enums_1.SwedenWhichRegionAlternative.SORMLAND]: 'Sörmland',
    [enums_1.SwedenWhichRegionAlternative.STOCKHOLM]: 'Stockholm',
    [enums_1.SwedenWhichRegionAlternative.UPPSALA]: 'Uppsala',
    [enums_1.SwedenWhichRegionAlternative.VARMLAND]: 'Värmland',
    [enums_1.SwedenWhichRegionAlternative.VASTERBOTTEN]: 'Västerbotten',
    [enums_1.SwedenWhichRegionAlternative.VASTERNORRLAND]: 'Västernorrland',
    [enums_1.SwedenWhichRegionAlternative.VASTMANLAND]: 'Västmanland',
    [enums_1.SwedenWhichRegionAlternative.VASTRAGOTALAND]: 'Västra Götaland',
};
