"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParent = exports.getBackendParent = exports.getBackendDepartments = exports.toBackendDepartment = void 0;
exports.getDepartments = getDepartments;
const lodash_1 = require("lodash");
const enums_1 = require("@bemlo/enums");
const enum_1 = require("./enum");
const { NO, SE, GB } = enums_1.Market;
const { DEPARTMENT, OPEN_FOR_ANYTHING, AKUTSJUKVARD, AMBULANS, FORLOSSNING, HEMSJUKVARD, MUNICIPAL, CENTER, JOURCENTRAL, RADIOLOGEN, ASIH, BAVA, HALSOCENTRAL, INFEKTION, MAMMOGRAFI, LUNGAVDELNING, HIA, REHABILITERING, HJARTAVDELNING, BARNMEDICIN, GASTROAVDELNING, ORTOPEDEN, NEONATAL, KVINNOKLINIK, SKOLSJUKVARD, ONH_MOTTAGNING, VACCINERING, TELEFONRADGIVNING, THORAXKIRURGEN, DIALYSMOTTAGNING, ONKOLOGEN, GERIATRIK, REUMATOLOGI, NJURAVDELNING, GYNEKOLOGDEPARTMENT, IVA, MEDICIN, MAVA, OPERATION, POSTOPERATION, NEUROLOGI, PSYKIATRI, SABO, DEMENS, VARDCENTRAL, SJUKSTUGA, NARAKUT, BARN_OCH_UNGDOM, KIRURGI, } = enums_1.WhichDepartmentToWorkInAlternative;
const DEPARTMENT_ALTERNATIVES = [
    {
        parent: DEPARTMENT,
        value: OPEN_FOR_ANYTHING,
        markets: [SE, NO, GB],
    },
    {
        parent: DEPARTMENT,
        value: DEPARTMENT,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: AKUTSJUKVARD,
        markets: [SE, NO],
    },
    {
        parent: DEPARTMENT,
        value: AMBULANS,
        markets: [SE, NO],
    },
    {
        parent: DEPARTMENT,
        value: FORLOSSNING,
        markets: [SE, NO],
    },
    {
        parent: MUNICIPAL,
        value: HEMSJUKVARD,
        markets: [SE, NO],
    },
    {
        parent: DEPARTMENT,
        value: IVA,
        markets: [SE, NO],
    },
    {
        parent: DEPARTMENT,
        value: MEDICIN,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: MAVA,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: OPERATION,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: POSTOPERATION,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: NEUROLOGI,
        markets: [SE, NO],
    },
    {
        parent: PSYKIATRI,
        value: PSYKIATRI,
        markets: [SE, NO],
    },
    {
        parent: MUNICIPAL,
        value: SABO,
        markets: [SE, NO],
    },
    {
        parent: MUNICIPAL,
        value: DEMENS,
        markets: [SE],
    },
    {
        parent: CENTER,
        value: VARDCENTRAL,
        markets: [SE, NO],
    },
    {
        parent: CENTER,
        value: SJUKSTUGA,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: NARAKUT,
        markets: [SE],
    },
    {
        parent: CENTER,
        value: BARN_OCH_UNGDOM,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: KIRURGI,
        markets: [SE, NO],
    },
    {
        parent: DEPARTMENT,
        value: RADIOLOGEN,
        markets: [SE],
    },
    {
        parent: CENTER,
        value: HALSOCENTRAL,
        markets: [SE, NO],
    },
    {
        parent: DEPARTMENT,
        value: INFEKTION,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: HJARTAVDELNING,
        markets: [SE, NO],
    },
    {
        parent: DEPARTMENT,
        value: HIA,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: GASTROAVDELNING,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: ASIH,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: BARNMEDICIN,
        markets: [SE, NO],
    },
    {
        parent: DEPARTMENT,
        value: BAVA,
        markets: [SE],
    },
    {
        parent: CENTER,
        value: DIALYSMOTTAGNING,
        markets: [SE],
    },
    {
        parent: CENTER,
        value: JOURCENTRAL,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: GERIATRIK,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: REUMATOLOGI,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: REHABILITERING,
        markets: [SE, NO],
    },
    {
        parent: DEPARTMENT,
        value: GYNEKOLOGDEPARTMENT,
        markets: [SE],
    },
    {
        parent: CENTER,
        value: KVINNOKLINIK,
        markets: [SE],
    },
    {
        parent: CENTER,
        value: MAMMOGRAFI,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: LUNGAVDELNING,
        markets: [SE, NO],
    },
    {
        parent: DEPARTMENT,
        value: NJURAVDELNING,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: NEONATAL,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: ORTOPEDEN,
        markets: [SE, NO],
    },
    {
        parent: DEPARTMENT,
        value: ONKOLOGEN,
        markets: [SE],
    },
    {
        parent: DEPARTMENT,
        value: THORAXKIRURGEN,
        markets: [SE],
    },
    {
        parent: MUNICIPAL,
        value: MUNICIPAL,
        markets: [SE],
    },
    {
        parent: CENTER,
        value: CENTER,
        markets: [SE],
    },
    {
        parent: CENTER,
        value: ONH_MOTTAGNING,
        markets: [SE, NO],
    },
    {
        parent: CENTER,
        value: VACCINERING,
        markets: [SE],
    },
    {
        parent: CENTER,
        value: TELEFONRADGIVNING,
        markets: [SE],
    },
    {
        parent: SKOLSJUKVARD,
        value: SKOLSJUKVARD,
        markets: [SE],
    },
];
const toBackendDepartment = (department) => {
    const backendDepartment = enums_1.WhichDepartmentToWorkInAlternative[department];
    if (!backendDepartment) {
        throw new Error(`Could not find backend department for ${department}`);
    }
    return backendDepartment;
};
exports.toBackendDepartment = toBackendDepartment;
const byMarket = ({ market }) => (alternative) => !market || alternative.markets.includes(market);
const byIsOpenForAll = ({ includeIsOpenForAll }) => (alternative) => includeIsOpenForAll ||
    alternative.value !== enums_1.WhichDepartmentToWorkInAlternative.OPEN_FOR_ANYTHING;
const byParent = ({ parents }) => (alternative) => !parents || parents.includes(alternative.parent);
const filterDepartmentsWith = (filter) => DEPARTMENT_ALTERNATIVES.filter(byMarket(filter))
    .filter(byIsOpenForAll(filter))
    .filter(byParent(filter));
const getBackendDepartments = (filter = {}) => {
    return filterDepartmentsWith(filter).map(({ value }) => value);
};
exports.getBackendDepartments = getBackendDepartments;
function getDepartments({ enum: _, parents, ...rest } = { enum: {} }) {
    const filter = {
        ...rest,
        parents: parents?.map((parent) => (0, exports.toBackendDepartment)(parent)),
    };
    const departments = filterDepartmentsWith(filter).map(({ value }) => value);
    const invertedWhichDepartmentsToWorkIn = (0, lodash_1.invert)(enums_1.WhichDepartmentToWorkInAlternative);
    return departments.map((value) => invertedWhichDepartmentsToWorkIn[value]);
}
const getBackendParent = (department) => {
    return DEPARTMENT_ALTERNATIVES.find(({ value }) => value === department)
        .parent;
};
exports.getBackendParent = getBackendParent;
const getParent = (department) => {
    const { parent } = DEPARTMENT_ALTERNATIVES.find(({ value }) => value === (0, exports.toBackendDepartment)(department));
    // keyOf returns the key of the enum value to match the generated enums in graphql.ts
    return (0, enum_1.keyOf)(enums_1.WhichDepartmentToWorkInAlternative, parent);
};
exports.getParent = getParent;
