"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiographyHeader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const Flex_1 = require("../../../elements/Flex");
const translate_1 = require("../../../translate");
const EditIcon_1 = require("../components/EditIcon");
const useProfessionalView_1 = require("../hooks/useProfessionalView");
const CANDIDATE_BADGE_COLORS = {
    [enums_1.CandidateType.BOOKED]: 'dark-green',
    [enums_1.CandidateType.MANAGED]: 'dark-blue',
    [enums_1.CandidateType.MIRRORED]: 'blue',
    [enums_1.CandidateType.SOVEREIGN]: 'blue',
    [enums_1.CandidateType.STATIC]: 'purple',
    [enums_1.CandidateType.SYNCED]: 'medium-green',
    [enums_1.CandidateType.EMPLOYED]: 'yellow',
};
const BiographyHeader = ({ onSendMessageClick, }) => {
    const { actor, data, isMe } = (0, useProfessionalView_1.useProfessionalView)();
    const t = (0, translate_1.useTranslations)();
    const { fullName, homeCountry, homeMunicipality, hpr, profession, specializations, updatedAt, } = data;
    const locationText = [
        homeMunicipality,
        homeCountry && t.$country(homeCountry),
    ]
        .filter(utils_1.isTruthy)
        .join(', ');
    const specializationText = specializations?.length >= 1
        ? `${specializations
            .slice(0, 2)
            .map((specialization) => t.$specialization(specialization))
            .join(', ')}${specializations.length > 2
            ? ` ${t.professional_view.biography.and_n_more(specializations.length - 2)}`
            : ''}`
        : null;
    return ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { items: "start", justify: "between", styles: { w: 'full' }, children: [(0, jsx_runtime_1.jsx)(Flex_1.Flex, { gap: 3.5, items: "start", children: (0, jsx_runtime_1.jsxs)(Flex_1.Flex, { col: true, gap: 2, items: "start", children: [(0, jsx_runtime_1.jsxs)(Flex_1.Flex, { gap: 2, items: "center", styles: { lineHeight: 1, pt: 2 }, wrap: true, children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, size: 2, children: fullName }), !isMe && ((0, jsx_runtime_1.jsx)(elements_1.Badge, { color: CANDIDATE_BADGE_COLORS[data.candidateType], size: "sm", children: t.professional_view.candidate_type(data.candidateType) })), profession && ((0, jsx_runtime_1.jsx)(elements_1.Badge, { color: "blue", size: "sm", children: t.$profession(profession) })), hpr?.isAuthorized && ((0, jsx_runtime_1.jsx)(elements_1.Badge, { color: "green", icon: "CheckIcon", size: "sm", children: t.professional_view.base.authorized() }))] }), (0, jsx_runtime_1.jsxs)(Flex_1.Flex, { col: true, styles: { lineHeight: 1.6 }, children: [specializationText && ((0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, color: constants_1.Color.BLUE, size: 5, children: specializationText })), locationText && ((0, jsx_runtime_1.jsx)(elements_1.Text, { color: constants_1.Color.DARK_GREY, size: 6, children: locationText })), (!isMe || !locationText) && ((0, jsx_runtime_1.jsxs)(elements_1.Text, { color: constants_1.Color.DARK_GREY, size: 6, children: [t.professional_view.base.last_updated(), ' ', t.$date.unix(updatedAt).fromNow()] }))] }), actor.can('contact', 'candidate') && ((0, jsx_runtime_1.jsx)(elements_1.Button, { icon: "ChatAltIcon", onClick: onSendMessageClick, size: "sm", children: t.professional_view.biography.send_message() }))] }) }), actor.can('update', 'biography') && ((0, jsx_runtime_1.jsx)(EditIcon_1.EditIcon, { permissionScope: "biography" }))] }));
};
exports.BiographyHeader = BiographyHeader;
