"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromNow = void 0;
/**
 * Returns a string representing the relative time between two dates,
 * such as 'a day ago', 'in 2 days' or '3 months ago'.
 *
 * @param options.currentDate
 * The date to count from.
 *
 * @param options.prefix
 * An optional string to prepend to the relative time string.
 * If specified as an array, the second element is used for future dates.
 *
 * @param options.referenceDate
 * The date to count to.
 *
 * @example
 * fromNow({
 *   currentDate: dayjs('2000-01-02'),
 *   prefix: 'Expiry date:',
 *   referenceDate: dayjs('2000-01-01'),
 * })
 * // 'Expiry date: a day ago'
 *
 * @example
 * fromNow({
 *   currentDate,
 *   prefix: ['Closed', 'Closes'],
 *   referenceDate,
 * })
 * // 'Closed an hour ago'
 * // 'Closes in an hour'
 */
const fromNow = (options) => {
    const { currentDate, prefix, referenceDate } = options;
    const isFuture = referenceDate.isAfter(currentDate);
    const prefixString = Array.isArray(prefix)
        ? `${isFuture ? (prefix[1] ?? prefix[0]) : prefix[0]} `
        : prefix
            ? `${prefix} `
            : '';
    const fromNowString = referenceDate.from(currentDate);
    return `${prefixString}${fromNowString}`;
};
exports.fromNow = fromNow;
