import { MenuItemArea } from '../../config/enums'
import { menuItemsFromConfig } from '../../config/menuItems'
import { Profession } from '../../generated/graphql'

export const useMenuItems = ({
  profession,
  menuItemArea,
}: {
  profession?: Profession
  menuItemArea?: MenuItemArea
} = {}) => menuItemsFromConfig({ profession, menuItemArea })
