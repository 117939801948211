"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JournalSystem = void 0;
var JournalSystem;
(function (JournalSystem) {
    JournalSystem["TAKECARE"] = "TAKECARE";
    JournalSystem["COSMIC"] = "COSMIC";
    JournalSystem["PMO"] = "PMO";
    JournalSystem["PROCAPITA"] = "PROCAPITA";
    JournalSystem["MELIOR"] = "MELIOR";
    /**  NOTE: This is a typo, it should be called OBSTETRIX */
    JournalSystem["OBSTERIX"] = "OBSTERIX";
    JournalSystem["NCSCROSS"] = "NCSCROSS";
    JournalSystem["BMS"] = "BMS";
    JournalSystem["ASYNJAVISPH"] = "ASYNJAVISPH";
    JournalSystem["VAS"] = "VAS";
    JournalSystem["IMX"] = "IMX";
    JournalSystem["PRATOR"] = "PRATOR";
    JournalSystem["MILLENNIUM"] = "MILLENNIUM";
    JournalSystem["COMBINE"] = "COMBINE";
})(JournalSystem || (exports.JournalSystem = JournalSystem = {}));
