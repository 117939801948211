"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftAvailability = void 0;
var ShiftAvailability;
(function (ShiftAvailability) {
    /**
     * Resource has already been booked for a shift that conflicts with the requested shift.
     */
    ShiftAvailability["CONFLICTING_SHIFT"] = "CONFLICTING_SHIFT";
    /**
     * Resource has scheduled leave that conflicts with the requested shift.
     */
    ShiftAvailability["SCHEDULED_LEAVE"] = "SCHEDULED_LEAVE";
    /**
     * Resource has self-reported as unavailable for the period or shift.
     */
    ShiftAvailability["PREFERRED_UNAVAILABLE"] = "PREFERRED_UNAVAILABLE";
    /**
     * There is no data available to determine the resource's availability.
     */
    ShiftAvailability["NO_DATA"] = "NO_DATA";
    /**
     * Resource has applied for a similar shift which indicates they are likely to be available.
     */
    ShiftAvailability["APPLIED_SIMILAR_SHIFT"] = "APPLIED_SIMILAR_SHIFT";
    /**
     * Resource has reported themselves available for at least part of the requested shift.
     */
    ShiftAvailability["PARTIAL_AVAILABILITY"] = "PARTIAL_AVAILABILITY";
    /**
     * Resource has reported themselves available for the entire requested shift.
     */
    ShiftAvailability["FULL_AVAILABILITY"] = "FULL_AVAILABILITY";
    /**
     * Resource is a consultant who has been booked to be available during the requested shift.
     */
    ShiftAvailability["TENDERED_AVAILABILITY"] = "TENDERED_AVAILABILITY";
    /**
     * Resource has is scheduled to work during the requested shift.
     */
    ShiftAvailability["SCHEDULED_AVAILABILITY"] = "SCHEDULED_AVAILABILITY";
})(ShiftAvailability || (exports.ShiftAvailability = ShiftAvailability = {}));
