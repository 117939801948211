"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchedulingPeriodStatus = void 0;
var SchedulingPeriodStatus;
(function (SchedulingPeriodStatus) {
    SchedulingPeriodStatus["PLANNED"] = "PLANNED";
    SchedulingPeriodStatus["CREATED"] = "CREATED";
    SchedulingPeriodStatus["SCHEDULED"] = "SCHEDULED";
    SchedulingPeriodStatus["COMPLETED"] = "COMPLETED";
})(SchedulingPeriodStatus || (exports.SchedulingPeriodStatus = SchedulingPeriodStatus = {}));
