"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateProfessionShortFactory = exports.translateProfessionFactory = void 0;
const locales_1 = require("../locales");
const translateProfessionFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('profession', locale);
    return (profession, count = 1) => {
        // Note: casting to * is fine, because we have a fall back if real count doesn't exist
        return t[profession].title[count] ?? t[profession].title['*'];
    };
};
exports.translateProfessionFactory = translateProfessionFactory;
const translateProfessionShortFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('profession', locale);
    return (profession) => {
        return t[profession].short;
    };
};
exports.translateProfessionShortFactory = translateProfessionShortFactory;
