"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OVERVIEW_GRID_TEMPLATE_COLUMNS = exports.getGridTemplateColumns = void 0;
const getGridTemplateColumns = (view, viewState) => {
    switch (view) {
        case 'tender':
            return getTenderScheduleGridTemplateColumns(viewState);
        case 'presentation':
            return getPresentationGridTemplateColumns(viewState);
        case 'acceptance':
            return getAcceptanceGridTemplateColumns(viewState);
        case 'booking':
            return getBookingScheduleGridTemplateColumns(viewState);
    }
};
exports.getGridTemplateColumns = getGridTemplateColumns;
const getTenderScheduleGridTemplateColumns = (viewState) => {
    switch (viewState) {
        case 'edit':
            return 'auto  minmax(100px, 1fr) 20px 100px 90px minmax(280px, 2fr)';
        case 'view':
            return 'minmax(100px, 1fr) 20px 100px 90px minmax(280px, 2fr)';
    }
};
const getPresentationGridTemplateColumns = (viewState) => {
    switch (viewState) {
        case 'edit':
            return 'auto minmax(100px, 1fr) 20px 130px 130px 90px minmax(280px, 2fr)';
        case 'view':
            return 'minmax(100px, 1fr) 20px 130px 130px 90px minmax(280px, 2fr)';
    }
};
const getAcceptanceGridTemplateColumns = (viewState) => {
    switch (viewState) {
        case 'edit':
            return 'auto minmax(100px, 1fr) 20px 130px 130px 90px minmax(280px, 2fr)';
        case 'view':
            return 'minmax(100px, 1fr) 20px 130px 90px minmax(280px, 2fr)';
    }
};
const getBookingScheduleGridTemplateColumns = (viewState) => {
    switch (viewState) {
        case 'edit':
            return 'auto minmax(100px, 1fr) 130px 90px minmax(280px, 2fr)';
        case 'view':
            return 'minmax(100px, 1fr) 130px 90px minmax(280px, 2fr)';
    }
};
exports.OVERVIEW_GRID_TEMPLATE_COLUMNS = '30px minmax(150px, 1fr) 60px 60px minmax(100px, 120px) minmax(280px, 3fr)';
