"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNegation = void 0;
const NEGATED_OPERATIONS = [
    'not_includes',
    'includes_none_of',
    'is_not',
    'is_not_part_of',
];
const isNegation = (type) => NEGATED_OPERATIONS.includes(type);
exports.isNegation = isNegation;
