"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timesToLength = void 0;
const date_1 = require("@bemlo/date");
/**
 * Calculate the length of a shift in hours or minutes
 * @param times start of shift, HH:mm and end of shift, HH:mm
 * @param unit 'hours' or 'minutes'
 * @returns length in specified unit
 */
const timesToLength = (
// TODO(BEMLO-3368): refactor this to use Dayjs shift!
times, unit) => {
    const start = (0, date_1.dayjsEn)().time(times.startTime).second(0).millisecond(0);
    const end = (0, date_1.dayjsEn)().time(times.endTime).second(0).millisecond(0);
    return (end.isBefore(start) ? end.add(1, 'day') : end).diff(start, unit, true);
};
exports.timesToLength = timesToLength;
