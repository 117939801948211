"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BOOKED_DAY_STYLE = exports.REJECTED_DAY_STYLE = exports.ACCEPTED_DAY_STYLE = exports.VIEW_PRESENTED_DAY_STYLE = exports.VIEW_TENDERED_DAY_STYLE = exports.DISABLED_DAY_STYLE = exports.EMPTY_SCOPE = exports.WEEK_DAY_INDICES = exports.ALL_AVAILABILITY_SHIFT_HOURS = exports.EMPTY_ARRAY = void 0;
const enums_1 = require("@bemlo/enums");
const constants_1 = require("../../../constants");
exports.EMPTY_ARRAY = [];
Object.freeze(exports.EMPTY_ARRAY);
exports.ALL_AVAILABILITY_SHIFT_HOURS = [
    enums_1.ShiftHours.DAY,
    enums_1.ShiftHours.EVENING,
    enums_1.ShiftHours.NIGHT,
];
Object.freeze(exports.ALL_AVAILABILITY_SHIFT_HOURS);
// Monday to Sunday
// TODO: We might want to adapt for locale later
exports.WEEK_DAY_INDICES = [0, 1, 2, 3, 4, 5, 6];
Object.freeze(exports.WEEK_DAY_INDICES);
exports.EMPTY_SCOPE = {
    scope: 0,
    shiftHours: exports.EMPTY_ARRAY,
};
Object.freeze(exports.EMPTY_SCOPE);
exports.DISABLED_DAY_STYLE = {
    backgroundColor: constants_1.Color.DARK_MEDIUM_GREY,
    color: constants_1.Color.DARKEST_MEDIUM_GREY,
};
exports.VIEW_TENDERED_DAY_STYLE = {
    backgroundColor: constants_1.Color.WHITE,
    color: constants_1.Color.DARK_GREY,
};
Object.freeze(exports.VIEW_TENDERED_DAY_STYLE);
exports.VIEW_PRESENTED_DAY_STYLE = {
    backgroundColor: constants_1.Color.WHITE,
    color: constants_1.Color.DARKER_GREY,
};
exports.ACCEPTED_DAY_STYLE = {
    backgroundColor: constants_1.Color.GREEN,
    color: constants_1.Color.WHITE,
    icon: {
        name: 'CheckCircleIcon',
        design: 'solid',
    },
};
Object.freeze(exports.ACCEPTED_DAY_STYLE);
exports.REJECTED_DAY_STYLE = {
    backgroundColor: constants_1.Color.RED,
    color: constants_1.Color.WHITE,
    icon: {
        name: 'XCircleIcon',
        design: 'outline',
    },
};
exports.BOOKED_DAY_STYLE = exports.ACCEPTED_DAY_STYLE;
Object.freeze(exports.BOOKED_DAY_STYLE);
