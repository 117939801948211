"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JournalSystems = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const Detail_1 = require("../../components/Detail");
const useProfessionalView_1 = require("../../hooks/useProfessionalView");
const useProfessionalViewAlternatives_1 = require("../../hooks/useProfessionalViewAlternatives");
const scopes_1 = require("../../scopes");
const JournalSystems = () => {
    const { actor, data, isEditing, update } = (0, useProfessionalView_1.useProfessionalView)();
    const t = (0, translate_1.useTranslations)();
    const { journalSystems } = data;
    const { journalSystemAlternatives } = (0, useProfessionalViewAlternatives_1.useProfessionalViewAlternatives)();
    const scope = scopes_1.scopes.biography.qualifications.journalSystems();
    if (!actor.can('read', scope)) {
        return null;
    }
    if (isEditing(scope)) {
        return ((0, jsx_runtime_1.jsx)(elements_1.MultiSelect, { disabled: !actor.can('update', scope), label: t.professional_view.biography.journal_systems(), onChange: update('journalSystems'), options: journalSystemAlternatives, value: journalSystems }));
    }
    if (!journalSystems?.length) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: journalSystems
            .map((journalSystem) => t.$journalSystem(journalSystem))
            .join(', '), heading: t.professional_view.biography.journal_system_other(), icon: "DesktopComputerIcon" }));
};
exports.JournalSystems = JournalSystems;
