"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.presentedScope = exports.presentedShiftHours = exports.isAvailabilityWeekPresented = exports.isDayPresented = void 0;
const constants_1 = require("../utils/constants");
const isDayPresented = (day) => !!day.presentedDayId;
exports.isDayPresented = isDayPresented;
const isAvailabilityWeekPresented = (availability) => !!availability.presentedAvailability;
exports.isAvailabilityWeekPresented = isAvailabilityWeekPresented;
const presentedShiftHours = (availability) => availability.presentedAvailability?.scope.shiftHours ?? constants_1.EMPTY_ARRAY;
exports.presentedShiftHours = presentedShiftHours;
const presentedScope = (availability) => availability.presentedAvailability?.scope.scope;
exports.presentedScope = presentedScope;
