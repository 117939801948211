"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tenderWaiverStatus = void 0;
const enums_1 = require("@bemlo/enums");
exports.tenderWaiverStatus = {
    [enums_1.TenderWaiverStatus.APPROVED]: 'Godkänd',
    [enums_1.TenderWaiverStatus.REJECTED]: 'Avvisad',
    [enums_1.TenderWaiverStatus.PENDING]: 'Skickad',
    [enums_1.TenderWaiverStatus.USED]: 'Stängd',
};
