"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormattedText = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/*
  Should be Markdown compatible (subset), so that if needed, we can switch it out for a Markdown
  renderer.
*/
const FormattedText = ({ children }) => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children.split('**').map((part, index) => {
            if (index % 2 === 0) {
                return part;
            }
            return (0, jsx_runtime_1.jsx)("b", { children: part }, index);
        }) }));
};
exports.FormattedText = FormattedText;
