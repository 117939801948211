"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBookingDayCellStatus = void 0;
const utils_1 = require("./utils");
const utils_2 = require("../presentation/utils");
const utils_3 = require("../tender/utils");
const constants_1 = require("../utils/constants");
const getBookingDayCellStatus = (day, _isWeekSelected, _viewState) => {
    // There is no edit view as of now
    const dayTendered = (0, utils_3.isDayTendered)(day);
    const dayPresented = (0, utils_2.isDayPresented)(day);
    const dayBooked = (0, utils_1.isDayBooked)(day);
    if (dayBooked)
        return constants_1.BOOKED_DAY_STYLE;
    if (dayPresented)
        return constants_1.REJECTED_DAY_STYLE;
    if (dayTendered)
        return constants_1.VIEW_TENDERED_DAY_STYLE;
    return constants_1.DISABLED_DAY_STYLE;
};
exports.getBookingDayCellStatus = getBookingDayCellStatus;
