"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dayjs = void 0;
exports.dayjs = {
    calendar: {
        lastDay: '[I går] HH:mm', // I går 14:30
        lastWeek: '[Forrige] dddd HH:mm', // Senaste Mandag 14:30
        nextDay: '[I morgen] HH:mm', // I morgon 14:30
        nextWeek: 'dddd HH:mm', // Søndag 14:30
        sameDay: '[I dag] HH:mm', // I dag 14:30
        sameElse: 'DD.MM.YYYY', // 13.03.1990
    },
    custom: {
        completeDate: 'YYYY-MM-DD', // 2011-10-17
        completeDateWithTime: 'YYYY-MM-DD HH:mm', // 2011-10-17 14:30
        monthDay: 'D MMM', // 12 Mars
        monthDayTime: 'D MMM HH:mm', // 12 Mars 14:30
        weekdayDate: 'ddd, D MMM GGGG', // Tis, 12 Mars 1990
    },
    formatDuration: {
        hours: 'H[h]',
        hoursMinutes: 'H[h] m[m]',
        minutes: 'm[m]',
    },
    formatWeek: {
        useWeekBeginning: false,
        week: '[u]W', // v12
        weekWithYear: '[u]W GGGG', // v12 2023
        weekWithoutPrefix: 'W', // 12
    },
};
