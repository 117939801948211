"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Municipalities = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const Detail_1 = require("../components/Detail");
const useProfessionalView_1 = require("../hooks/useProfessionalView");
const useProfessionalViewAlternatives_1 = require("../hooks/useProfessionalViewAlternatives");
const scopes_1 = require("../scopes");
const Municipalities = () => {
    const { actor, data, isEditing, patchEditData } = (0, useProfessionalView_1.useProfessionalView)();
    const { municipalityAlternatives } = (0, useProfessionalViewAlternatives_1.useProfessionalViewAlternatives)();
    const t = (0, translate_1.useTranslations)();
    if (!municipalityAlternatives.length) {
        return null;
    }
    const scope = scopes_1.scopes.interests.municipalities();
    if (!actor.can('read', scope)) {
        return null;
    }
    const label = t.professional_view.interests.municipality_other();
    const icon = 'HomeIcon';
    const { municipalities } = data;
    if (isEditing(scope)) {
        return ((0, jsx_runtime_1.jsx)(elements_1.MultiSelect, { disabled: !actor.can('update', scope), label: label, labelIcon: icon, onChange: (municipalities) => patchEditData({ municipalities }), options: municipalityAlternatives, value: municipalities }));
    }
    const content = municipalities
        .map((municipality) => t.$municipality(municipality))
        .join(', ');
    return (0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: content, heading: label, icon: icon });
};
exports.Municipalities = Municipalities;
