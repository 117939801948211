"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Location = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const useProfessionalView_1 = require("../../hooks/useProfessionalView");
const scopes_1 = require("../../scopes");
const Location = () => {
    const { actor, data, update } = (0, useProfessionalView_1.useProfessionalView)();
    const t = (0, translate_1.useTranslations)();
    const { homeCountry, homeMunicipality } = data;
    const countryScope = scopes_1.scopes.biography.homeCountry();
    const municipalityScope = scopes_1.scopes.biography.homeMunicipality();
    const currentScopes = [countryScope, municipalityScope];
    if (currentScopes.every((scope) => !actor.can('read', scope))) {
        return null;
    }
    const municipalityOptions = (0, utils_1.getMunicipalities)({
        country: homeCountry ?? enums_1.Country.SWEDEN,
    })
        .map((value) => ({
        key: value,
        label: t.$municipality(value),
        value,
    }))
        .sort((a, b) => a.label.localeCompare(b.label, t.$locale));
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 3.5, wrap: true, children: [actor.can('read', countryScope) && ((0, jsx_runtime_1.jsx)(elements_1.Combobox, { clearable: true, disabled: !actor.can('update', countryScope), label: t.professional_view.biography.country(), onChange: update('homeCountry'), options: Object.entries(enums_1.Country).map(([key, value]) => ({
                    key,
                    label: t.$country(value),
                    value,
                })), value: homeCountry })), actor.can('read', municipalityScope) && ((0, jsx_runtime_1.jsx)(elements_1.Combobox, { clearable: true, disabled: !actor.can('update', municipalityScope), label: t.professional_view.biography.home_municipality(), onChange: update('homeMunicipality'), options: municipalityOptions, value: homeMunicipality }))] }));
};
exports.Location = Location;
