"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScheduleViolations = void 0;
const overlapping_shifts_1 = require("../rules/overlapping-shifts");
const rest_between_shifts_1 = require("../rules/rest-between-shifts");
const create_shifts_by_date_schedule_1 = require("../utils/create-shifts-by-date-schedule");
const dayjsShiftByTimeComparator = (a, b) => {
    if (a.startDateTime.isBefore(b.startDateTime))
        return -1;
    if (a.startDateTime.isAfter(b.startDateTime))
        return 1;
    if (a.endDateTime.isBefore(b.endDateTime))
        return -1;
    if (a.endDateTime.isAfter(b.endDateTime))
        return 1;
    return a.breakTime - b.breakTime;
};
const getScheduleViolations = (schedule, ruleSet) => {
    const scheduleByDate = (0, create_shifts_by_date_schedule_1.scheduleToShiftsByDate)(schedule);
    const violations = {};
    scheduleByDate.forEach((dateShifts) => {
        if (!dateShifts.length)
            return;
        const previousDayShifts = scheduleByDate.get(dateShifts[0].startDateTime.subtract(1, 'day').format('YYYY-MM-DD'));
        const sortedPossibleViolations = [
            ...dateShifts,
            ...(previousDayShifts ?? []),
        ].sort(dayjsShiftByTimeComparator);
        for (const shift of dateShifts) {
            for (const possibleConflictingShift of sortedPossibleViolations) {
                if (shift.id === possibleConflictingShift.id)
                    continue;
                if (violations[shift.id]?.some((violation) => violation.violatingShift.id === possibleConflictingShift.id)) {
                    continue;
                }
                const overlappingShiftsViolation = (0, overlapping_shifts_1.overlappingShiftsViolationFor)(shift, possibleConflictingShift);
                if (overlappingShiftsViolation) {
                    if (!violations[shift.id]) {
                        violations[shift.id] = [];
                    }
                    if (!violations[possibleConflictingShift.id]) {
                        violations[possibleConflictingShift.id] = [];
                    }
                    violations[overlappingShiftsViolation[0].shiftId].push(overlappingShiftsViolation[0]);
                    violations[overlappingShiftsViolation[1].shiftId].push(overlappingShiftsViolation[1]);
                    continue;
                }
                const restTimeViolation = (0, rest_between_shifts_1.restTimeBetweenViolationFor)(shift, possibleConflictingShift, ruleSet);
                if (restTimeViolation) {
                    if (!violations[shift.id]) {
                        violations[shift.id] = [];
                    }
                    if (!violations[possibleConflictingShift.id]) {
                        violations[possibleConflictingShift.id] = [];
                    }
                    violations[restTimeViolation[0].shiftId].push(restTimeViolation[0]);
                    violations[restTimeViolation[1].shiftId].push(restTimeViolation[1]);
                }
            }
        }
    });
    return Object.values(violations).flat();
};
exports.getScheduleViolations = getScheduleViolations;
