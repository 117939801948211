"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.localeForMarket = localeForMarket;
exports.customerIoForLocale = customerIoForLocale;
const enums_1 = require("@bemlo/enums");
const marketLocaleRecord = {
    [enums_1.Market.DK]: enums_1.Locale.DA,
    [enums_1.Market.FI]: enums_1.Locale.FI,
    [enums_1.Market.GB]: enums_1.Locale.EN,
    [enums_1.Market.NO]: enums_1.Locale.NB,
    [enums_1.Market.SE]: enums_1.Locale.SV,
};
function localeForMarket(market) {
    const locale = marketLocaleRecord[market];
    if (!locale) {
        throw new Error(`No locale found for "${market}"`);
    }
    return locale;
}
const localeCustomerIoRecord = {
    [enums_1.Locale.DA]: 'da',
    [enums_1.Locale.FI]: 'fi',
    [enums_1.Locale.EN]: 'en',
    [enums_1.Locale.NB]: 'nb',
    [enums_1.Locale.SV]: 'sv',
};
function customerIoForLocale(locale) {
    const customerIoLocale = localeCustomerIoRecord[locale];
    if (!customerIoLocale) {
        throw new Error(`No customerIo locale found for "${locale}"`);
    }
    return customerIoLocale;
}
