"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkAddShiftToExistingScheduleViolations = void 0;
const overlapping_shifts_1 = require("../rules/overlapping-shifts");
const rest_between_shifts_1 = require("../rules/rest-between-shifts");
const weekly_rest_1 = require("../rules/weekly-rest");
const checkAddShiftToExistingScheduleViolations = (newShift, schedule, ruleSet) => {
    const overlappingShiftsViolations = (0, overlapping_shifts_1.overlappingShiftsViolationWhenAddingShiftToExistingSchedule)(newShift, schedule);
    const overlappingShiftIdsSet = new Set(overlappingShiftsViolations.map((violation) => violation.violatingShift.id));
    // Does not really make sense to check daily rest time for overlapping shifts.
    const nonOverlappingSchedule = schedule.filter((shift) => !overlappingShiftIdsSet.has(shift.id));
    const restTimeBetweenShiftsViolations = (0, rest_between_shifts_1.restTimeBetweenViolationWhenAddingShiftToExistingSchedule)(newShift, nonOverlappingSchedule, ruleSet);
    const uninterruptedRestTimeViolations = (0, weekly_rest_1.uninterruptedRestViolationsFor)([...schedule, newShift], ruleSet);
    return {
        overlappingShiftsViolations,
        restTimeBetweenShiftsViolations,
        uninterruptedRestTimeViolations,
    };
};
exports.checkAddShiftToExistingScheduleViolations = checkAddShiftToExistingScheduleViolations;
