"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateShiftTypeFactory = void 0;
const locales_1 = require("../locales");
const translateShiftTypeFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('shiftType', locale);
    return (shiftType) => {
        return t[shiftType];
    };
};
exports.translateShiftTypeFactory = translateShiftTypeFactory;
