import { Enumerable } from '@bemlo/utils'

export const ENVIRONMENTS = [
  'development',
  'test',
  'stage',
  'production',
] as const

export type Env = (typeof ENVIRONMENTS)[number]

export function isEnv(env: Enumerable<Env>) {
  if (Array.isArray(env)) {
    return env.includes(process.env.GATSBY_ENV as Env)
  }
  return getEnv() === env
}

export function getEnv() {
  return process.env.GATSBY_ENV as Env
}
