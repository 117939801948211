import { I18nContext } from '@bemlo/ui'
import { ReactNode } from 'react'

import translations, { locale } from './locales/__LOCALE__'

type Translations = typeof translations

interface I18nProviderProps {
  children: ReactNode
}

const value = {
  locale,
  setLocale: () => undefined,
}

export const I18nProvider = ({ children }: I18nProviderProps) => {
  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>
}

export const getTranslation = <TNamespace extends keyof Translations>(
  namespace?: TNamespace,
) => {
  return {
    ...translations.base,
    ...((namespace
      ? translations[namespace]
      : undefined) as Translations[TNamespace]),
  }
}

export const useTranslation = getTranslation
export { locale } from './locales/__LOCALE__'
