"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingAvailabilityHeaderRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const AvailabilityRow_1 = require("../AvailabilityRow");
const ShiftHoursCell_1 = require("../molecules/ShiftHoursCell");
const WeekDaysRow_1 = require("../molecules/WeekDaysRow");
const get_grid_template_columns_1 = require("../utils/get-grid-template-columns");
const EMPTY_ARRAY = [];
Object.freeze(EMPTY_ARRAY);
const BookingAvailabilityHeaderRow = () => {
    const gridTemplateColumns = (0, get_grid_template_columns_1.getGridTemplateColumns)('booking', 'view');
    const t = (0, translate_1.useTranslations)();
    return ((0, jsx_runtime_1.jsxs)(AvailabilityRow_1.GridRowHeaderStyles, { gridTemplateColumns: gridTemplateColumns, children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, children: t.common.period() }), (0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, children: t.common.scope() }), (0, jsx_runtime_1.jsx)(ShiftHoursCell_1.ShiftHoursHeader, { viewState: 'view', availabilities: EMPTY_ARRAY }), (0, jsx_runtime_1.jsx)(WeekDaysRow_1.WeekDaysRowHeader, { viewState: 'view', availabilities: EMPTY_ARRAY })] }));
};
exports.BookingAvailabilityHeaderRow = BookingAvailabilityHeaderRow;
