"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.professionsForMarkets = professionsForMarkets;
exports.professionForSpecialization = professionForSpecialization;
const enums_1 = require("@bemlo/enums");
const specialization_1 = require("./specialization");
const MARKET_PROFESSIONS_RECORD = {
    [enums_1.Market.DK]: [
        enums_1.Profession.ASSISTANT_NURSE,
        enums_1.Profession.DOCTOR,
        enums_1.Profession.NURSE,
    ],
    [enums_1.Market.FI]: [
        enums_1.Profession.ASSISTANT_NURSE,
        enums_1.Profession.DOCTOR,
        enums_1.Profession.NURSE,
    ],
    [enums_1.Market.GB]: [
        enums_1.Profession.ASSISTANT_NURSE,
        enums_1.Profession.CARER,
        enums_1.Profession.DOCTOR,
        enums_1.Profession.NURSE,
        enums_1.Profession.SENIOR_CARER,
    ],
    [enums_1.Market.NO]: [
        enums_1.Profession.ASSISTANT_NURSE,
        enums_1.Profession.DOCTOR,
        enums_1.Profession.MEDICAL_LABORATORY_TECHNICIAN,
        enums_1.Profession.MEDICAL_SECRETARY,
        enums_1.Profession.NURSE,
        enums_1.Profession.PHYSIOTHERAPIST,
        enums_1.Profession.PSYCHOLOGIST,
        enums_1.Profession.SOCIAL_EDUCATOR,
    ],
    [enums_1.Market.SE]: [
        enums_1.Profession.ASSISTANT_NURSE,
        enums_1.Profession.AUDIOLOGIST,
        enums_1.Profession.CARER,
        enums_1.Profession.DOCTOR,
        enums_1.Profession.NURSE,
        enums_1.Profession.OCCUPATIONAL_THERAPIST,
        enums_1.Profession.PHYSIOTHERAPIST,
        enums_1.Profession.PSYCHOLOGIST,
        enums_1.Profession.PSYCHOTHERAPIST,
        enums_1.Profession.SOCIALSEKRETERARE,
        enums_1.Profession.SOCIONOM,
    ],
};
function professionsForMarkets(markets) {
    if (!Array.isArray(markets)) {
        markets = [markets];
    }
    const resultSet = new Set();
    for (const market of markets) {
        const professions = MARKET_PROFESSIONS_RECORD[market];
        if (!professions) {
            throw new Error(`Invalid market "${market}"`);
        }
        for (const profession of professions) {
            resultSet.add(profession);
        }
    }
    return Array.from(resultSet);
}
function professionForSpecialization(specialization) {
    for (const [profession, specializations] of Object.entries(specialization_1.professionSpecializationsRecord)) {
        if (specializations.includes(specialization)) {
            return profession;
        }
    }
    throw new Error(`Specialization "${specialization}" does not belong to any profession`);
}
