"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Currency = void 0;
var Currency;
(function (Currency) {
    Currency["SEK"] = "SEK";
    Currency["DKK"] = "DKK";
    Currency["NOK"] = "NOK";
    Currency["EUR"] = "EUR";
    Currency["GBP"] = "GBP";
    Currency["USD"] = "USD";
})(Currency || (exports.Currency = Currency = {}));
