"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenderAvailabilityFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const AvailabilityRow_1 = require("../AvailabilityRow");
const get_grid_template_columns_1 = require("../utils/get-grid-template-columns");
const TenderAvailabilityFooter = ({ availabilities, tenderScope, viewState, }) => {
    const t = (0, translate_1.useTranslations)();
    const gridTemplateColumns = (0, get_grid_template_columns_1.getGridTemplateColumns)('tender', viewState);
    const sum = availabilities.length
        ? Math.round(availabilities.reduce((acc, curr) => acc + curr.newScope.scope, 0) /
            availabilities.length)
        : 0;
    return ((0, jsx_runtime_1.jsxs)(AvailabilityRow_1.GridRowHeaderStyles, { styles: { borderTop: constants_1.theme.border.style.thinLight }, gridTemplateColumns: gridTemplateColumns, children: [viewState === 'edit' && (0, jsx_runtime_1.jsx)(elements_1.Div, { styles: { pl: 3 } }), (0, jsx_runtime_1.jsxs)(elements_1.Text, { bold: true, children: [t.common.total(), " "] }), (0, jsx_runtime_1.jsx)(elements_1.Div, {}), (0, jsx_runtime_1.jsxs)(elements_1.Text, { bold: true, children: [sum, " / ", t.common.percent_sign(tenderScope)] }), (0, jsx_runtime_1.jsx)(elements_1.Div, {}), (0, jsx_runtime_1.jsx)(elements_1.Div, {})] }));
};
exports.TenderAvailabilityFooter = TenderAvailabilityFooter;
