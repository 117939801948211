"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.driversLicencesForMarket = driversLicencesForMarket;
const enums_1 = require("@bemlo/enums");
function driversLicencesForMarket(market) {
    switch (market) {
        case enums_1.Market.DK:
        case enums_1.Market.FI:
        case enums_1.Market.GB:
        case enums_1.Market.NO:
        case enums_1.Market.SE:
            return [
                enums_1.DriversLicense.NONE,
                enums_1.DriversLicense.AUTOMATIC,
                enums_1.DriversLicense.MANUAL,
            ];
        default: {
            const exhaustiveCheck = market;
            throw new Error(`No driversLicencesForMarket for "${exhaustiveCheck}"`);
        }
    }
}
