"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateWorkFormFactory = void 0;
const enums_1 = require("@bemlo/enums");
const locales_1 = require("../locales");
const translate_using_both_key_and_value_1 = require("../utils/translate-using-both-key-and-value");
const translateWorkFormFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('workForm', locale);
    // Casting because of some issue with Value being a number
    return (0, translate_using_both_key_and_value_1.translateUsingBothKeyAndValue)(enums_1.WorkForm, t);
};
exports.translateWorkFormFactory = translateWorkFormFactory;
