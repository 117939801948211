"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeTime = void 0;
const normalizeTime = ({ hour, minute }) => {
    // Convert minutes to hours:
    while (minute >= 60) {
        hour++;
        minute -= 60;
    }
    while (minute < 0) {
        hour--;
        minute += 60;
    }
    // Transpose into 24-hour format:
    while (hour < 0) {
        hour += 24;
    }
    hour = hour % 24;
    // Convert hour decimals to minutes:
    minute += Math.round((hour % 1) * 60);
    hour = Math.floor(hour);
    return { hour, minute };
};
exports.normalizeTime = normalizeTime;
