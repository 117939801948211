"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenderAvailabilityRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const getTenderDayCellStatus_1 = require("./getTenderDayCellStatus");
const utils_1 = require("./utils");
const elements_1 = require("../../../elements");
const TooltipIcon_1 = require("../../../molecules/TooltipIcon");
const PeriodCell_1 = require("../atoms/PeriodCell");
const ScopeCell_1 = require("../atoms/ScopeCell");
const AvailabilityRow_1 = require("../AvailabilityRow");
const ShiftHoursCell_1 = require("../molecules/ShiftHoursCell");
const WeekDaysRow_1 = require("../molecules/WeekDaysRow");
const constants_1 = require("../utils/constants");
const get_grid_template_columns_1 = require("../utils/get-grid-template-columns");
const remove_availabilities_1 = require("../utils/remove-availabilities");
const TenderAvailabilityRow = ({ availability, setAvailability, viewState, tenderScope, schema, }) => {
    const gridTemplateColumns = (0, get_grid_template_columns_1.getGridTemplateColumns)('tender', viewState);
    const res = schema?.safeParse({
        scope: availability.newScope.scope,
        shiftHours: availability.newScope.shiftHours,
        days: availability.days,
    });
    const errors = !availability.selected || res === undefined || res.success
        ? undefined
        : res.error;
    const toggleIsWeekSelected = () => {
        if (availability.selected) {
            return setAvailability?.((0, remove_availabilities_1.removeAvailability)(availability));
        }
        setAvailability?.((0, remove_availabilities_1.addAvailability)(availability, {
            getDefaultScope: () => tenderScope,
            getDefaultShiftHours: () => constants_1.ALL_AVAILABILITY_SHIFT_HOURS,
            shouldAddDay: utils_1.canTenderDay,
        }));
    };
    const updateScope = (scope) => {
        const newAvailability = { ...availability };
        newAvailability.newScope = {
            ...newAvailability.newScope,
            scope,
        };
        setAvailability?.(newAvailability);
    };
    const updateShiftHours = (shiftHours) => {
        const newAvailability = { ...availability };
        newAvailability.newScope = {
            ...newAvailability.newScope,
            shiftHours,
        };
        setAvailability?.(newAvailability);
    };
    const toggleDay = (dayIndex) => {
        const newAvailability = { ...availability };
        newAvailability.days[dayIndex] = {
            ...newAvailability.days[dayIndex],
            selected: !newAvailability.days[dayIndex].selected,
        };
        const allDaysInPeriod = newAvailability.days.filter((day) => day.includedInPeriod);
        if (allDaysInPeriod.every((day) => !day.selected)) {
            return setAvailability?.((0, remove_availabilities_1.removeAvailability)(newAvailability));
        }
        newAvailability.selected = true;
        setAvailability?.(newAvailability);
    };
    return ((0, jsx_runtime_1.jsxs)(AvailabilityRow_1.AvailabilityRowStyles, { gridTemplateColumns: gridTemplateColumns, disabled: !availability.selected, children: [viewState === 'edit' && ((0, jsx_runtime_1.jsx)(elements_1.Checkbox, { checked: availability.selected, onChange: toggleIsWeekSelected })), (0, jsx_runtime_1.jsx)(PeriodCell_1.PeriodCell, { selected: availability.selected, endsAt: availability.period.endsAt, startsAt: availability.period.startsAt }), (0, jsx_runtime_1.jsx)(elements_1.Div, { children: errors && ((0, jsx_runtime_1.jsx)(TooltipIcon_1.TooltipIcon, { styles: { ml: 'auto', mr: 4 }, text: errors.errors[0].message, tooltipIntent: "danger" })) }), (0, jsx_runtime_1.jsx)(ScopeCell_1.ScopeCell, { disabled: !availability.selected, scope: availability.newScope.scope, updateScope: updateScope, viewState: viewState, error: errors?.formErrors.fieldErrors.scope?.[0], initialScope: tenderScope }), (0, jsx_runtime_1.jsx)(ShiftHoursCell_1.ShiftHoursCell, { availableShiftHours: constants_1.ALL_AVAILABILITY_SHIFT_HOURS, viewState: viewState, selectedShiftHours: availability.newScope.shiftHours, setSelectedShiftHours: updateShiftHours, disabled: !availability.selected, error: errors?.formErrors.fieldErrors.shiftHours?.[0] }), (0, jsx_runtime_1.jsx)(WeekDaysRow_1.WeekDaysRow, { days: availability.days, toggleDay: viewState === 'edit' ? toggleDay : undefined, viewState: viewState, getDayCellStatus: getTenderDayCellStatus_1.getTenderDayCellStatus })] }, availability.key));
};
exports.TenderAvailabilityRow = TenderAvailabilityRow;
