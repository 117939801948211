"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uninterruptedRestViolationsFor = void 0;
const enums_1 = require("@bemlo/enums");
const shouldGroupViolation = (violations, newViolation) => {
    const previousViolation = violations[violations.length - 1];
    return (previousViolation &&
        previousViolation.periodStart.isSame(newViolation.periodStart));
};
/**
 * Sorts the schedule by start time and removes shifts that are contained within another shift.
 *
 * If a shift is contained within another shift, we only want to keep the outer shift
 * This is because the inner shift will not have any rest time violations with the next one
 * Example:
 * Shift 1: Day 1 8:00 - 12:00
 * Shift 2: Day 1 9:00 - 11:00
 * Shift 3: Day 2 11:30 - 16:00
 * Compared to shift 2, shift 3 has over 24 hours of rest, but not compared to shift 1
 * In practice, this should likely not be a problem, as we do not want overlapping shifts.
 * But when creating a schedule, we do not want to make these errors when the schedule is malformed.
 *
 * @param schedule - The schedule of shifts to check for violations.
 * @returns An array of uninterrupted rest time violations
 */
const sortAndRemoveContainedShifts = (schedule) => {
    const copiedSchedule = [...schedule];
    copiedSchedule.sort((a, b) => a.startDateTime.isBefore(b.startDateTime) ? -1 : 1);
    return copiedSchedule.filter((shift, index) => {
        if (index === 0) {
            return true;
        }
        return shift.endDateTime.isAfter(copiedSchedule[index - 1].endDateTime);
    });
};
const calculateTimeUntilRestTimeDeadline = (currentShift, mostRecentRequiredRestFulfillmentTimestamp, restTimeReq) => {
    const restRequirementFulfillmentDeadline = mostRecentRequiredRestFulfillmentTimestamp.add(restTimeReq.everyPeriodInHours, 'hours');
    return restRequirementFulfillmentDeadline.diff(currentShift.endDateTime, 'minutes');
};
const hasEnoughRestTime = (currentShift, previousShift, restTimeReq) => {
    const restTimeInMinutes = currentShift.startDateTime.diff(previousShift.endDateTime, 'minutes');
    return restTimeInMinutes >= restTimeReq.requiredRestTimeInMinutes;
};
const uninterruptedRestViolationsFor = (schedule, ruleSet) => {
    if (schedule.length === 0) {
        return [];
    }
    const sortedScheduleWithoutContainedShifts = sortAndRemoveContainedShifts(schedule);
    const restTimeReq = ruleSet.uninterruptedRestTimeRequirement;
    const violations = [];
    // Assumes that the weekly rest time requirement is fulfilled at the start of the schedule
    let mostRecentRequiredRestFulfillmentTimestamp = sortedScheduleWithoutContainedShifts[0].startDateTime;
    for (let i = 1; i < sortedScheduleWithoutContainedShifts.length; i++) {
        const currentShift = sortedScheduleWithoutContainedShifts[i];
        const previousShift = sortedScheduleWithoutContainedShifts[i - 1];
        if (hasEnoughRestTime(currentShift, previousShift, restTimeReq)) {
            mostRecentRequiredRestFulfillmentTimestamp = currentShift.startDateTime;
            continue;
        }
        /**
         * At first we checked if too much time had passed since the last rest at the beginning of this shift.
         * Then we marked it as a violation. But this does not capture the rule properly. We need to check
         * if given this shift, is there enough time after the shift to fulfill the rest requirement.
         * If done like in the first iteration, we would miss this case if this was the final shift or
         * if the next shift had enough rest time.
         */
        const timeUntilDeadline = calculateTimeUntilRestTimeDeadline(currentShift, mostRecentRequiredRestFulfillmentTimestamp, restTimeReq);
        if (timeUntilDeadline < restTimeReq.requiredRestTimeInMinutes) {
            const newViolation = {
                violationType: enums_1.SchedulingViolationType.UNINTERRUPTED_REST_TIME,
                periodStart: mostRecentRequiredRestFulfillmentTimestamp,
                periodEnd: currentShift.endDateTime,
                requiredRestTimeInMinutes: restTimeReq.requiredRestTimeInMinutes,
            };
            if (shouldGroupViolation(violations, newViolation)) {
                violations[violations.length - 1] = newViolation;
            }
            else {
                violations.push(newViolation);
            }
        }
    }
    return violations;
};
exports.uninterruptedRestViolationsFor = uninterruptedRestViolationsFor;
