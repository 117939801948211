"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debugTranslations = void 0;
/*
  Wrap the translation object in /translations/index.tsx to wrap translation with $X$

  return debugTranslations({
    ...translations.base,
    ...translations,
    ...((namespace
      ? translations[namespace]
      : undefined) as Translations[TNamespace]),
  })

  To display the translation path instead of translation, you can set the second argument of
  debugTranslations to true
*/
const debugTranslations = (obj, inspect) => {
    const proxyHandler = (path) => {
        return {
            get(target, prop) {
                const newPath = `${path}.${prop}`;
                const value = target[prop];
                switch (prop) {
                    case '$date':
                        return value;
                }
                if (inspect) {
                    switch (typeof value) {
                        case 'string':
                            return newPath;
                        case 'function':
                        case 'undefined':
                            return () => newPath;
                        default:
                            break;
                    }
                }
                switch (typeof value) {
                    case 'function': {
                        let called = false;
                        queueMicrotask(() => {
                            if (!called) {
                                console.error('Function not called:', newPath);
                            }
                        });
                        return (...argumentsList) => {
                            called = true;
                            return `$${value(...argumentsList)}$`;
                        };
                    }
                    case 'string':
                        return `$${value}$`;
                    case 'undefined':
                        console.error('Missing translation:', newPath);
                        return () => `#MISSING_TRANSLATION (${newPath})#`;
                    default:
                        break;
                }
                return new Proxy(value, proxyHandler(newPath));
            },
        };
    };
    return new Proxy(obj, proxyHandler(''));
};
exports.debugTranslations = debugTranslations;
