"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PresentationAvailabilitySchedule = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const PresentationAvailabilityHeaderRow_1 = require("./PresentationAvailabilityHeaderRow");
const PresentationAvailabilityRow_1 = require("./PresentationAvailabilityRow");
const AvailabilityScheduleGridWrapper_1 = require("../atoms/AvailabilityScheduleGridWrapper");
const PresentationAvailabilitySchedule = ({ availabilities, setAvailabilities, viewState, presentationState, schema, }) => {
    const setAvailabilityForWeek = (index) => (availability) => {
        const newAvailabilities = [...availabilities];
        newAvailabilities[index] = availability;
        setAvailabilities?.(newAvailabilities);
    };
    return ((0, jsx_runtime_1.jsxs)(AvailabilityScheduleGridWrapper_1.AvailabilityScheduleGridWrapper, { hideOverflow: true, children: [(0, jsx_runtime_1.jsx)(PresentationAvailabilityHeaderRow_1.PresentationAvailabilityHeaderRow, { presentationState: presentationState, viewState: viewState, availabilities: availabilities, setAvailabilities: setAvailabilities }), availabilities.map((week, index) => ((0, jsx_runtime_1.jsx)(PresentationAvailabilityRow_1.PresentationAvailabilityRow, { presentationState: presentationState, viewState: viewState, availability: week, setAvailability: setAvailabilityForWeek(index), schema: schema?.element }, week.key)))] }));
};
exports.PresentationAvailabilitySchedule = PresentationAvailabilitySchedule;
