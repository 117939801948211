import { NavigateOptions } from '@reach/router'
// eslint-disable-next-line no-restricted-imports
import { navigate as gatsbyNavigate } from 'gatsby'

const withTrailingSlash = (path: string) =>
  path.endsWith('/') ? path : `${path}/`

const insertTrailingSlash = (parts: string[], divider: string) =>
  `${withTrailingSlash(parts[0])}${divider}${parts.slice(1).join('')}`

export const forceTrailingSlash = (path: string) => {
  if (!/#|\?/.test(path)) return withTrailingSlash(path)
  if (path.includes('?')) return insertTrailingSlash(path.split('?'), '?')
  if (path.includes('#')) return insertTrailingSlash(path.split('#'), '#')
  return path
}

// Overloads matching gatsby's navigate function
// Eslint disabled to mimic gatsby's types
export function navigate(
  to: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  options?: NavigateOptions<{}>,
): Promise<void>
export function navigate(to: number): Promise<void>
// eslint-disable-next-line @typescript-eslint/ban-types
export function navigate(to: string | number, options?: NavigateOptions<{}>) {
  if (typeof to === 'number') {
    return gatsbyNavigate(to)
  }
  if (typeof to === 'string') {
    return gatsbyNavigate(forceTrailingSlash(to), options)
  }
}
