"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAnalytics = void 0;
const react_1 = require("react");
const useAnalytics = (options) => {
    const analytics = typeof window === 'undefined' ? null : (window.analytics ?? null);
    (0, react_1.useEffect)(() => {
        if (options.enabled) {
            analytics?.load?.(options.writeKey);
        }
    }, [analytics?.load, options.enabled]);
    return analytics;
};
exports.useAnalytics = useAnalytics;
