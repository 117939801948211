"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DriversLicenses = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const enums_1 = require("@bemlo/enums");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const Detail_1 = require("../../components/Detail");
const useProfessionalView_1 = require("../../hooks/useProfessionalView");
const scopes_1 = require("../../scopes");
const DriversLicenses = () => {
    const { actor, data, isEditing, update } = (0, useProfessionalView_1.useProfessionalView)();
    const t = (0, translate_1.useTranslations)();
    const { driversLicense } = data;
    const scope = scopes_1.scopes.biography.qualifications.driversLicense();
    if (!actor.can('read', scope)) {
        return null;
    }
    if (isEditing(scope)) {
        return ((0, jsx_runtime_1.jsx)(elements_1.Listbox, { disabled: !actor.can('update', scope), label: t.professional_view.base.drivers_license(), onChange: update('driversLicense'), options: Object.entries(enums_1.DriversLicense).map(([key, value]) => ({
                key,
                label: t.$driversLicense(value),
                value,
            })), required: true, value: driversLicense ?? enums_1.DriversLicense.NONE }));
    }
    if (!driversLicense || driversLicense === enums_1.DriversLicense.NONE) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: t.$driversLicense(driversLicense), heading: t.professional_view.base.drivers_license(), icon: "IdentificationIcon" }));
};
exports.DriversLicenses = DriversLicenses;
