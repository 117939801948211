"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIntentProps = void 0;
const color_1 = require("./color");
const INTENT_PROPS_MAP = {
    danger: {
        backgroundColor: color_1.Color.RED,
        borderColor: color_1.Color.RED,
        color: color_1.Color.WHITE,
        linkColor: color_1.Color.LIGHTEST_RED,
        iconName: 'ExclamationCircleIcon',
    },
    info: {
        backgroundColor: color_1.Color.LIGHTEST_BLUE,
        borderColor: color_1.Color.DARK_BLUE,
        color: color_1.Color.DARK_BLUE,
        linkColor: color_1.Color.DARKEST_BLUE,
        iconName: 'InformationCircleIcon',
    },
    success: {
        backgroundColor: color_1.Color.LIGHTER_GREEN,
        borderColor: color_1.Color.DARKEST_GREEN,
        color: color_1.Color.DARKEST_GREEN,
        linkColor: color_1.Color.DARK_GREEN,
        iconName: 'CheckIcon',
    },
    warning: {
        backgroundColor: color_1.Color.LIGHTER_RED,
        borderColor: color_1.Color.DARK_RED,
        color: color_1.Color.DARK_RED,
        linkColor: color_1.Color.MEDIUM_RED,
        iconName: 'ExclamationIcon',
    },
};
const getIntentProps = (intent) => {
    return INTENT_PROPS_MAP[intent];
};
exports.getIntentProps = getIntentProps;
