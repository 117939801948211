"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftsToPrice = exports.shiftToPrice = exports.joinShiftPrices = exports.shiftsToPrices = exports.shiftToPrices = void 0;
const parse_pay_types_1 = require("./parse-pay-types");
const work_hours_1 = require("./work-hours");
const shiftToPrices = (shift, payTypes = {}) => {
    const workHours = (0, work_hours_1.workHoursByShift)(shift);
    const hours = {
        day: workHours.DAY / 60,
        evening: workHours.EVENING / 60,
        holiday: workHours.HOLIDAY / 60,
        night: workHours.NIGHT / 60,
        weekend: workHours.WEEKEND / 60,
        weekendNight: workHours.WEEKEND_NIGHT / 60,
    };
    const pricing = (0, parse_pay_types_1.parsePayTypes)(shift.shiftPricing ?? payTypes);
    return {
        DAY: hours.day * pricing.DAY,
        EVENING: hours.evening * pricing.EVENING,
        HOLIDAY: hours.holiday * pricing.HOLIDAY,
        NIGHT: hours.night * pricing.NIGHT,
        WEEKEND: hours.weekend * pricing.WEEKEND,
        WEEKEND_NIGHT: hours.weekendNight * pricing.WEEKEND_NIGHT,
    };
};
exports.shiftToPrices = shiftToPrices;
const shiftsToPrices = (shifts, payTypes = {}) => {
    const prices = {
        DAY: 0,
        EVENING: 0,
        HOLIDAY: 0,
        NIGHT: 0,
        WEEKEND: 0,
        WEEKEND_NIGHT: 0,
    };
    for (const shift of shifts) {
        const shiftPrices = (0, exports.shiftToPrices)(shift, payTypes);
        prices.DAY += shiftPrices.DAY;
        prices.EVENING += shiftPrices.EVENING;
        prices.HOLIDAY += shiftPrices.HOLIDAY;
        prices.NIGHT += shiftPrices.NIGHT;
        prices.WEEKEND += shiftPrices.WEEKEND;
        prices.WEEKEND_NIGHT += shiftPrices.WEEKEND_NIGHT;
    }
    return prices;
};
exports.shiftsToPrices = shiftsToPrices;
const joinShiftPrices = (prices) => {
    const { DAY, EVENING, HOLIDAY, NIGHT, WEEKEND, WEEKEND_NIGHT } = prices;
    return DAY + EVENING + HOLIDAY + NIGHT + WEEKEND + WEEKEND_NIGHT;
};
exports.joinShiftPrices = joinShiftPrices;
const shiftToPrice = (shift, payTypes = {}) => {
    const prices = (0, exports.shiftToPrices)(shift, payTypes);
    return (0, exports.joinShiftPrices)(prices);
};
exports.shiftToPrice = shiftToPrice;
const shiftsToPrice = (shifts, payTypes = {}) => {
    const prices = (0, exports.shiftsToPrices)(shifts, payTypes);
    return (0, exports.joinShiftPrices)(prices);
};
exports.shiftsToPrice = shiftsToPrice;
