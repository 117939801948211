"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.norwayWhichRegionAlternative = exports.specialWhichRegionAlternative = void 0;
const enums_1 = require("@bemlo/enums");
exports.specialWhichRegionAlternative = {
    [enums_1.SpecialWhichRegionAlternative.ALL]: 'Alle',
    [enums_1.SpecialWhichRegionAlternative.NONE]: 'Ingen',
};
exports.norwayWhichRegionAlternative = {
    [enums_1.NorwayWhichRegionAlternative.AGDER]: 'Agder',
    [enums_1.NorwayWhichRegionAlternative.INNLANDET]: 'Innlandet',
    [enums_1.NorwayWhichRegionAlternative.MORE_OG_ROMSDAL]: 'Møre og Romsdal',
    [enums_1.NorwayWhichRegionAlternative.NORDLAND]: 'Nordland',
    [enums_1.NorwayWhichRegionAlternative.OSLO]: 'Oslo',
    [enums_1.NorwayWhichRegionAlternative.ROGALAND]: 'Rogaland',
    [enums_1.NorwayWhichRegionAlternative.TROMS_OG_FINNMARK]: 'Troms og Finnmark',
    [enums_1.NorwayWhichRegionAlternative.TRONDELAG]: 'Trøndelag',
    [enums_1.NorwayWhichRegionAlternative.VESTFOLD_OG_TELEMARK]: 'Vestfold og Telemark',
    [enums_1.NorwayWhichRegionAlternative.VESTLAND]: 'Vestland',
    [enums_1.NorwayWhichRegionAlternative.VIKEN]: 'Viken',
};
