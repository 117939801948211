"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.whichDepartmentToWorkInAlternative = void 0;
const enums_1 = require("@bemlo/enums");
const $ = enums_1.WhichDepartmentToWorkInAlternative;
const job = (preposition, title) => ({ preposition, title });
exports.whichDepartmentToWorkInAlternative = {
    [$.AKUTSJUKVARD]: job('in', 'Emergency Care'),
    [$.AMBULANS]: job('in', 'Ambulance'),
    [$.ASIH]: job('in', 'Advanced Home Health Care'),
    [$.BARN_OCH_UNGDOM]: job('at', 'Child and Youth Clinic'),
    [$.BARNMEDICIN]: job('at', "Children's Department"),
    [$.BARNONKOLOGEN]: job('at', 'Pediatric Oncology'),
    [$.BAVA]: job('at', 'Emergency Care, Children'),
    [$.CENTER]: job('at', 'Clinic'),
    [$.DEMENS]: job('at', 'Dementia Residence'),
    [$.DEPARTMENT]: job('at', 'Department'),
    [$.DIALYSMOTTAGNING]: job('at', 'Dialysis Clinic'),
    [$.FORLOSSNING]: job('in', 'Delivery/Maternity'),
    [$.GASTROAVDELNING]: job('at', 'Gastroenterology'),
    [$.GERIATRIK]: job('at', 'Geriatric Department'),
    [$.GYNEKOLOGDEPARTMENT]: job('at', 'Gynecological Department'),
    [$.HALSOCENTRAL]: job('at', 'Health Center'),
    [$.HEMSJUKVARD]: job('in', 'Home Health Care'),
    [$.HIA]: job('at', 'Cardiac Intensive Care'),
    [$.HJARTAVDELNING]: job('at', 'Cardiology Department'),
    [$.INFEKTION]: job('at', 'Infectious Disease Department'),
    [$.IVA]: job('at', 'Intensive Care Unit'),
    [$.JOURCENTRAL]: job('at', 'On-call Clinic'),
    [$.KIRURGI]: job('in', 'Surgery'),
    [$.KVINNOKLINIK]: job('at', 'Gynecological Clinic'),
    [$.LUNGAVDELNING]: job('at', 'Pulmonary Department'),
    [$.MAMMOGRAFI]: job('in', 'Mammography'),
    [$.MAVA]: job('at', 'Medical Emergency Department'),
    [$.MEDICIN]: job('at', 'Medicine'),
    [$.MUNICIPAL]: job('in', 'Municipal Care'),
    [$.NARAKUT]: job('at', 'Local Emergency'),
    [$.NEONATAL]: job('at', 'Neonatal Department'),
    [$.NEUROLOGI]: job('at', 'Neurology'),
    [$.NJURAVDELNING]: job('at', 'Kidney Department'),
    [$.ONH_MOTTAGNING]: job('at', 'ENT Clinic'),
    [$.ONKOLOGEN]: job('at', 'Oncology'),
    [$.OPEN_FOR_ANYTHING]: job('in', 'Open for Different'),
    [$.OPERATION]: job('in', 'Surgery'),
    [$.ORTOPEDEN]: job('at', 'Orthopedics'),
    [$.POSTOPERATION]: job('at', 'Post-surgery'),
    [$.PSYKIATRI]: job('in', 'Psychiatry'),
    [$.RADIOLOGEN]: job('at', 'Radiology'),
    [$.REHABILITERING]: job('in', 'Rehabilitation'),
    [$.REUMATOLOGI]: job('at', 'Rheumatology'),
    [$.SABO]: job('at', 'Residential Care Home for Elderly'),
    [$.SJUKSTUGA]: job('at', 'Infirmary'),
    [$.SKOLSJUKVARD]: job('at', 'School Health Care'),
    [$.TELEFONRADGIVNING]: job('in', 'Telephone Counseling'),
    [$.THORAXKIRURGEN]: job('at', 'Thoracic Surgery'),
    [$.VACCINERING]: job('in', 'Vaccination'),
    [$.VARDCENTRAL]: job('at', 'Primary Health Care Center'),
};
