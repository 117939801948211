"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateHowMuchWorkFactory = void 0;
const enums_1 = require("@bemlo/enums");
const locales_1 = require("../locales");
const translate_using_both_key_and_value_1 = require("../utils/translate-using-both-key-and-value");
const translateHowMuchWorkFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('howMuchWork', locale);
    return (0, translate_using_both_key_and_value_1.translateUsingBothKeyAndValue)(enums_1.HowMuchWorkAlternative, t);
};
exports.translateHowMuchWorkFactory = translateHowMuchWorkFactory;
