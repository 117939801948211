"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateShiftDate = exports.validateShift = void 0;
const date_1 = require("@bemlo/date");
const validateShift = (shift) => {
    const start = shift.startDateTime;
    const end = shift.endDateTime;
    let adjustedEnd = end;
    let shiftEndAdjusted = false;
    if (!end.isAfter(start)) {
        // Shift crosses midnight, add 1 day to end
        adjustedEnd = adjustedEnd.add(1, 'day');
        shiftEndAdjusted = true;
    }
    const duration = adjustedEnd.diff(start, 'hour', true);
    if (duration > 24) {
        // Shift is longer than 24h, adjust end time
        adjustedEnd = start.add(24, 'hour');
        shiftEndAdjusted = true;
    }
    const fixes = {};
    if (shiftEndAdjusted) {
        fixes.endDateTime = adjustedEnd;
    }
    // Now check onCallTime, if defined
    if (shift.onCallTime) {
        const onCallStart = shift.onCallTime.startDateTime;
        const onCallEnd = shift.onCallTime.endDateTime;
        let adjustedOnCallStart = onCallStart;
        let adjustedOnCallEnd = onCallEnd;
        let onCallStartAdjusted = false;
        let onCallEndAdjusted = false;
        // Adjust onCallStart if before shiftStart
        if (adjustedOnCallStart.isBefore(start)) {
            adjustedOnCallStart = start;
            onCallStartAdjusted = true;
        }
        // Adjust onCallEnd if after adjustedEnd
        if (adjustedOnCallEnd.isAfter(adjustedEnd)) {
            adjustedOnCallEnd = adjustedEnd;
            onCallEndAdjusted = true;
        }
        // If onCallEnd <= onCallStart, adjust accordingly
        if (!adjustedOnCallEnd.isAfter(adjustedOnCallStart)) {
            // Invalid on-call time, need to adjust
            if (!onCallStartAdjusted && !onCallEndAdjusted) {
                // Neither was adjusted before, adjust both to cover entire shift
                adjustedOnCallStart = start;
                adjustedOnCallEnd = adjustedEnd;
                onCallStartAdjusted = true;
                onCallEndAdjusted = true;
            }
            else if (onCallStartAdjusted && !onCallEndAdjusted) {
                // Start was adjusted, need to adjust end to be after start
                adjustedOnCallEnd = adjustedOnCallStart.add(1, 'minute'); // Ensure at least 1 minute duration
                if (adjustedOnCallEnd.isAfter(adjustedEnd)) {
                    adjustedOnCallEnd = adjustedEnd;
                }
                onCallEndAdjusted = true;
            }
            else if (!onCallStartAdjusted && onCallEndAdjusted) {
                // End was adjusted, need to adjust start to be before end
                adjustedOnCallStart = adjustedOnCallEnd.subtract(1, 'minute');
                if (adjustedOnCallStart.isBefore(start)) {
                    adjustedOnCallStart = start;
                }
                onCallStartAdjusted = true;
            }
            else {
                // Both were adjusted before, but still invalid, adjust to cover entire shift
                adjustedOnCallStart = start;
                adjustedOnCallEnd = adjustedEnd;
                onCallStartAdjusted = true;
                onCallEndAdjusted = true;
            }
        }
        if (onCallStartAdjusted || onCallEndAdjusted) {
            const onCallFixes = {};
            if (onCallStartAdjusted) {
                onCallFixes.startDateTime = adjustedOnCallStart;
            }
            if (onCallEndAdjusted) {
                onCallFixes.endDateTime = adjustedOnCallEnd;
            }
            if (Object.keys(onCallFixes).length > 0) {
                fixes.onCallTime = onCallFixes;
            }
        }
    }
    if (Object.keys(fixes).length > 0) {
        return {
            isValid: false,
            fixes,
        };
    }
    return {
        isValid: true,
        fixes: undefined,
    };
};
exports.validateShift = validateShift;
const validateShiftDate = (shift) => {
    return (0, exports.validateShift)({
        startDateTime: (0, date_1.dayjsEn)(shift.startDateTime),
        endDateTime: (0, date_1.dayjsEn)(shift.endDateTime),
        onCallTime: shift.onCallTime
            ? {
                startDateTime: (0, date_1.dayjsEn)(shift.onCallTime.startDateTime),
                endDateTime: (0, date_1.dayjsEn)(shift.onCallTime.endDateTime),
            }
            : null,
    });
};
exports.validateShiftDate = validateShiftDate;
