"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tenderStatus = void 0;
const enums_1 = require("@bemlo/enums");
exports.tenderStatus = {
    [enums_1.TenderStatus.DRAFT]: 'Utkast',
    [enums_1.TenderStatus.PUBLISHED]: 'Publicerad',
    [enums_1.TenderStatus.CLOSED]: 'Stängd',
    [enums_1.TenderStatus.FILLED]: 'Tillsatt',
    [enums_1.TenderStatus.URGENT]: 'Akut',
    [enums_1.TenderStatus.ASSIGNED]: 'Tilldelat',
};
