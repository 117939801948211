"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptanceAvailabilityHeaderRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const AvailabilityRow_1 = require("../AvailabilityRow");
const ShiftHoursCell_1 = require("../molecules/ShiftHoursCell");
const WeekDaysRow_1 = require("../molecules/WeekDaysRow");
const utils_1 = require("../presentation/utils");
const are_all_selected_1 = require("../utils/are-all-selected");
const constants_1 = require("../utils/constants");
const get_grid_template_columns_1 = require("../utils/get-grid-template-columns");
const remove_availabilities_1 = require("../utils/remove-availabilities");
const AcceptanceAvailabilityHeaderRow = ({ availabilities, setAvailabilities, viewState, }) => {
    const t = (0, translate_1.useTranslations)();
    const gridTemplateColumns = (0, get_grid_template_columns_1.getGridTemplateColumns)('acceptance', viewState);
    const weekSelectionStates = (0, are_all_selected_1.areAllAvailabilityWeeksSelected)(availabilities, utils_1.isAvailabilityWeekPresented);
    const selectWeekBulk = () => {
        if (!weekSelectionStates.areAllSelected) {
            return setAvailabilities?.((0, remove_availabilities_1.addAvailabilities)(availabilities, {
                getDefaultScope: (availability) => (0, utils_1.presentedScope)(availability) ?? 0,
                getDefaultShiftHours: utils_1.presentedShiftHours,
                shouldAddAvailability: utils_1.isAvailabilityWeekPresented,
                shouldAddDay: utils_1.isDayPresented,
            }));
        }
        return setAvailabilities?.((0, remove_availabilities_1.removeAvailabilities)(availabilities));
    };
    const shiftHoursState = availabilities.map((week) => ({
        selected: week.selected,
        shiftHours: week.newScope.shiftHours ?? [],
        availableShiftHours: week.presentedAvailability?.scope.shiftHours ?? constants_1.EMPTY_ARRAY,
    }));
    const dayStates = availabilities.map((availability) => ({
        selectable: (0, utils_1.isAvailabilityWeekPresented)(availability),
        days: availability.days.map((day) => ({
            selected: day.selected,
            selectable: (0, utils_1.isDayPresented)(day),
        })),
    }));
    const toggleDays = ({ dayIndex, newValue, }) => {
        const newAvailabilities = availabilities.map((availability) => {
            if (!(0, utils_1.isDayPresented)(availability.days[dayIndex])) {
                return availability;
            }
            const updatedDays = [...availability.days];
            updatedDays[dayIndex] = {
                ...updatedDays[dayIndex],
                selected: newValue,
            };
            const noDaysSelected = updatedDays.every((day) => !day.selected);
            if (noDaysSelected) {
                return (0, remove_availabilities_1.removeAvailability)(availability);
            }
            return {
                ...availability,
                selected: true,
                newScope: {
                    ...availability.newScope,
                    shiftHours: (0, utils_1.presentedShiftHours)(availability),
                },
                days: updatedDays,
            };
        });
        setAvailabilities?.(newAvailabilities);
    };
    return ((0, jsx_runtime_1.jsxs)(AvailabilityRow_1.GridRowHeaderStyles, { gridTemplateColumns: gridTemplateColumns, children: [viewState === 'edit' && ((0, jsx_runtime_1.jsx)(elements_1.Checkbox, { checked: weekSelectionStates.isSomeSelected, indeterminate: !weekSelectionStates.areAllSelected, onChange: selectWeekBulk })), (0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, children: t.common.period() }), (0, jsx_runtime_1.jsx)(elements_1.Div, {}), (0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, children: t.common.accepted_scope() }), (0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, children: t.common.remaining_scope() }), (0, jsx_runtime_1.jsx)(ShiftHoursCell_1.ShiftHoursHeader, { viewState: 'view', availabilities: shiftHoursState }), (0, jsx_runtime_1.jsx)(WeekDaysRow_1.WeekDaysRowHeader, { viewState: viewState, availabilities: dayStates, toggleDays: toggleDays })] }));
};
exports.AcceptanceAvailabilityHeaderRow = AcceptanceAvailabilityHeaderRow;
