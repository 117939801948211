"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatWeek = void 0;
const isInNearTime = (currentDate, year, week) => {
    const currentWeek = currentDate.isoWeek();
    const currentYear = currentDate.isoWeekYear();
    return (year === currentYear || (week < currentWeek && year === currentYear + 1));
};
const getFormat = (instance, { showYear = false, short = false }) => {
    const { weekWithYear, week, weekWithoutPrefix } = instance.$locale().formatWeek;
    return short ? weekWithoutPrefix : showYear ? weekWithYear : week;
};
const formatWeek = (_option, dayjsClass, dayjsFactory) => {
    dayjsClass.prototype.formatWeek = function (options) {
        const currentDate = dayjsFactory();
        const showYear = (() => {
            switch (options?.showYear) {
                case 'never':
                    return false;
                case 'always':
                    return true;
                default: {
                    const week = this.isoWeek();
                    const year = this.isoWeekYear();
                    return !isInNearTime(currentDate, year, week);
                }
            }
        })();
        const format = getFormat(this, { showYear });
        const useWeekBeginning = this.$locale().formatWeek.useWeekBeginning;
        if (useWeekBeginning) {
            return this.startOf('week').format(format);
        }
        return this.format(format);
    };
    dayjsClass.prototype.formatWeekRange = function (toDate, short = false) {
        const currentDate = dayjsFactory();
        const week = this.isoWeek();
        const year = this.isoWeekYear();
        const toDateWeek = toDate.isoWeek();
        const toDateYear = toDate.isoWeekYear();
        const showYear = !isInNearTime(currentDate, year, week) ||
            !isInNearTime(currentDate, toDateYear, toDateWeek);
        const format = getFormat(this, { showYear });
        const useWeekBeginning = this.$locale().formatWeek.useWeekBeginning;
        const start = useWeekBeginning ? this.startOf('week') : this;
        const formatted = start.format(format);
        if (week === toDateWeek && year === toDateYear) {
            return formatted;
        }
        const to = useWeekBeginning ? toDate.startOf('week') : toDate;
        const toDateFormatted = to.format(short ? getFormat(this, { short: true }) : format);
        return `${formatted}–${toDateFormatted}`;
    };
};
exports.formatWeek = formatWeek;
