"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailabilityScheduleWeekOverview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const AvailabilityScheduleWeekOverviewHeader_1 = require("./AvailabilityScheduleWeekOverviewHeader");
const AvailabilityScheduleWeekOverviewRow_1 = require("./AvailabilityScheduleWeekOverviewRow");
const elements_1 = require("../../../elements");
const AvailabilityScheduleWeekOverview = ({ week, }) => {
    const [isOpen, setIsOpen] = (0, react_1.useState)(week.tendered);
    const toggleIsOpen = () => setIsOpen(!isOpen);
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, children: [(0, jsx_runtime_1.jsx)(AvailabilityScheduleWeekOverviewHeader_1.AvailabilityScheduleWeekOverviewHeader, { week: week, toggleIsOpen: toggleIsOpen, isOpen: isOpen }), isOpen &&
                week.presentations.map((presentation) => ((0, jsx_runtime_1.jsx)(AvailabilityScheduleWeekOverviewRow_1.AvailabilityScheduleWeekOverviewRow, { rowData: presentation, type: "presentation", tenderedShiftHours: week.scope.shiftHours }, presentation.id))), isOpen &&
                week.bookings.map((booking) => ((0, jsx_runtime_1.jsx)(AvailabilityScheduleWeekOverviewRow_1.AvailabilityScheduleWeekOverviewRow, { rowData: booking, type: "booking", tenderedShiftHours: week.scope.shiftHours }, booking.id)))] }));
};
exports.AvailabilityScheduleWeekOverview = AvailabilityScheduleWeekOverview;
