"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileUploadType = void 0;
const enums_1 = require("@bemlo/enums");
exports.fileUploadType = {
    [enums_1.FileUploadType.CV]: 'CV',
    [enums_1.FileUploadType.LICENSE]: 'License',
    [enums_1.FileUploadType.HOSP]: 'HOSP',
    [enums_1.FileUploadType.IVO]: 'IVO',
    [enums_1.FileUploadType.OTHER]: 'Other document',
    [enums_1.FileUploadType.REFERENCE]: 'Reference',
    [enums_1.FileUploadType.SPECIALIZATION]: 'Specialization',
};
