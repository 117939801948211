"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComparatorOperationInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ComparatorInput_1 = require("./ComparatorInput");
const useComparatorOperations_1 = require("./hooks/useComparatorOperations");
const useFallbackComparator_1 = require("./hooks/useFallbackComparator");
const ComparatorOperationInput = ({ variant, selectedOperation, setSelectedOperation, numberOfSelectedItems, noNegations, showPartOf, }) => {
    const operations = (0, useComparatorOperations_1.useComparatorOperations)(variant, numberOfSelectedItems, noNegations, showPartOf);
    (0, useFallbackComparator_1.useFallbackComparator)(operations, selectedOperation, setSelectedOperation);
    return ((0, jsx_runtime_1.jsx)(ComparatorInput_1.ComparatorInput, { groups: [{ items: operations }], design: "light", onSelect: setSelectedOperation, singleSelect: true, value: selectedOperation, compact: true, children: operations.find((operation) => operation.value === selectedOperation)
            ?.label }));
};
exports.ComparatorOperationInput = ComparatorOperationInput;
