"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.specialization = void 0;
const enums_1 = require("@bemlo/enums");
const doctorSpecialization = {
    [enums_1.DoctorSpecialization.DOCTOR_AKUTSJUKVARD]: {
        education: 'Akutsjukvård',
        title: {
            '*': 'Specialister inom akutsjukvård',
            '1': 'Specialist inom akutsjukvård',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ALLERGISJUKDOMAR]: {
        education: 'Allergisjukdomar',
        title: {
            '*': 'Specialister inom allergisjukdomar',
            '1': 'Specialist inom allergisjukdomar',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ALLMANMEDICIN]: {
        education: 'Allmänmedicin',
        title: {
            '*': 'Allmänspecialister',
            '1': 'Allmänspecialist',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ANESTESIOCHINTENSIVVARD]: {
        education: 'Anestesi- och intensivvård',
        title: {
            '*': 'Specialister inom anestesi- och intensivvård',
            '1': 'Specialist inom anestesi- och intensivvård',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ARBETSOCHMILJOMEDICIN]: {
        education: 'Arbets- och miljömedicin',
        title: {
            '*': 'Specialister inom arbets- och miljömedicin',
            '1': 'Specialist inom arbets- och miljömedicin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSHEMATOLOGI]: {
        education: 'Barn- och ungdomshematologi',
        title: {
            '*': 'Specialister inom barn- och ungdomshematologi',
            '1': 'Specialist inom barn- och ungdomshematologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSKARDIOLOGI]: {
        education: 'Barn- och ungdomskardiologi',
        title: {
            '*': 'Specialister inom barn- och ungdomskardiologi',
            '1': 'Specialist inom barn- och ungdomskardiologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSKIRURGI]: {
        education: 'Barn- och ungdomskirurgi',
        title: {
            '*': 'Specialister inom barn- och ungdomskirurgi',
            '1': 'Specialist inom barn- och ungdomskirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSMEDICIN]: {
        education: 'Barn- och ungdomsmedicin',
        title: {
            '*': 'Specialister inom barn- och ungdomsmedicin',
            '1': 'Specialist inom barn- och ungdomsmedicin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSNEUROLOGI]: {
        education: 'Barn- och ungdomsneurologi',
        title: {
            '*': 'Specialister inom barn- och ungdomsneurologi',
            '1': 'Specialist inom barn- och ungdomsneurologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSPSYKIATRI]: {
        education: 'Barn- och ungdomspsykiatri',
        title: {
            '*': 'Specialister inom barn- och ungdomspsykiatri',
            '1': 'Specialist inom barn- och ungdomspsykiatri',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ENDOKRINOLOGIOCHDIABETOLOGI]: {
        education: 'Endokrinologi och diabetologi',
        title: {
            '*': 'Specialister inom endokrinologi och diabetologi',
            '1': 'Specialist inom endokrinologi och diabetologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_GASTROENTEROLOGI]: {
        education: 'Gastroenterologi',
        title: {
            '*': 'Specialister inom gastroenterologi',
            '1': 'Specialist inom gastroenterologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_GENERIC_SPECIALIZATION]: {
        education: 'Specialistläkare',
        title: {
            '*': 'Specialistläkare',
            '1': 'Specialistläkare',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_GERIATRIK]: {
        education: 'Geriatrik',
        title: {
            '*': 'Specialister inom geriatrik',
            '1': 'Specialist inom geriatrik',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_HANDKIRURGI]: {
        education: 'Handkirurgi',
        title: {
            '*': 'Specialister inom handkirurgi',
            '1': 'Specialist inom handkirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_HEMATOLOGI]: {
        education: 'Hematologi',
        title: {
            '*': 'Specialister inom hematologi',
            '1': 'Specialist inom hematologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_HORSELOCHBALANSRUBBNINGAR]: {
        education: 'Hörsel- och balansrubbningar',
        title: {
            '*': 'Specialister inom hörsel och balansrubbningar',
            '1': 'Specialist inom hörsel och balansrubbningar',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_HUDOCHKONSSJUKDOMAR]: {
        education: 'Hud- och könssjukdomar',
        title: {
            '*': 'Specialister inom hud- och könssjukdomar',
            '1': 'Specialist inom hud- och könssjukdomar',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_IMMUNOLOGI]: {
        education: 'Immunologi',
        title: {
            '*': 'Specialister inom immunologi',
            '1': 'Specialist inom immunologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_INFEKTIONSSJUKDOMAR]: {
        education: 'Infektionssjukdomar',
        title: {
            '*': 'Specialister inom infektionssjukdomar',
            '1': 'Specialist inom infektionssjukdomar',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_INTERNMEDICIN]: {
        education: 'Internmedicin',
        title: {
            '*': 'Specialister inom internmedicin',
            '1': 'Specialist inom internmedicin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KARDIOLOGI]: {
        education: 'Kardiologi',
        title: {
            '*': 'Specialister inom kardiologi',
            '1': 'Specialist inom kardiologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KARLKIRURGI]: {
        education: 'Kärlkirurgi',
        title: {
            '*': 'Specialister inom kärlkirurg',
            '1': 'Specialist inom kärlkirurg',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KIRURGI]: {
        education: 'Kirurgi',
        title: {
            '*': 'Specialister inom kirurgi',
            '1': 'Specialist inom kirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKFARMAKOLOGI]: {
        education: 'Klinisk farmakologi',
        title: {
            '*': 'Specialister inom klinisk farmakologi',
            '1': 'Specialist inom klinisk farmakologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKFYSIOLOGI]: {
        education: 'Klinisk fysiologi',
        title: {
            '*': 'Specialister inom klinisk fysiologi',
            '1': 'Specialist inom klinisk fysiologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKGENETIK]: {
        education: 'Klinisk genetik',
        title: {
            '*': 'Specialister inom klinisk genetik',
            '1': 'Specialist inom klinisk genetik',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKKEMI]: {
        education: 'Klinisk kemi',
        title: {
            '*': 'Specialister inom klinisk kemi',
            '1': 'Specialist inom klinisk kemi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKMIKROBIOLOGI]: {
        education: 'Klinisk mikrobiologi',
        title: {
            '*': 'Specialister inom klinisk mikrobiologi',
            '1': 'Specialist inom klinisk mikrobiologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKNEUROFYSIOLOGI]: {
        education: 'Klinisk neurofysiologi',
        title: {
            '*': 'Specialister inom klinisk neurofysiologi',
            '1': 'Specialist inom klinisk neurofysiologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_LUNGSJUKDOMAR]: {
        education: 'Lungsjukdomar',
        title: {
            '*': 'Specialister inom lungsjukdomar',
            '1': 'Specialist inom lungsjukdomar',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NEONATOLOGI]: {
        education: 'Neonatologi',
        title: {
            '*': 'Specialister inom neonatologi',
            '1': 'Specialist inom neonatologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NEUROKIRURGI]: {
        education: 'Neurokirurgi',
        title: {
            '*': 'Specialister inom neurokirurgi',
            '1': 'Specialist inom neurokirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NEUROLOGI]: {
        education: 'Neurologi',
        title: {
            '*': 'Specialister inom neurologi',
            '1': 'Specialist inom neurologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NEURORADIOLOGI]: {
        education: 'Neuroradiologi',
        title: {
            '*': 'Specialister inom neuroradiologi',
            '1': 'Specialist inom neuroradiologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NJURMEDICIN]: {
        education: 'Njurmedicin',
        title: {
            '*': 'Specialister inom njurmedicin',
            '1': 'Specialist inom njurmedicin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NONE]: {
        education: 'Leg. Läkare',
        title: {
            '*': 'Leg. Läkare',
            '1': 'Leg. Läkare',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NUKLEARMEDICIN]: {
        education: 'Nuklearmedicin',
        title: {
            '*': 'Specialister inom nuklearmedicin',
            '1': 'Specialist inom nuklearmedicin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_OBSTETRIKOCHGYNEKOLOGI]: {
        education: 'Obstetrik och gynekologi',
        title: {
            '*': 'Specialister inom obstetrik och gynekologi',
            '1': 'Specialist inom obstetrik och gynekologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_OGONSJUKDOMAR]: {
        education: 'Ögonsjukdomar',
        title: {
            '*': 'Specialister inom ögonsjukdomar',
            '1': 'Specialist inom ögonsjukdomar',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ONKOLOGI]: {
        education: 'Onkologi',
        title: {
            '*': 'Specialister inom onkologi',
            '1': 'Specialist inom onkologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ORONNASAHALSSJUKDOMAR]: {
        education: 'Öron-, näs- och halssjukdomar',
        title: {
            '*': 'Specialister inom öron-, näs- och halssjukdomar',
            '1': 'Specialist inom öron-, näs- och halssjukdomar',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ORTOPEDI]: {
        education: 'Ortopedi',
        title: {
            '*': 'Specialister inom ortopedi',
            '1': 'Specialist inom ortopedi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_PALLIATIVMEDICIN]: {
        education: 'Palliativmedicin',
        title: {
            '*': 'Specialister inom palliativmedicin',
            '1': 'Specialist inom palliativmedicin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_PATOLOGI]: {
        education: 'Patologi',
        title: {
            '*': 'Specialister inom patologi',
            '1': 'Specialist inom patologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_PLASTIKKIRURGI]: {
        education: 'Plastikkirurgi',
        title: {
            '*': 'Specialister inom plastikkirurgi',
            '1': 'Specialist inom plastikkirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_PSYKIATRI]: {
        education: 'Psykiatri',
        title: {
            '*': 'Specialister inom psykiatri',
            '1': 'Specialist inom psykiatri',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_RADIOLOGI]: {
        education: 'Radiologi',
        title: {
            '*': 'Specialister inom radiologi',
            '1': 'Specialist inom radiologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_RATTSMEDICIN]: {
        education: 'Rättsmedicin',
        title: {
            '*': 'Specialister inom rättsmedicin',
            '1': 'Specialist inom rättsmedicin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_REHABILITERINGSMEDICIN]: {
        education: 'Rehabiliteringsmedicin',
        title: {
            '*': 'Specialister inom rehabiliteringsmedicin',
            '1': 'Specialist inom rehabiliteringsmedicin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_REUMATOLOGI]: {
        education: 'Reumatologi',
        title: {
            '*': 'Specialister inom reumatologi',
            '1': 'Specialist inom reumatologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ROSTOCHTALRUBBNINGAR]: {
        education: 'Röst- och talrubbningar',
        title: {
            '*': 'Specialister inom röst- och talrubbningar',
            '1': 'Specialist inom röst- och talrubbningar',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_SOCIALMEDICIN]: {
        education: 'Socialmedicin',
        title: {
            '*': 'Specialister inom socialmedicin',
            '1': 'Specialist inom socialmedicin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_THORAXKIRURGI]: {
        education: 'Thoraxkirurgi',
        title: {
            '*': 'Specialister inom thoraxkirurgi',
            '1': 'Specialist inom thoraxkirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_UROLOGI]: {
        education: 'Urologi',
        title: {
            '*': 'Specialister inom urologi',
            '1': 'Specialist inom urologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_UTLANDSKUTBILDNING]: {
        education: 'Utländsk utbildning',
        title: {
            '*': 'Utlandsutbildade',
            '1': 'Utlandsutbildad',
        },
    },
};
const nurseSpecialization = {
    [enums_1.NurseSpecialization.NURSE_AKUTSJUKVARD]: {
        education: 'Akutsjukvård',
        title: {
            '*': 'Akutsjuksköterskor',
            '1': 'Akutsjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_AMBULANSSJUKVARD]: {
        education: 'Ambulanssjukvård',
        title: {
            '*': 'Ambulanssjuksköterskor',
            '1': 'Ambulanssjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_ANESTESTISJUKVARD]: {
        education: 'Anestesisjukvård',
        title: {
            '*': 'Anestesisjuksköterskor',
            '1': 'Anestesisjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_ASTMA_KOL]: {
        education: 'Astma/KOL',
        title: {
            '*': 'Astma/KOL-sjuksköterskor',
            '1': 'Astma/KOL-sjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_BARNMORSKA]: {
        education: 'Barnmorska',
        title: {
            '*': 'Barnmorskor',
            '1': 'Barnmorska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_BARNOCHUNGDOM]: {
        education: 'Barn och ungdom',
        title: {
            '*': 'Barn- och ungdomssjuksköterskor',
            '1': 'Barn- och ungdomssjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_DEMENSVARD]: {
        education: 'Demensvård',
        title: {
            '*': 'Demenssjuksköterskor',
            '1': 'Demenssjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_DIABETES]: {
        education: 'Diabetessjukvård',
        title: {
            '*': 'Diabetessjuksköterskor',
            '1': 'Diabetessjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_DIALYS]: {
        education: 'Dialyssjukvård',
        title: {
            '*': 'Dialyssjuksköterskor',
            '1': 'Dialyssjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_DISTRIKTSKOTERSKA]: {
        education: 'Distriktssjukvård',
        title: {
            '*': 'Distriktsköterskor',
            '1': 'Distriktssköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_GENERIC_SPECIALIZATION]: {
        education: 'Specialistsjuksköterska',
        title: {
            '*': 'Specialistsjuksköterskor',
            '1': 'Specialistsjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_GERIATRIK]: {
        education: 'Geriatrik',
        title: {
            '*': 'Geriatriksjuksköterskor',
            '1': 'Geriatriksjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_INTENSIVVARD]: {
        education: 'Intensivvård',
        title: {
            '*': 'Intensivvårdssjuksköterskor',
            '1': 'Intensivvårdssjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_KIRURGISKVARD]: {
        education: 'Kirurgisk vård',
        title: {
            '*': 'Kirurgisjuksköterskor',
            '1': 'Kirurgisjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_MEDICINSKVARD]: {
        education: 'Medicinsk vård',
        title: {
            '*': 'Medicinsjuksköterskor',
            '1': 'Medicinsjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_NONE]: {
        education: 'Leg. Sjuksköterska',
        title: {
            '*': 'Leg. Sjuksköterskor',
            '1': 'Leg. Sjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_OGONSJUKDOMAR]: {
        education: 'Ögonsjukdomar',
        title: {
            '*': 'Ögonssjuksköterskor',
            '1': 'Ögonsjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_ONKOLOGISKVARD]: {
        education: 'Onkologisk vård',
        title: {
            '*': 'Onkologisjuksköterskor',
            '1': 'Onkologisjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_OPERATIONSSJUKVARD]: {
        education: 'Operationssjukvård',
        title: {
            '*': 'Operationssjuksköterskor',
            '1': 'Operationssjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_ORTOPTIST]: {
        education: 'Ortoptist',
        title: {
            '*': 'Ortoptister',
            '1': 'Ortoptist',
        },
    },
    [enums_1.NurseSpecialization.NURSE_PSYKIATRISKVARD]: {
        education: 'Psykiatrisk vård',
        title: {
            '*': 'Psykiatrisjuksköterskor',
            '1': 'Psykiatrisjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_RONTGEN]: {
        education: 'Röntgen',
        title: {
            '*': 'Röntgensjuksköterskor',
            '1': 'Röntgensjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_SILVIASJUKSKOTERSKA]: {
        education: 'Demenssjukvård',
        title: {
            '*': 'Silviasjuksköterskor',
            '1': 'Silviasjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_SJUKGYMNAST]: {
        education: 'Sjukgymnastik',
        title: {
            '*': 'Sjukgymnaster',
            '1': 'Sjukgymnast',
        },
    },
    [enums_1.NurseSpecialization.NURSE_SKOLSJUKVARD]: {
        education: 'Skolsjukvård',
        title: {
            '*': 'Skolsjuksköterskor',
            '1': 'Skolsjuksköterska',
        },
    },
    [enums_1.NurseSpecialization.NURSE_VARDAVALDRE]: {
        education: 'Vård av äldre',
        title: {
            '*': 'Äldrespecialister',
            '1': 'Äldrespecialist',
        },
    },
};
exports.specialization = {
    ...doctorSpecialization,
    ...nurseSpecialization,
};
// TODO: replace with TypeScript satisfies when TypeScript is upgraded
const _satisfies = exports.specialization;
