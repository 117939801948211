"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenderWaiverStatus = void 0;
var TenderWaiverStatus;
(function (TenderWaiverStatus) {
    TenderWaiverStatus["PENDING"] = "PENDING";
    TenderWaiverStatus["APPROVED"] = "APPROVED";
    TenderWaiverStatus["REJECTED"] = "REJECTED";
    TenderWaiverStatus["USED"] = "USED";
})(TenderWaiverStatus || (exports.TenderWaiverStatus = TenderWaiverStatus = {}));
