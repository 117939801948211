import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/gatsby'
import { identify } from '@bemlo/tracking'

import {
  ConnectionStatus,
  ProfessionalIdentityFragment,
  useMeQuery,
} from '../../generated/graphql'
import { ProviderProps } from '../../common/types'
import { useSiteMetadata } from '../hooks/useSiteMetadata'
import { currentMarket } from '../../config/market-configs'
import { useCookiePreferenceContext } from './CookiePreferenceContext'
import { onAuthStateChanged, User } from 'firebase/auth'
import { useFirebase } from '../hooks/useFirebase'

type UtmValues = { [key: string]: string }

interface AuthContextInterface {
  currentUser?: User | null
  refetchMe: () => void
  me?: ProfessionalIdentityFragment | null
  employersInContactWith: string[]
  employersWithContactRequests: string[]
  isLoggedIn: boolean
  signOut: () => void
  utmValues: UtmValues
  loading: boolean
}

const AuthContext = createContext<AuthContextInterface | undefined>(undefined)

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(
      'useAuthContext must be used within the useAuthContext.Provider',
    )
  }
  return context
}

export const AuthContextProvider = ({
  children,
  utmValues,
}: ProviderProps & { utmValues: UtmValues }) => {
  const { getAuth } = useFirebase()
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState<User | null>()
  const isLoggedIn = !!currentUser?.uid
  const meta = useSiteMetadata()
  const [, setCookiePreference] = useCookiePreferenceContext()
  const { data, refetch: refetchMe } = useMeQuery({
    skip: !isLoggedIn,
  })

  const excludeBemlo = ({ employerId }: { employerId: string }) =>
    employerId !== 'bemlo'
  const me = data
    ? {
        ...data.me,
        connections: data.me.connections.filter(excludeBemlo),
      }
    : undefined

  useEffect(() => {
    onAuthStateChanged(getAuth(), (newCurrentUser) => {
      setLoading(false)
      setCurrentUser(newCurrentUser)
      if (newCurrentUser) {
        identify(newCurrentUser.uid, {
          market: currentMarket,
          name: newCurrentUser.displayName,
          email: newCurrentUser.email,
          isBemlo: newCurrentUser.email?.includes(meta.emailDomain),
          product: meta.siteName.toUpperCase(),
        })
        // eslint-disable-next-line import/namespace
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: newCurrentUser.uid,
            name: newCurrentUser.displayName,
            ...(newCurrentUser.email && { email: newCurrentUser.email }),
            isBemlo: newCurrentUser.email?.includes(meta.emailDomain),
          })
        })
        setCookiePreference('ALL_COOKIES')
      }
    })
  }, [])

  const value = useMemo(() => {
    const employersInContactWith =
      me?.connections
        .filter((connection) => connection.status === ConnectionStatus.UNLOCKED)
        .map(({ employerId }) => employerId) ?? []

    const employersWithContactRequests =
      me?.connections
        .filter((connection) => connection.status === ConnectionStatus.PENDING)
        .map(({ employerId }) => employerId) ?? []

    return {
      currentUser,
      isLoggedIn,
      refetchMe,
      me,
      employersInContactWith,
      employersWithContactRequests,
      signOut: () => getAuth().signOut(),
      utmValues,
      loading,
    }
  }, [currentUser, isLoggedIn, me, refetchMe])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
