"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.country = void 0;
const enums_1 = require("@bemlo/enums");
exports.country = {
    [enums_1.Country.BELGIUM]: 'Belgia',
    [enums_1.Country.DENMARK]: 'Danmark',
    [enums_1.Country.FINLAND]: 'Finland',
    [enums_1.Country.FRANCE]: 'Frankrike',
    [enums_1.Country.GERMANY]: 'Tyskland',
    [enums_1.Country.NORWAY]: 'Norge',
    [enums_1.Country.SPAIN]: 'Spania',
    [enums_1.Country.SWEDEN]: 'Sverige',
    [enums_1.Country.UNITED_KINGDOM]: 'Storbritannia',
};
