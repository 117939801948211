"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceAvailabilitySource = void 0;
var ResourceAvailabilitySource;
(function (ResourceAvailabilitySource) {
    ResourceAvailabilitySource["PREFERENCE"] = "PREFERENCE";
    ResourceAvailabilitySource["TENDER"] = "TENDER";
    ResourceAvailabilitySource["SCHEDULE"] = "SCHEDULE";
    ResourceAvailabilitySource["VACATION"] = "VACATION";
})(ResourceAvailabilitySource || (exports.ResourceAvailabilitySource = ResourceAvailabilitySource = {}));
