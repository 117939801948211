"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countryForMarket = countryForMarket;
exports.marketForCountry = marketForCountry;
exports.currencyForCountry = currencyForCountry;
exports.getCountryForRegion = getCountryForRegion;
exports.getCountryForMunicipality = getCountryForMunicipality;
const lodash_1 = require("lodash");
const enums_1 = require("@bemlo/enums");
const MARKET_COUNTRY_RECORD = {
    [enums_1.Market.DK]: enums_1.Country.DENMARK,
    [enums_1.Market.FI]: enums_1.Country.FINLAND,
    [enums_1.Market.GB]: enums_1.Country.UNITED_KINGDOM,
    [enums_1.Market.NO]: enums_1.Country.NORWAY,
    [enums_1.Market.SE]: enums_1.Country.SWEDEN,
};
function countryForMarket(market) {
    const country = MARKET_COUNTRY_RECORD[market];
    if (!country) {
        throw new Error(`No country found for "${market}"`);
    }
    return country;
}
const COUNTRY_MARKET_RECORD = (0, lodash_1.invert)(MARKET_COUNTRY_RECORD);
Object.fromEntries(Object.entries(MARKET_COUNTRY_RECORD).map(([market, country]) => [
    country,
    market,
]));
function marketForCountry(country) {
    const market = COUNTRY_MARKET_RECORD[country];
    return market;
}
const COUNTRY_CURRENCY_RECORD = {
    [enums_1.Country.DENMARK]: enums_1.Currency.DKK,
    [enums_1.Country.FINLAND]: enums_1.Currency.EUR,
    [enums_1.Country.NORWAY]: enums_1.Currency.NOK,
    [enums_1.Country.SWEDEN]: enums_1.Currency.SEK,
    [enums_1.Country.UNITED_KINGDOM]: enums_1.Currency.GBP,
    [enums_1.Country.FRANCE]: enums_1.Currency.EUR,
    [enums_1.Country.GERMANY]: enums_1.Currency.EUR,
    [enums_1.Country.SPAIN]: enums_1.Currency.EUR,
    [enums_1.Country.BELGIUM]: enums_1.Currency.EUR,
};
function currencyForCountry(country) {
    return COUNTRY_CURRENCY_RECORD[country];
}
let getCountryForRegionCache = undefined;
function getCountryForRegion(region) {
    if (!getCountryForRegionCache) {
        ;
        getCountryForRegionCache = Object.fromEntries(Object.entries(enums_1.COUNTRY_REGION_MUNICIPALITY_HIERARCHY).flatMap(([country, regions]) => Object.keys(regions).map((region) => [region, country])));
    }
    return getCountryForRegionCache[region];
}
let getCountryForMunicipalityCache = undefined;
function getCountryForMunicipality(municipality) {
    if (!getCountryForMunicipalityCache) {
        ;
        getCountryForMunicipalityCache = Object.fromEntries(Object.entries(enums_1.COUNTRY_REGION_MUNICIPALITY_HIERARCHY).flatMap(([country, regions]) => Object.values(regions).flatMap((municipalities) => municipalities.map((municipality) => [municipality, country]))));
    }
    return getCountryForMunicipalityCache[municipality];
}
