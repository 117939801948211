"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Detail = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Flex_1 = require("./Flex");
const HoverCard_1 = require("./HoverCard");
const Icon_1 = require("./Icon");
const Link_1 = require("./Link");
const Text_1 = require("./Text");
const constants_1 = require("../constants");
const Detail = (props) => {
    const detail = ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { gap: props.compact ? 2 : 3, items: "center", styles: { flexShrink: '1', overflow: 'hidden' }, children: [(0, jsx_runtime_1.jsx)(Icon_1.Icon, { color: props.iconColor ?? constants_1.Color.DARKER_GREY, design: props.solidIcon ? 'solid' : 'outline', name: props.icon, size: "sm" }), typeof props.label === 'string' ? ((0, jsx_runtime_1.jsx)(Text_1.Text, { fontSize: 14, bold: props.bold, color: props.labelColor ?? constants_1.Color.DARKER_GREY, styles: {
                    whiteSpace: props.ellipsis ? 'nowrap' : 'normal',
                    overflow: props.ellipsis ? 'hidden' : 'visible',
                    textOverflow: props.ellipsis ? 'ellipsis' : 'clip',
                }, children: props.label })) : (props.label)] }));
    if (props.hoverCard) {
        return ((0, jsx_runtime_1.jsx)(HoverCard_1.HoverCard, { arrow: false, side: "bottom", sideOffset: -50, trigger: (0, jsx_runtime_1.jsxs)(Flex_1.Flex, { gap: 2, children: [detail, (0, jsx_runtime_1.jsx)(Icon_1.Icon, { size: "sm", name: "InformationCircleIcon", color: props.labelColor ?? constants_1.Color.BLUE })] }), children: props.hoverCard }));
    }
    if (props.to) {
        return ((0, jsx_runtime_1.jsx)(Link_1.Link, { to: props.to, styles: { width: '100%', display: 'block' }, children: (0, jsx_runtime_1.jsxs)(Flex_1.Flex, { gap: 2, children: [detail, (0, jsx_runtime_1.jsx)(Icon_1.Icon, { size: "sm", name: "ExternalLinkIcon", color: constants_1.Color.BLUE })] }) }));
    }
    return detail;
};
exports.Detail = Detail;
