"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chat = void 0;
const ChatActions_1 = require("./ChatActions");
const ChatAvatar_1 = require("./ChatAvatar");
const ChatBadge_1 = require("./ChatBadge");
const ChatBrowser_1 = require("./ChatBrowser");
const ChatBrowserControls_1 = require("./ChatBrowserControls");
const ChatCard_1 = require("./ChatCard");
const ChatCards_1 = require("./ChatCards");
const ChatContent_1 = require("./ChatContent");
const ChatEmptyPane_1 = require("./ChatEmptyPane");
const ChatHeader_1 = require("./ChatHeader");
const ChatPane_1 = require("./ChatPane");
const ChatTabs_1 = require("./ChatTabs");
const ChatView_1 = require("./ChatView");
exports.Chat = {
    Actions: ChatActions_1.ChatActions,
    Avatar: ChatAvatar_1.ChatAvatar,
    Badge: ChatBadge_1.ChatBadge,
    Browser: ChatBrowser_1.ChatBrowser,
    BrowserControls: ChatBrowserControls_1.ChatBrowserControls,
    Card: ChatCard_1.ChatCard,
    Cards: ChatCards_1.ChatCards,
    Content: ChatContent_1.ChatContent,
    EmptyPane: ChatEmptyPane_1.ChatEmptyPane,
    Header: ChatHeader_1.ChatHeader,
    Pane: ChatPane_1.ChatPane,
    Tabs: ChatTabs_1.ChatTabs,
    View: ChatView_1.ChatView,
};
__exportStar(require("./types"), exports);
