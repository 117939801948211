"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatMessages = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_2 = require("react");
const ChatMessage_1 = require("./ChatMessage");
const elements_1 = require("../../elements");
exports.ChatMessages = (0, react_2.forwardRef)(function ChatMessages(props, ref) {
    return ((0, jsx_runtime_1.jsx)(elements_1.Flex, { col: true, gap: 3.5, grow: 1, ref: ref, styles: { height: 0, overflowY: 'auto', px: 4, py: 3.5 }, children: props.messages.map((message) => ((0, react_1.createElement)(ChatMessage_1.ChatMessage, { ...message, id: message.id, isMe: message.sender.key === props.userId, key: message.key, onToggleFlag: props.onToggleFlag }))) }));
});
