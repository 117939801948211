"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatMembersHoverable = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const constants_1 = require("../../constants");
const elements_1 = require("../../elements");
const ChatMembersHoverable = (props) => {
    return ((0, jsx_runtime_1.jsx)(elements_1.AvatarStack, { avatars: props.members.map((member) => ({
            ...member,
            image: member.avatarUrl,
        })), hoverCardContent: (0, jsx_runtime_1.jsx)(elements_1.Paper, { styles: { p: 2.5 }, children: (0, jsx_runtime_1.jsx)(elements_1.Div, { styles: {
                    alignItems: 'center',
                    columnGap: 3.5,
                    display: 'grid',
                    fontSize: constants_1.theme.font.size[6],
                    gridTemplateColumns: 'auto auto',
                    rowGap: 2.5,
                }, children: props.members.map((member) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 2.5, items: "center", children: [(0, jsx_runtime_1.jsx)(elements_1.Avatar, { ...member, image: member.avatarUrl, size: "sm" }), (0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, children: member.name }), member.email && ((0, jsx_runtime_1.jsx)(elements_1.Link, { to: `mailto:${member.email}`, children: (0, jsx_runtime_1.jsx)(elements_1.Text, { color: constants_1.Color.BLUE, size: 6.5, children: member.email }) }))] })] }), member.role ? ((0, jsx_runtime_1.jsx)(elements_1.Badge, { color: "green", size: "sm", children: member.role })) : ((0, jsx_runtime_1.jsx)("div", {}))] }, member.key))) }) }) }));
};
exports.ChatMembersHoverable = ChatMembersHoverable;
