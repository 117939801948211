"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFallbackComparator = void 0;
const react_1 = require("react");
const useFallbackComparator = (operations, selectedOperation, setSelectedOperation) => {
    (0, react_1.useEffect)(() => {
        if (!operations.find((operation) => operation.value === selectedOperation)) {
            setSelectedOperation(operations[0]?.value ?? 'is');
        }
    }, [operations]);
};
exports.useFallbackComparator = useFallbackComparator;
