"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WhichRegionAlternative = exports.UnitedKingdomWhichRegionAlternative = exports.SwedenWhichRegionAlternative = exports.NorwayWhichRegionAlternative = exports.SpecialWhichRegionAlternative = void 0;
const region_municipality_1 = require("./data/region-municipality");
var SpecialWhichRegionAlternative;
(function (SpecialWhichRegionAlternative) {
    SpecialWhichRegionAlternative["ALL"] = "regionAll";
    SpecialWhichRegionAlternative["NONE"] = "regionNone";
})(SpecialWhichRegionAlternative || (exports.SpecialWhichRegionAlternative = SpecialWhichRegionAlternative = {}));
function createWhichRegionAlternative(hierarchy) {
    return Object.keys(hierarchy).reduce((result, key) => {
        result[key] = hierarchy[key].value;
        return result;
    }, {});
}
exports.NorwayWhichRegionAlternative = createWhichRegionAlternative(region_municipality_1.NORWAY_REGION_MUNICIPALITY_HIERARCHY);
exports.SwedenWhichRegionAlternative = createWhichRegionAlternative(region_municipality_1.SWEDEN_REGION_MUNICIPALITY_HIERARCHY);
exports.UnitedKingdomWhichRegionAlternative = createWhichRegionAlternative(region_municipality_1.UNITED_KINGDOM_REGION_MUNICIPALITY_HIERARCHY);
exports.WhichRegionAlternative = {
    ...SpecialWhichRegionAlternative,
    ...exports.NorwayWhichRegionAlternative,
    ...exports.SwedenWhichRegionAlternative,
    ...exports.UnitedKingdomWhichRegionAlternative,
};
