"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tenderStatus = void 0;
const enums_1 = require("@bemlo/enums");
exports.tenderStatus = {
    [enums_1.TenderStatus.DRAFT]: 'Utkast',
    [enums_1.TenderStatus.PUBLISHED]: 'Publisert',
    [enums_1.TenderStatus.CLOSED]: 'Avsluttet',
    [enums_1.TenderStatus.FILLED]: 'Tillsatt',
    [enums_1.TenderStatus.URGENT]: 'Haster',
    [enums_1.TenderStatus.ASSIGNED]: 'Tildelt',
};
