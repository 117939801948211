import { isTruthy } from '@bemlo/utils'
import { createContext, useContext, useMemo } from 'react'

import { ProviderProps } from '../../common/types'
import { marketConfig } from '../../config/market-configs'
import {
  ContextCompanyFragment,
  useGetContextCompaniesQuery,
} from '../../generated/graphql'

import companiesContextContentQuery, {
  ContentfulCompany,
  PremiumPlacement,
} from './companiesContextContent/__MARKET__'

export type CompanyWithScore = ContentfulCompany &
  ContextCompanyFragment['companyRating']

interface CompaniesContextValues {
  companies: CompanyWithScore[]
  premiumPlacements: PremiumPlacement[]
}

const CompaniesContext = createContext<CompaniesContextValues | null>(null)

export const useCompaniesContext = () => {
  const context = useContext(CompaniesContext)
  if (!context) {
    throw new Error(
      'useCompaniesContext must be used within the useCompaniesContext.Provider',
    )
  }
  return context
}

export const CompaniesContextProvider = ({ children }: ProviderProps) => {
  const { data: employersData } = useGetContextCompaniesQuery({
    variables: {
      markets: [marketConfig.market],
      reviewsFrom: marketConfig.reviewsFrom,
    },
    context: {
      isAuthRequired: false,
    },
  })

  const contentfulData = companiesContextContentQuery()

  const value = useMemo(() => {
    const contentfulCompanies = contentfulData.companies.edges.reduce<
      Record<string, ContentfulCompany>
    >(
      (acc, company) => ({
        ...acc,
        [company.node.employerId]: company.node,
      }),
      {},
    )

    const premiumPlacements = contentfulData.premiumPlacements.edges.map(
      (edge) => edge.node,
    )

    const companies: CompanyWithScore[] =
      employersData?.allRecruitingOrganizations
        .map((organization) => {
          const contentfulCompany = contentfulCompanies[organization.id]

          if (!contentfulCompany) {
            /*
              We don't have a contentful company for this employer in this market.
              Warning for this is logged during build process
            */
            return
          }

          return {
            ...contentfulCompany,
            ...organization.companyRating,
          }
        })
        .filter(isTruthy) ?? []

    return {
      companies,
      premiumPlacements,
    }
  }, [employersData, contentfulData])

  return (
    <CompaniesContext.Provider value={value}>
      {children}
    </CompaniesContext.Provider>
  )
}
