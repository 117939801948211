"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactRequests = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ContactRequest_1 = require("./ContactRequest");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const SectionHeading_1 = require("../components/SectionHeading");
const useProfessionalView_1 = require("../hooks/useProfessionalView");
const ContactRequests = ({ contactRequests, onContactRequestClick, onOpenContactRequestsClick, }) => {
    const { actor, isMe } = (0, useProfessionalView_1.useProfessionalView)();
    const t = (0, translate_1.useTranslations)();
    if (!isMe ||
        !contactRequests.length ||
        !actor.can('read', 'contactRequests')) {
        return null;
    }
    const unreadCount = contactRequests.filter((contactRequest) => {
        return !contactRequest.contactRequestOpenedAt;
    }).length;
    return ((0, jsx_runtime_1.jsxs)(elements_1.Paper, { children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { items: "center", justify: "between", styles: {
                    backgroundColor: constants_1.Color.DARK_GREEN,
                    borderTopLeftRadius: constants_1.theme.border.radius.normal - 1,
                    borderTopRightRadius: constants_1.theme.border.radius.normal - 1,
                    px: 3.5,
                    py: 2,
                }, children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 2, items: "center", children: [(0, jsx_runtime_1.jsx)(SectionHeading_1.SectionHeading, { styles: { color: constants_1.Color.WHITE, mb: 0 }, children: t.professional_view.contact_requests.contact_request_other() }), unreadCount >= 1 && ((0, jsx_runtime_1.jsx)(elements_1.Badge, { color: "green", size: "sm", children: t.professional_view.contact_requests.n_new(unreadCount) }))] }), (0, jsx_runtime_1.jsx)(elements_1.Icon, { color: constants_1.Color.WHITE, name: "ArrowsExpandIcon", onClick: onOpenContactRequestsClick, styles: { cursor: 'pointer' } })] }), (0, jsx_runtime_1.jsx)(elements_1.Div, { styles: { maxH: '224px', overflow: 'auto' }, children: contactRequests.map((contactRequest) => ((0, jsx_runtime_1.jsx)(ContactRequest_1.ContactRequest, { contactRequest: contactRequest, onClick: onContactRequestClick }, contactRequest.id))) })] }));
};
exports.ContactRequests = ContactRequests;
