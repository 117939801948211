"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDateRangePicker = void 0;
const react_1 = require("react");
const useRangeType_1 = require("./useRangeType");
const useDateRangePicker = (args) => {
    const enabledRangeTypes = args.types?.length ? args.types : useRangeType_1.ALL_RANGE_TYPES;
    const [startDate, setStartDate] = (0, react_1.useState)(args.startDate);
    const [endDate, setEndDate] = (0, react_1.useState)(args.endDate);
    const [rangeType, setRangeType] = (0, react_1.useState)(enabledRangeTypes[0]);
    const { selectedOption, options } = (0, useRangeType_1.useRangeTypes)(rangeType, enabledRangeTypes);
    (0, react_1.useEffect)(() => {
        if (rangeType === 'custom')
            return;
        const lastDate = selectedOption?.getLastDate?.(startDate);
        if (!lastDate)
            return;
        setEndDate(lastDate);
    }, []);
    const handleSetRangeType = (range) => {
        const newSelectedOption = options.find((option) => option.value === range);
        if (!newSelectedOption)
            return;
        const firstDate = newSelectedOption.getFirstDate?.(startDate);
        const lastDate = firstDate && newSelectedOption.getLastDate?.(firstDate);
        setRangeType(range);
        if (!firstDate || !lastDate)
            return;
        setStartDate(firstDate);
        setEndDate(lastDate);
    };
    const handleOnChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };
    return (0, react_1.useMemo)(() => ({
        startDate,
        endDate,
        onChange: handleOnChange,
        rangeType,
        setRangeType: handleSetRangeType,
        enabledRangeTypes,
    }), [startDate, endDate, rangeType, args.types]);
};
exports.useDateRangePicker = useDateRangePicker;
