"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShiftDurations = exports.shiftToDayjs = void 0;
const date_1 = require("@bemlo/date");
const getDuration = (startMoment, endMoment) => {
    return date_1.dayjsEn.duration(endMoment.diff(startMoment));
};
const getShiftDurations = (shift) => {
    const shiftDuration = getDuration(shift.startDateTime, shift.endDateTime);
    const breakDuration = date_1.dayjsEn.duration(shift.breakTime, 'minutes');
    const onCallDuration = shift.onCallTime
        ? getDuration(shift.onCallTime.startDateTime, shift.onCallTime.endDateTime)
        : date_1.dayjsEn.duration(0);
    let scheduled = shiftDuration.subtract(onCallDuration);
    if (scheduled.asHours()) {
        scheduled = scheduled.subtract(breakDuration);
    }
    return {
        // The full length of the shift, no matter on call, break or ordinary time
        shift: shiftDuration,
        // The time spent on break
        break: breakDuration,
        // The time spent on call
        onCall: onCallDuration,
        // The time spent working and not on break or on call
        scheduled,
    };
};
exports.getShiftDurations = getShiftDurations;
const dateToDayjs = (dateTime) => {
    return typeof dateTime === 'number'
        ? date_1.dayjsEn.unix(dateTime)
        : (0, date_1.dayjsEn)(dateTime);
};
const shiftToDayjs = (shift) => {
    const startDateTime = dateToDayjs(shift.startDateTime);
    const endDateTime = dateToDayjs(shift.endDateTime);
    let onCallTime = null;
    if (shift.onCallTime) {
        onCallTime = {
            type: shift.onCallTime.type,
            startDateTime: dateToDayjs(shift.onCallTime.startDateTime),
            endDateTime: dateToDayjs(shift.onCallTime.endDateTime),
            startTime: shift.onCallTime.startTime,
            endTime: shift.onCallTime.endTime,
        };
    }
    const dayjsShift = {
        ...shift,
        startDateTime,
        endDateTime,
        onCallTime,
    };
    return { ...dayjsShift, durations: getShiftDurations(dayjsShift) };
};
exports.shiftToDayjs = shiftToDayjs;
