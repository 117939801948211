"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePersistedState = void 0;
const react_1 = require("react");
const usePersistedState = (key, defaultValue) => {
    const [state, setInternalState] = (0, react_1.useState)(() => {
        try {
            const storedValue = localStorage.getItem(key);
            return storedValue ? JSON.parse(storedValue) : defaultValue;
        }
        catch {
            return defaultValue;
        }
    });
    const setState = (value) => {
        setInternalState((oldValue) => {
            if (typeof value === 'function') {
                value = value(oldValue);
            }
            try {
                localStorage.setItem(key, JSON.stringify(value));
            }
            catch {
                console.error('Error saving to localStorage');
            }
            return value;
        });
    };
    return [state, setState];
};
exports.usePersistedState = usePersistedState;
