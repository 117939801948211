"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.day = void 0;
const enums_1 = require("@bemlo/enums");
exports.day = {
    [enums_1.Day.MONDAY]: 'Mandag',
    [enums_1.Day.TUESDAY]: 'Tirsdag',
    [enums_1.Day.WEDNESDAY]: 'Onsdag',
    [enums_1.Day.THURSDAY]: 'Torsdag',
    [enums_1.Day.FRIDAY]: 'Fredag',
    [enums_1.Day.SATURDAY]: 'Lørdag',
    [enums_1.Day.SUNDAY]: 'Søndag',
};
