"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatBadge = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../constants");
const elements_1 = require("../../elements");
const ChatBadge = (props) => {
    return ((0, jsx_runtime_1.jsx)(elements_1.Badge, { ...props, size: "xs", squared: true, styles: { fontWeight: constants_1.theme.font.weight.medium, whiteSpace: 'nowrap' }, children: props.children }));
};
exports.ChatBadge = ChatBadge;
