"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trimTime = exports.formatTimeToken = exports.formatTime = void 0;
const clamp = (value, min, max) => {
    return value < min ? min : value > max ? max : value;
};
const formatTime = (time) => {
    const trimmedTime = (0, exports.trimTime)(time);
    if (!trimmedTime) {
        return trimmedTime;
    }
    const [hours, minutes] = trimmedTime.split(':');
    const hh = (0, exports.formatTimeToken)(hours.slice(0, 2));
    if (hh >= '24') {
        return '24:00';
    }
    const mm = (0, exports.formatTimeToken)(time.includes(':') ? minutes.slice(0, 2) : hours.slice(2, 4), 59);
    return `${hh}:${mm}`;
};
exports.formatTime = formatTime;
const formatTimeToken = (token, max = Infinity) => {
    const paddedNumber = String(token).padStart(2, '0');
    const parsedNumber = parseInt(paddedNumber, 10);
    if (!parsedNumber) {
        return '00';
    }
    const clampedNumber = clamp(parsedNumber, 0, max);
    return String(clampedNumber).padStart(2, '0');
};
exports.formatTimeToken = formatTimeToken;
const trimTime = (time) => {
    return time.replace(/[^0-9:]/g, '');
};
exports.trimTime = trimTime;
