"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAvailabilitiesSchema = void 0;
const react_1 = require("react");
const enums_1 = require("@bemlo/enums");
const hooks_1 = require("../../../hooks");
const translate_1 = require("../../../translate");
const useAvailabilitiesSchema = ({ maxScope }) => {
    const t = (0, translate_1.useTranslations)();
    const z = (0, hooks_1.useZod)();
    return (0, react_1.useMemo)(() => z.array(z
        .object({
        scope: z
            .number()
            .min(1, t.availability_schedule.schema_error_scope_must_be_greater_than_or_equal_to(1))
            .max(maxScope, t.availability_schedule.schema_error_scope_must_be_less_than_or_equal_to(maxScope)),
        availableScope: z.number().optional(),
        shiftHours: z
            .array(z.nativeEnum(enums_1.ShiftHours))
            .min(1, t.availability_schedule.schema_error_select_at_least_one_shift_hours_type()),
        days: z
            .array(z.object({
            selected: z.boolean(),
        }))
            .refine((days) => days.filter((day) => day.selected).length > 0, {
            message: t.availability_schedule.schema_error_select_at_least_one_day(),
        }),
    })
        .refine((availability) => availability.availableScope === undefined ||
        availability.scope <= availability.availableScope, (availability) => ({
        path: ['scope'],
        message: t.availability_schedule.schema_error_scope_must_be_less_than_or_equal_to(availability.availableScope ?? 0),
    }))), [z, maxScope]);
};
exports.useAvailabilitiesSchema = useAvailabilitiesSchema;
