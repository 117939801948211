"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfessionalViewContextProvider = exports.useProfessionalView = exports.ProfessionalViewContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const utils_1 = require("@bemlo/utils");
const can = (permissions, action, scope) => {
    const parts = scope.split('/');
    // Find the value of the most specific supplied permission:
    while (parts.length > 0) {
        const permissionScope = parts.join('/');
        const permission = permissions[permissionScope];
        if (permission?.[action] !== undefined) {
            return !!permission[action];
        }
        parts.pop();
    }
    return false;
};
const getValidHomeMunicipality = (professional) => {
    if (!professional.homeMunicipality) {
        return undefined;
    }
    const municipalities = (0, utils_1.getMunicipalities)({
        country: professional.homeCountry ?? undefined,
    });
    return municipalities.includes(professional.homeMunicipality)
        ? professional.homeMunicipality
        : undefined;
};
const getValidSpecializations = (professional, market) => {
    const { profession, specializations } = professional;
    const allSpecializations = (0, utils_1.getSpecializations)({
        market,
        professions: profession ? [profession] : undefined,
    });
    return specializations.filter((specialization) => allSpecializations.includes(specialization));
};
const parseProfessional = (professional, market) => {
    return {
        ...professional,
        homeMunicipality: getValidHomeMunicipality(professional),
        specializations: getValidSpecializations(professional, market),
    };
};
exports.ProfessionalViewContext = (0, react_1.createContext)(null);
const useProfessionalView = () => {
    const context = (0, react_1.useContext)(exports.ProfessionalViewContext);
    if (!context) {
        throw new Error('useProfessionalView must be used within a ProfessionalViewContext');
    }
    return context;
};
exports.useProfessionalView = useProfessionalView;
const ProfessionalViewContextProvider = (props) => {
    const [editState, setEditState] = (0, react_1.useState)(null);
    const { compact = false, isMe, market, permissions, professional } = props;
    const data = parseProfessional(editState?.data ?? professional, market);
    const editData = editState?.data ?? null;
    const editScopes = editState?.scope ?? null;
    const actor = {
        can: can.bind(null, props.permissions),
    };
    const isEditing = (scope) => {
        if (!editState) {
            return false;
        }
        if (!scope) {
            return true;
        }
        // Check whether we're editing the specified scope.
        // Elements scoped `/foo/bar` will be visible if the edit scope is either `/foo/bar` or `/foo`.
        return `${scope}/`.startsWith(`${editState.scope}/`);
    };
    const patchEditData = (data) => {
        setEditState((currentEditState) => {
            return currentEditState
                ? { ...currentEditState, data: { ...currentEditState.data, ...data } }
                : null;
        });
    };
    const toggleEditMode = (scope) => {
        setEditState((currentEditState) => {
            return currentEditState
                ? null
                : {
                    data: professional,
                    scope,
                };
        });
    };
    const trimAndUpdate = (name) => {
        return () => {
            const value = editData?.[name];
            if (typeof value === 'string') {
                patchEditData?.({ [name]: value.trim() });
            }
        };
    };
    const update = (name) => {
        return (value) => {
            patchEditData?.({ [name]: value });
        };
    };
    return ((0, jsx_runtime_1.jsx)(exports.ProfessionalViewContext.Provider, { value: {
            actor,
            compact,
            data,
            editData,
            editScopes,
            isEditing,
            isMe,
            market,
            patchEditData,
            permissions,
            professional,
            setEditState,
            toggleEditMode,
            trimAndUpdate,
            update,
        }, children: props.children }));
};
exports.ProfessionalViewContextProvider = ProfessionalViewContextProvider;
