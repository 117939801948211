"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptanceAvailabilitySchedule = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const AcceptanceAvailabilityHeaderRow_1 = require("./AcceptanceAvailabilityHeaderRow");
const AcceptanceAvailabilityRow_1 = require("./AcceptanceAvailabilityRow");
const AvailabilityScheduleGridWrapper_1 = require("../atoms/AvailabilityScheduleGridWrapper");
const AcceptanceAvailabilitySchedule = ({ availabilities, setAvailabilities, viewState, schema, }) => {
    const setAvailabilityForWeek = (index) => (availability) => {
        const newAvailabilities = [...availabilities];
        newAvailabilities[index] = availability;
        setAvailabilities?.(newAvailabilities);
    };
    return ((0, jsx_runtime_1.jsxs)(AvailabilityScheduleGridWrapper_1.AvailabilityScheduleGridWrapper, { hideOverflow: true, children: [(0, jsx_runtime_1.jsx)(AcceptanceAvailabilityHeaderRow_1.AcceptanceAvailabilityHeaderRow, { viewState: viewState, availabilities: availabilities, setAvailabilities: setAvailabilities }), availabilities.map((week, index) => ((0, jsx_runtime_1.jsx)(AcceptanceAvailabilityRow_1.AcceptanceAvailabilityRow, { schema: schema?.element, viewState: viewState, availability: week, setAvailability: setAvailabilityForWeek(index) }, week.key)))] }));
};
exports.AcceptanceAvailabilitySchedule = AcceptanceAvailabilitySchedule;
