"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.country = void 0;
const enums_1 = require("@bemlo/enums");
exports.country = {
    [enums_1.Country.BELGIUM]: 'Belgium',
    [enums_1.Country.DENMARK]: 'Denmark',
    [enums_1.Country.FINLAND]: 'Finland',
    [enums_1.Country.FRANCE]: 'France',
    [enums_1.Country.GERMANY]: 'Germany',
    [enums_1.Country.NORWAY]: 'Norway',
    [enums_1.Country.SPAIN]: 'Spain',
    [enums_1.Country.SWEDEN]: 'Sweden',
    [enums_1.Country.UNITED_KINGDOM]: 'United Kingdom',
};
