"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addInTz = void 0;
const addInTz = (_option, dayjsClass, dayjsFactory) => {
    // Add in timezone
    dayjsClass.prototype.addInTz = function (...args) {
        const timezone = this.$x && this.$x.$timezone;
        if (!timezone) {
            throw new Error('No timezone set');
        }
        return dayjsFactory.tz(dayjsFactory(this.toDate())
            .tz(timezone)
            .add(...args)
            .format('YYYY-MM-DD HH:mm:ss'), timezone);
    };
    // Subtract in timezone
    dayjsClass.prototype.subtractInTz = function (...args) {
        const timezone = this.$x && this.$x.$timezone;
        if (!timezone) {
            throw new Error('No timezone set');
        }
        return dayjsFactory.tz(dayjsFactory(this.toDate())
            .tz(timezone)
            .subtract(...args)
            .format('YYYY-MM-DD HH:mm:ss'), timezone);
    };
};
exports.addInTz = addInTz;
