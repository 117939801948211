"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.workHours = void 0;
const enums_1 = require("@bemlo/enums");
exports.workHours = {
    [enums_1.WorkHours.DAY]: 'Dag',
    [enums_1.WorkHours.EVENING]: 'Kveld',
    [enums_1.WorkHours.HOLIDAY]: 'Høytid',
    [enums_1.WorkHours.NIGHT]: 'Natt',
    [enums_1.WorkHours.WEEKEND]: 'Helg',
    [enums_1.WorkHours.WEEKEND_NIGHT]: 'Helgenatt',
};
