"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateShiftAvailabilityOverlap = exports.getShiftAvailabilityWeight = exports.shiftAvailabilityToWeight = void 0;
const enums_1 = require("@bemlo/enums");
exports.shiftAvailabilityToWeight = {
    [enums_1.ShiftAvailability.CONFLICTING_SHIFT]: -30,
    [enums_1.ShiftAvailability.SCHEDULED_LEAVE]: -20,
    [enums_1.ShiftAvailability.PREFERRED_UNAVAILABLE]: -10,
    [enums_1.ShiftAvailability.NO_DATA]: 0,
    [enums_1.ShiftAvailability.APPLIED_SIMILAR_SHIFT]: 10,
    [enums_1.ShiftAvailability.PARTIAL_AVAILABILITY]: 20,
    [enums_1.ShiftAvailability.FULL_AVAILABILITY]: 30,
    [enums_1.ShiftAvailability.TENDERED_AVAILABILITY]: 40,
    [enums_1.ShiftAvailability.SCHEDULED_AVAILABILITY]: 50,
};
const getShiftAvailabilityWeight = (availabilities) => {
    if (!availabilities.length)
        return 0;
    const weight = availabilities.map((a) => exports.shiftAvailabilityToWeight[a.type]);
    const min = Math.min(...weight);
    if (min < 0)
        return min;
    return Math.max(...weight);
};
exports.getShiftAvailabilityWeight = getShiftAvailabilityWeight;
var overlap_1 = require("./overlap");
Object.defineProperty(exports, "calculateShiftAvailabilityOverlap", { enumerable: true, get: function () { return overlap_1.calculateShiftAvailabilityOverlap; } });
