"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShiftPresets = void 0;
const lodash_1 = require("lodash");
const react_1 = require("react");
const schedule_1 = require("@bemlo/schedule");
const toPresetKey = ({ scheduleResourceId, ...input }) => (0, schedule_1.serializeShift)({ ...input, date: 'PRESET' }, { scheduleResourceId });
const useShiftPresets = ({ shifts, persisted = [], }) => {
    const [draft, setDraft] = (0, react_1.useState)([]);
    return (0, react_1.useMemo)(() => {
        const combinedData = [
            ...shifts,
            ...draft,
            ...persisted.map(({ id, ...data }) => ({
                ...data,
                persistedId: id,
            })),
        ];
        const withKey = combinedData.map((data) => {
            return {
                key: toPresetKey(data),
                startTime: data.startTime,
                endTime: data.endTime,
                breakTime: data.breakTime,
                comment: data.comment,
                scheduleResourceId: data.scheduleResourceId,
                persistedId: data.persistedId,
                onCallTime: data.onCallTime
                    ? {
                        type: data.onCallTime.type,
                        startTime: data.onCallTime.startTime,
                        endTime: data.onCallTime.endTime,
                    }
                    : undefined,
            };
        });
        // Saved presets should render at the top of the list, then sorted by time and properties
        const sorted = withKey.sort((a, b) => {
            if (a.persistedId && !b.persistedId) {
                return -1;
            }
            if (!a.persistedId && b.persistedId) {
                return 1;
            }
            if (a.startTime !== b.startTime) {
                return a.startTime.localeCompare(b.startTime);
            }
            if (a.endTime !== b.endTime) {
                return a.endTime.localeCompare(b.endTime);
            }
            if (a.breakTime !== b.breakTime) {
                return a.breakTime - b.breakTime;
            }
            if (!a.onCallTime && b.onCallTime) {
                return -1;
            }
            if (a.onCallTime && !b.onCallTime) {
                return 1;
            }
            if (a.onCallTime && b.onCallTime) {
                if (a.onCallTime.startTime !== b.onCallTime.startTime) {
                    return a.onCallTime.startTime.localeCompare(b.onCallTime.startTime);
                }
                if (a.onCallTime.endTime !== b.onCallTime.endTime) {
                    return a.onCallTime.endTime.localeCompare(b.onCallTime.endTime);
                }
            }
            return a.comment.localeCompare(b.comment);
        });
        // Remove duplicates, keeping the ones with persistedId
        // important that list is sorted before this step
        const unique = (0, lodash_1.uniqBy)(sorted, 'key');
        // Determine if break times differ for the same start and end times which means
        // badges should be displayed for the user to select the correct break time
        const hasParallelPresetsWithDifferentBreakTimes = (() => {
            const breaksBySlot = unique.reduce((acc, shift) => {
                const key = `${shift.startTime}-${shift.endTime}`;
                if (!acc[key]) {
                    acc[key] = new Set();
                }
                acc[key].add(shift.breakTime);
                return acc;
            }, {});
            return Object.values(breaksBySlot).some((breakTimes) => breakTimes.size > 1);
        })();
        const createPreset = (input) => {
            const key = toPresetKey(input);
            const existing = unique.find((preset) => preset.key === key);
            if (existing)
                return existing;
            setDraft((curr) => [...curr, input]);
            return { ...input, key, persistedId: undefined };
        };
        return {
            presets: unique,
            hasParallelPresetsWithDifferentBreakTimes,
            createPreset,
        };
    }, [shifts, persisted, draft]);
};
exports.useShiftPresets = useShiftPresets;
