"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWtdForCountry = void 0;
const enums_1 = require("@bemlo/enums");
const eu_working_time_directive_1 = require("../constants/eu-working-time-directive");
const se_working_time_directive_1 = require("../constants/se-working-time-directive");
const countryWtdRecord = {
    [enums_1.Country.SWEDEN]: se_working_time_directive_1.SE_WORKING_TIME_DIRECTIVE,
};
const EU_COUNTRIES = [
    enums_1.Country.BELGIUM,
    enums_1.Country.DENMARK,
    enums_1.Country.FINLAND,
    enums_1.Country.FRANCE,
    enums_1.Country.GERMANY,
    enums_1.Country.NORWAY,
    enums_1.Country.SWEDEN,
    enums_1.Country.SPAIN,
    enums_1.Country.UNITED_KINGDOM,
];
const getWtdForCountry = (country) => {
    const countryWtd = countryWtdRecord[country];
    if (countryWtd) {
        return countryWtd;
    }
    if (EU_COUNTRIES.includes(country)) {
        return eu_working_time_directive_1.EU_WORKING_TIME_DIRECTIVE;
    }
    throw new Error(`No working time directive found for country ${country}`);
};
exports.getWtdForCountry = getWtdForCountry;
