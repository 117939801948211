"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CandidateRequirementType = void 0;
var CandidateRequirementType;
(function (CandidateRequirementType) {
    CandidateRequirementType["CUSTOM"] = "CUSTOM";
    CandidateRequirementType["DRIVERS_LICENSE"] = "DRIVERS_LICENSE";
    CandidateRequirementType["EXPERIENCE"] = "EXPERIENCE";
    CandidateRequirementType["JOURNAL_SYSTEM"] = "JOURNAL_SYSTEM";
    CandidateRequirementType["REFERENCE"] = "REFERENCE";
    CandidateRequirementType["SPECIALIZATION"] = "SPECIALIZATION";
    // Document types:
    CandidateRequirementType["BACKGROUND_CHECK"] = "BACKGROUND_CHECK";
    CandidateRequirementType["CV_DOCUMENT"] = "CV_DOCUMENT";
    CandidateRequirementType["HOSP_DOCUMENT"] = "HOSP_DOCUMENT";
    CandidateRequirementType["IVO_DOCUMENT"] = "IVO_DOCUMENT";
    CandidateRequirementType["OTHER_DOCUMENT"] = "OTHER_DOCUMENT";
    CandidateRequirementType["REFERENCE_DOCUMENT"] = "REFERENCE_DOCUMENT";
    CandidateRequirementType["SITH_CARD"] = "SITH_CARD";
    CandidateRequirementType["SPECIALIZATION_DOCUMENT"] = "SPECIALIZATION_DOCUMENT";
})(CandidateRequirementType || (exports.CandidateRequirementType = CandidateRequirementType = {}));
