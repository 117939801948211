"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkHours = void 0;
var WorkHours;
(function (WorkHours) {
    WorkHours["DAY"] = "DAY";
    WorkHours["EVENING"] = "EVENING";
    WorkHours["HOLIDAY"] = "HOLIDAY";
    WorkHours["NIGHT"] = "NIGHT";
    WorkHours["WEEKEND"] = "WEEKEND";
    WorkHours["WEEKEND_NIGHT"] = "WEEKEND_NIGHT";
})(WorkHours || (exports.WorkHours = WorkHours = {}));
