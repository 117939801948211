"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormatTenderedAvailability = void 0;
const date_1 = require("@bemlo/date");
const utils_1 = require("@bemlo/utils");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const constants_1 = require("../utils/constants");
const createDefaultAvailability = (week, options) => {
    const daysInPeriod = week.days.filter((day) => day.withinRange);
    if (daysInPeriod.length === 0) {
        // This should never happen.
        return undefined;
    }
    return {
        key: week.key,
        days: week.days.map((day) => ({
            date: day.date,
            includedInPeriod: day.withinRange,
            selected: day.withinRange && options.state === 'fullySelected',
        })),
        selected: options.state === 'fullySelected',
        period: {
            startsAt: daysInPeriod[0].date.unix(),
            endsAt: daysInPeriod[daysInPeriod.length - 1].date.unix(),
        },
        newScope: options.state === 'fullySelected'
            ? { scope: options.scope, shiftHours: constants_1.ALL_AVAILABILITY_SHIFT_HOURS }
            : constants_1.EMPTY_SCOPE,
    };
};
const useFormatTenderedAvailability = (tender) => {
    const t = (0, translate_1.useTranslations)();
    const weeks = (0, elements_1.useWeeksForRange)(tender?.startsAt ?? 0, tender?.endsAt ?? 0);
    return () => {
        if (!tender) {
            return [];
        }
        if (tender.tenderedAvailabilities.length === 0) {
            return weeks
                .map((week) => createDefaultAvailability(week, {
                state: 'fullySelected',
                scope: tender.scope,
            }))
                .filter(utils_1.isTruthy);
        }
        const tenderedAvailabilityWeekMap = new Map();
        tender.tenderedAvailabilities.forEach((availability) => {
            const availabilityWeek = t.$date
                .unix(availability.period.startsAt)
                .isoWeek();
            tenderedAvailabilityWeekMap.set(availabilityWeek, availability);
        });
        return weeks
            .map((week) => {
            const availability = tenderedAvailabilityWeekMap.get(week.number);
            if (!availability) {
                return createDefaultAvailability(week, { state: 'empty' });
            }
            const dayLookup = new Map(availability.days.map((day) => [
                date_1.dayjsEn.unix(day.date).startOf('day').unix(),
                day,
            ]));
            return {
                key: tender.id + week.key,
                selected: true,
                period: availability.period,
                newScope: availability.scope,
                tenderedAvailability: {
                    id: availability.id,
                    scope: availability.scope,
                },
                days: week.days.map((day) => {
                    const availabilityDay = dayLookup.get(day.date.startOf('day').unix());
                    return {
                        date: day.date,
                        includedInPeriod: day.withinRange,
                        selected: day.withinRange && !!availabilityDay,
                        tenderedDayId: availabilityDay?.id,
                    };
                }),
            };
        })
            .filter(utils_1.isTruthy);
    };
};
exports.useFormatTenderedAvailability = useFormatTenderedAvailability;
