"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onCallTypeAbbreviation = exports.onCallTypeShort = exports.onCallType = void 0;
const enums_1 = require("@bemlo/enums");
exports.onCallType = {
    [enums_1.OnCallType.RESIDENT]: 'Resident On-Call',
    [enums_1.OnCallType.NON_RESIDENT]: 'Non-Resident On-Call',
};
exports.onCallTypeShort = {
    [enums_1.OnCallType.RESIDENT]: 'Resident',
    [enums_1.OnCallType.NON_RESIDENT]: 'Non-Resident',
};
exports.onCallTypeAbbreviation = {
    [enums_1.OnCallType.RESIDENT]: 'RES',
    [enums_1.OnCallType.NON_RESIDENT]: 'NON-RES',
};
