"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.form = void 0;
exports.form = {
    at_least: () => 'minst',
    at_most: () => 'max',
    character_one: () => 'tecken',
    character_other: () => 'tecken',
    custom: () => 'Period',
    equal_to: () => 'lika med',
    exactly: () => 'exakt',
    from: () => 'Från',
    greater_than: () => 'mer än',
    invalid_date: () => 'Ogiltigt datum',
    invalid_email: () => 'Ogiltig e-post',
    invalid_format: () => 'Ogiltigt format',
    invalid_price: () => 'Ogiltigt pris',
    invalid_value: () => 'Ogiltigt värde',
    less_than: () => 'färre än',
    month: () => 'Månad',
    more_than: () => 'fler än',
    must_be: () => 'måste vara',
    must_be_a_number: () => 'Måste vara ett giltigt nummer',
    must_contain: () => 'måste innehålla',
    must_end_with: () => 'måste sluta med',
    must_start_with: () => 'måste börja med',
    n_weeks: (n) => `${n} Veckor`,
    no_alternatives: () => 'Inga alternativ',
    optional: () => 'frivilligt',
    or: () => 'eller',
    required: () => 'Obligatorisk',
    select_a_date: () => 'Välj ett datum',
    select_a_value: () => 'Välj ett värde',
    smaller_than: () => 'mindre än',
    the_date: () => 'Datumet',
    the_value: () => 'Värdet',
    the_value_must_be_a_multiple_of: (number) => `Värdet måste vara delbart med ${number}`,
    the_value_must_be_finite: () => 'Värdet får inte vara oändligt',
    to: () => 'Till',
    value_one: () => 'värde',
    value_other: () => 'värden',
    week: () => 'Vecka',
    you_must_select: () => 'Välj',
};
