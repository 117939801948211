"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingAvailabilityRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const getBookingDayCellStatus_1 = require("./getBookingDayCellStatus");
const PeriodCell_1 = require("../atoms/PeriodCell");
const ScopeCell_1 = require("../atoms/ScopeCell");
const AvailabilityRow_1 = require("../AvailabilityRow");
const ShiftHoursCell_1 = require("../molecules/ShiftHoursCell");
const WeekDaysRow_1 = require("../molecules/WeekDaysRow");
const utils_1 = require("../tender/utils");
const get_grid_template_columns_1 = require("../utils/get-grid-template-columns");
const BookingAvailabilityRow = ({ availability, }) => {
    const gridTemplateColumns = (0, get_grid_template_columns_1.getGridTemplateColumns)('booking', 'view');
    return ((0, jsx_runtime_1.jsxs)(AvailabilityRow_1.AvailabilityRowStyles, { gridTemplateColumns: gridTemplateColumns, disabled: !availability.selected, children: [(0, jsx_runtime_1.jsx)(PeriodCell_1.PeriodCell, { selected: availability.selected, endsAt: availability.period.endsAt, startsAt: availability.period.startsAt }), (0, jsx_runtime_1.jsx)(ScopeCell_1.ScopeCell, { scope: availability.newScope.scope, viewState: 'view' }), (0, jsx_runtime_1.jsx)(ShiftHoursCell_1.ShiftHoursCell, { availableShiftHours: (0, utils_1.tenderedShiftHours)(availability), viewState: 'view', selectedShiftHours: availability.newScope.shiftHours }), (0, jsx_runtime_1.jsx)(WeekDaysRow_1.WeekDaysRow, { days: availability.days, viewState: 'view', getDayCellStatus: getBookingDayCellStatus_1.getBookingDayCellStatus })] }, availability.key));
};
exports.BookingAvailabilityRow = BookingAvailabilityRow;
