"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Description = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../../../constants");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const SectionHeading_1 = require("../../components/SectionHeading");
const useProfessionalView_1 = require("../../hooks/useProfessionalView");
const scopes_1 = require("../../scopes");
const Description = () => {
    const { actor, data, isEditing, isMe, trimAndUpdate, update } = (0, useProfessionalView_1.useProfessionalView)();
    const t = (0, translate_1.useTranslations)();
    const { description } = data;
    const scope = scopes_1.scopes.biography.description();
    if (!actor.can('read', scope)) {
        return null;
    }
    if (isEditing(scope)) {
        return ((0, jsx_runtime_1.jsx)(elements_1.Form.Textarea, { autogrow: true, disabled: !actor.can('update', scope), label: isMe
                ? t.professional_view.biography.about_me()
                : t.professional_view.biography.about_the_candidate(), onBlur: trimAndUpdate('description'), onChange: update('description'), value: description }));
    }
    const value = description?.trim();
    if (!value) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, children: [(0, jsx_runtime_1.jsx)(SectionHeading_1.SectionHeading, { children: t.professional_view.biography.biography() }), (0, jsx_runtime_1.jsx)(elements_1.Paragraph, { color: constants_1.Color.BLACK, size: "small", styles: {
                    lineHeight: 1.4,
                    maxWidth: 512,
                    mb: 0,
                    opacity: 1,
                    w: 'full',
                }, children: (0, jsx_runtime_1.jsx)(elements_1.Raw, { children: value }) })] }));
};
exports.Description = Description;
