"use strict";
// source: https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
/**
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 *
 * The hash code for a string object is computed as
 *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
 * using number arithmetic, where s[i] is the i th character
 * of the given string, n is the length of the string,
 * and ^ indicates exponentiation.
 * (The hash value of the empty string is zero.)
 *
 * @param {string} input a string
 * @return {number} a hash code value for the given string. Can be negative
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringHashCode = stringHashCode;
function stringHashCode(input) {
    let hash = 0;
    for (let index = 0; index < input.length; index++)
        hash = (Math.imul(31, hash) + input.charCodeAt(index)) | 0;
    return hash;
}
