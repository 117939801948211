"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduleDraftStatus = void 0;
var ScheduleDraftStatus;
(function (ScheduleDraftStatus) {
    ScheduleDraftStatus["GENERATING"] = "GENERATING";
    ScheduleDraftStatus["COMPLETED"] = "COMPLETED";
    ScheduleDraftStatus["ACCEPTED"] = "ACCEPTED";
    ScheduleDraftStatus["REJECTED"] = "REJECTED";
    ScheduleDraftStatus["FAILED"] = "FAILED";
})(ScheduleDraftStatus || (exports.ScheduleDraftStatus = ScheduleDraftStatus = {}));
