"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateZodErrorMap = exports.translateIssueMessage = void 0;
const zod_1 = require("zod");
/**
 * @see https://github.com/colinhacks/zod/blob/master/src/locales/en.ts
 */
const translateIssueMessage = (t, issue) => {
    switch (issue.code) {
        case zod_1.z.ZodIssueCode.invalid_date:
            return t.invalid_date();
        case zod_1.z.ZodIssueCode.invalid_string:
            if (typeof issue.validation === 'object') {
                const v = issue.validation;
                if ('includes' in v) {
                    return `${t.the_value()} ${t.must_contain()} "${v.includes}"`;
                }
                else if ('startsWith' in v) {
                    return `${t.the_value()} ${t.must_start_with()} "${v.startsWith}"`;
                }
                else if ('endsWith' in v) {
                    return `${t.the_value()} ${t.must_end_with()} "${v.endsWith}"`;
                }
            }
            if (issue.validation === 'email') {
                return t.invalid_email();
            }
            return t.invalid_value();
        case zod_1.z.ZodIssueCode.invalid_type:
            return issue.received === zod_1.z.ZodParsedType.undefined
                ? t.required()
                : t.invalid_value();
        case zod_1.z.ZodIssueCode.not_multiple_of:
            return t.the_value_must_be_a_multiple_of(issue.multipleOf);
        case zod_1.z.ZodIssueCode.not_finite:
            return t.the_value_must_be_finite();
        case zod_1.z.ZodIssueCode.too_big:
        case zod_1.z.ZodIssueCode.too_small: {
            const { exact, inclusive, type, ...rest } = issue;
            const isCollection = type === 'array' || type === 'set' || type === 'string';
            const isMaximum = 'maximum' in rest;
            const value = Number(isMaximum ? rest.maximum : rest.minimum);
            if (isCollection && !isMaximum && rest.minimum === 1) {
                return t.required();
            }
            let output = '';
            if (type === 'array') {
                output += `${t.you_must_select()} `;
            }
            else {
                output += type === 'date' ? t.the_date() : t.the_value();
                output += ` ${isCollection ? t.must_contain() : t.must_be()} `;
            }
            if (exact) {
                output += t.exactly();
            }
            else if (isCollection) {
                if (isMaximum) {
                    output += inclusive ? t.at_most() : t.less_than();
                }
                else {
                    output += inclusive ? t.at_least() : t.more_than();
                }
            }
            else {
                output += isMaximum ? t.smaller_than() : t.greater_than();
                if (inclusive) {
                    output += ` ${t.or()} ${t.equal_to()}`;
                }
            }
            output += type === 'date' ? ` ${new Date(value)}` : ` ${value}`;
            if (type === 'array') {
                output += ` ${value === 1 ? t.value_one() : t.value_other()}`;
            }
            else if (type === 'string') {
                output += ` ${value === 1 ? t.character_one() : t.character_other()}`;
            }
            return output;
        }
        default:
            return t.invalid_value();
    }
};
exports.translateIssueMessage = translateIssueMessage;
/**
 * Globally overrides the default Zod error messages using custom translated messages.
 *
 * @see https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md
 */
const translateZodErrorMap = (t) => {
    return (issue) => ({ message: (0, exports.translateIssueMessage)(t, issue) });
};
exports.translateZodErrorMap = translateZodErrorMap;
