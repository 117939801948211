"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.time = void 0;
const time = (_option, dayjsClass, dayjsFactory) => {
    dayjsFactory.time = function (time) {
        if (time === '24:00') {
            time = '00:00';
        }
        return dayjsFactory(time, 'HH:mm', true);
    };
    function time(time) {
        if (time) {
            const date = dayjsFactory.time(time);
            if (!date.isValid()) {
                return this.hour(Number.NaN).minute(Number.NaN);
            }
            return this.hour(date.hour()).minute(date.minute());
        }
        return this.format('HH:mm');
    }
    dayjsClass.prototype.time = time;
};
exports.time = time;
