"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUnitRelationBadge = void 0;
const enums_1 = require("@bemlo/enums");
const translate_1 = require("../../translate");
const UnitRelationMap = {
    [enums_1.UnitRelation.HOME_UNIT]: {
        badgeColor: 'lighter-green',
        iconName: 'HomeIcon',
    },
    [enums_1.UnitRelation.CROSS_UNIT]: {
        badgeColor: 'transparent-purple',
        iconName: 'OfficeBuildingIcon',
    },
    [enums_1.UnitRelation.CROSS_ORG]: {
        badgeColor: 'lighter-blue',
        iconName: 'CubeTransparentIcon',
    },
};
const useUnitRelationBadge = (relation) => {
    const t = (0, translate_1.useTranslations)();
    if (relation) {
        const value = UnitRelationMap[relation];
        return {
            ...value,
            text: t.unit_relation(relation),
        };
    }
    return null;
};
exports.useUnitRelationBadge = useUnitRelationBadge;
