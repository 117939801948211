"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileUploadTypesForMarket = fileUploadTypesForMarket;
const enums_1 = require("@bemlo/enums");
const common = [
    enums_1.FileUploadType.CV,
    enums_1.FileUploadType.LICENSE,
    enums_1.FileUploadType.OTHER,
    enums_1.FileUploadType.REFERENCE,
    enums_1.FileUploadType.SPECIALIZATION,
];
function fileUploadTypesForMarket(market) {
    switch (market) {
        case enums_1.Market.DK:
        case enums_1.Market.FI:
        case enums_1.Market.NO:
        case enums_1.Market.GB:
            return common;
        case enums_1.Market.SE:
            return [enums_1.FileUploadType.HOSP, enums_1.FileUploadType.IVO, ...common];
        default: {
            const exhaustiveCheck = market;
            throw new Error(`No fileUploadTypesForMarket for "${exhaustiveCheck}"`);
        }
    }
}
