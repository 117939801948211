"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentPageSidebarSection = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../constants");
const Flex_1 = require("../Flex");
const ContentPageSidebarSection = ({ gap = 3, ...props }) => ((0, jsx_runtime_1.jsx)(Flex_1.Flex, { col: true, gap: gap, styles: {
        p: 3,
        borderBottom: constants_1.theme.border.style.thinLight,
        ...props.styles,
    }, children: props.children }));
exports.ContentPageSidebarSection = ContentPageSidebarSection;
