"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.form = void 0;
exports.form = {
    at_least: () => 'minst',
    at_most: () => 'max',
    character_one: () => 'tegn',
    character_other: () => 'tegn',
    custom: () => 'Tilpasset',
    equal_to: () => 'lik',
    exactly: () => 'nøyaktig',
    from: () => 'Fra',
    greater_than: () => 'større enn',
    invalid_date: () => 'Ugyldig dato',
    invalid_email: () => 'Ugyldig e-post',
    invalid_format: () => 'Ugyldig format',
    invalid_price: () => 'Ugyldlig pris',
    invalid_value: () => 'Ugyldig verdi',
    less_than: () => 'mindre enn',
    month: () => 'Måned',
    more_than: () => 'mer enn',
    must_be: () => 'må være',
    must_be_a_number: () => 'Må være et gyldig nummer',
    must_contain: () => 'må inneholde',
    must_end_with: () => 'må avsluttes med',
    must_start_with: () => 'må begynne med',
    n_weeks: (n) => `${n} Uker`,
    no_alternatives: () => 'Ingen alternativer',
    optional: () => 'frivillig',
    or: () => 'eller',
    required: () => 'Obligatorisk',
    select_a_date: () => 'Velg en dato',
    select_a_value: () => 'Velg en verdi',
    smaller_than: () => 'mindre enn',
    the_date: () => 'Datoen',
    the_value: () => 'Verdien',
    the_value_must_be_a_multiple_of: (number) => `Verdien må være delelig med ${number}`,
    the_value_must_be_finite: () => 'Verdien må ikke være uendelig',
    to: () => 'Til',
    value_one: () => 'verdi',
    value_other: () => 'verdier',
    week: () => 'Uke',
    you_must_select: () => 'Velg',
};
