"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatActions = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../constants");
const elements_1 = require("../../elements");
const translate_1 = require("../../translate");
const ChatActions = (props) => {
    const t = (0, translate_1.useTranslations)();
    const hasSearchBar = !!props.onQueryChange;
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: hasSearchBar, gap: 2.5, grow: 1, justify: "between", styles: { p: 3 }, children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 2, items: "center", children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { fontWeight: constants_1.theme.font.weight.medium, size: 6, children: props.title ?? t.chat_view.conversation_other() }), props.titleRowSlot] }), (props.onQueryChange || props.onStartConversationClick) && ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 3, items: "center", children: [props.onQueryChange && ((0, jsx_runtime_1.jsx)(elements_1.Form.Input, { onChange: props.onQueryChange, placeholder: `${t.common.search()}...`, size: 6, styles: {
                            flexGrow: 1,
                            lineHeight: '16px',
                            minWidth: 0,
                            py: 2,
                            width: 0,
                        }, type: "search" })), props.onStartConversationClick && ((0, jsx_runtime_1.jsx)(elements_1.Button, { icon: "PlusSmIcon", onClick: props.onStartConversationClick, size: "sm", children: t.chat_view.new() }))] }))] }));
};
exports.ChatActions = ChatActions;
