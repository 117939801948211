"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnCallType = void 0;
var OnCallType;
(function (OnCallType) {
    // On-call at the hospital or facility
    OnCallType["RESIDENT"] = "RESIDENT";
    // On-call from home or another remote location
    OnCallType["NON_RESIDENT"] = "NON_RESIDENT";
})(OnCallType || (exports.OnCallType = OnCallType = {}));
