"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tenderedScope = exports.tenderedShiftHours = exports.isAvailabilityWeekTendered = exports.isDayTendered = exports.tenderableShiftHours = exports.canTenderAvailabilityWeek = exports.canTenderDay = void 0;
const constants_1 = require("../utils/constants");
const canTenderDay = (day) => day.includedInPeriod;
exports.canTenderDay = canTenderDay;
const canTenderAvailabilityWeek = (_availability) => true;
exports.canTenderAvailabilityWeek = canTenderAvailabilityWeek;
const tenderableShiftHours = (_availability) => constants_1.ALL_AVAILABILITY_SHIFT_HOURS;
exports.tenderableShiftHours = tenderableShiftHours;
const isDayTendered = (day) => !!day.tenderedDayId;
exports.isDayTendered = isDayTendered;
const isAvailabilityWeekTendered = (availability) => !!availability.tenderedAvailability;
exports.isAvailabilityWeekTendered = isAvailabilityWeekTendered;
const tenderedShiftHours = (availability) => availability.tenderedAvailability?.scope.shiftHours ?? constants_1.EMPTY_ARRAY;
exports.tenderedShiftHours = tenderedShiftHours;
const tenderedScope = (availability) => availability.tenderedAvailability?.scope.scope;
exports.tenderedScope = tenderedScope;
