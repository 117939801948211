"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenderStatus = void 0;
var TenderStatus;
(function (TenderStatus) {
    TenderStatus["ASSIGNED"] = "ASSIGNED";
    TenderStatus["CLOSED"] = "CLOSED";
    TenderStatus["DRAFT"] = "DRAFT";
    TenderStatus["FILLED"] = "FILLED";
    TenderStatus["PUBLISHED"] = "PUBLISHED";
    TenderStatus["URGENT"] = "URGENT";
})(TenderStatus || (exports.TenderStatus = TenderStatus = {}));
