"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Row = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const react_1 = require("react");
const enums_1 = require("@bemlo/enums");
const Booking_1 = require("./Booking");
const ExactTimeInput_1 = require("./ExactTimeInput");
const ShiftHoursAvailabilityCell_1 = require("./ShiftHoursAvailabilityCell");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const { DAY, EVENING, NIGHT } = enums_1.ShiftHours;
const ModeSwitch = (props) => {
    if (props.isBooked)
        return (0, jsx_runtime_1.jsx)(elements_1.Div, {});
    return ((0, jsx_runtime_1.jsx)(elements_1.Button, { size: "xxs", design: "transparent-light", icon: props.isExact ? 'SunIcon' : 'ClockIcon', iconDesign: "solid", onClick: props.onSwitch }));
};
const Row = (props) => {
    const booking = props.booking;
    const exactAvailability = props.availability.find(({ shiftHours }) => !shiftHours);
    const [isExact, setIsExact] = (0, react_1.useState)(!!exactAvailability);
    const [startTime, setStartTime] = (0, react_1.useState)(exactAvailability?.startDateTime.tz('utc').format('HH:mm') ??
        props.defaultTimes.startTime);
    const [endTime, setEndTime] = (0, react_1.useState)(exactAvailability?.endDateTime.tz('utc').format('HH:mm') ??
        props.defaultTimes.endTime);
    const { [DAY]: dayAvailability, [EVENING]: eveningAvailability, [NIGHT]: nightAvailability, } = (0, lodash_1.groupBy)(props.availability, 'shiftHours');
    const handleToggleExact = async () => {
        // Clear up any existing availability when switching between modes
        if (isExact) {
            const ids = props.availability.filter(({ shiftHours }) => !shiftHours);
            if (ids.length) {
                void props.onDelete(ids.map(({ id }) => id));
            }
            setIsExact(false);
        }
        else {
            const ids = props.availability
                .filter(({ shiftHours }) => shiftHours)
                .map(({ id }) => id);
            if (ids.length) {
                void props.onDelete(ids);
            }
            const defaultTimes = props.defaultTimes;
            void props.onCreateExactAvailability(props.day.time(defaultTimes.startTime).startOf('minute'), props.day.time(defaultTimes.endTime).startOf('minute'));
            setStartTime(defaultTimes.startTime);
            setEndTime(defaultTimes.endTime);
            setIsExact(true);
        }
    };
    const handleUpdateExact = () => {
        const exactAvailability = props.availability.find(({ shiftHours }) => !shiftHours);
        if (!exactAvailability)
            return;
        const newStartDateTime = props.day.time(startTime).startOf('minute');
        let newEndDateTime = props.day.time(endTime).startOf('minute');
        if (newEndDateTime.isBefore(newStartDateTime) ||
            newEndDateTime.isSame(newStartDateTime)) {
            newEndDateTime = newEndDateTime.add(1, 'day');
        }
        void props.onUpdateExactAvailability(exactAvailability.id, newStartDateTime, newEndDateTime);
        props.setDefaultTime({ startTime, endTime });
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, styles: { justifyContent: 'center' }, children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { color: constants_1.Color.DARKEST_MEDIUM_GREY, styles: { fontSize: 10 }, children: props.day.format('ddd') }), (0, jsx_runtime_1.jsx)(elements_1.Text, { size: 6, color: constants_1.Color.CHARCOAL, bold: true, children: props.day.format('DD') })] }), booking ? ((0, jsx_runtime_1.jsx)(Booking_1.Booking, { booking: booking })) : isExact ? ((0, jsx_runtime_1.jsx)(ExactTimeInput_1.ExactTimeInput, { startTime: startTime, endTime: endTime, onStartTimeChange: setStartTime, onEndTimeChange: setEndTime, onBlur: handleUpdateExact })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ShiftHoursAvailabilityCell_1.ShiftHoursAvailabilityCell, { type: DAY, availability: dayAvailability?.[0], onMarkAsAvailable: () => props.onMarkAsAvailable(DAY), onMarkAsUnavailable: props.onMarkAsUnavailable, onDelete: props.onDelete }), (0, jsx_runtime_1.jsx)(ShiftHoursAvailabilityCell_1.ShiftHoursAvailabilityCell, { type: EVENING, availability: eveningAvailability?.[0], onMarkAsAvailable: () => props.onMarkAsAvailable(EVENING), onMarkAsUnavailable: props.onMarkAsUnavailable, onDelete: props.onDelete }), (0, jsx_runtime_1.jsx)(ShiftHoursAvailabilityCell_1.ShiftHoursAvailabilityCell, { type: NIGHT, availability: nightAvailability?.[0], onMarkAsAvailable: () => props.onMarkAsAvailable(NIGHT), onMarkAsUnavailable: props.onMarkAsUnavailable, onDelete: props.onDelete })] })), (0, jsx_runtime_1.jsx)(ModeSwitch, { isBooked: !!booking, isExact: isExact, onSwitch: handleToggleExact })] }));
};
exports.Row = Row;
