"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingAvailabilitySchedule = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const BookingAvailabilityHeaderRow_1 = require("./BookingAvailabilityHeaderRow");
const BookingAvailabilityRow_1 = require("./BookingAvailabilityRow");
const BookingAvailabilitySchedule = ({ availabilities, }) => {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(BookingAvailabilityHeaderRow_1.BookingAvailabilityHeaderRow, {}), availabilities.map((availability) => ((0, jsx_runtime_1.jsx)(BookingAvailabilityRow_1.BookingAvailabilityRow, { availability: availability }, availability.key)))] }));
};
exports.BookingAvailabilitySchedule = BookingAvailabilitySchedule;
