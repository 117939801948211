"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatSignificant = void 0;
const formatSignificant = (_option, dayjsClass, dayjsFactory) => {
    dayjsClass.prototype.formatSignificant = function (options = {}) {
        const currentDate = dayjsFactory();
        const showAtLeast = options.showAtLeast;
        const showTime = options.showTime;
        if (this.isSame(currentDate, 'day') && !showAtLeast && showTime) {
            return this.format('LT'); // E.g. 12:15
        }
        if (this.isSame(currentDate, 'year') && showAtLeast !== 'year') {
            const format = showTime
                ? this.$locale().custom.monthDayTime // E.g. 7 Jun 12:15
                : this.$locale().custom.monthDay; // E.g. 7 Jun
            return this.format(format);
        }
        // Different year
        return this.format(showTime
            ? 'lll' // E.g. 7 Jun 2023 12:15
            : 'll');
    };
};
exports.formatSignificant = formatSignificant;
