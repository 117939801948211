"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HowMuchExperienceAlternative = void 0;
var HowMuchExperienceAlternative;
(function (HowMuchExperienceAlternative) {
    HowMuchExperienceAlternative["STUDENT"] = "experienceStudent";
    HowMuchExperienceAlternative["NEW_GRADUATE"] = "experienceNewGraduate";
    HowMuchExperienceAlternative["LESS_THAN_ONE"] = "experience<1";
    HowMuchExperienceAlternative["ONE_TO_TWO"] = "experience1-2";
    HowMuchExperienceAlternative["TWO"] = "experience2";
    HowMuchExperienceAlternative["TWO_TO_THREE"] = "experience2-3";
    HowMuchExperienceAlternative["THREE_TO_FIVE"] = "experience3-5";
    HowMuchExperienceAlternative["FIVE_TO_TEN"] = "experience5-10";
    HowMuchExperienceAlternative["MORE_THAN_TEN"] = "experience>10";
})(HowMuchExperienceAlternative || (exports.HowMuchExperienceAlternative = HowMuchExperienceAlternative = {}));
