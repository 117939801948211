"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onCallTypeAbbreviation = exports.onCallTypeShort = exports.onCallType = void 0;
const enums_1 = require("@bemlo/enums");
exports.onCallType = {
    [enums_1.OnCallType.RESIDENT]: 'Jour',
    [enums_1.OnCallType.NON_RESIDENT]: 'Beredskap',
};
exports.onCallTypeShort = {
    [enums_1.OnCallType.RESIDENT]: 'Jour',
    [enums_1.OnCallType.NON_RESIDENT]: 'Beredskap',
};
exports.onCallTypeAbbreviation = {
    [enums_1.OnCallType.RESIDENT]: 'JOUR',
    [enums_1.OnCallType.NON_RESIDENT]: 'BRSK',
};
