"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCurrencyRange = exports.formatCurrency = void 0;
const formatCurrency = (locale) => (currency, value, roundToNearest = 1) => {
    if (value == null) {
        return '-';
    }
    // Double-rounding just to be sure we avoid IEEE 754 rounding errors:
    const roundedValue = Math.round(Math.round(value / roundToNearest) * roundToNearest);
    return roundedValue.toLocaleString(locale, {
        currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: 'currency',
    });
};
exports.formatCurrency = formatCurrency;
const formatCurrencyRange = (locale) => (currency, min, max, options = {}) => {
    if (min == null || max == null) {
        return '-';
    }
    if (min != null && max != null && min > max) {
        ;
        [min, max] = [max, min];
    }
    const formattedMax = (0, exports.formatCurrency)(locale)(currency, max, options.roundToNearest);
    if (min === max) {
        return formattedMax;
    }
    const delimiter = options.spaced ? ' – ' : '–';
    const formattedMin = (0, exports.formatCurrency)(locale)(currency, min, options.roundToNearest);
    return `${formattedMin}${delimiter}${formattedMax}`;
};
exports.formatCurrencyRange = formatCurrencyRange;
