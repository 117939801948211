"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Biography = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const BiographyHeader_1 = require("./BiographyHeader");
const BiographySummary_1 = require("./BiographySummary");
const Description_1 = require("./components/Description");
const DriversLicenses_1 = require("./components/DriversLicenses");
const Employment_1 = require("./components/Employment");
const Experience_1 = require("./components/Experience");
const FullName_1 = require("./components/FullName");
const JournalSystems_1 = require("./components/JournalSystems");
const LicenseStatuses_1 = require("./components/LicenseStatuses");
const Location_1 = require("./components/Location");
const elements_1 = require("../../../elements");
const Flex_1 = require("../../../elements/Flex");
const Paper_1 = require("../../../elements/Paper");
const FileUploads_1 = require("../file-uploads/FileUploads");
const useProfessionalView_1 = require("../hooks/useProfessionalView");
const ProfileProgress_1 = require("../profile-progress/ProfileProgress");
const scopes_1 = require("../scopes");
const MINIMUM_PERFECT_PROFILE_COMPLETION_PERCENTAGE = 90;
const useCalculateProfileCompletionPercentage = (professional) => {
    const { actor } = (0, useProfessionalView_1.useProfessionalView)();
    const allScoredValues = [
        [1, professional.countries],
        [1, professional.departments],
        [1, professional.description],
        [0.5, professional.driversLicense],
        [2, professional.email],
        [1, professional.experience],
        [2, professional.fileUploads?.[0]],
        [1, professional.fileUploads?.[1]],
        [0.5, professional.homeCountry],
        [1, professional.homeMunicipality],
        ...(actor.can('read', scopes_1.scopes.information.hpr())
            ? [[3, professional.hpr]]
            : []),
        [1, professional.jobTypes],
        [0.5, professional.journalSystems],
        [1, professional.municipalities],
        ...(actor.can('read', scopes_1.scopes.information.personalIdentityNumber())
            ? [[3, professional.personalIdentityNumber]]
            : []),
        [2, professional.phoneNumber],
        [3, professional.profession],
        ...(actor.can('read', scopes_1.scopes.references())
            ? [[2, professional.references[0]]]
            : []),
        [1, professional.regions],
        [2, professional.specializations],
        [1, professional.startDate],
        [1, professional.workForms],
    ];
    const professionalScore = allScoredValues
        .filter(([, value]) => isValueSpecified(value))
        .reduce((total, [score]) => total + score, 0);
    const perfectScore = allScoredValues.reduce((total, [score]) => total + score, 0);
    // Round to the nearest 10:
    return Math.round((professionalScore / perfectScore) * 10) * 10;
};
const isValueSpecified = (value) => {
    return Array.isArray(value) ? value.length >= 1 : !!value;
};
const Biography = ({ onAddAuthorizationClick, onAddFileUploadClick, onAddReferenceClick, onEditFileUploadClick, onOpenFileUploadClick, onSendMessageClick, }) => {
    const { data, isEditing } = (0, useProfessionalView_1.useProfessionalView)();
    const profileCompletionPercentage = useCalculateProfileCompletionPercentage(data);
    return ((0, jsx_runtime_1.jsx)(Paper_1.Paper, { children: (0, jsx_runtime_1.jsxs)(Flex_1.Flex, { col: true, children: [(0, jsx_runtime_1.jsx)(Flex_1.Flex, { col: true, gap: 3.5, styles: { p: 3.5 }, children: isEditing('biography') ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(FullName_1.FullName, {}), (0, jsx_runtime_1.jsx)(Description_1.Description, {}), (0, jsx_runtime_1.jsx)(Location_1.Location, {}), (0, jsx_runtime_1.jsx)(elements_1.HorizontalRule, {}), (0, jsx_runtime_1.jsx)(Experience_1.Experience, {}), (0, jsx_runtime_1.jsx)(LicenseStatuses_1.LicenseStatuses, {}), (0, jsx_runtime_1.jsx)(elements_1.HorizontalRule, {}), (0, jsx_runtime_1.jsx)(JournalSystems_1.JournalSystems, {}), (0, jsx_runtime_1.jsx)(DriversLicenses_1.DriversLicenses, {}), (0, jsx_runtime_1.jsx)(Employment_1.Employment, {})] })) : ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { col: true, gap: 4, children: [(0, jsx_runtime_1.jsx)(BiographyHeader_1.BiographyHeader, { onSendMessageClick: onSendMessageClick }), (0, jsx_runtime_1.jsx)(Description_1.Description, {}), (0, jsx_runtime_1.jsx)(BiographySummary_1.BiographySummary, {}), (0, jsx_runtime_1.jsx)(FileUploads_1.FileUploads, { onAddFileUploadClick: onAddFileUploadClick, onEditFileUploadClick: onEditFileUploadClick, onOpenFileUploadClick: onOpenFileUploadClick })] })) }), profileCompletionPercentage <
                    MINIMUM_PERFECT_PROFILE_COMPLETION_PERCENTAGE && ((0, jsx_runtime_1.jsx)(ProfileProgress_1.ProfileProgress, { onAddAuthorizationClick: onAddAuthorizationClick, onAddFileUploadClick: onAddFileUploadClick, onAddReferenceClick: onAddReferenceClick, profileCompletionPercentage: profileCompletionPercentage }))] }) }));
};
exports.Biography = Biography;
