"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FromNow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const date_1 = require("@bemlo/date");
const MINUTE_MS = 60_000;
/**
 * A component that auto-updates `dayjs.fromNow()` every minute.
 */
const FromNow = (props) => {
    const [text, setText] = (0, react_1.useState)(props.date.fromNow(props.withoutSuffix));
    (0, react_1.useEffect)(() => {
        // If the date is at least 1 day old, don't auto-update:
        if ((0, date_1.dayjsEn)().diff(props.date, 'day') >= 1)
            return;
        const interval = setInterval(() => {
            setText(props.date.fromNow(props.withoutSuffix));
        }, MINUTE_MS);
        return () => {
            clearInterval(interval);
        };
    }, []);
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: text });
};
exports.FromNow = FromNow;
