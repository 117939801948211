"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EU_WORKING_TIME_DIRECTIVE = void 0;
const common_1 = require("./common");
exports.EU_WORKING_TIME_DIRECTIVE = {
    restTimeBetweenShiftsRequirement: {
        requiredRestTimeInMinutes: 11 * common_1.MINUTES_IN_AN_HOUR,
    },
    uninterruptedRestTimeRequirement: {
        // 24 hours + 11 hours of daily rest time seems to be the correct interpretation of the directive
        requiredRestTimeInMinutes: 35 * common_1.MINUTES_IN_AN_HOUR,
        everyPeriodInHours: common_1.DAYS_IN_A_WEEK * common_1.HOURS_IN_A_DAY,
    },
};
