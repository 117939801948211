"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vector = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const BemloPlusVector_1 = require("./BemloPlusVector");
const constants_1 = require("../../constants");
const VECTOR_MAP = {
    'bemlo-plus': BemloPlusVector_1.BemloPlusVector,
};
const Vector = (props) => {
    const VectorComponent = VECTOR_MAP[props.name];
    return ((0, jsx_runtime_1.jsx)(VectorComponent, { className: props.className, color: props.color ?? constants_1.Color.WHITE, size: props.size ?? 48 }));
};
exports.Vector = Vector;
