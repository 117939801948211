"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWhichRegionAlternatives = getWhichRegionAlternatives;
const lodash_1 = require("lodash");
const enums_1 = require("@bemlo/enums");
const COUNTRY_WHICH_REGION_ALTERNATIVES_RECORD = {
    [enums_1.Country.DENMARK]: [],
    [enums_1.Country.FINLAND]: [],
    [enums_1.Country.NORWAY]: Object.values(enums_1.NorwayWhichRegionAlternative),
    [enums_1.Country.SWEDEN]: Object.values(enums_1.SwedenWhichRegionAlternative),
    [enums_1.Country.UNITED_KINGDOM]: Object.values(enums_1.UnitedKingdomWhichRegionAlternative),
};
function getWhichRegionAlternatives({ enum: myEnum, countries, includeAll, includeNone, }) {
    let whichRegionAlternatives = [];
    if (countries) {
        whichRegionAlternatives = countries
            .map((country) => {
            const alternatives = COUNTRY_WHICH_REGION_ALTERNATIVES_RECORD[country] ?? [];
            if (!alternatives) {
                throw new Error(`Invalid country "${country}"`);
            }
            return alternatives;
        })
            .flat();
    }
    else {
        whichRegionAlternatives = Object.values(COUNTRY_WHICH_REGION_ALTERNATIVES_RECORD).flat();
    }
    if (includeNone) {
        whichRegionAlternatives.unshift(enums_1.SpecialWhichRegionAlternative.NONE);
    }
    if (includeAll) {
        whichRegionAlternatives.unshift(enums_1.SpecialWhichRegionAlternative.ALL);
    }
    if (myEnum) {
        /*
          If enum is supplied, we want to return the keys of WhichRegionAlternative instead of value,
          because that will match that enum's value
        */
        const invertedWhichRegionAlternatives = (0, lodash_1.invert)(enums_1.WhichRegionAlternative);
        return whichRegionAlternatives.map((value) => invertedWhichRegionAlternatives[value]);
    }
    return whichRegionAlternatives;
}
