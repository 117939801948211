"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProfessionalViewAlternatives = void 0;
const react_1 = require("react");
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const useProfessionalView_1 = require("./useProfessionalView");
const translate_1 = require("../../../translate");
const useProfessionalViewAlternatives = () => {
    const { data, market } = (0, useProfessionalView_1.useProfessionalView)();
    const t = (0, translate_1.useTranslations)();
    const { countries, profession } = data;
    const countryAlternatives = (0, react_1.useMemo)(() => {
        return Object.entries(enums_1.Country)
            .map(([key, value]) => ({
            key,
            label: t.$country(value),
            value,
        }))
            .sort((a, b) => a.label.localeCompare(b.label, t.$locale));
    }, [t]);
    const departmentAlternatives = (0, react_1.useMemo)(() => {
        return Object.entries(enums_1.WhichDepartmentToWorkInAlternative)
            .map(([key, value]) => ({
            key,
            label: t.$whichDepartmentToWorkInAlternative(value),
            value,
        }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [t]);
    const howMuchWorkAlternatives = (0, react_1.useMemo)(() => {
        return Object.entries(enums_1.HowMuchWorkAlternative)
            .map(([key, value]) => ({
            key,
            label: t.$howMuchWorkAlternative(value),
            value,
        }))
            .sort((a, b) => a.label.localeCompare(b.label, t.$locale));
    }, [t]);
    const jobTypeAlternatives = (0, react_1.useMemo)(() => {
        return Object.entries(enums_1.JobType).map(([key, value]) => ({
            key,
            label: t.$jobType(value),
            value,
        }));
    }, [t]);
    const municipalityAlternatives = (0, react_1.useMemo)(() => {
        return countries
            .flatMap((country) => {
            // Ignore countries without markets:
            try {
                return (0, utils_1.getMunicipalities)({ country });
            }
            catch {
                return [];
            }
        })
            .map((value) => ({
            key: value,
            label: t.$municipality(value),
            value,
        }))
            .sort((a, b) => a.label.localeCompare(b.label, t.$locale));
    }, [countries, t]);
    const regionAlternatives = (0, react_1.useMemo)(() => {
        return countries
            .flatMap((country) => {
            // Ignore countries without markets:
            try {
                return (0, utils_1.getWhichRegionAlternatives)({ countries: [country] });
            }
            catch {
                return [];
            }
        })
            .map((value) => ({
            key: value,
            label: t.$whichRegionAlternative(value),
            value,
        }))
            .sort((a, b) => a.label.localeCompare(b.label, t.$locale));
    }, [countries, t]);
    const workFormAlternatives = (0, react_1.useMemo)(() => {
        return Object.entries(enums_1.WorkForm)
            .filter((e) => typeof e[1] === 'number')
            .map(([key, value]) => ({
            key,
            label: t.$workForm(value),
            value,
        }));
    }, [t]);
    const journalSystemAlternatives = (0, react_1.useMemo)(() => {
        return Object.entries(enums_1.JournalSystem)
            .map(([key, value]) => ({
            key,
            label: t.$journalSystem(value),
            value,
        }))
            .sort((a, b) => a.label.localeCompare(b.label, t.$locale));
    }, [t]);
    const professionsForMarketAlternatives = (0, react_1.useMemo)(() => {
        const professions = (0, utils_1.professionsForMarkets)(market);
        return professions
            .map((value) => ({
            key: value,
            label: t.$profession(value),
            value,
        }))
            .sort((a, b) => a.label.localeCompare(b.label, t.$locale));
    }, [market, utils_1.professionsForMarkets, t]);
    const specializationsForMarketAndProfessionAlternatives = (0, react_1.useMemo)(() => {
        const allSpecializations = (0, utils_1.getSpecializations)({
            market,
            professions: profession ? [profession] : undefined,
        });
        return allSpecializations
            .map((value) => ({
            key: value,
            label: t.$specializationEducation(value),
            value,
        }))
            .sort((a, b) => a.label.localeCompare(b.label, t.$locale));
    }, [market, utils_1.getSpecializations, profession, t]);
    return {
        countryAlternatives,
        departmentAlternatives,
        howMuchWorkAlternatives,
        jobTypeAlternatives,
        journalSystemAlternatives,
        municipalityAlternatives,
        professionsForMarketAlternatives,
        specializationsForMarketAndProfessionAlternatives,
        regionAlternatives,
        workFormAlternatives,
    };
};
exports.useProfessionalViewAlternatives = useProfessionalViewAlternatives;
