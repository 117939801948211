"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COUNTRY_REGION_MUNICIPALITY_HIERARCHY = void 0;
const region_municipality_1 = require("./region-municipality");
const country_1 = require("../country");
const createCountryHierarchy = (regionMunicipalityHierarchy) => {
    return Object.values(regionMunicipalityHierarchy).reduce((result, item) => {
        result[item.value] = Object.values(item.municipalities);
        return result;
    }, {});
};
exports.COUNTRY_REGION_MUNICIPALITY_HIERARCHY = {
    [country_1.Country.NORWAY]: createCountryHierarchy(region_municipality_1.NORWAY_REGION_MUNICIPALITY_HIERARCHY),
    [country_1.Country.SWEDEN]: createCountryHierarchy(region_municipality_1.SWEDEN_REGION_MUNICIPALITY_HIERARCHY),
    [country_1.Country.UNITED_KINGDOM]: createCountryHierarchy(region_municipality_1.UNITED_KINGDOM_REGION_MUNICIPALITY_HIERARCHY),
};
