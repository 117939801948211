"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmallCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Card_1 = require("./Card");
const ClickableDeprecated_1 = require("./ClickableDeprecated");
const Column_1 = require("./Column");
const Icon_1 = require("./Icon");
const Row_1 = require("./Row");
const Text_1 = require("./Text");
const constants_1 = require("../constants");
const DEFAULT_DESIGN = 'default';
const ITEM_STYLES = {
    default: {
        color: constants_1.Color.DARK_BLUE,
        hoverBackgroundColor: constants_1.Color.LIGHTEST_BLUE,
        lightColor: constants_1.Color.MEDIUM_BLUE,
    },
    error: {
        color: constants_1.Color.RED,
        hoverBackgroundColor: constants_1.Color.LIGHTER_RED,
        lightColor: constants_1.Color.MEDIUM_RED,
    },
    success: {
        color: constants_1.Color.DARKER_GREEN,
        hoverBackgroundColor: constants_1.Color.LIGHTER_GREEN,
        lightColor: constants_1.Color.DARK_GREEN,
    },
};
const SmallCardContext = (0, react_1.createContext)({ title: '' });
const getTextSize = (small) => (small ? 6 : undefined);
const Description = (props) => {
    const rootProps = (0, react_1.useContext)(SmallCardContext);
    const style = ITEM_STYLES[rootProps.design ?? DEFAULT_DESIGN];
    return ((0, jsx_runtime_1.jsx)(Text_1.Text, { color: style.lightColor, lineHeight: 1, noSelect: true, size: 6, ...props }));
};
const Subtitle = (props) => {
    const rootProps = (0, react_1.useContext)(SmallCardContext);
    const textSize = getTextSize(rootProps.small);
    return (0, jsx_runtime_1.jsx)(Text_1.Text, { lineHeight: 1, noSelect: true, size: textSize, ...props });
};
const SmallCard = (props) => {
    const design = props.design ?? DEFAULT_DESIGN;
    const textSize = getTextSize(props.small);
    const style = ITEM_STYLES[design];
    const buttonStyle = ITEM_STYLES[props.buttonDesign ?? design];
    return ((0, jsx_runtime_1.jsx)(SmallCardContext.Provider, { value: props, children: (0, jsx_runtime_1.jsx)(Card_1.Card, { boxShadow: "none", className: props.className, children: (0, jsx_runtime_1.jsxs)(Row_1.Row, { alignItems: "center", gridGap: 3, justifyContent: "space-between", p: props.small ? 1 : 2, children: [(0, jsx_runtime_1.jsx)(ClickableDeprecated_1.ClickableDeprecated, { borderRadius: constants_1.theme.border.radius.normal, flexGrow: 2, hoverBackgroundColor: style.hoverBackgroundColor, onClick: props.onClick, overflow: "hidden", px: 2, children: (0, jsx_runtime_1.jsxs)(Row_1.Row, { alignItems: "center", color: style.color, gridGap: 3, textAlign: "left", children: [props.icon && ((0, jsx_runtime_1.jsx)(Icon_1.Icon, { backgroundColor: style.hoverBackgroundColor, name: props.icon, round: true, size: props.small ? 'md-plus' : 'lg' })), (0, jsx_runtime_1.jsxs)(Column_1.Column, { gridGap: 2, py: 2, children: [(0, jsx_runtime_1.jsxs)(Row_1.Row, { alignItems: "center", gridGap: 2, children: [(0, jsx_runtime_1.jsx)(Text_1.Text, { bold: true, lineHeight: 1, noSelect: true, size: textSize, children: props.title }), props.subtitle &&
                                                    (typeof props.subtitle === 'string' ? ((0, jsx_runtime_1.jsx)(Subtitle, { children: props.subtitle })) : (props.subtitle))] }), props.description &&
                                            (typeof props.description === 'string' ? ((0, jsx_runtime_1.jsx)(Description, { children: props.description })) : (props.description))] })] }) }), props.buttonIcon && ((0, jsx_runtime_1.jsx)(ClickableDeprecated_1.ClickableDeprecated, { borderRadius: constants_1.theme.border.radius.normal, color: buttonStyle.color, flexShrink: 0, hoverBackgroundColor: buttonStyle.hoverBackgroundColor, onClick: props.onButtonClick, overflow: "hidden", p: 1, children: (0, jsx_runtime_1.jsx)(Icon_1.Icon, { name: props.buttonIcon }) }))] }) }) }));
};
exports.SmallCard = SmallCard;
exports.SmallCard.Description = Description;
exports.SmallCard.Subtitle = Subtitle;
