"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../constants");
const elements_1 = require("../../elements");
const translate_1 = require("../../translate");
const ChatInput = (props) => {
    const t = (0, translate_1.useTranslations)();
    const trimmedValue = props.value.trim();
    const submit = () => {
        if (!trimmedValue)
            return;
        props.onSubmit(trimmedValue);
    };
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 3, styles: { backgroundColor: constants_1.Color.WHITE, px: 4, py: 2.5 }, children: [(0, jsx_runtime_1.jsx)(elements_1.Form.Textarea, { autogrow: true, onChange: props.onChange, placeholder: t.chat_view.write_a_message(), styles: { minHeight: 'auto', p: 3, width: '100%', maxHeight: 300 }, value: props.value }), (0, jsx_runtime_1.jsx)(elements_1.Button, { disabled: !trimmedValue, onClick: submit, children: t.chat_view.send() })] }));
};
exports.ChatInput = ChatInput;
