"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberToDay = exports.dayToNumber = void 0;
const enums_1 = require("@bemlo/enums");
/**
 * Convert a Day enum member to a number as understood by Dayjs.day().
 */
const dayToNumber = (day) => {
    switch (day) {
        case enums_1.Day.SUNDAY:
            return 0;
        case enums_1.Day.MONDAY:
            return 1;
        case enums_1.Day.TUESDAY:
            return 2;
        case enums_1.Day.WEDNESDAY:
            return 3;
        case enums_1.Day.THURSDAY:
            return 4;
        case enums_1.Day.FRIDAY:
            return 5;
        case enums_1.Day.SATURDAY:
            return 6;
    }
};
exports.dayToNumber = dayToNumber;
/**
 * Convert a number as understood by Dayjs.day() to a Day enum member.
 */
const numberToDay = (number) => {
    const day = number % 7;
    switch (day) {
        case 0:
            return enums_1.Day.SUNDAY;
        case 1:
            return enums_1.Day.MONDAY;
        case 2:
            return enums_1.Day.TUESDAY;
        case 3:
            return enums_1.Day.WEDNESDAY;
        case 4:
            return enums_1.Day.THURSDAY;
        case 5:
            return enums_1.Day.FRIDAY;
        case 6:
            return enums_1.Day.SATURDAY;
    }
    throw new Error(`Invalid day number: ${number}`);
};
exports.numberToDay = numberToDay;
