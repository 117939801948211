"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUIShiftsFromSlots = exports.getScheduleShiftStatus = void 0;
const enums_1 = require("@bemlo/enums");
const schedule_1 = require("@bemlo/schedule");
const getScheduleShiftStatus = ({ hasAcceptedPresentation, hasPendingApplications, hasPendingPresentations, hasPendingSuggestions, isArchived, isBooked, isPublished, isTendered, }) => {
    const isNotPublished = !isPublished && !isTendered && !isBooked;
    if (isBooked)
        return 'BOOKED';
    if (isArchived)
        return 'ARCHIVED';
    if (hasPendingSuggestions)
        return 'SUGGESTED';
    if (hasAcceptedPresentation)
        return 'ACCEPTED';
    if (isNotPublished)
        return 'VACANT';
    if (hasPendingApplications)
        return 'APPLIED';
    if (hasPendingPresentations)
        return 'PRESENTED';
    if (isPublished)
        return 'PUBLISHED';
    if (isTendered)
        return 'TENDERED';
    return 'VACANT';
};
exports.getScheduleShiftStatus = getScheduleShiftStatus;
const toShift = ({ pendingApplications = [], pendingSuggestions = [], scheduleResourceId, scheduleSlotId, unit, unitId, rowKey, }, shift) => {
    const booking = shift.bookings.find((booking) => !booking.canceledAt);
    const isAbsent = !!booking?.markedAbsentAt;
    const isArchived = !!shift.archivedAt;
    const isNotPublished = !shift.isPublished && !shift.isTendered && !booking;
    const status = (0, exports.getScheduleShiftStatus)({
        isBooked: !!booking,
        isArchived,
        hasPendingSuggestions: !!pendingSuggestions.length,
        hasPendingApplications: !!pendingApplications.length,
        isPublished: shift.isPublished,
        isTendered: shift.isTendered,
        // Tender only
        hasAcceptedPresentation: false,
        hasPendingPresentations: false,
    });
    const dayjsShift = (0, schedule_1.shiftToDayjs)(shift);
    return {
        rowKey: rowKey ?? shift.tenderShift?.rowId ?? undefined,
        scheduleResourceId,
        interestedCandidates: booking || isNotPublished
            ? 0
            : pendingApplications.length + pendingSuggestions.length,
        pendingApplications: pendingApplications.length,
        status,
        unitId,
        unitName: unit?.name,
        candidateIds: shift.bookings.map((booking) => booking.candidateId),
        scheduleSlotId,
        bookedCandidateId: booking?.candidateId,
        bookedCandidate: booking?.candidate,
        isBooked: !!booking,
        isAcknowledged: !!booking?.candidateAcknowledgedAt,
        isArchived,
        isPublished: shift.isPublished,
        isTendered: shift.isTendered,
        isUrgent: shift.isUrgent,
        isAbsent,
        ...dayjsShift,
    };
};
const useUIShiftsFromSlots = (slots) => {
    return slots.flatMap(({ id: scheduleSlotId, shifts, applications, suggestions, scheduleResourceId, unitId, unit, }) => {
        const pendingApplications = applications.filter((application) => application.status === enums_1.ShiftApplicationStatus.APPLIED);
        const pendingSuggestions = suggestions.filter(({ acceptedAt, rejectedAt, canceledAt }) => !acceptedAt && !rejectedAt && !canceledAt);
        return shifts.map((shift) => toShift({
            pendingApplications,
            pendingSuggestions,
            scheduleResourceId,
            scheduleSlotId,
            unit,
            unitId,
        }, shift));
    });
};
exports.useUIShiftsFromSlots = useUIShiftsFromSlots;
