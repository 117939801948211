"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTenderDayCellStatus = void 0;
const utils_1 = require("./utils");
const constants_1 = require("../../../constants");
const constants_2 = require("../utils/constants");
const getTenderDayCellStatus = (day, _isWeekDisabled, viewState) => {
    const canTender = (0, utils_1.canTenderDay)(day);
    const isTendered = (0, utils_1.isDayTendered)(day);
    const isDaySelectable = canTender;
    if (viewState === 'view') {
        if (isTendered) {
            return constants_2.VIEW_TENDERED_DAY_STYLE;
        }
        return constants_2.DISABLED_DAY_STYLE;
    }
    if (!canTender) {
        return constants_2.DISABLED_DAY_STYLE;
    }
    if (day.selected) {
        return {
            backgroundColor: constants_1.Color.BLUE,
            color: constants_1.Color.WHITE,
            icon: {
                name: 'CheckCircleIcon',
                design: 'solid',
            },
            isDaySelectable,
        };
    }
    if (isDaySelectable) {
        return {
            ...constants_2.VIEW_TENDERED_DAY_STYLE,
            backgroundColor: constants_1.Color.TRANSPARENT,
            isDaySelectable,
            icon: {
                name: 'Circle',
                design: 'outline',
            },
        };
    }
    return constants_2.DISABLED_DAY_STYLE;
};
exports.getTenderDayCellStatus = getTenderDayCellStatus;
