"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatAvatar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../constants");
const elements_1 = require("../../elements");
const ChatAvatar = ({ image, isInternal, name, size, styles, }) => {
    const pixelSize = constants_1.theme.elements.avatar.size[size ?? 'md'];
    return isInternal ? ((0, jsx_runtime_1.jsx)(elements_1.Div, { styles: {
            alignItems: 'center',
            backgroundColor: constants_1.Color.BLUE,
            borderRadius: 999,
            display: 'flex',
            flexShrink: 0,
            height: pixelSize,
            justifyContent: 'center',
            width: pixelSize,
            ...styles,
        }, children: (0, jsx_runtime_1.jsx)(elements_1.Icon, { color: constants_1.Color.WHITE, name: "ChatAltIcon", size: pixelSize * 0.6 }) })) : ((0, jsx_runtime_1.jsx)(elements_1.Avatar, { image: image, name: name, size: size, styles: styles, type: "image", squared: true }));
};
exports.ChatAvatar = ChatAvatar;
