"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.truncate = void 0;
/**
 * Truncates a string to a specified max length, splitting only between words.
 *
 * @example
 * ```ts
 * truncate('Hello world!', 16) // => 'Hello world!'
 * truncate('Hello world!', 8) // => 'Hello...'
 * ```
 */
const truncate = (string, maxLength) => {
    const words = string.split(/\s+/);
    let output = '';
    for (const word of words) {
        if (output.length + word.length >= maxLength) {
            if (!output.length)
                output += word;
            output += '...';
            break;
        }
        else {
            if (output.length)
                output += ' ';
            output += word;
        }
    }
    return output;
};
exports.truncate = truncate;
