"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALL_RANGE_TYPES = exports.useDateRangePicker = exports.DateRangePicker = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const CustomRange_1 = require("./CustomRange");
const RangeNavigator_1 = require("./RangeNavigator");
const useDateRangePicker_1 = require("./useDateRangePicker");
Object.defineProperty(exports, "useDateRangePicker", { enumerable: true, get: function () { return useDateRangePicker_1.useDateRangePicker; } });
const useRangeType_1 = require("./useRangeType");
Object.defineProperty(exports, "ALL_RANGE_TYPES", { enumerable: true, get: function () { return useRangeType_1.ALL_RANGE_TYPES; } });
const translate_1 = require("../../translate");
const Button_1 = require("../Button");
const Flex_1 = require("../Flex");
const PopoverCombobox_1 = require("../Menu/PopoverCombobox");
const DateRangePicker = (props) => {
    const t = (0, translate_1.useTranslations)();
    const { selectedOption, options } = (0, useRangeType_1.useRangeTypes)(props.rangeType, props.enabledRangeTypes);
    const handleClickToday = () => {
        const todayRange = selectedOption?.getToday?.();
        if (todayRange)
            return props.onChange(...todayRange);
        const today = t.$date();
        // End date should always be defined since today button is disabled when it's not
        if (!props.endDate)
            return props.onChange(today, today);
        const endDiff = props.endDate.diff(props.startDate.startOf('d'), 'd');
        props.onChange(today, today.add(endDiff, 'day'));
    };
    return ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { styles: { height: 'fit-content' }, gap: 2, children: [props.showToday && ((0, jsx_runtime_1.jsx)(Button_1.Button, { design: "white", disabled: !props.endDate, onClick: handleClickToday, size: "xs", children: selectedOption?.todayLabel ?? t.common.today() })), options.length > 1 && ((0, jsx_runtime_1.jsx)(PopoverCombobox_1.PopoverCombobox, { compact: true, closeOnSelect: true, value: props.rangeType, onSelect: props.setRangeType, groups: { items: options }, type: "trigger", trigger: (0, jsx_runtime_1.jsx)(Button_1.Button, { size: "xs", design: "white", children: selectedOption?.label }) })), props.rangeType === 'custom' ? ((0, jsx_runtime_1.jsx)(CustomRange_1.CustomRange, { startDate: props.startDate.toDate(), endDate: props.endDate?.toDate(), onChange: (values) => props.onChange(values.startDate ? t.$date(values.startDate) : props.startDate, values.endDate ? t.$date(values.endDate) : props.endDate), startLabel: props.startDate.format('L'), endLabel: props.endDate?.format('L') })) : ((0, jsx_runtime_1.jsx)(RangeNavigator_1.RangeNavigator, { label: selectedOption?.getLabel?.(props.startDate) ?? '', onChange: (delta) => {
                    if (!selectedOption)
                        return;
                    const newFirstDate = selectedOption.getMovedDate?.(props.startDate, delta);
                    const newLastDate = newFirstDate && selectedOption.getLastDate?.(newFirstDate);
                    if (!newFirstDate || !newLastDate)
                        return;
                    props.onChange(newFirstDate, newLastDate);
                } }))] }));
};
exports.DateRangePicker = DateRangePicker;
DateRangePicker.Navigator = RangeNavigator_1.RangeNavigator;
