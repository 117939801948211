"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dayjs = void 0;
exports.dayjs = {
    calendar: {
        lastDay: '[Yesterday at] h:mm A', // Yesterday at 2:30 AM
        lastWeek: '[Last] dddd [at] h:mm A', // Last Monday at 2:30 AM
        nextDay: '[Tomorrow at] h:mm A', // Tomorrow at 2:30 AM
        nextWeek: 'dddd [at] h:mm A', // Sunday at 2:30 AM
        sameDay: '[Today at] h:mm A', // Today at 2:30 AM
        sameElse: 'DD/MM/YYYY', // 17/10/2011
    },
    custom: {
        completeDate: 'YYYY-MM-DD', // 2011-10-17
        completeDateWithTime: 'YYYY-MM-DD HH:mm', // 2011-10-17 14:30
        monthDay: 'D MMM',
        monthDayTime: 'D MMM HH:mm',
        weekdayDate: 'ddd, D MMM GGGG',
    },
    formatDuration: {
        hours: 'H[h]',
        hoursMinutes: 'H[h] m[m]',
        minutes: 'm[m]',
    },
    formatWeek: {
        useWeekBeginning: true,
        week: '[w/b] D/M', // w/b 18/9
        weekWithYear: '[w/b] D/M/YY', // w/b 18/9/23
        weekWithoutPrefix: 'D/M', // 18/9
    },
};
