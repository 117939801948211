"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDuration = void 0;
const formatDuration = (_option, _dayjsClass, dayjsFactory) => {
    dayjsFactory.formatDuration = function (units, locale) {
        const duration = dayjsFactory.duration(units).locale(locale);
        const format = dayjsFactory.Ls[locale].formatDuration;
        const hours = duration.asHours();
        /**
         * @example 10m
         * @example 0m
         */
        if (hours < 1) {
            return duration.format(format.minutes);
        }
        if (hours >= 24) {
            // Hack to support durations over 24h
            ;
            duration.$d.hours = Math.floor(hours);
        }
        /**
         * @example 3h
         */
        if (!duration.minutes()) {
            return duration.format(format.hours);
        }
        return duration.format(format.hoursMinutes);
    };
};
exports.formatDuration = formatDuration;
