"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serializeTime = void 0;
const format_time_1 = require("./format-time");
const normalize_time_1 = require("./normalize-time");
const serializeTime = (time) => {
    const { hour, minute } = (0, normalize_time_1.normalizeTime)(time);
    return `${(0, format_time_1.formatTimeToken)(hour)}:${(0, format_time_1.formatTimeToken)(minute)}`;
};
exports.serializeTime = serializeTime;
