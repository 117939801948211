import { css, Global } from '@emotion/react'
import { useLocation } from '@reach/router'
import Drift from 'react-driftjs'

import { useAuthContext } from './context/AuthContext'

export const DriftContainer = () => {
  const { currentUser } = useAuthContext()
  const location = useLocation()

  const hideDrift =
    location.pathname.includes('/jobb') ||
    location.pathname.includes('/p/profil') ||
    location.pathname.includes('login') ||
    location.pathname.includes('/ref') ||
    location.pathname.includes('/jobs')

  return (
    <>
      {hideDrift && (
        <Global
          styles={
            hideDrift &&
            css`
              .drift-conductor-item {
                display: none !important;
              }
            `
          }
        />
      )}
      <Drift
        appId="x2792er3vay5"
        userId={currentUser ? currentUser.uid : undefined}
        attributes={
          currentUser
            ? {
                email: currentUser.email,
                name: currentUser.displayName,
              }
            : undefined
        }
      />
    </>
  )
}
