"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertUIDraftShiftToShift = void 0;
const schedule_1 = require("@bemlo/schedule");
const convertUIDraftShiftToShift = (draft) => {
    const dateTime = (0, schedule_1.fromDateTimeComponentsWithOnCall)(draft.date, draft.startTime, draft.endTime, draft.onCallTime);
    const onCallTime = draft.onCallTime && dateTime.onCallTime
        ? {
            type: draft.onCallTime.type,
            startTime: draft.onCallTime.startTime,
            endTime: draft.onCallTime.endTime,
            startDateTime: dateTime.onCallTime.startDateTime,
            endDateTime: dateTime.onCallTime.endDateTime,
        }
        : null;
    const durations = (0, schedule_1.getShiftDurations)({
        startDateTime: dateTime.startDateTime,
        endDateTime: dateTime.endDateTime,
        breakTime: draft.breakTime,
        onCallTime,
    });
    return {
        scheduleSlotId: undefined,
        status: 'DRAFT',
        ...draft,
        startDateTime: dateTime.startDateTime,
        endDateTime: dateTime.endDateTime,
        onCallTime,
        durations,
        isArchived: false,
        isPublished: false,
        isTendered: false,
        isBooked: false,
        isUrgent: false,
    };
};
exports.convertUIDraftShiftToShift = convertUIDraftShiftToShift;
