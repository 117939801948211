"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Location = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Municipalities_1 = require("./Municipalities");
const Regions_1 = require("./Regions");
const elements_1 = require("../../../elements");
const useProfessionalView_1 = require("../hooks/useProfessionalView");
const useProfessionalViewAlternatives_1 = require("../hooks/useProfessionalViewAlternatives");
const scopes_1 = require("../scopes");
const Location = () => {
    const { isEditing, actor } = (0, useProfessionalView_1.useProfessionalView)();
    const { municipalityAlternatives, regionAlternatives } = (0, useProfessionalViewAlternatives_1.useProfessionalViewAlternatives)();
    if (!municipalityAlternatives.length && !regionAlternatives.length) {
        return null;
    }
    const currentScopes = [
        scopes_1.scopes.interests.municipalities(),
        scopes_1.scopes.interests.regions(),
    ];
    if (currentScopes.every((scope) => !actor.can('read', scope))) {
        return null;
    }
    const isEditingScope = currentScopes.some((scope) => isEditing(scope));
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: !isEditingScope, gap: 3, children: [(0, jsx_runtime_1.jsx)(Regions_1.Regions, {}), (0, jsx_runtime_1.jsx)(Municipalities_1.Municipalities, {})] }));
};
exports.Location = Location;
