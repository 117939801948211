"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unitedKingdomWhichRegionAlternative = exports.specialWhichRegionAlternative = void 0;
const enums_1 = require("@bemlo/enums");
exports.specialWhichRegionAlternative = {
    [enums_1.SpecialWhichRegionAlternative.ALL]: 'All',
    [enums_1.SpecialWhichRegionAlternative.NONE]: 'None',
};
exports.unitedKingdomWhichRegionAlternative = {
    [enums_1.UnitedKingdomWhichRegionAlternative.SOUTH_WEST_ENGLAND]: 'South West England',
};
