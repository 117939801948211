"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ui = void 0;
exports.ui = {
    break_n_min: (breakTime) => `Pause: ${breakTime} min`,
    cancel: () => 'Avbryt',
    confirm: () => 'Bekreft',
    filter_ellipsis: () => 'Filtrer...',
    includes: () => 'inkluderer',
    includes_all_of: () => 'inkluderer alle av',
    includes_any_of: () => 'inkluderer en eller flere av',
    includes_none_of: () => 'inkluderer ingen av',
    is: () => 'er',
    is_after: () => 'er fra og med',
    is_any_of: () => 'er en eller flere av',
    is_before: () => 'er till og med',
    is_not: () => 'er ikke',
    is_not_part_of: () => 'er ikke en del av',
    is_part_of: () => 'er en del av',
    n_shifts_available: (n) => `${n} vakter tilgjengelig`,
    no_alternatives: () => 'Ingen alternativer',
    no_break: () => 'Ingen pause',
    not_includes: () => 'inkluderer ikke',
    optional: () => 'frivillig',
    reply: () => 'Svar',
    reply_from_x: (name) => `Svar fra ${name}`,
    selected_one: () => 'Valgt',
    shift_acknowledged_by_candidate: () => 'Vaktbooking bekreftet av kandidaten',
    week: (number) => `Uke${number !== undefined ? ` ${number}` : ''}`,
};
