"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateUsingBothKeyAndValue = void 0;
const translateUsingBothKeyAndValue = (myEnum, t) => {
    /*
      We want to support e.g. using both `DEMENS` and `departmentDemens` as translation key from
  
      enum WhichRegionAlternative {
        DEMENS = "departmentDemens",
      }
  
      because when using @bemlo/enum we use `departmentDemens`, and when getting it though
      `../generated/graphql` it will be `DEMENS`.
    */
    const translations = {};
    for (const enumKey in myEnum) {
        const enumValue = myEnum[enumKey];
        translations[enumKey] = t[enumValue]; // DEMENS: "Demensboende"
        translations[enumValue] = t[enumValue]; // departmentDemens: "Demensboende"
    }
    return (key) => {
        return translations[key];
    };
};
exports.translateUsingBothKeyAndValue = translateUsingBothKeyAndValue;
