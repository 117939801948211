"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tenderWaiverStatus = void 0;
const enums_1 = require("@bemlo/enums");
exports.tenderWaiverStatus = {
    [enums_1.TenderWaiverStatus.APPROVED]: 'Approved',
    [enums_1.TenderWaiverStatus.REJECTED]: 'Rejected',
    [enums_1.TenderWaiverStatus.PENDING]: 'Pending',
    [enums_1.TenderWaiverStatus.USED]: 'Closed',
};
