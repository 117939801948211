"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScopeHeader = exports.ScopeCell = exports.ScopeCellView = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const ScopeCellView = ({ scope, initialScope, error, }) => {
    const t = (0, translate_1.useTranslations)();
    const displayValue = ((0, jsx_runtime_1.jsx)(elements_1.Text, { size: 6, styles: { color: error ? constants_1.Color.RED : undefined }, children: scope !== undefined ? t.common.percent_sign(scope) : '\u2014' }));
    if (initialScope === undefined || initialScope === scope) {
        return displayValue;
    }
    const displayInitialValue = t.common.percent_sign(initialScope);
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { size: 6, styles: {
                    textDecoration: 'line-through',
                    opacity: 0.5,
                }, children: displayInitialValue }), displayValue] }));
};
exports.ScopeCellView = ScopeCellView;
const ScopeCellEditing = ({ disabled, scope, updateScope, }) => {
    const [newScope, setNewScope] = (0, react_1.useState)(scope);
    return ((0, jsx_runtime_1.jsx)(elements_1.Form.NumberInput, { disabled: disabled, styles: { minWidth: 100, width: 100 }, autoFocus: true, value: newScope, onChange: (value) => value && setNewScope(value), onBlur: () => updateScope?.(newScope), fullWidth: true }));
};
const ScopeCellEdit = ({ scope, updateScope, disabled, error, initialScope, }) => {
    const [isEditing, setIsEditing] = (0, react_1.useState)(false);
    const updateScopeAndEndEditing = (scope) => {
        updateScope?.(scope);
        setIsEditing(false);
    };
    if (isEditing) {
        return ((0, jsx_runtime_1.jsx)(ScopeCellEditing, { disabled: disabled, scope: scope, updateScope: updateScopeAndEndEditing }));
    }
    return ((0, jsx_runtime_1.jsxs)(elements_1.Clickable, { disabled: disabled, onClick: () => setIsEditing(true), styles: { display: 'flex', gap: 2, minWidth: 100 }, children: [(0, jsx_runtime_1.jsx)(exports.ScopeCellView, { scope: scope, initialScope: initialScope, error: error }), (0, jsx_runtime_1.jsx)(elements_1.Icon, { name: "PencilIcon", color: error ? constants_1.Color.RED : disabled ? constants_1.Color.DARK_MEDIUM_GREY : constants_1.Color.BLUE, size: "sm" })] }));
};
const ScopeCell = ({ scope, viewState, updateScope, disabled, error, initialScope, }) => {
    switch (viewState) {
        case 'edit':
            return ((0, jsx_runtime_1.jsx)(ScopeCellEdit, { disabled: disabled, scope: scope, updateScope: updateScope, error: error, initialScope: initialScope }));
        case 'view':
            return (0, jsx_runtime_1.jsx)(exports.ScopeCellView, { scope: scope, initialScope: initialScope });
    }
};
exports.ScopeCell = ScopeCell;
const ScopeHeader = () => {
    const t = (0, translate_1.useTranslations)();
    return (0, jsx_runtime_1.jsx)(elements_1.Text, { size: 6, children: t.common.scope() });
};
exports.ScopeHeader = ScopeHeader;
