"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Row = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Box_1 = require("./Box");
/**
 * @deprecated use <Div /> instead
 */
const Row = (props) => {
    return ((0, jsx_runtime_1.jsx)(Box_1.Box, { flexDirection: props.reverse ? 'row-reverse' : 'row', ...props, children: props.children }));
};
exports.Row = Row;
