"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profession = void 0;
const enums_1 = require("@bemlo/enums");
const profession_1 = require("../en/profession");
exports.profession = {
    ...profession_1.profession,
    [enums_1.Profession.DOCTOR]: {
        short: 'LÆGE',
        title: {
            '*': 'Læger',
            '1': 'Læge',
        },
    },
    [enums_1.Profession.NURSE]: {
        short: 'SPL',
        title: {
            '*': 'Sygeplejersker',
            '1': 'Sygeplejerske',
        },
    },
    [enums_1.Profession.ASSISTANT_NURSE]: {
        short: 'ASPL',
        title: {
            '*': 'Assisterende sygeplejersker',
            '1': 'Assisterende sygeplejerske',
        },
    },
};
