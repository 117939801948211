"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.itemStyles = exports.panelAnimation = exports.panelStyles = void 0;
const react_1 = require("@emotion/react");
const constants_1 = require("../../constants");
exports.panelStyles = {
    border: constants_1.theme.border.style.thin,
    borderRadius: constants_1.theme.border.radius.normal,
    boxShadow: constants_1.theme.boxShadow.normal,
    width: '100%',
    overflow: 'hidden',
    background: constants_1.Color.WHITE,
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
};
const slideUpAndFade = (0, react_1.keyframes)({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});
const slideRightAndFade = (0, react_1.keyframes)({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});
const slideDownAndFade = (0, react_1.keyframes)({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});
const slideLeftAndFade = (0, react_1.keyframes)({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});
exports.panelAnimation = {
    '&[data-state="open"]': {
        '&[data-side="top"]': { animationName: slideDownAndFade },
        '&[data-side="right"]': { animationName: slideLeftAndFade },
        '&[data-side="bottom"]': { animationName: slideUpAndFade },
        '&[data-side="left"]': { animationName: slideRightAndFade },
    },
};
exports.itemStyles = {
    font: 'inherit',
    fontSize: constants_1.theme.font.size[6],
    gap: constants_1.theme.spacing['2.5'],
    padding: `${constants_1.theme.padding.button.small.y}px ${constants_1.theme.padding.button.smaller.x}px`,
    overflow: 'hidden',
    color: constants_1.Color.BLACK,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
        background: constants_1.Color.LIGHTEST_BLUE,
    },
};
