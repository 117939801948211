"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.acceptedScope = exports.acceptedShiftHours = exports.isAvailabilityWeekAccepted = exports.isDayAccepted = void 0;
const constants_1 = require("../utils/constants");
const isDayAccepted = (day) => !!day.acceptedDayId;
exports.isDayAccepted = isDayAccepted;
const isAvailabilityWeekAccepted = (availability) => !!availability.acceptedAvailability;
exports.isAvailabilityWeekAccepted = isAvailabilityWeekAccepted;
const acceptedShiftHours = (availability) => availability.acceptedAvailability?.scope.shiftHours ?? constants_1.EMPTY_ARRAY;
exports.acceptedShiftHours = acceptedShiftHours;
const acceptedScope = (availability) => availability.acceptedAvailability?.scope.scope;
exports.acceptedScope = acceptedScope;
