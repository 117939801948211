"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatEmptyPane = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../elements");
const translate_1 = require("../../translate");
const ChatEmptyPane = (props) => {
    const t = (0, translate_1.useTranslations)();
    return ((0, jsx_runtime_1.jsx)(elements_1.EmptyState, { heading: t.chat_view.no_conversation_heading(props.isBrowsable), icon: "ChatAlt2Icon", paragraph: t.chat_view.no_conversation_paragraph(props.isBrowsable, props.isCreatable), children: props.children }));
};
exports.ChatEmptyPane = ChatEmptyPane;
