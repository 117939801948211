"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profession = void 0;
const enums_1 = require("@bemlo/enums");
exports.profession = {
    [enums_1.Profession.ASSISTANT_NURSE]: {
        short: 'HFA',
        title: {
            '*': 'Helsefagarbeidere',
            '1': 'Helsefagarbeider',
        },
    },
    [enums_1.Profession.AUDIOLOGIST]: {
        short: 'AUD',
        title: {
            '*': 'Audiologer',
            '1': 'Audiolog',
        },
    },
    [enums_1.Profession.CARER]: {
        short: 'PAS',
        title: {
            '*': 'Pleieassistenter',
            '1': 'Pleieassistent',
        },
    },
    [enums_1.Profession.DOCTOR]: {
        short: 'LEGE',
        title: {
            '*': 'Leger',
            '1': 'Lege',
        },
    },
    [enums_1.Profession.MEDICAL_LABORATORY_TECHNICIAN]: {
        short: 'BIO',
        title: {
            '*': 'Bioingeniører',
            '1': 'Bioingeniør',
        },
    },
    [enums_1.Profession.MEDICAL_SECRETARY]: {
        short: 'HSEK',
        title: {
            '*': 'Helsesekretærer',
            '1': 'Helsesekretær',
        },
    },
    [enums_1.Profession.NURSE]: {
        short: 'SPL',
        title: {
            '*': 'Sykepleiere',
            '1': 'Sykepleier',
        },
    },
    [enums_1.Profession.PHYSIOTHERAPIST]: {
        short: 'FYS',
        title: {
            '*': 'Fysioterapeuter',
            '1': 'Fysioterapeut',
        },
    },
    [enums_1.Profession.PSYCHOLOGIST]: {
        short: 'PSYK',
        title: {
            '*': 'Psykologer',
            '1': 'Psykolog',
        },
    },
    [enums_1.Profession.PSYCHOTHERAPIST]: {
        short: 'PSTH',
        title: {
            '*': 'Psykoterapeuter',
            '1': 'Psykoterapeut',
        },
    },
    [enums_1.Profession.SOCIAL_EDUCATOR]: {
        short: 'VPL',
        title: {
            '*': 'Vernepleiere',
            '1': 'Vernepleier',
        },
    },
};
// TODO: replace with TypeScript satisfies when TypeScript is upgraded
const _satisfies = exports.profession;
