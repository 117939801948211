"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areAllAvailabilityWeeksSelected = exports.areAllDaysSelected = exports.areAllShiftHoursSelected = void 0;
const constants_1 = require("./constants");
const constants_2 = require("./constants");
const areAllShiftHoursSelected = (availabilities) => {
    const selectedAvailabilities = availabilities.filter((availability) => availability.selected);
    return constants_1.ALL_AVAILABILITY_SHIFT_HOURS.reduce((result, shiftHours) => {
        const areAllSelected = selectedAvailabilities.every((week) => !week.availableShiftHours.includes(shiftHours) ||
            week.shiftHours.includes(shiftHours));
        const isSomeSelected = selectedAvailabilities.some((week) => week.shiftHours.includes(shiftHours));
        return {
            ...result,
            [shiftHours]: {
                areAllSelected,
                isSomeSelected,
                disabled: !selectedAvailabilities.some((week) => week.availableShiftHours.includes(shiftHours)),
            },
        };
    }, {});
};
exports.areAllShiftHoursSelected = areAllShiftHoursSelected;
const areAllDaysSelected = (availabilities) => {
    const selectedAvailabilities = availabilities.filter((availability) => availability.selectable);
    return constants_2.WEEK_DAY_INDICES.map((dayIndex) => {
        const selectableDays = selectedAvailabilities
            .map((availability) => availability.days[dayIndex])
            .filter((day) => day.selectable);
        const areAllSelected = selectableDays.every((day) => day.selected);
        const isSomeSelected = selectableDays.some((day) => day.selected);
        return {
            areAllSelected,
            isSomeSelected,
            disabled: selectableDays.length === 0,
        };
    });
};
exports.areAllDaysSelected = areAllDaysSelected;
const areAllAvailabilityWeeksSelected = (availabilities, includedIf = () => true) => {
    const includedAvailabilities = availabilities.filter(includedIf);
    const areAllSelected = includedAvailabilities.every((availability) => availability.selected);
    const isSomeSelected = includedAvailabilities.some((availability) => availability.selected);
    return { areAllSelected, isSomeSelected };
};
exports.areAllAvailabilityWeeksSelected = areAllAvailabilityWeeksSelected;
