"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditShifts = void 0;
const useSchedule_1 = require("./useSchedule");
const useEditShifts = (input) => {
    const { onCreateDraftShift, onDeleteDraftShift, enableEditing } = (0, useSchedule_1.useSchedule)();
    const handleCreateDraftShift = (date, scheduleSlotId) => {
        if (!input)
            return;
        onCreateDraftShift?.({
            ...input,
            date,
            scheduleSlotId,
            rowKey: input.rowKey,
        });
    };
    const handleDeleteDraftShift = (id) => onDeleteDraftShift?.(id);
    return {
        onCreateDraftShift: handleCreateDraftShift,
        onDeleteDraftShift: handleDeleteDraftShift,
        enableEditing,
    };
};
exports.useEditShifts = useEditShifts;
