"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Booking = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const Booking = (props) => {
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 2, items: "center", styles: {
            gridColumn: '2 / 5',
            borderRadius: constants_1.theme.border.radius.small,
            justifyContent: 'center',
            background: constants_1.Color.DARK_GREEN,
            color: constants_1.Color.WHITE,
        }, children: [(0, jsx_runtime_1.jsx)(elements_1.Icon, { name: "CheckIcon", color: constants_1.Color.WHITE, design: "solid" }), (0, jsx_runtime_1.jsx)(elements_1.Flex, { col: true, styles: { textAlign: 'center' }, children: (0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, size: 6, children: props.booking.unit.name }) })] }));
};
exports.Booking = Booking;
