import { MarketFeature } from '../../config/enums'
import { marketConfig } from '../../config/market-configs'

export const useFeatures = () => {
  return Object.values(MarketFeature).reduce(
    (features, feature) => {
      features[feature] = marketConfig.features.includes(feature)
      return features
    },
    {} as Record<MarketFeature, boolean>,
  )
}
