"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Circle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Box_1 = require("./Box");
/**
 * @deprecated
 */
const Circle = (props) => {
    return ((0, jsx_runtime_1.jsx)(Box_1.Box, { ...props, alignItems: "center", borderRadius: 999, flexShrink: 0, justifyContent: "center" }));
};
exports.Circle = Circle;
