"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShiftHours = void 0;
const lodash_1 = require("lodash");
const date_1 = require("@bemlo/date");
const enums_1 = require("@bemlo/enums");
const DAY_CONSTRAINTS = [6, 18];
const EVENING_CONSTRAINTS = [13, 23];
const NIGHT_CONSTRAINTS = [21, 9];
const isDay = (hour) => {
    return hour >= DAY_CONSTRAINTS[0] && hour < DAY_CONSTRAINTS[1];
};
const isEvening = (hour) => {
    return hour >= EVENING_CONSTRAINTS[0] && hour < EVENING_CONSTRAINTS[1];
};
const isNight = (hour) => {
    return hour >= NIGHT_CONSTRAINTS[0] || hour < NIGHT_CONSTRAINTS[1];
};
const getShiftHours = (shift) => {
    const isOnCall = (hour) => {
        return shift.onCallTime
            ? hour.isBetween(shift.onCallTime.startDateTime, shift.onCallTime.endDateTime, 'hour', '[]')
            : false;
    };
    const score = {
        DAY: 0,
        NIGHT: 0,
        EVENING: 0,
    };
    // Length of shift including onCallTime
    const hoursCeil = Math.ceil(date_1.dayjsEn.duration(shift.endDateTime.diff(shift.startDateTime)).asHours());
    const startHour = shift.startDateTime;
    for (let i = 0; i < hoursCeil; i++) {
        const hour = startHour.add(i, 'hour');
        if (isOnCall(hour)) {
            continue;
        }
        const hourValue = hour.hour();
        if (isDay(hourValue)) {
            score.DAY++;
        }
        if (isEvening(hourValue)) {
            score.EVENING++;
        }
        if (isNight(hourValue)) {
            score.NIGHT++;
        }
    }
    const shiftHours = (0, lodash_1.maxBy)([enums_1.ShiftHours.DAY, enums_1.ShiftHours.EVENING, enums_1.ShiftHours.NIGHT], (shiftHours) => score[shiftHours]);
    return shiftHours;
};
exports.getShiftHours = getShiftHours;
