"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateTenderStatusFactory = void 0;
const locales_1 = require("../locales");
const translateTenderStatusFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('tenderStatus', locale);
    return (tenderStatus) => {
        return t[tenderStatus];
    };
};
exports.translateTenderStatusFactory = translateTenderStatusFactory;
