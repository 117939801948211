import { createContext, useContext, useEffect, useState } from 'react'
import { group } from '@bemlo/tracking'

import { ProviderProps } from '../../common/types'
import { Profession } from '../../generated/graphql'

interface UserContextValues {
  profession: Profession
  updateProfession: (newProfession: Profession) => void
}

const UserContext = createContext<UserContextValues | null>(null)

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error(
      'useUserContext must be used within the useUserContext.Provider',
    )
  }
  return context
}

export const useSelectedProfession = (newProfession?: Profession) => {
  const { profession, updateProfession } = useUserContext()

  useEffect(() => {
    if (newProfession && profession !== newProfession) {
      updateProfession(newProfession)
    }
  }, [])
}

export const UserContextProvider = ({ children }: ProviderProps) => {
  const [profession, setProfession] = useState(Profession.NURSE)

  useEffect(() => {
    const loadProfession = () => {
      const loadedProfession = localStorage.getItem('profession')
      return loadedProfession && loadedProfession in Profession
        ? (loadedProfession as Profession)
        : Profession.NURSE
    }

    const init = () => {
      const initProfession = loadProfession()

      if (initProfession) setProfession(initProfession)
    }
    init()
  }, [])

  const updateProfession = (newProfession: Profession) => {
    localStorage.setItem('profession', newProfession)
    setProfession(newProfession)

    group(profession)
  }

  return (
    <UserContext.Provider
      value={{
        profession,
        updateProfession,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
