"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.locale = void 0;
const enums_1 = require("@bemlo/enums");
exports.locale = {
    [enums_1.Locale.DA]: 'Danish',
    [enums_1.Locale.EN]: 'English',
    [enums_1.Locale.FI]: 'Finnish',
    [enums_1.Locale.NB]: 'Norwegian (bokmål)',
    [enums_1.Locale.SV]: 'Swedish',
};
