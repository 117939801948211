"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.experienceEnumToNumber = exports.yearsOfExperienceToFrontendEnum = exports.yearsOfExperienceToEnum = exports.experienceTimestampToEnum = void 0;
const enums_1 = require("@bemlo/enums");
const enum_1 = require("./enum");
const EXPERIENCE_ENUMS_LOWER_BOUNDS = [
    [enums_1.HowMuchExperienceAlternative.MORE_THAN_TEN, 10],
    [enums_1.HowMuchExperienceAlternative.FIVE_TO_TEN, 5],
    [enums_1.HowMuchExperienceAlternative.THREE_TO_FIVE, 3],
    [enums_1.HowMuchExperienceAlternative.TWO_TO_THREE, 2],
    [enums_1.HowMuchExperienceAlternative.TWO, 2],
    [enums_1.HowMuchExperienceAlternative.ONE_TO_TWO, 1],
    [enums_1.HowMuchExperienceAlternative.LESS_THAN_ONE, 0],
    [enums_1.HowMuchExperienceAlternative.NEW_GRADUATE, 0],
    [enums_1.HowMuchExperienceAlternative.STUDENT, 0],
];
const experienceEnumToNumberMap = EXPERIENCE_ENUMS_LOWER_BOUNDS.reduce((acc, [enumValue, lowerBound]) => acc.set(enumValue, lowerBound), new Map());
const unixTimeToYear = (unixTime) => new Date(unixTime).getFullYear();
const experienceTimestampToEnum = (experience) => (0, exports.yearsOfExperienceToEnum)(unixTimeToYear(experience));
exports.experienceTimestampToEnum = experienceTimestampToEnum;
const yearsOfExperienceToEnum = (experience) => EXPERIENCE_ENUMS_LOWER_BOUNDS.find(([, lowerBound]) => experience >= lowerBound)?.[0];
exports.yearsOfExperienceToEnum = yearsOfExperienceToEnum;
/*
  Required since the @bemlo/enums HowMuchExperienceAlternative looks like:
    STUDENT = 'student'
  But the frontend HowMuchExperienceAlternative looks like:
    STUDENT = 'STUDENT'
*/
const yearsOfExperienceToFrontendEnum = (experience) => (0, enum_1.keyOf)(enums_1.HowMuchExperienceAlternative, (0, exports.yearsOfExperienceToEnum)(experience));
exports.yearsOfExperienceToFrontendEnum = yearsOfExperienceToFrontendEnum;
const experienceEnumToNumber = (experience) => experienceEnumToNumberMap.get(experience) ??
    experienceEnumToNumberMap.get(enums_1.HowMuchExperienceAlternative[experience]);
exports.experienceEnumToNumber = experienceEnumToNumber;
