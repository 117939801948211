"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isKeyTruthy = exports.isTruthy = exports.isNotUndefined = exports.isNotNull = void 0;
/**
 * Returns true if the value is not `null`, in a type-safe way.
 */
const isNotNull = (item) => {
    return item !== null;
};
exports.isNotNull = isNotNull;
/**
 * Returns true if the value is not `undefined`, in a type-safe way.
 */
const isNotUndefined = (item) => {
    return item !== undefined;
};
exports.isNotUndefined = isNotUndefined;
/**
 * Returns true if the value is truthy, in a type-safe way.
 *
 * A better alternative to `.filter(Boolean)`.
 *
 * @example
 * ```ts
 * const values = [
 *   true && { value: true },
 *   false && { value: false },
 * ]
 *
 * typeof values.filter(Boolean)
 * // ❌ (boolean | { value: boolean })[]
 *
 * typeof values.filter(isTruthy)
 * // ✅ { value: boolean }[]
 * ```
 */
const isTruthy = (value) => {
    return !!value;
};
exports.isTruthy = isTruthy;
/**
 * Returns true if the value of the specified key in a defined object is falsy, in a type-safe way.
 * @param key a key of an object
 * @returns a type predicate that checks if the value of the specified key is falsy
 */
const isKeyTruthy = (key) => {
    return (obj) => {
        return !!obj[key];
    };
};
exports.isKeyTruthy = isKeyTruthy;
