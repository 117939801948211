"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CandidateType = void 0;
var CandidateType;
(function (CandidateType) {
    CandidateType["BOOKED"] = "BOOKED";
    CandidateType["MANAGED"] = "MANAGED";
    CandidateType["MIRRORED"] = "MIRRORED";
    CandidateType["SOVEREIGN"] = "SOVEREIGN";
    CandidateType["STATIC"] = "STATIC";
    CandidateType["SYNCED"] = "SYNCED";
    CandidateType["EMPLOYED"] = "EMPLOYED";
})(CandidateType || (exports.CandidateType = CandidateType = {}));
