"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftsToTenderedHours = exports.shiftsToShiftHours = exports.shiftsToWorkHours = exports.shiftsToHours = exports.shiftToWorkHours = exports.shiftToHours = exports.shiftWorkDuration = void 0;
const date_1 = require("@bemlo/date");
const getDuration = (date, startTime, endTime) => {
    const startMoment = (0, date_1.parseDateTime)(date, startTime);
    // Check for:
    //  - Night shifts that might cross midnight
    //  - 24 hour shifts
    let endMoment = (0, date_1.parseDateTime)(date, endTime);
    if (endMoment.isBefore(startMoment) || endMoment.isSame(startMoment)) {
        endMoment = endMoment.add(1, 'day');
    }
    return date_1.dayjsEn.duration(endMoment.diff(startMoment));
};
const shiftWorkDuration = (shift) => {
    const shiftDuration = getDuration(shift.date, shift.startTime, shift.endTime);
    const breakDuration = date_1.dayjsEn.duration(shift.breakTime, 'minutes');
    const onCallDuration = shift.onCallTime
        ? getDuration(shift.date, shift.onCallTime.startTime, shift.onCallTime.endTime)
        : date_1.dayjsEn.duration(0);
    let workTime = shiftDuration.subtract(onCallDuration);
    if (workTime.asHours()) {
        workTime = workTime.subtract(breakDuration);
    }
    const tenderedTime = shiftDuration.subtract(breakDuration);
    return {
        // The full length of the shift, no matter on call, break or ordinary time
        shift: shiftDuration,
        // Shift duration minus the break time, how tenders see the shift for fill rate
        tenderedTime,
        // The time spent on break
        break: breakDuration,
        // The time spent on call
        onCall: onCallDuration,
        // The time spent working and not on break or on call
        workTime,
    };
};
exports.shiftWorkDuration = shiftWorkDuration;
const shiftToHours = (shift) => {
    return getDuration(shift.date, shift.startTime, shift.endTime).asHours();
};
exports.shiftToHours = shiftToHours;
const shiftToWorkHours = (shift) => {
    return (0, exports.shiftWorkDuration)(shift).workTime.asHours();
};
exports.shiftToWorkHours = shiftToWorkHours;
const shiftsToHours = (shifts) => {
    return shifts.reduce((acc, shift) => acc + (0, exports.shiftToHours)(shift), 0);
};
exports.shiftsToHours = shiftsToHours;
const shiftsToWorkHours = (shifts) => {
    return shifts.reduce((acc, shift) => acc + (0, exports.shiftToWorkHours)(shift), 0);
};
exports.shiftsToWorkHours = shiftsToWorkHours;
const shiftsToShiftHours = (shifts) => {
    return shifts.reduce((acc, shift) => acc + (0, exports.shiftWorkDuration)(shift).shift.asHours(), 0);
};
exports.shiftsToShiftHours = shiftsToShiftHours;
const shiftsToTenderedHours = (shifts) => {
    return shifts.reduce((acc, shift) => acc + (0, exports.shiftWorkDuration)(shift).tenderedTime.asHours(), 0);
};
exports.shiftsToTenderedHours = shiftsToTenderedHours;
