"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterPersonalData = void 0;
// Add to the fields whatever is forbidden
const FORBIDDEN_PROPERTIES = [
    'name',
    'firstName',
    'lastName',
    'fullName',
    'personalIdentityNumber',
    'ssn',
    'email',
    'phone',
    'phoneNumber',
    'telephone',
    'address',
];
// We need to treat invariants such as FirstName, firstName, firstname same way
const UNIFIED_FORBIDDEN_PROPERTIES = FORBIDDEN_PROPERTIES.map((field) => field.toLowerCase());
const filterPersonalData = (inputObject) => {
    const result = {};
    if (inputObject) {
        Object.keys(inputObject).forEach((key) => {
            if (!UNIFIED_FORBIDDEN_PROPERTIES.includes(key.toLowerCase())) {
                result[key] = inputObject[key];
            }
        });
    }
    return result;
};
exports.filterPersonalData = filterPersonalData;
