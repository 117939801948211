"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Market = void 0;
var Market;
(function (Market) {
    Market["DK"] = "DK";
    Market["FI"] = "FI";
    Market["GB"] = "GB";
    Market["NO"] = "NO";
    Market["SE"] = "SE";
})(Market || (exports.Market = Market = {}));
