"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMunicipalities = getMunicipalities;
exports.getRegionForMunicipality = getRegionForMunicipality;
const lodash_1 = require("lodash");
const enums_1 = require("@bemlo/enums");
function getMunicipalities({ enum: myEnum, country, region, }) {
    const regionMunicipalityHierarchy = country
        ? (enums_1.COUNTRY_REGION_MUNICIPALITY_HIERARCHY[country] ?? {})
        : Object.assign({}, ...Object.values(enums_1.COUNTRY_REGION_MUNICIPALITY_HIERARCHY));
    const municipalities = region
        ? (regionMunicipalityHierarchy[region] ?? [])
        : Object.values(regionMunicipalityHierarchy).flat();
    if (myEnum) {
        /*
          If enum is supplied, we want to return the keys of Municipality instead of value,
          because that will match that enum's value
        */
        const invertedMunicipalities = (0, lodash_1.invert)(enums_1.Municipality);
        return municipalities.map((value) => invertedMunicipalities[value]);
    }
    return municipalities;
}
let getRegionForMunicipalityCache = undefined;
function getRegionForMunicipality(municipality) {
    if (!getRegionForMunicipalityCache) {
        ;
        getRegionForMunicipalityCache = Object.fromEntries(Object.values(enums_1.COUNTRY_REGION_MUNICIPALITY_HIERARCHY).flatMap((regionMunicipalityHierarchy) => Object.entries(regionMunicipalityHierarchy).flatMap(([region, municipalities]) => municipalities.map((municipality) => [municipality, region]))));
    }
    return getRegionForMunicipalityCache[municipality];
}
