"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTimeRange = void 0;
const formatTimeRange = (_option, dayjsClass, dayjsFactory) => {
    dayjsClass.prototype.formatTimeRange = function (toTime) {
        if (!dayjsFactory.isDayjs(toTime)) {
            toTime = dayjsFactory.time(toTime);
        }
        return this.format('LT') + ' – ' + toTime.format('LT');
    };
};
exports.formatTimeRange = formatTimeRange;
