"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizePersonalIdentityNumber = exports.isValidPersonalIdentityNumber = exports.completePersonalIdentityNumber = exports.formatPersonalIdentityNumber = exports.PERSONAL_IDENTITY_NUMBER_REGEXP = void 0;
exports.PERSONAL_IDENTITY_NUMBER_REGEXP = /^[0-9]{8}-?[0-9]{4}$/;
/**
 * Formats a Swedish personal identity number to a hyphen-delimited 12-character string.
 *
 * @example
 * ```ts
 * formatPersonalIdentityNumber('8112289874') // => '811228-9874'
 * formatPersonalIdentityNumber('811228-9874') // => '811228-9874'
 * formatPersonalIdentityNumber('198112289874') // => '19811228-9874'
 * formatPersonalIdentityNumber('19811228-9874') // => '19811228-9874'
 * formatPersonalIdentityNumber('000 1981 12 28 !"#¤ 9874 ...') // => '19811228-9874'
 * ```
 *
 * @see https://en.wikipedia.org/wiki/Personal_identity_number_(Sweden)
 */
const formatPersonalIdentityNumber = (value) => {
    const digits = value.replace(/[^0-9]/g, '');
    if (digits.length <= 8)
        return digits;
    return `${digits.slice(-12, -4)}-${digits.slice(-4)}`;
};
exports.formatPersonalIdentityNumber = formatPersonalIdentityNumber;
/**
 * Formats and extends a 10-character Swedish personal identity number to a
 * hyphen-delimited 12-character string.
 *
 * @example
 * ```ts
 * completePersonalIdentityNumber('8112289874') // => '19811228-9874'
 * completePersonalIdentityNumber('811228-9874') // => '19811228-9874'
 * completePersonalIdentityNumber('198112289874') // => '19811228-9874'
 * completePersonalIdentityNumber('19811228-9874') // => '19811228-9874'
 * completePersonalIdentityNumber('000 1981 12 28 !"#¤ 9874 ...') // => '19811228-9874'
 * ```
 */
const completePersonalIdentityNumber = (value) => {
    const base = (0, exports.normalizePersonalIdentityNumber)(value);
    // Only complete 10-character personal identity numbers
    // or 6-character birth dates.
    if (base.length !== 10 && base.length !== 6) {
        return (0, exports.formatPersonalIdentityNumber)(value);
    }
    const datePart = base.substring(0, 6);
    const decade = parseInt(datePart.substring(0, 2));
    const currentFullYear = new Date().getFullYear().toString();
    const currentCentury = parseInt(currentFullYear.substring(0, 2));
    const currentDecade = parseInt(currentFullYear.substring(2, 4));
    const century = decade <= currentDecade ? currentCentury : currentCentury - 1;
    const dayPart = datePart.substring(4, 6);
    const monthPart = datePart.substring(2, 4);
    const date = Date.parse(`${century}${decade}-${monthPart}-${dayPart}`);
    if (isNaN(date)) {
        return (0, exports.formatPersonalIdentityNumber)(value);
    }
    return (0, exports.formatPersonalIdentityNumber)(`${century}${base}`);
};
exports.completePersonalIdentityNumber = completePersonalIdentityNumber;
/**
 * Checks whether the input string is a valid-looking 12-character personal identity number.
 * Note that this function does not check whether the four last digits are a valid checksum.
 *
 * @example
 * ```ts
 * isValidPersonalIdentityNumber('8112289874') // => false
 * isValidPersonalIdentityNumber('811228-9874') // => false
 * isValidPersonalIdentityNumber('198112289874') // => true
 * isValidPersonalIdentityNumber('19811228-9874') // => true
 * isValidPersonalIdentityNumber(' 19811228-9874') // => false
 * ```
 *
 * @see https://en.wikipedia.org/wiki/Personal_identity_number_(Sweden)
 */
const isValidPersonalIdentityNumber = (value) => {
    return exports.PERSONAL_IDENTITY_NUMBER_REGEXP.test(value);
};
exports.isValidPersonalIdentityNumber = isValidPersonalIdentityNumber;
/**
 * Formats a Swedish personal identity number to a hyphen-delimited 12-character string.
 *
 * @example
 * ```ts
 * normalizePersonalIdentityNumber('8112289874') // => '8112289874'
 * normalizePersonalIdentityNumber('811228-9874') // => '8112289874'
 * normalizePersonalIdentityNumber('198112289874') // => '198112289874'
 * normalizePersonalIdentityNumber('19811228-9874') // => '198112289874'
 * normalizePersonalIdentityNumber('000 1981 12 28 !"#¤ 9874 ...') // => '198112289874'
 * ```
 *
 * @see https://en.wikipedia.org/wiki/Personal_identity_number_(Sweden)
 */
const normalizePersonalIdentityNumber = (value) => {
    return value.replace(/[^0-9]/g, '').slice(-12);
};
exports.normalizePersonalIdentityNumber = normalizePersonalIdentityNumber;
