"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.color = exports.mergeStyles = exports.zIndex = exports.variant = exports.typography = exports.space = exports.size = exports.position = exports.overflow = exports.opacity = exports.layout = exports.grid = exports.fontSize = exports.flexbox = exports.display = exports.boxShadow = exports.borderRadius = exports.border = exports.background = void 0;
var styled_system_1 = require("styled-system");
Object.defineProperty(exports, "background", { enumerable: true, get: function () { return styled_system_1.background; } });
Object.defineProperty(exports, "border", { enumerable: true, get: function () { return styled_system_1.border; } });
Object.defineProperty(exports, "borderRadius", { enumerable: true, get: function () { return styled_system_1.borderRadius; } });
Object.defineProperty(exports, "boxShadow", { enumerable: true, get: function () { return styled_system_1.boxShadow; } });
Object.defineProperty(exports, "display", { enumerable: true, get: function () { return styled_system_1.display; } });
Object.defineProperty(exports, "flexbox", { enumerable: true, get: function () { return styled_system_1.flexbox; } });
Object.defineProperty(exports, "fontSize", { enumerable: true, get: function () { return styled_system_1.fontSize; } });
Object.defineProperty(exports, "grid", { enumerable: true, get: function () { return styled_system_1.grid; } });
Object.defineProperty(exports, "layout", { enumerable: true, get: function () { return styled_system_1.layout; } });
Object.defineProperty(exports, "opacity", { enumerable: true, get: function () { return styled_system_1.opacity; } });
Object.defineProperty(exports, "overflow", { enumerable: true, get: function () { return styled_system_1.overflow; } });
Object.defineProperty(exports, "position", { enumerable: true, get: function () { return styled_system_1.position; } });
Object.defineProperty(exports, "size", { enumerable: true, get: function () { return styled_system_1.size; } });
Object.defineProperty(exports, "space", { enumerable: true, get: function () { return styled_system_1.space; } });
Object.defineProperty(exports, "typography", { enumerable: true, get: function () { return styled_system_1.typography; } });
Object.defineProperty(exports, "variant", { enumerable: true, get: function () { return styled_system_1.variant; } });
Object.defineProperty(exports, "zIndex", { enumerable: true, get: function () { return styled_system_1.zIndex; } });
var merge_styles_1 = require("./merge-styles");
Object.defineProperty(exports, "mergeStyles", { enumerable: true, get: function () { return merge_styles_1.mergeStyles; } });
const color = (props) => props.color ? { color: props.color } : {};
exports.color = color;
