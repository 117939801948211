"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTableProps = exports.TablePropsContext = void 0;
const react_1 = require("react");
exports.TablePropsContext = (0, react_1.createContext)(null);
const useTableProps = () => {
    const context = (0, react_1.useContext)(exports.TablePropsContext);
    if (!context) {
        throw new Error('useTableContext must be used within the TableContext.Provider');
    }
    return context;
};
exports.useTableProps = useTableProps;
