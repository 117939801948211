"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Locale = void 0;
var Locale;
(function (Locale) {
    /**
     * English
     */
    Locale["EN"] = "en";
    /**
     * Norwegian Bokmål
     */
    Locale["NB"] = "nb";
    /**
     * Swedish
     */
    Locale["SV"] = "sv";
    /**
     * Danish
     */
    Locale["DA"] = "da";
    /**
     * Finnish
     */
    Locale["FI"] = "fi";
})(Locale || (exports.Locale = Locale = {}));
