"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.valueOf = exports.keyOf = exports.entryOf = void 0;
const INTEGER_REGEXP = /^0|[1-9][0-9]*$/;
/**
 * @example
 * ```ts
 * enum UserStatus {
 *   ACTIVE = 'isActive',
 *   INACTIVE = 'isInactive',
 * }
 *
 * entryOf(UserStatus, 'ACTIVE')
 * // ['ACTIVE', 'isActive']
 *
 * entryOf(UserStatus, 'isInactive')
 * // ['INACTIVE', 'isInactive']
 * ```
 */
const entryOf = (object, keyOrValue) => {
    const entry = Object.entries(object)
        .filter(([k]) => !INTEGER_REGEXP.test(k))
        .find(([k, v]) => k === keyOrValue || v === keyOrValue);
    return entry;
};
exports.entryOf = entryOf;
/**
 * @example
 * ```ts
 * enum UserStatus {
 *   ACTIVE = 'isActive',
 *   INACTIVE = 'isInactive',
 * }
 *
 * keyOf(UserStatus, 'ACTIVE')
 * // 'ACTIVE'
 *
 * keyOf(UserStatus, 'isInactive')
 * // 'INACTIVE'
 * ```
 */
const keyOf = (object, keyOrValue) => {
    return (0, exports.entryOf)(object, keyOrValue)?.[0];
};
exports.keyOf = keyOf;
/**
 * @example
 * ```ts
 * enum UserStatus {
 *   ACTIVE = 'isActive',
 *   INACTIVE = 'isInactive',
 * }
 *
 * valueOf(UserStatus, 'ACTIVE')
 * // 'isActive'
 *
 * valueOf(UserStatus, 'isInactive')
 * // 'isInactive'
 * ```
 */
const valueOf = (object, keyOrValue) => {
    return (0, exports.entryOf)(object, keyOrValue)?.[1];
};
exports.valueOf = valueOf;
