"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateBroadcastMessageRecipientGroupFactory = void 0;
const locales_1 = require("../locales");
const translateBroadcastMessageRecipientGroupFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('broadcastMessageRecipientGroup', locale);
    return (broadcastMessageRecipientGroup) => {
        return t[broadcastMessageRecipientGroup];
    };
};
exports.translateBroadcastMessageRecipientGroupFactory = translateBroadcastMessageRecipientGroupFactory;
