"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftsToMaxWorkHours = exports.shiftsToMaxWorkHoursByWeek = exports.longestShiftLength = void 0;
const lodash_1 = require("lodash");
const date_1 = require("@bemlo/date");
const shift_to_hours_1 = require("./shift-to-hours");
const MAX_WORK_HOURS_PER_WEEK = 40;
const longestShiftLength = (shifts) => {
    return Math.max(...shifts.map(shift_to_hours_1.shiftToWorkHours));
};
exports.longestShiftLength = longestShiftLength;
const shiftsToMaxWorkHoursByWeek = (shifts) => {
    return (0, lodash_1.chain)(shifts)
        .groupBy('date')
        .map((shifts, date) => ({
        date,
        hours: (0, exports.longestShiftLength)(shifts),
    }))
        .groupBy(({ date }) => (0, date_1.dayjsEn)(date).format('YYYY-WW'))
        .map((shifts, week) => ({
        week,
        hours: Math.min((0, lodash_1.sumBy)(shifts, 'hours'), MAX_WORK_HOURS_PER_WEEK),
    }))
        .value();
};
exports.shiftsToMaxWorkHoursByWeek = shiftsToMaxWorkHoursByWeek;
const shiftsToMaxWorkHours = (shifts) => {
    return (0, lodash_1.sumBy)((0, exports.shiftsToMaxWorkHoursByWeek)(shifts), 'hours');
};
exports.shiftsToMaxWorkHours = shiftsToMaxWorkHours;
