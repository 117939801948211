"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useZod = void 0;
const zod_1 = require("zod");
const i18n_1 = require("@bemlo/i18n");
const translate_1 = require("../translate");
const zod_error_map_1 = require("../translate/mappers/zod-error-map");
const useZod = () => {
    const locale = (0, translate_1.useLocale)();
    const formTranslations = (0, i18n_1.getTranslation)('form', locale);
    zod_1.z.setErrorMap((0, zod_error_map_1.translateZodErrorMap)(formTranslations));
    return zod_1.z;
};
exports.useZod = useZod;
