"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Employment = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const enums_1 = require("@bemlo/enums");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const Detail_1 = require("../../components/Detail");
const useProfessionalView_1 = require("../../hooks/useProfessionalView");
const scopes_1 = require("../../scopes");
const Employment = () => {
    const { actor, data, isEditing, update } = (0, useProfessionalView_1.useProfessionalView)();
    const t = (0, translate_1.useTranslations)();
    const { employmentStatus, startDate } = data;
    const scope = scopes_1.scopes.biography.employmentStatus();
    if (!actor.can('read', scope)) {
        return null;
    }
    if (isEditing(scope)) {
        return ((0, jsx_runtime_1.jsx)(elements_1.Listbox, { disabled: !actor.can('update', scope), label: t.professional_view.biography.current_employment(), onChange: update('employmentStatus'), options: Object.entries(enums_1.CurrentEmploymentStatus).map(([key, value]) => ({
                key,
                label: t.$currentEmploymentStatus(value),
                value,
            })), value: employmentStatus }));
    }
    if (!startDate) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: t.professional_view.interests.open_to_work_from(t.$date.unix(startDate).format('ll')), heading: t.professional_view.biography.currently_employed(), icon: "OfficeBuildingIcon" }));
};
exports.Employment = Employment;
