"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Resource = void 0;
const react_1 = require("react");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_2 = require("react");
const schedule_1 = require("@bemlo/schedule");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const by_filter_1 = require("../by-filter");
const Cell_1 = require("../components/Cell");
const CellShiftsColumn_1 = require("../components/CellShiftsColumn");
const NewShift_1 = require("../components/NewShift");
const ResourceTitle_1 = require("../components/ResourceTitle");
const Row_1 = require("../components/Row");
const ShiftError_1 = require("../components/ShiftError");
const useSchedule_1 = require("../useSchedule");
const get_shifts_in_week_1 = require("../utils/get-shifts-in-week");
const use_schedule_errors_1 = require("../utils/use-schedule-errors");
// TODO: BEMLO-3419
const REST_ERRORS = {
    'no-day-rest': 'Ingen dygnsvila',
    'no-week-rest': 'Ingen veckovila',
    'no-compensating-rest': 'Otillräcklig kompensationsvila',
    'reduced-day-rest': 'Förkortad dygnsvila',
};
const Resource = (props) => {
    const t = (0, translate_1.useTranslations)();
    const { days, currentWeek, shifts: allShifts, availability: allAvailability, shiftPopover, workingTimeDirectiveRuleSet, isScrolled, } = (0, useSchedule_1.useSchedule)();
    const { candidateShifts, candidateAvailability, dayErrors, activeCandidateShifts, } = (0, react_2.useMemo)(() => {
        const candidateShifts = allShifts.filter((0, by_filter_1.byCellFilter)({
            scheduleResourceId: props.scheduleResourceId,
            bookedCandidateId: props.candidateId,
        }));
        const candidateAvailability = allAvailability.filter(({ candidateId }) => candidateId === props.candidateId);
        const activeCandidateShifts = candidateShifts.filter((shift) => shift.status !== 'CANCELLED');
        const dayErrors = props.resourceRestRules
            ? (0, schedule_1.getRestErrorsMap)(activeCandidateShifts, props.resourceRestRules)
            : {};
        return {
            candidateShifts,
            candidateAvailability,
            activeCandidateShifts,
            dayErrors,
        };
    }, [
        allShifts,
        props.candidateId,
        props.scheduleResourceId,
        props.resourceRestRules,
    ]);
    const shiftErrors = (0, use_schedule_errors_1.useScheduleErrors)(activeCandidateShifts, workingTimeDirectiveRuleSet);
    const shiftsInWeek = currentWeek
        ? (0, get_shifts_in_week_1.getShiftsInWeek)(currentWeek, candidateShifts)
        : [];
    if (!shiftsInWeek.length &&
        !candidateAvailability.length &&
        !props.permanent) {
        return null;
    }
    const bookedShifts = shiftsInWeek.filter(({ status, bookedCandidateId }) => bookedCandidateId === props.candidateId && status === 'BOOKED');
    const title = props.titleComponent ?? ((0, jsx_runtime_1.jsx)(ResourceTitle_1.ResourceTitle, { noName: props.noName, name: props.name, avatarUrl: props.avatarUrl, resourceUrl: props.resourceUrl, bookedShifts: bookedShifts }));
    const HoverCardContent = props.hoverCard;
    return ((0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [(0, jsx_runtime_1.jsx)(Cell_1.Cell, { isTitle: true, rowSpan: 1, rightShadow: isScrolled, children: HoverCardContent ? ((0, jsx_runtime_1.jsx)(elements_1.HoverCard, { side: "right", trigger: (0, jsx_runtime_1.jsx)(elements_1.HoverCard.Trigger, { children: title }), children: (0, jsx_runtime_1.jsx)(HoverCardContent, { candidateId: props.candidateId, shifts: candidateShifts, availability: candidateAvailability, ...(props.hoverCardProps || {}) }) })) : (title) }), days.map((day) => {
                const shiftsOnDay = candidateShifts.filter((shift) => shift.date === day.dateISO);
                const isOccupied = shiftsOnDay.some((shift) => ['BOOKED', 'SUGGESTED'].includes(shift.status) &&
                    shift.bookedCandidateId);
                const availabilityOnDay = candidateAvailability.filter(({ date }) => date === day.dateISO);
                const errorsOnDay = dayErrors[day.dateISO]?.map((error) => ({
                    error: `${REST_ERRORS[error.type]} (${t.$n_hours(error.restTime)})`,
                    isWarning: error.severity === 'warning',
                })) ?? [];
                return ((0, jsx_runtime_1.jsx)(Cell_1.AvailabilityCell, { disabled: !day.withinRange, weekend: day.isWeekend, availability: availabilityOnDay, isOccupied: isOccupied, children: (0, jsx_runtime_1.jsx)(CellShiftsColumn_1.CellShiftsColumn, { children: shiftsOnDay.map((shift) => {
                            const errorsOnShift = shiftErrors.filter((error) => error.shiftId === shift.id);
                            return ((0, react_1.createElement)(NewShift_1.NewShift, { ...props, key: shift.id, shift: shift, status: shift.status, position: "center", shiftIds: [shift.id], isAbsent: shift.isAbsent, isAcknowledged: shift.isAcknowledged, popoverContent: shiftPopover, unitName: props.showUnitName ? shift.unitName : undefined, error: (0, jsx_runtime_1.jsx)(ShiftError_1.ShiftError, { errors: [...errorsOnDay, ...errorsOnShift] }) }));
                        }) }) }, day.dateISO));
            })] }));
};
exports.Resource = Resource;
