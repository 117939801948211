"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.howMuchExperience = void 0;
const enums_1 = require("@bemlo/enums");
exports.howMuchExperience = {
    [enums_1.HowMuchExperienceAlternative.STUDENT]: 'Student',
    [enums_1.HowMuchExperienceAlternative.NEW_GRADUATE]: 'Nylig uteksaminert',
    [enums_1.HowMuchExperienceAlternative.LESS_THAN_ONE]: 'Mindre enn 1 år',
    [enums_1.HowMuchExperienceAlternative.ONE_TO_TWO]: '1-2 år',
    [enums_1.HowMuchExperienceAlternative.TWO]: '2 år',
    [enums_1.HowMuchExperienceAlternative.TWO_TO_THREE]: '2-3 år',
    [enums_1.HowMuchExperienceAlternative.THREE_TO_FIVE]: '3-5 år',
    [enums_1.HowMuchExperienceAlternative.FIVE_TO_TEN]: '5-10 år',
    [enums_1.HowMuchExperienceAlternative.MORE_THAN_TEN]: 'Mer enn 10 år',
};
