"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatView = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../constants");
const elements_1 = require("../../elements");
const ChatView = (props) => {
    return ((0, jsx_runtime_1.jsx)(elements_1.Flex, { styles: { backgroundColor: constants_1.Color.WHITE, height: '100%', width: '100%' }, children: props.children }));
};
exports.ChatView = ChatView;
