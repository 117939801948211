"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailabilityScheduleWeekOverviewRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const DayCell_1 = require("../atoms/DayCell");
const AvailabilityRow_1 = require("../AvailabilityRow");
const ShiftHoursCell_1 = require("../molecules/ShiftHoursCell");
const WeekDaysRow_1 = require("../molecules/WeekDaysRow");
const get_grid_template_columns_1 = require("../utils/get-grid-template-columns");
const AvailabilityScheduleWeekOverviewRow = ({ rowData, type, tenderedShiftHours, }) => {
    const t = (0, translate_1.useTranslations)();
    return ((0, jsx_runtime_1.jsxs)(AvailabilityRow_1.AvailabilityRowBase, { gridTemplateColumns: get_grid_template_columns_1.OVERVIEW_GRID_TEMPLATE_COLUMNS, styles: {
            backgroundColor: constants_1.Color.WHITE,
            borderBottom: constants_1.theme.border.style.thinLight,
        }, children: [(0, jsx_runtime_1.jsx)(elements_1.Div, {}), (0, jsx_runtime_1.jsxs)(elements_1.Flex, { styles: { gap: 1, alignItems: 'center' }, children: [(0, jsx_runtime_1.jsx)(elements_1.Avatar, { type: "initials", name: rowData.name, size: "sm", styles: {
                            border: `1.5px solid ${type === 'presentation' ? constants_1.Color.YELLOW : constants_1.Color.GREEN}`,
                        } }), (0, jsx_runtime_1.jsx)(elements_1.Link, { styles: {
                            color: constants_1.Color.BLUE,
                            fontWeight: constants_1.theme.font.weight.medium,
                            fontSize: constants_1.theme.font.size['6.5'],
                        }, to: rowData.linkTo, children: rowData.name })] }), type === 'booking' ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(elements_1.Div, {}), (0, jsx_runtime_1.jsx)(elements_1.Badge, { color: "green", squared: true, size: "sm", styles: { width: 'fit-content' }, children: t.common.percent_sign(rowData.scope.scope) })] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(elements_1.Badge, { color: "yellow", squared: true, size: "sm", styles: { width: 'fit-content' }, children: t.common.percent_sign(rowData.scope.scope) }), (0, jsx_runtime_1.jsx)(elements_1.Div, {})] })), (0, jsx_runtime_1.jsx)(ShiftHoursCell_1.ShiftHoursCell, { selectedShiftHours: rowData.scope.shiftHours, availableShiftHours: tenderedShiftHours, viewState: "view" }), (0, jsx_runtime_1.jsx)(WeekDaysRow_1.WeekDaysContainer, { children: rowData.days.map((day) => ((0, jsx_runtime_1.jsx)(DayCell_1.OverviewBodyCell, { status: day.status }, day.id))) })] }));
};
exports.AvailabilityScheduleWeekOverviewRow = AvailabilityScheduleWeekOverviewRow;
