"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.References = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const molecules_1 = require("../../../molecules");
const translate_1 = require("../../../translate");
const SectionHeading_1 = require("../components/SectionHeading");
const useProfessionalView_1 = require("../hooks/useProfessionalView");
const scopes_1 = require("../scopes");
const References = ({ onAddReferenceClick, onDeleteReferenceClick, onReferenceClick, }) => {
    const { actor, data, isMe } = (0, useProfessionalView_1.useProfessionalView)();
    const t = (0, translate_1.useTranslations)();
    const scope = scopes_1.scopes.references();
    if (!actor.can('read', scope)) {
        return null;
    }
    const { references } = data;
    return ((0, jsx_runtime_1.jsxs)(elements_1.Paper, { styles: { flex: 'col', gap: 1, p: 3.5 }, children: [(0, jsx_runtime_1.jsx)(SectionHeading_1.SectionHeading, { children: t.professional_view.references.references() }), (0, jsx_runtime_1.jsx)(elements_1.Flex, { col: true, gap: 2, children: references.length ? (references.map((reference) => ((0, jsx_runtime_1.jsx)(molecules_1.ReferenceCard, { button: isMe
                        ? {
                            design: 'error',
                            iconName: 'XIcon',
                            onClick: () => onDeleteReferenceClick(reference.id),
                        }
                        : undefined, hideResponse: isMe, onClick: onReferenceClick, reference: reference }, reference.id)))) : ((0, jsx_runtime_1.jsx)(elements_1.Div, { styles: { pb: 2, pt: 1 }, children: (0, jsx_runtime_1.jsx)(elements_1.Text, { color: constants_1.Color.DARK_GREY, size: 6, children: actor.can('update', scope)
                            ? t.professional_view.references.no_references_added()
                            : t.professional_view.references.no_references() }) })) }), (0, jsx_runtime_1.jsx)(elements_1.Flex, { items: "center", styles: { pt: 2 }, children: actor.can('update', scope) && ((0, jsx_runtime_1.jsx)(elements_1.Button, { design: "primary-inverted", onClick: onAddReferenceClick, size: "sm", children: t.professional_view.references.add_reference() })) })] }));
};
exports.References = References;
