"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.workHoursPerWeek = exports.averageWeeklyWorkHours = exports.workHoursInWeek = void 0;
const lodash_1 = require("lodash");
const date_1 = require("@bemlo/date");
const shift_to_hours_1 = require("../shift-to-hours");
// Return the number of hours in a given week from a array of shifts
const workHoursInWeek = (shifts, week) => {
    return (0, shift_to_hours_1.shiftsToWorkHours)(shifts.filter((shift) => (0, date_1.dayjsEn)(shift.date).isSame(week, 'week')));
};
exports.workHoursInWeek = workHoursInWeek;
// Return the average number of hours per week based on a array of shifts
const averageWeeklyWorkHours = (shifts) => {
    const weeks = (0, lodash_1.uniq)(shifts.map((shift) => (0, date_1.dayjsEn)(shift.date).isoWeek()));
    if (!weeks.length)
        return 0;
    return (0, shift_to_hours_1.shiftsToWorkHours)(shifts) / weeks.length;
};
exports.averageWeeklyWorkHours = averageWeeklyWorkHours;
// Return the number of hours per week based on a array of shifts
const workHoursPerWeek = (shifts) => {
    return (0, lodash_1.chain)(shifts.map((shift) => (0, date_1.dayjsEn)(shift.date)))
        .uniqBy((date) => date.format('YYYY-WW'))
        .sortBy((date) => date.format('YYYY-WW'))
        .map((week) => ({
        hours: (0, exports.workHoursInWeek)(shifts, week),
        week: week.isoWeek(),
    }))
        .value();
};
exports.workHoursPerWeek = workHoursPerWeek;
