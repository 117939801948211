"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPresentationDayStatusByPresentationState = void 0;
const utils_1 = require("./utils");
const constants_1 = require("../../../constants");
const getAcceptanceDayCellStatus_1 = require("../acceptance/getAcceptanceDayCellStatus");
const utils_2 = require("../tender/utils");
const constants_2 = require("../utils/constants");
const getPresentationDayStatusByPresentationState = (presentationStatue) => {
    return presentationStatue === 'acceptance'
        ? getAcceptanceDayCellStatus_1.getAcceptanceDayCellStatus
        : getPresentationDayCellStatus;
};
exports.getPresentationDayStatusByPresentationState = getPresentationDayStatusByPresentationState;
const getPresentationDayCellStatus = (day, weekDisabled, viewState) => {
    const isTendered = (0, utils_2.isDayTendered)(day);
    const isPresented = (0, utils_1.isDayPresented)(day);
    const isDaySelectable = isTendered && !weekDisabled;
    if (viewState === 'view') {
        if (isPresented) {
            return {
                backgroundColor: constants_1.Color.BLUE,
                color: constants_1.Color.WHITE,
                icon: {
                    name: 'CheckCircleIcon',
                    design: 'solid',
                },
                isDaySelectable,
            };
        }
        if (isTendered) {
            return {
                backgroundColor: constants_1.Color.WHITE,
                color: constants_1.Color.DARKER_GREY,
                isDaySelectable,
            };
        }
        return {
            backgroundColor: constants_1.Color.DARK_MEDIUM_GREY,
            color: constants_1.Color.DARKEST_MEDIUM_GREY,
            isDaySelectable,
        };
    }
    if (day.selected) {
        return {
            backgroundColor: constants_1.Color.BLUE,
            color: constants_1.Color.WHITE,
            icon: {
                name: 'CheckCircleIcon',
                design: 'solid',
            },
            isDaySelectable,
        };
    }
    if (isDaySelectable) {
        return {
            ...constants_2.VIEW_TENDERED_DAY_STYLE,
            isDaySelectable,
            backgroundColor: constants_1.Color.TRANSPARENT,
            icon: isDaySelectable
                ? {
                    name: 'Circle',
                    design: 'outline',
                }
                : undefined,
        };
    }
    return {
        backgroundColor: constants_1.Color.DARK_MEDIUM_GREY,
        color: constants_1.Color.DARKEST_MEDIUM_GREY,
        isDaySelectable,
    };
};
