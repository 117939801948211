"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriodCell = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const date_1 = require("@bemlo/date");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const getPeriodDisplayString = (startsAt, endsAt) => {
    const [rangeStart, rangeEnd] = [date_1.dayjsEn.unix(startsAt), date_1.dayjsEn.unix(endsAt)];
    if (rangeStart.isSame(rangeEnd, 'day')) {
        return rangeStart.formatSignificant({ showAtLeast: 'month' });
    }
    return rangeStart.formatRange(rangeEnd);
};
const PeriodCell = ({ startsAt, endsAt, selected }) => {
    const t = (0, translate_1.useTranslations)();
    const period = getPeriodDisplayString(startsAt, endsAt);
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { styles: {
                    color: selected ? constants_1.Color.BLUE : undefined,
                    fontWeight: constants_1.theme.font.weight.medium,
                }, children: t.week(t.$date.unix(startsAt).isoWeek()) }), (0, jsx_runtime_1.jsx)(elements_1.Text, { styles: {
                    color: selected ? constants_1.Color.DARK_GREY : undefined,
                    fontWeight: constants_1.theme.font.weight.book,
                    fontSize: constants_1.theme.font.size['6'],
                }, children: period })] }));
};
exports.PeriodCell = PeriodCell;
