"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatisticsSeriesKey = void 0;
var StatisticsSeriesKey;
(function (StatisticsSeriesKey) {
    StatisticsSeriesKey["APPLIED_HOURS"] = "APPLIED_HOURS";
    StatisticsSeriesKey["BOOKED_HOURS"] = "BOOKED_HOURS";
    StatisticsSeriesKey["COST"] = "COST";
    StatisticsSeriesKey["FILL_RATE"] = "FILL_RATE";
    StatisticsSeriesKey["REQUESTED_HOURS"] = "REQUESTED_HOURS";
    StatisticsSeriesKey["NUMBER_OF_TENDERS"] = "NUMBER_OF_TENDERS";
    StatisticsSeriesKey["APPROVED_HOURS"] = "APPROVED_HOURS";
    StatisticsSeriesKey["UTILIZATION_RATE"] = "UTILIZATION_RATE";
    StatisticsSeriesKey["SHIFTS_COUNT"] = "SHIFTS_COUNT";
    StatisticsSeriesKey["BOOKINGS_COUNT"] = "BOOKINGS_COUNT";
    StatisticsSeriesKey["APPLICATIONS_COUNT"] = "APPLICATIONS_COUNT";
})(StatisticsSeriesKey || (exports.StatisticsSeriesKey = StatisticsSeriesKey = {}));
