"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PresentationAvailabilityHeaderRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const AvailabilityRow_1 = require("../AvailabilityRow");
const ShiftHoursCell_1 = require("../molecules/ShiftHoursCell");
const WeekDaysRow_1 = require("../molecules/WeekDaysRow");
const utils_1 = require("../tender/utils");
const are_all_selected_1 = require("../utils/are-all-selected");
const get_grid_template_columns_1 = require("../utils/get-grid-template-columns");
const remove_availabilities_1 = require("../utils/remove-availabilities");
const PresentationAvailabilityHeaderRow = ({ availabilities, setAvailabilities, viewState, presentationState, }) => {
    const t = (0, translate_1.useTranslations)();
    const gridTemplateColumns = (0, get_grid_template_columns_1.getGridTemplateColumns)(presentationState, viewState);
    const weekSelectionStates = (0, are_all_selected_1.areAllAvailabilityWeeksSelected)(availabilities, utils_1.isAvailabilityWeekTendered);
    const selectWeekBulk = () => {
        if (!weekSelectionStates.areAllSelected) {
            return setAvailabilities?.((0, remove_availabilities_1.addAvailabilities)(availabilities, {
                getDefaultScope: (availability) => Math.min((0, utils_1.tenderedScope)(availability) ?? 0, 100),
                getDefaultShiftHours: utils_1.tenderedShiftHours,
                shouldAddAvailability: utils_1.isAvailabilityWeekTendered,
                shouldAddDay: utils_1.isDayTendered,
            }));
        }
        return setAvailabilities?.((0, remove_availabilities_1.removeAvailabilities)(availabilities));
    };
    const shiftHoursState = availabilities.map((availability) => ({
        selected: availability.selected,
        shiftHours: availability.newScope.shiftHours,
        availableShiftHours: (0, utils_1.tenderedShiftHours)(availability),
    }));
    const toggleShiftHours = ({ action, shiftHours, }) => {
        const newAvailabilities = availabilities.map((availability) => {
            if (!availability.selected ||
                !availability.tenderedAvailability?.scope.shiftHours.includes(shiftHours))
                return availability;
            if (action === 'remove' &&
                availability.newScope.shiftHours.includes(shiftHours)) {
                return {
                    ...availability,
                    newScope: {
                        ...availability.newScope,
                        shiftHours: availability.newScope.shiftHours.filter((sh) => sh !== shiftHours),
                    },
                };
            }
            if (action === 'add' &&
                !availability.newScope.shiftHours.includes(shiftHours)) {
                return {
                    ...availability,
                    newScope: {
                        ...availability.newScope,
                        shiftHours: [...availability.newScope.shiftHours, shiftHours],
                    },
                };
            }
            return availability;
        });
        setAvailabilities?.(newAvailabilities);
    };
    const dayStates = availabilities.map((availability) => ({
        selectable: (0, utils_1.isAvailabilityWeekTendered)(availability),
        days: availability.days.map((day) => ({
            selected: day.selected,
            selectable: (0, utils_1.isDayTendered)(day),
        })),
    }));
    const toggleDays = ({ dayIndex, newValue, }) => {
        const newAvailabilities = availabilities.map((availability) => {
            if (!(0, utils_1.isDayTendered)(availability.days[dayIndex]) ||
                availability.days[dayIndex].selected === newValue) {
                return availability;
            }
            const updatedDays = [...availability.days];
            updatedDays[dayIndex] = {
                ...updatedDays[dayIndex],
                selected: newValue,
            };
            const noDaysSelected = updatedDays.every((day) => !day.selected);
            if (noDaysSelected) {
                return (0, remove_availabilities_1.removeAvailability)(availability);
            }
            return {
                ...availability,
                selected: true,
                days: updatedDays,
            };
        });
        setAvailabilities?.(newAvailabilities);
    };
    return ((0, jsx_runtime_1.jsxs)(AvailabilityRow_1.GridRowHeaderStyles, { gridTemplateColumns: gridTemplateColumns, children: [viewState === 'edit' && ((0, jsx_runtime_1.jsx)(elements_1.Checkbox, { checked: weekSelectionStates.isSomeSelected, indeterminate: !weekSelectionStates.areAllSelected, onChange: selectWeekBulk })), (0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, children: t.common.period() }), (0, jsx_runtime_1.jsx)(elements_1.Div, {}), (0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, children: presentationState === 'acceptance'
                    ? t.common.accepted_scope()
                    : t.common.presented_scope() }), presentationState === 'presentation' && ((0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, children: t.common.remaining_scope() })), (0, jsx_runtime_1.jsx)(ShiftHoursCell_1.ShiftHoursHeader, { viewState: viewState, availabilities: shiftHoursState, updateShiftHours: toggleShiftHours }), (0, jsx_runtime_1.jsx)(WeekDaysRow_1.WeekDaysRowHeader, { viewState: viewState, availabilities: dayStates, toggleDays: toggleDays })] }));
};
exports.PresentationAvailabilityHeaderRow = PresentationAvailabilityHeaderRow;
