"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toArray = void 0;
/**
 * Converts a value to an array, if it's not already an array.
 *
 * @example
 * ```ts
 * toArray('foo') // => ['foo']
 * toArray(['foo', 'bar']) // => ['foo', 'bar']
 * ```
 */
const toArray = (value) => {
    return Array.isArray(value) ? value : [value];
};
exports.toArray = toArray;
