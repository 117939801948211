"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryDetail = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const utils_1 = require("@bemlo/utils");
const Flex_1 = require("./Flex");
const Icon_1 = require("./Icon");
const Text_1 = require("./Text");
const color_1 = require("../constants/color");
const SummaryDetail = (props) => {
    const subtitles = props.subtitle ? (0, utils_1.toArray)(props.subtitle) : undefined;
    return ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { gap: "20px", items: "start", styles: props.styles, children: [(0, jsx_runtime_1.jsx)(Icon_1.Icon, { color: color_1.Color.BLUE, name: props.icon, size: props.size }), (0, jsx_runtime_1.jsxs)(Flex_1.Flex, { col: true, gap: 1, children: [(0, jsx_runtime_1.jsx)(Text_1.Text, { bold: true, size: props.size === 'sm' ? 6 : 4, children: props.title }), subtitles?.map((subtitle, index) => ((0, jsx_runtime_1.jsx)(Text_1.Text, { color: color_1.Color.DARKER_GREY, size: 6, children: subtitle }, index))), props.children] })] }));
};
exports.SummaryDetail = SummaryDetail;
