import { initializeApp } from 'firebase/app'
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth'
import {
  getStorage,
  connectStorageEmulator,
  FirebaseStorage,
} from 'firebase/storage'
import { clientConfig } from '../../config/clientConfig'

type Instance = {
  getAuth: () => Auth
  getStorage: () => FirebaseStorage
}

let cache: Instance | undefined = undefined

export const useFirebase = (): Instance => {
  if (typeof window === 'undefined') {
    return {
      getAuth: () => {
        throw new Error('Firebase is not initialized')
      },
      getStorage: () => {
        throw new Error('Firebase is not initialized')
      },
    }
  }

  if (!cache) {
    // Initialize Firebase
    const firebaseApp = initializeApp(clientConfig.FIREBASE_CONFIG)

    if (firebaseApp && clientConfig.CONNECT_FIREBASE_EMULATOR) {
      const auth = getAuth(firebaseApp)

      const url = new URL(location.href)

      connectAuthEmulator(auth, `http://${url.hostname}:9099`, {
        disableWarnings: true,
      })

      const storage = getStorage(firebaseApp)
      connectStorageEmulator(storage, url.hostname, 9199)
    }

    const auth = getAuth(firebaseApp)
    const storage = getStorage(firebaseApp)

    cache = {
      getAuth: () => auth,
      getStorage: () => storage,
    }
  }

  return cache
}
