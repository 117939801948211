"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor = exports.LazyEditor = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const constants_1 = require("../../constants");
const Flex_1 = require("../Flex");
const Loader_1 = require("../Loader");
/*
  This is a heavy component, and it don't have to be loaded instantly, so makes sense to make it
  lazily loaded.
*/
exports.LazyEditor = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('./Editor'))));
const BlockLoader = () => {
    return ((0, jsx_runtime_1.jsx)(Flex_1.Flex, { styles: {
            alignItems: 'center',
            border: constants_1.theme.border.style.thin,
            borderRadius: constants_1.theme.border.radius.normal,
            justifyContent: 'center',
            minHeight: 343, // Same height as it will be when rendered
        }, children: (0, jsx_runtime_1.jsx)(Loader_1.Loader, { color: constants_1.Color.DARK_MEDIUM_GREY }) }));
};
const Editor = (props) => {
    return ((0, jsx_runtime_1.jsx)(react_1.Suspense, { fallback: (0, jsx_runtime_1.jsx)(BlockLoader, {}), children: (0, jsx_runtime_1.jsx)(exports.LazyEditor, { ...props }) }));
};
exports.Editor = Editor;
