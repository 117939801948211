"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNITED_KINGDOM_REGION_MUNICIPALITY_HIERARCHY = exports.SWEDEN_REGION_MUNICIPALITY_HIERARCHY = exports.NORWAY_REGION_MUNICIPALITY_HIERARCHY = void 0;
exports.NORWAY_REGION_MUNICIPALITY_HIERARCHY = {
    AGDER: {
        municipalities: {
            RISOR: 'Risør',
            GRIMSTAD: 'Grimstad',
            ARENDAL: 'Arendal',
            KRISTIANSAND: 'Kristiansand',
            LINDESNES: 'Lindesnes',
            FARSUND: 'Farsund',
            FLEKKEFJORD: 'Flekkefjord',
            GJERSTAD: 'Gjerstad',
            VEGARSHEI: 'Vegårshei',
            TVEDESTRAND: 'Tvedestrand',
            FROLAND: 'Froland',
            LILLESAND: 'Lillesand',
            BIRKENES: 'Birkenes',
            AMLI: 'Åmli',
            IVELAND: 'Iveland',
            EVJE_OG_HORNNES: 'Evje og Hornnes',
            BYGLAND: 'Bygland',
            VALLE: 'Valle',
            BYKLE: 'Bykle',
            VENNESLA: 'Vennesla',
            ASERAL: 'Åseral',
            LYNGDAL: 'Lyngdal',
            HAEGEBOSTAD: 'Hægebostad',
            KVINESDAL: 'Kvinesdal',
            SIRDAL: 'Sirdal',
        },
        value: 'AGDER',
    },
    INNLANDET: {
        municipalities: {
            KONGSVINGER: 'Kongsvinger',
            HAMAR: 'Hamar',
            LILLEHAMMER: 'Lillehammer',
            GJOVIK: 'Gjøvik',
            RINGSAKER: 'Ringsaker',
            LOTEN: 'Løten',
            STANGE: 'Stange',
            NORD_ODAL: 'Nord-Odal',
            SOR_ODAL: 'Sør-Odal',
            EIDSKOG: 'Eidskog',
            GRUE: 'Grue',
            ASNES: 'Åsnes',
            VALER_INNLANDET: 'Våler - Innlandet', // Våler also exists in VIKEN
            ELVERUM: 'Elverum',
            TRYSIL: 'Trysil',
            AMOT: 'Åmot',
            STOR_ELVDAL: 'Stor-Elvdal',
            RENDALEN: 'Rendalen',
            ENGERDAL: 'Engerdal',
            TOLGA: 'Tolga',
            TYNSET: 'Tynset',
            ALVDAL: 'Alvdal',
            FOLLDAL: 'Folldal',
            OS: 'Os',
            DOVRE: 'Dovre',
            LESJA: 'Lesja',
            SKJAK: 'Skjåk',
            LOM: 'Lom',
            VAGA: 'Vågå',
            NORD_FRON: 'Nord-Fron',
            SEL: 'Sel',
            SOR_FRON: 'Sør-Fron',
            RINGEBU: 'Ringebu',
            OYER: 'Øyer',
            GAUSDAL: 'Gausdal',
            OSTRE_TOTEN: 'Østre Toten',
            VESTRE_TOTEN: 'Vestre Toten',
            GRAN: 'Gran',
            SONDRE_LAND: 'Søndre Land',
            NORDRE_LAND: 'Nordre Land',
            SOR_AURDAL: 'Sør-Aurdal',
            ETNEDAL: 'Etnedal',
            NORD_AURDAL: 'Nord-Aurdal',
            VESTRE_SLIDRE: 'Vestre Slidre',
            OYSTRE_SLIDRE: 'Øystre Slidre',
            VANG: 'Vang',
        },
        value: 'INNLANDET',
    },
    MORE_OG_ROMSDAL: {
        municipalities: {
            KRISTIANSUND: 'Kristiansund',
            MOLDE: 'Molde',
            ALESUND: 'Ålesund',
            VANYLVEN: 'Vanylven',
            SANDE: 'Sande',
            HEROY_MORE_OG_ROMSDAL: 'Herøy - Møre og Romsdal', // Herøy also exists in NORDLAND
            ULSTEIN: 'Ulstein',
            HAREID: 'Hareid',
            ORSTA: 'Ørsta',
            STRANDA: 'Stranda',
            SYKKYLVEN: 'Sykkylven',
            SULA: 'Sula',
            GISKE: 'Giske',
            VESTNES: 'Vestnes',
            RAUMA: 'Rauma',
            AUKRA: 'Aukra',
            AVEROY: 'Averøy',
            GJEMNES: 'Gjemnes',
            TINGVOLL: 'Tingvoll',
            SUNNDAL: 'Sunndal',
            SURNADAL: 'Surnadal',
            SMOLA: 'Smøla',
            AURE: 'Aure',
            VOLDA: 'Volda',
            FJORD: 'Fjord',
            HUSTADVIKA: 'Hustadvika',
        },
        value: 'MORE_OG_ROMSDAL',
    },
    NORDLAND: {
        municipalities: {
            BODO: 'Bodø',
            NARVIK: 'Narvik',
            BINDAL: 'Bindal',
            SOMNA: 'Sømna',
            BRONNOY: 'Brønnøy',
            VEGA: 'Vega',
            VEVELSTAD: 'Vevelstad',
            HEROY_NORDLAND: 'Herøy - Nordland', // Herøy also exists in MORE_OG_ROMSDAL
            ALSTAHAUG: 'Alstahaug',
            LEIRFJORD: 'Leirfjord',
            VEFSN: 'Vefsn',
            GRANE: 'Grane',
            AARBORTE_HATTFJELLDAL: 'Aarborte - Hattfjelldal',
            DONNA: 'Dønna',
            NESNA: 'Nesna',
            HEMNES: 'Hemnes',
            RANA: 'Rana',
            LUROY: 'Lurøy',
            TRAENA: 'Træna',
            RODOY: 'Rødøy',
            MELOY: 'Meløy',
            GILDESKAL: 'Gildeskål',
            BEIARN: 'Beiarn',
            SALTDAL: 'Saltdal',
            FAUSKE_FUOSSKO: 'Fauske - Fuossko',
            SORFOLD: 'Sørfold',
            STEIGEN: 'Steigen',
            LODINGEN: 'Lødingen',
            EVENES_EVENASSI: 'Evenes - Evenášši',
            ROST: 'Røst',
            VAEROY: 'Værøy',
            FLAKSTAD: 'Flakstad',
            VESTVAGOY: 'Vestvågøy',
            VAGAN: 'Vågan',
            HADSEL: 'Hadsel',
            BO: 'Bø',
            OKSNES: 'Øksnes',
            SORTLAND_SUORTA: 'Sortland - Suortá',
            ANDOY: 'Andøy',
            MOSKENES: 'Moskenes',
            HABMER_HAMAROY: 'Hábmer - Hamarøy',
        },
        value: 'NORDLAND',
    },
    OSLO: {
        value: 'OSLO',
        municipalities: {
            OSLO: 'Oslo',
        },
    },
    ROGALAND: {
        value: 'ROGALAND',
        municipalities: {
            EIGERSUND: 'Eigersund',
            STAVANGER: 'Stavanger',
            HAUGESUND: 'Haugesund',
            SANDNES: 'Sandnes',
            SOKNDAL: 'Sokndal',
            LUND_ROGALAND: 'Lund - Rogaland', // Lund also exists in Sweden
            BJERKREIM: 'Bjerkreim',
            HA: 'Hå',
            KLEPP: 'Klepp',
            TIME: 'Time',
            GJESDAL: 'Gjesdal',
            SOLA: 'Sola',
            RANDABERG: 'Randaberg',
            STRAND: 'Strand',
            HJELMELAND: 'Hjelmeland',
            SULDAL: 'Suldal',
            SAUDA: 'Sauda',
            KVITSOY: 'Kvitsøy',
            BOKN: 'Bokn',
            TYSVAER: 'Tysvær',
            KARMOY: 'Karmøy',
            UTSIRA: 'Utsira',
            VINDAFJORD: 'Vindafjord',
        },
    },
    TROMS_OG_FINNMARK: {
        municipalities: {
            TROMSO: 'Tromsø',
            HARSTAD: 'Harstad',
            ALTA: 'Alta',
            VARDO: 'Vardø',
            VADSO: 'Vadsø',
            HAMMERFEST: 'Hammerfest',
            KVAEFJORD: 'Kvæfjord',
            TJELDSUND: 'Tjeldsund',
            IBESTAD: 'Ibestad',
            GRATANGEN: 'Gratangen',
            LAVANGEN: 'Lavangen',
            BARDU: 'Bardu',
            SALANGEN: 'Salangen',
            MALSELV: 'Målselv',
            SORREISA: 'Sørreisa',
            DYROY: 'Dyrøy',
            SENJA: 'Senja',
            BALSFJORD: 'Balsfjord',
            KARLSOY: 'Karlsøy',
            LYNGEN: 'Lyngen',
            STORFJORD: 'Storfjord',
            KAFJORD: 'Kåfjord',
            SKJERVOY: 'Skjervøy',
            NORDREISA: 'Nordreisa',
            KVAENANGEN: 'Kvænangen',
            KAUTOKEINO: 'Kautokeino',
            LOPPA: 'Loppa',
            HASVIK: 'Hasvik',
            MASOY: 'Måsøy',
            NORDKAPP: 'Nordkapp',
            PORSANGER: 'Porsanger',
            KARASJOK: 'Karasjok',
            LEBESBY: 'Lebesby',
            GAMVIK: 'Gamvik',
            BERLEVAG: 'Berlevåg',
            TANA: 'Tana',
            NESSEBY: 'Nesseby',
            BATSFJORD: 'Båtsfjord',
            SOR_VARANGER: 'Sør-Varanger',
        },
        value: 'TROMS_OG_FINNMARK',
    },
    TRONDELAG: {
        municipalities: {
            TRONDHEIM: 'Trondheim',
            STEINKJER: 'Steinkjer',
            NAMSOS: 'Namsos',
            FROYA: 'Frøya',
            OSEN: 'Osen',
            OPPDAL: 'Oppdal',
            RENNEBU: 'Rennebu',
            ROROS: 'Røros',
            HOLTALEN: 'Holtålen',
            MIDTRE_GAULDAL: 'Midtre Gauldal',
            MELHUS: 'Melhus',
            SKAUN: 'Skaun',
            MALVIK: 'Malvik',
            SELBU: 'Selbu',
            TYDAL: 'Tydal',
            MERAKER: 'Meråker',
            STJORDAL: 'Stjørdal',
            FROSTA: 'Frosta',
            LEVANGER: 'Levanger',
            VERDAL: 'Verdal',
            SNASA: 'Snåsa',
            LIERNE: 'Lierne',
            ROYRVIK: 'Røyrvik',
            NAMSSKOGAN: 'Namsskogan',
            GRONG: 'Grong',
            HOYLANDET: 'Høylandet',
            OVERHALLA: 'Overhalla',
            FLATANGER: 'Flatanger',
            LEKA: 'Leka',
            INDEROY: 'Inderøy',
            INDRE_FOSEN: 'Indre Fosen',
            HEIM: 'Heim',
            HITRA: 'Hitra',
            ORLAND: 'Ørland',
            AFJORD: 'Åfjord',
            ORKLAND: 'Orkland',
            NAEROYSUND: 'Nærøysund',
            RINDAL: 'Rindal',
        },
        value: 'TRONDELAG',
    },
    VESTFOLD_OG_TELEMARK: {
        municipalities: {
            HORTEN: 'Horten',
            HOLMESTRAND: 'Holmestrand',
            TONSBERG: 'Tønsberg',
            SANDEFJORD: 'Sandefjord',
            LARVIK: 'Larvik',
            PORSGRUNN: 'Porsgrunn',
            SKIEN: 'Skien',
            NOTODDEN: 'Notodden',
            FAERDER: 'Færder',
            SILJAN: 'Siljan',
            BAMBLE: 'Bamble',
            KRAGERO: 'Kragerø',
            DRANGEDAL: 'Drangedal',
            NOME: 'Nome',
            MIDT_TELEMARK: 'Midt-Telemark',
            TINN: 'Tinn',
            HJARTDAL: 'Hjartdal',
            SELJORD: 'Seljord',
            KVITESEID: 'Kviteseid',
            NISSEDAL: 'Nissedal',
            FYRESDAL: 'Fyresdal',
            TOKKE: 'Tokke',
            VINJE: 'Vinje',
        },
        value: 'VESTFOLD_OG_TELEMARK',
    },
    VESTLAND: {
        municipalities: {
            BERGEN: 'Bergen',
            KINN: 'Kinn',
            ETNE: 'Etne',
            SVEIO: 'Sveio',
            BOMLO: 'Bømlo',
            STORD: 'Stord',
            FITJAR: 'Fitjar',
            TYSNES: 'Tysnes',
            KVINNHERAD: 'Kvinnherad',
            ULLENSVANG: 'Ullensvang',
            EIDFJORD: 'Eidfjord',
            ULVIK: 'Ulvik',
            VOSS: 'Voss',
            KVAM: 'Kvam',
            SAMNANGER: 'Samnanger',
            BJORNAFJORDEN: 'Bjørnafjorden',
            AUSTEVOLL: 'Austevoll',
            OYGARDEN: 'Øygarden',
            ASKOY: 'Askøy',
            VAKSDAL: 'Vaksdal',
            MODALEN: 'Modalen',
            OSTEROY: 'Osterøy',
            ALVER: 'Alver',
            AUSTRHEIM: 'Austrheim',
            FEDJE: 'Fedje',
            MASFJORDEN: 'Masfjorden',
            GULEN: 'Gulen',
            SOLUND: 'Solund',
            HYLLESTAD: 'Hyllestad',
            HOYANGER: 'Høyanger',
            VIK: 'Vik',
            SOGNDAL: 'Sogndal',
            AURLAND: 'Aurland',
            LAERDAL: 'Lærdal',
            ARDAL: 'Årdal',
            LUSTER: 'Luster',
            ASKVOLL: 'Askvoll',
            FJALER: 'Fjaler',
            SUNNFJORD: 'Sunnfjord',
            BREMANGER: 'Bremanger',
            STAD: 'Stad',
            GLOPPEN: 'Gloppen',
            STRYN: 'Stryn',
        },
        value: 'VESTLAND',
    },
    VIKEN: {
        municipalities: {
            HALDEN: 'Halden',
            MOSS: 'Moss',
            SARPSBORG: 'Sarpsborg',
            FREDRIKSTAD: 'Fredrikstad',
            DRAMMEN: 'Drammen',
            KONGSBERG: 'Kongsberg',
            RINGERIKE: 'Ringerike',
            HVALER: 'Hvaler',
            AREMARK: 'Aremark',
            MARKER: 'Marker',
            INDRE_OSTFOLD: 'Indre Østfold',
            SKIPTVET: 'Skiptvet',
            RAKKESTAD: 'Rakkestad',
            RADE: 'Råde',
            VALER_VIKEN: 'Våler - Viken', // Våler also exists in INNLANDET
            VESTBY: 'Vestby',
            NORDRE_FOLLO: 'Nordre Follo',
            AS: 'Ås',
            FROGN: 'Frogn',
            NESODDEN: 'Nesodden',
            BAERUM: 'Bærum',
            ASKER: 'Asker',
            AURSKOG_HOLAND: 'Aurskog-Høland',
            RAELINGEN: 'Rælingen',
            ENEBAKK: 'Enebakk',
            LORENSKOG: 'Lørenskog',
            LILLESTROM: 'Lillestrøm',
            NITTEDAL: 'Nittedal',
            GJERDRUM: 'Gjerdrum',
            ULLENSAKER: 'Ullensaker',
            NES: 'Nes',
            EIDSVOLL: 'Eidsvoll',
            NANNESTAD: 'Nannestad',
            HURDAL: 'Hurdal',
            HOLE: 'Hole',
            FLA: 'Flå',
            NESBYEN: 'Nesbyen',
            GOL: 'Gol',
            HEMSEDAL: 'Hemsedal',
            AL: 'Ål',
            HOL: 'Hol',
            SIGDAL: 'Sigdal',
            KRODSHERAD: 'Krødsherad',
            MODUM: 'Modum',
            OVRE_EIKER: 'Øvre Eiker',
            LIER: 'Lier',
            FLESBERG: 'Flesberg',
            ROLLAG: 'Rollag',
            NORE_OG_UVDAL: 'Nore og Uvdal',
            JEVNAKER: 'Jevnaker',
            LUNNER: 'Lunner',
        },
        value: 'VIKEN',
    },
};
exports.SWEDEN_REGION_MUNICIPALITY_HIERARCHY = {
    BLEKINGE: {
        municipalities: {
            KARLSHAMN: 'Karlshamn',
            KARLSKRONA: 'Karlskrona',
            OLOFSTROM: 'Olofström',
            RONNEBY: 'Ronneby',
            SOLVESBORG: 'Sölvesborg',
        },
        value: 'regionBlekinge',
    },
    DALARNA: {
        municipalities: {
            ALVDALEN: 'Älvdalen',
            AVESTA: 'Avesta',
            BORLANGE: 'Borlänge',
            FALUN: 'Falun',
            GAGNEF: 'Gagnef',
            HEDEMORA: 'Hedemora',
            LEKSAND: 'Leksand',
            LUDVIKA: 'Ludvika',
            MALUNG_SALEN: 'Malung-Sälen',
            MORA: 'Mora',
            ORSA: 'Orsa',
            RATTVIK: 'Rättvik',
            SATER: 'Säter',
            SMEDJEBACKEN: 'Smedjebacken',
            VANSBRO: 'Vansbro',
        },
        value: 'regionDalarna',
    },
    GAVLEBORG: {
        municipalities: {
            BOLLNAS: 'Bollnäs',
            GAVLE: 'Gävle',
            HOFORS: 'Hofors',
            HUDIKSVALL: 'Hudiksvall',
            LJUSDAL: 'Ljusdal',
            NORDANSTIG: 'Nordanstig',
            OCKELBO: 'Ockelbo',
            OVANAKER: 'Ovanåker',
            SANDVIKEN: 'Sandviken',
            SODERHAMN: 'Söderhamn',
        },
        value: 'regionGavleborg',
    },
    GOTLAND: {
        municipalities: {
            GOTLAND: 'Gotland',
        },
        value: 'regionGotland',
    },
    HALLAND: {
        municipalities: {
            FALKENBERG: 'Falkenberg',
            HALMSTAD: 'Halmstad',
            HYLTE: 'Hylte',
            KUNGSBACKA: 'Kungsbacka',
            LAHOLM: 'Laholm',
            VARBERG: 'Varberg',
        },
        value: 'regionHalland',
    },
    JAMTLAND_HARJEDALEN: {
        municipalities: {
            ARE: 'Åre',
            BERG: 'Berg',
            BRACKE: 'Bräcke',
            HARJEDALEN: 'Härjedalen',
            KROKOM: 'Krokom',
            OSTERSUND: 'Östersund',
            RAGUNDA: 'Ragunda',
            STROMSUND: 'Strömsund',
        },
        value: 'regionJamtlandharjedalen',
    },
    JONKOPING: {
        municipalities: {
            ANEBY: 'Aneby',
            EKSJO: 'Eksjö',
            GISLAVED: 'Gislaved',
            GNOSJO: 'Gnosjö',
            HAABO: 'Habo',
            JONKOPING: 'Jönköping',
            MULLSJO: 'Mullsjö',
            NASSJO: 'Nässjö',
            SAVSJO: 'Sävsjö',
            TRANAS: 'Tranås',
            VAGGERYD: 'Vaggeryd',
            VARNAMO: 'Värnamo',
            VETLANDA: 'Vetlanda',
        },
        value: 'regionJonkopings',
    },
    KALMAR: {
        municipalities: {
            BORGHOLM: 'Borgholm',
            EMMABODA: 'Emmaboda',
            HOGSBY: 'Högsby',
            HULTSFRED: 'Hultsfred',
            KALMAR: 'Kalmar',
            MONSTERAS: 'Mönsterås',
            MORBYLANGA: 'Mörbylånga',
            NYBRO: 'Nybro',
            OSKARSHAMN: 'Oskarshamn',
            TORSAS: 'Torsås',
            VASTERVIK: 'Västervik',
            VIMMERBY: 'Vimmerby',
        },
        value: 'regionKalmar',
    },
    KRONOBERG: {
        municipalities: {
            ALMHULT: 'Älmhult',
            ALVESTA: 'Alvesta',
            LESSEBO: 'Lessebo',
            LJUNGBY: 'Ljungby',
            MARKARYD: 'Markaryd',
            TINGSRYD: 'Tingsryd',
            UPPVIDINGE: 'Uppvidinge',
            VAXJO: 'Växjö',
        },
        value: 'regionKronoberg',
    },
    NORRBOTTEN: {
        municipalities: {
            ALVSBYN: 'Älvsbyn',
            ARJEPLOG: 'Arjeplog',
            ARVIDSJAUR: 'Arvidsjaur',
            BODEN: 'Boden',
            GALLIVARE: 'Gällivare',
            HAPARANDA: 'Haparanda',
            JOKKMOKK: 'Jokkmokk',
            KALIX: 'Kalix',
            KIRUNA: 'Kiruna',
            LULEA: 'Luleå',
            OVERKALIX: 'Överkalix',
            OVERTORNEA: 'Övertorneå',
            PAJALA: 'Pajala',
            PITEA: 'Piteå',
        },
        value: 'regionNorrbotten',
    },
    OREBRO: {
        municipalities: {
            ASKERSUND: 'Askersund',
            DEGERFORS: 'Degerfors',
            HALLEFORS: 'Hällefors',
            HALLSBERG: 'Hallsberg',
            KARLSKOGA: 'Karlskoga',
            KUMLA: 'Kumla',
            LAXA: 'Laxå',
            LEKEBERG: 'Lekeberg',
            LINDESBERG: 'Lindesberg',
            LJUSNARSBERG: 'Ljusnarsberg',
            NORA: 'Nora',
            OREBRO: 'Örebro',
        },
        value: 'regionOrebro',
    },
    OSTERGOTLAND: {
        municipalities: {
            ATVIDABERG: 'Åtvidaberg',
            BOXHOLM: 'Boxholm',
            FINSPANG: 'Finspång',
            KINDA: 'Kinda',
            LINKOPING: 'Linköping',
            MJOLBY: 'Mjölby',
            MOTALA: 'Motala',
            NORRKOPING: 'Norrköping',
            ODESHOG: 'Ödeshög',
            SODERKOPING: 'Söderköping',
            VADSTENA: 'Vadstena',
            VALDEMARSVIK: 'Valdemarsvik',
            YDRE: 'Ydre',
        },
        value: 'regionOstergotland',
    },
    SKANE: {
        municipalities: {
            ANGELHOLM: 'Ängelholm',
            ASTORP: 'Åstorp',
            BASTAD: 'Båstad',
            BJUV: 'Bjuv',
            BROMOLLA: 'Bromölla',
            BURLOV: 'Burlöv',
            ESLOV: 'Eslöv',
            HASSLEHOLM: 'Hässleholm',
            HELSINGBORG: 'Helsingborg',
            HOGANAS: 'Höganäs',
            HOOR: 'Höör',
            HORBY: 'Hörby',
            KAVLINGE: 'Kävlinge',
            KLIPPAN: 'Klippan',
            KRISTIANSTAD: 'Kristianstad',
            LANDSKRONA: 'Landskrona',
            LOMMA: 'Lomma',
            LUND: 'Lund',
            MALMO: 'Malmö',
            ORKELLJUNGA: 'Örkelljunga',
            OSBY: 'Osby',
            OSTRA_GOINGE: 'Östra Göinge',
            PERSTORP: 'Perstorp',
            SIMRISHAMN: 'Simrishamn',
            SJOBO: 'Sjöbo',
            SKURUP: 'Skurup',
            STAFFANSTORP: 'Staffanstorp',
            SVALOV: 'Svalöv',
            SVEDALA: 'Svedala',
            TOMELILLA: 'Tomelilla',
            TRELLEBORG: 'Trelleborg',
            VELLINGE: 'Vellinge',
            YSTAD: 'Ystad',
        },
        value: 'regionSkane',
    },
    SORMLAND: {
        municipalities: {
            ESKILSTUNA: 'Eskilstuna',
            FLEN: 'Flen',
            GNESTA: 'Gnesta',
            KATRINEHOLM: 'Katrineholm',
            NYKOPING: 'Nyköping',
            OXELOSUND: 'Oxelösund',
            STRANGNAS: 'Strängnäs',
            TROSA: 'Trosa',
            VINGAKER: 'Vingåker',
        },
        value: 'regionSormland',
    },
    STOCKHOLM: {
        municipalities: {
            BOTKYRKA: 'Botkyrka',
            DANDERYD: 'Danderyd',
            EKERO: 'Ekerö',
            HANINGE: 'Haninge',
            HUDDINGE: 'Huddinge',
            JARFALLA: 'Järfälla',
            LIDINGO: 'Lidingö',
            NACKA: 'Nacka',
            NORRTALJE: 'Norrtälje',
            NYKVARN: 'Nykvarn',
            NYNASHAMN: 'Nynäshamn',
            OSTERAKER: 'Österåker',
            SALEM: 'Salem',
            SIGTUNA: 'Sigtuna',
            SODERTALJE: 'Södertälje',
            SOLLENTUNA: 'Sollentuna',
            SOLNA: 'Solna',
            STOCKHOLM: 'Stockholm',
            SUNDBYBERG: 'Sundbyberg',
            TABY: 'Täby',
            TYRESO: 'Tyresö',
            UPPLANDS_BRO: 'Upplands-Bro',
            UPPLANDS_VASBY: 'Upplands Väsby',
            VALLENTUNA: 'Vallentuna',
            VARMDO: 'Värmdö',
            VAXHOLM: 'Vaxholm',
        },
        value: 'regionStockholm',
    },
    UPPSALA: {
        municipalities: {
            ALVKARLEBY: 'Älvkarleby',
            ENKOPING: 'Enköping',
            HABO: 'Håbo',
            HEBY: 'Heby',
            KNIVSTA: 'Knivsta',
            OSTHAMMAR: 'Östhammar',
            TIERP: 'Tierp',
            UPPSALA: 'Uppsala',
        },
        value: 'regionUppsala',
    },
    VARMLAND: {
        municipalities: {
            ARJANG: 'Årjäng',
            ARVIKA: 'Arvika',
            EDA: 'Eda',
            FILIPSTAD: 'Filipstad',
            FORSHAGA: 'Forshaga',
            GRUMS: 'Grums',
            HAGFORS: 'Hagfors',
            HAMMARO: 'Hammarö',
            KARLSTAD: 'Karlstad',
            KIL: 'Kil',
            KRISTINEHAMN: 'Kristinehamn',
            MUNKFORS: 'Munkfors',
            SAFFLE: 'Säffle',
            STORFORS: 'Storfors',
            SUNNE: 'Sunne',
            TORSBY: 'Torsby',
        },
        value: 'regionVarmland',
    },
    VASTERBOTTEN: {
        municipalities: {
            ASELE: 'Åsele',
            BJURHOLM: 'Bjurholm',
            DOROTEA: 'Dorotea',
            LYCKSELE: 'Lycksele',
            MALA: 'Malå',
            NORDMALING: 'Nordmaling',
            NORSJO: 'Norsjö',
            ROBERTSFORS: 'Robertsfors',
            SKELLEFTEA: 'Skellefteå',
            SORSELE: 'Sorsele',
            STORUMAN: 'Storuman',
            UMEA: 'Umeå',
            VANNAS: 'Vännäs',
            VILHELMINA: 'Vilhelmina',
            VINDELN: 'Vindeln',
        },
        value: 'regionVasterbotten',
    },
    VASTERNORRLAND: {
        municipalities: {
            ANGE: 'Ånge',
            HARNOSAND: 'Härnösand',
            KRAMFORS: 'Kramfors',
            ORNSKOLDSVIK: 'Örnsköldsvik',
            SOLLEFTEA: 'Sollefteå',
            SUNDSVALL: 'Sundsvall',
            TIMRA: 'Timrå',
        },
        value: 'regionVasternorrland',
    },
    VASTMANLAND: {
        municipalities: {
            ARBOGA: 'Arboga',
            FAGERSTA: 'Fagersta',
            HALLSTAHAMMAR: 'Hallstahammar',
            KOPING: 'Köping',
            KUNGSOR: 'Kungsör',
            NORBERG: 'Norberg',
            SALA: 'Sala',
            SKINNSKATTEBERG: 'Skinnskatteberg',
            SURAHAMMAR: 'Surahammar',
            VASTERAS: 'Västerås',
        },
        value: 'regionVastmanland',
    },
    VASTRAGOTALAND: {
        municipalities: {
            ALE: 'Ale',
            ALINGSAS: 'Alingsås',
            AMAL: 'Åmål',
            BENGTSFORS: 'Bengtsfors',
            BOLLEBYGD: 'Bollebygd',
            BORAS: 'Borås',
            DALS_ED: 'Dals-Ed',
            ESSUNGA: 'Essunga',
            FALKOPING: 'Falköping',
            FARGELANDA: 'Färgelanda',
            GOTEBORG: 'Göteborg',
            GOTENE: 'Götene',
            GRASTORP: 'Grästorp',
            GULLSPANG: 'Gullspång',
            HARRYDA: 'Härryda',
            HERRLJUNGA: 'Herrljunga',
            HJO: 'Hjo',
            KARLSBORG: 'Karlsborg',
            KUNGALV: 'Kungälv',
            LERUM: 'Lerum',
            LIDKOPING: 'Lidköping',
            LILLA_EDET: 'Lilla Edet',
            LYSEKIL: 'Lysekil',
            MARIESTAD: 'Mariestad',
            MARK: 'Mark',
            MELLERUD: 'Mellerud',
            MOLNDAL: 'Mölndal',
            MUNKEDAL: 'Munkedal',
            OCKERO: 'Öckerö',
            ORUST: 'Orust',
            PARTILLE: 'Partille',
            SKARA: 'Skara',
            SKOVDE: 'Skövde',
            SOTENAS: 'Sotenäs',
            STENUNGSUND: 'Stenungsund',
            STROMSTAD: 'Strömstad',
            SVENLJUNGA: 'Svenljunga',
            TANUM: 'Tanum',
            TIBRO: 'Tibro',
            TIDAHOLM: 'Tidaholm',
            TJORN: 'Tjörn',
            TOREBODA: 'Töreboda',
            TRANEMO: 'Tranemo',
            TROLLHATTAN: 'Trollhättan',
            UDDEVALLA: 'Uddevalla',
            ULRICEHAMN: 'Ulricehamn',
            VANERSBORG: 'Vänersborg',
            VARA: 'Vara',
            VARGARDA: 'Vårgårda',
        },
        value: 'regionVastragotaland',
    },
};
exports.UNITED_KINGDOM_REGION_MUNICIPALITY_HIERARCHY = {
    SOUTH_WEST_ENGLAND: {
        municipalities: {
            BRISTOL: 'Bristol',
        },
        value: 'SOUTH_WEST_ENGLAND',
    },
};
