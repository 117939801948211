"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WhichDepartmentToWorkInAlternative = void 0;
var WhichDepartmentToWorkInAlternative;
(function (WhichDepartmentToWorkInAlternative) {
    WhichDepartmentToWorkInAlternative["OPEN_FOR_ANYTHING"] = "departmentOppenforallt";
    WhichDepartmentToWorkInAlternative["OPERATION"] = "departmentOperation";
    WhichDepartmentToWorkInAlternative["POSTOPERATION"] = "departmentPostoperation";
    WhichDepartmentToWorkInAlternative["AMBULANS"] = "departmentAmbulans";
    WhichDepartmentToWorkInAlternative["IVA"] = "departmentIva";
    WhichDepartmentToWorkInAlternative["DEPARTMENT"] = "department";
    WhichDepartmentToWorkInAlternative["AKUTSJUKVARD"] = "departmentAkutsjukvard";
    WhichDepartmentToWorkInAlternative["NARAKUT"] = "departmentNarakut";
    WhichDepartmentToWorkInAlternative["MAVA"] = "departmentMAVA";
    WhichDepartmentToWorkInAlternative["MEDICIN"] = "departmentMedicin";
    WhichDepartmentToWorkInAlternative["INFEKTION"] = "departmentInfektion";
    WhichDepartmentToWorkInAlternative["KIRURGI"] = "departmentKirurgi";
    WhichDepartmentToWorkInAlternative["THORAXKIRURGEN"] = "departmentThoraxKirurgen";
    WhichDepartmentToWorkInAlternative["HIA"] = "departmentHIA";
    WhichDepartmentToWorkInAlternative["HJARTAVDELNING"] = "departmentHjartavdelning";
    WhichDepartmentToWorkInAlternative["LUNGAVDELNING"] = "departmentLungavdelning";
    WhichDepartmentToWorkInAlternative["NJURAVDELNING"] = "departmentNjuravdelning";
    WhichDepartmentToWorkInAlternative["GASTROAVDELNING"] = "departmentGastroavdelning";
    WhichDepartmentToWorkInAlternative["BAVA"] = "departmentBAVA";
    WhichDepartmentToWorkInAlternative["BARNONKOLOGEN"] = "departmentBarnOnkologen";
    WhichDepartmentToWorkInAlternative["BARNMEDICIN"] = "departmentBarnmedicin";
    WhichDepartmentToWorkInAlternative["FORLOSSNING"] = "departmentForlossning";
    WhichDepartmentToWorkInAlternative["NEONATAL"] = "departmentNeonatal";
    WhichDepartmentToWorkInAlternative["BARN_OCH_UNGDOM"] = "departmentBarnOchUngdom";
    WhichDepartmentToWorkInAlternative["PSYKIATRI"] = "departmentPsykiatri";
    WhichDepartmentToWorkInAlternative["RADIOLOGEN"] = "departmentRadiologen";
    WhichDepartmentToWorkInAlternative["GYNEKOLOGDEPARTMENT"] = "departmentGynekologDepartment";
    WhichDepartmentToWorkInAlternative["ORTOPEDEN"] = "departmentOrtopeden";
    WhichDepartmentToWorkInAlternative["ONKOLOGEN"] = "departmentOnkologen";
    WhichDepartmentToWorkInAlternative["GERIATRIK"] = "departmentGeriatrik";
    WhichDepartmentToWorkInAlternative["NEUROLOGI"] = "departmentNeurologi";
    WhichDepartmentToWorkInAlternative["REHABILITERING"] = "departmentRehabilitering";
    WhichDepartmentToWorkInAlternative["REUMATOLOGI"] = "departmentReumatologi";
    WhichDepartmentToWorkInAlternative["MUNICIPAL"] = "departmentMunicipal";
    WhichDepartmentToWorkInAlternative["HEMSJUKVARD"] = "departmentHemsjukvard";
    WhichDepartmentToWorkInAlternative["SABO"] = "departmentSabo";
    WhichDepartmentToWorkInAlternative["ASIH"] = "departmentASIH";
    WhichDepartmentToWorkInAlternative["DEMENS"] = "departmentDemens";
    WhichDepartmentToWorkInAlternative["CENTER"] = "departmentCenter";
    WhichDepartmentToWorkInAlternative["VARDCENTRAL"] = "departmentVardcentral";
    WhichDepartmentToWorkInAlternative["HALSOCENTRAL"] = "departmentHalsocentral";
    WhichDepartmentToWorkInAlternative["SJUKSTUGA"] = "departmentSjukstuga";
    WhichDepartmentToWorkInAlternative["KVINNOKLINIK"] = "departmentKvinnoklinik";
    WhichDepartmentToWorkInAlternative["DIALYSMOTTAGNING"] = "departmentDialys";
    WhichDepartmentToWorkInAlternative["ONH_MOTTAGNING"] = "departmentONH";
    WhichDepartmentToWorkInAlternative["VACCINERING"] = "departmentVaccinering";
    WhichDepartmentToWorkInAlternative["MAMMOGRAFI"] = "departmentMammografi";
    WhichDepartmentToWorkInAlternative["SKOLSJUKVARD"] = "departmentSkola";
    WhichDepartmentToWorkInAlternative["TELEFONRADGIVNING"] = "departmentTelefonradgivning";
    WhichDepartmentToWorkInAlternative["JOURCENTRAL"] = "departmentJourcentral";
})(WhichDepartmentToWorkInAlternative || (exports.WhichDepartmentToWorkInAlternative = WhichDepartmentToWorkInAlternative = {}));
