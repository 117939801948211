"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SWEDISH_HOLIDAYS = void 0;
const enums_1 = require("@bemlo/enums");
const SWEDISH_PUBLIC_HOLIDAYS = [
    'Nyårsdagen',
    'Trettondedag jul',
    'Långfredagen',
    'Påskdagen',
    'Annandag påsk',
    'Första Maj',
    'Kristi himmelfärds dag',
    'Pingstdagen',
    'Sveriges nationaldag',
    'Midsommardagen',
    'Alla Helgons dag',
    'Juldagen',
    'Annandag jul',
];
const SWEDISH_OPTIONAL_HOLIDAYS = [
    'Påskafton',
    'Pingstafton',
    'Midsommarafton',
    'Julafton',
    'Nyårsafton',
];
const ALL_SWEDISH_HOLIDAYS = [
    ...SWEDISH_PUBLIC_HOLIDAYS,
    ...SWEDISH_OPTIONAL_HOLIDAYS,
];
exports.SWEDISH_HOLIDAYS = {
    country: enums_1.Country.SWEDEN,
    all: ALL_SWEDISH_HOLIDAYS,
    optional: SWEDISH_OPTIONAL_HOLIDAYS,
    public: SWEDISH_PUBLIC_HOLIDAYS,
};
