"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateShiftHoursFactory = exports.translateDayFactory = exports.translateShiftTypeFactory = exports.translateOnCallTypeFactory = exports.translateWorkPlaceRelationshipToApplicantFactory = exports.translateWorkHoursFactory = exports.translateWorkFormFactory = exports.translateWhichRegionAlternativeFactory = exports.translateWhichDepartmentToWorkInAlternativeFactory = exports.translateTenderWaiverStatusFactory = exports.translateTenderStatusFactory = exports.translateTenderPricingFactory = exports.translateSpecializationTitleFactory = exports.translateSpecializationEducationFactory = exports.translateSchedulingViolationTypeFactory = exports.translateBroadcastMessageRecipientGroupFactory = exports.translateProfessionShortFactory = exports.translateProfessionFactory = exports.translateMunicipalityFactory = exports.translateLocaleFactory = exports.translateLicenseStatusFactory = exports.translateJournalSystemFactory = exports.translateJobTypeFactory = exports.translateHowMuchWorkFactory = exports.translateHowMuchExperienceFactory = exports.translateFileUploadTypeFactory = exports.translateDynamicShiftStatusFactory = exports.translateDriversLicenseFactory = exports.translateCurrentEmploymentStatusFactory = exports.translateCountryFactory = void 0;
var country_1 = require("./country");
Object.defineProperty(exports, "translateCountryFactory", { enumerable: true, get: function () { return country_1.translateCountryFactory; } });
var current_employment_status_1 = require("./current-employment-status");
Object.defineProperty(exports, "translateCurrentEmploymentStatusFactory", { enumerable: true, get: function () { return current_employment_status_1.translateCurrentEmploymentStatusFactory; } });
var drivers_license_1 = require("./drivers-license");
Object.defineProperty(exports, "translateDriversLicenseFactory", { enumerable: true, get: function () { return drivers_license_1.translateDriversLicenseFactory; } });
var dynamic_shift_status_1 = require("./dynamic-shift-status");
Object.defineProperty(exports, "translateDynamicShiftStatusFactory", { enumerable: true, get: function () { return dynamic_shift_status_1.translateDynamicShiftStatusFactory; } });
var file_upload_type_1 = require("./file-upload-type");
Object.defineProperty(exports, "translateFileUploadTypeFactory", { enumerable: true, get: function () { return file_upload_type_1.translateFileUploadTypeFactory; } });
var how_much_experience_1 = require("./how-much-experience");
Object.defineProperty(exports, "translateHowMuchExperienceFactory", { enumerable: true, get: function () { return how_much_experience_1.translateHowMuchExperienceFactory; } });
var how_much_work_1 = require("./how-much-work");
Object.defineProperty(exports, "translateHowMuchWorkFactory", { enumerable: true, get: function () { return how_much_work_1.translateHowMuchWorkFactory; } });
var job_type_1 = require("./job-type");
Object.defineProperty(exports, "translateJobTypeFactory", { enumerable: true, get: function () { return job_type_1.translateJobTypeFactory; } });
var journal_system_1 = require("./journal-system");
Object.defineProperty(exports, "translateJournalSystemFactory", { enumerable: true, get: function () { return journal_system_1.translateJournalSystemFactory; } });
var license_status_1 = require("./license-status");
Object.defineProperty(exports, "translateLicenseStatusFactory", { enumerable: true, get: function () { return license_status_1.translateLicenseStatusFactory; } });
var locale_1 = require("./locale");
Object.defineProperty(exports, "translateLocaleFactory", { enumerable: true, get: function () { return locale_1.translateLocaleFactory; } });
var municipality_1 = require("./municipality");
Object.defineProperty(exports, "translateMunicipalityFactory", { enumerable: true, get: function () { return municipality_1.translateMunicipalityFactory; } });
var profession_1 = require("./profession");
Object.defineProperty(exports, "translateProfessionFactory", { enumerable: true, get: function () { return profession_1.translateProfessionFactory; } });
Object.defineProperty(exports, "translateProfessionShortFactory", { enumerable: true, get: function () { return profession_1.translateProfessionShortFactory; } });
var broadcast_message_recipient_group_1 = require("./broadcast-message-recipient-group");
Object.defineProperty(exports, "translateBroadcastMessageRecipientGroupFactory", { enumerable: true, get: function () { return broadcast_message_recipient_group_1.translateBroadcastMessageRecipientGroupFactory; } });
var scheduling_violation_type_1 = require("./scheduling-violation-type");
Object.defineProperty(exports, "translateSchedulingViolationTypeFactory", { enumerable: true, get: function () { return scheduling_violation_type_1.translateSchedulingViolationTypeFactory; } });
var specialization_1 = require("./specialization");
Object.defineProperty(exports, "translateSpecializationEducationFactory", { enumerable: true, get: function () { return specialization_1.translateSpecializationEducationFactory; } });
Object.defineProperty(exports, "translateSpecializationTitleFactory", { enumerable: true, get: function () { return specialization_1.translateSpecializationTitleFactory; } });
var tender_pricing_1 = require("./tender-pricing");
Object.defineProperty(exports, "translateTenderPricingFactory", { enumerable: true, get: function () { return tender_pricing_1.translateTenderPricingFactory; } });
var tender_status_1 = require("./tender-status");
Object.defineProperty(exports, "translateTenderStatusFactory", { enumerable: true, get: function () { return tender_status_1.translateTenderStatusFactory; } });
var tender_waiver_status_1 = require("./tender-waiver-status");
Object.defineProperty(exports, "translateTenderWaiverStatusFactory", { enumerable: true, get: function () { return tender_waiver_status_1.translateTenderWaiverStatusFactory; } });
var which_department_to_work_in_alternative_1 = require("./which-department-to-work-in-alternative");
Object.defineProperty(exports, "translateWhichDepartmentToWorkInAlternativeFactory", { enumerable: true, get: function () { return which_department_to_work_in_alternative_1.translateWhichDepartmentToWorkInAlternativeFactory; } });
var which_region_alternative_1 = require("./which-region-alternative");
Object.defineProperty(exports, "translateWhichRegionAlternativeFactory", { enumerable: true, get: function () { return which_region_alternative_1.translateWhichRegionAlternativeFactory; } });
var work_form_1 = require("./work-form");
Object.defineProperty(exports, "translateWorkFormFactory", { enumerable: true, get: function () { return work_form_1.translateWorkFormFactory; } });
var work_hours_1 = require("./work-hours");
Object.defineProperty(exports, "translateWorkHoursFactory", { enumerable: true, get: function () { return work_hours_1.translateWorkHoursFactory; } });
var workplace_relationship_to_applicant_1 = require("./workplace-relationship-to-applicant");
Object.defineProperty(exports, "translateWorkPlaceRelationshipToApplicantFactory", { enumerable: true, get: function () { return workplace_relationship_to_applicant_1.translateWorkPlaceRelationshipToApplicantFactory; } });
var on_call_type_1 = require("./on-call-type");
Object.defineProperty(exports, "translateOnCallTypeFactory", { enumerable: true, get: function () { return on_call_type_1.translateOnCallTypeFactory; } });
var shift_type_1 = require("./shift-type");
Object.defineProperty(exports, "translateShiftTypeFactory", { enumerable: true, get: function () { return shift_type_1.translateShiftTypeFactory; } });
var day_1 = require("./day");
Object.defineProperty(exports, "translateDayFactory", { enumerable: true, get: function () { return day_1.translateDayFactory; } });
var shift_hours_1 = require("./shift-hours");
Object.defineProperty(exports, "translateShiftHoursFactory", { enumerable: true, get: function () { return shift_hours_1.translateShiftHoursFactory; } });
