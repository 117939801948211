"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenderAvailabilitySchedule = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const TenderAvailabilityFooter_1 = require("./TenderAvailabilityFooter");
const TenderAvailabilityHeaderRow_1 = require("./TenderAvailabilityHeaderRow");
const TenderAvailabilityRow_1 = require("./TenderAvailabilityRow");
const AvailabilityScheduleGridWrapper_1 = require("../atoms/AvailabilityScheduleGridWrapper");
const TenderAvailabilitySchedule = ({ availabilities, setAvailabilities, viewState, tenderScope, schema, }) => {
    const setAvailabilityForWeek = (index) => (availability) => {
        const newAvailabilities = [...availabilities];
        newAvailabilities[index] = availability;
        setAvailabilities?.(newAvailabilities);
    };
    return ((0, jsx_runtime_1.jsxs)(AvailabilityScheduleGridWrapper_1.AvailabilityScheduleGridWrapper, { hideOverflow: true, children: [(0, jsx_runtime_1.jsx)(TenderAvailabilityHeaderRow_1.TenderAvailabilityHeaderRow, { viewState: viewState, availabilities: availabilities, setAvailabilities: setAvailabilities, tenderScope: tenderScope }), availabilities.map((week, index) => ((0, jsx_runtime_1.jsx)(TenderAvailabilityRow_1.TenderAvailabilityRow, { viewState: viewState, availability: week, setAvailability: setAvailabilityForWeek(index), tenderScope: tenderScope, schema: schema?.element }, week.key))), viewState === 'edit' && ((0, jsx_runtime_1.jsx)(TenderAvailabilityFooter_1.TenderAvailabilityFooter, { availabilities: availabilities, tenderScope: tenderScope, viewState: viewState }))] }));
};
exports.TenderAvailabilitySchedule = TenderAvailabilitySchedule;
