"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tenderStatus = void 0;
const enums_1 = require("@bemlo/enums");
exports.tenderStatus = {
    [enums_1.TenderStatus.DRAFT]: 'Draft',
    [enums_1.TenderStatus.PUBLISHED]: 'Published',
    [enums_1.TenderStatus.CLOSED]: 'Closed',
    [enums_1.TenderStatus.FILLED]: 'Filled',
    [enums_1.TenderStatus.URGENT]: 'Urgent',
    [enums_1.TenderStatus.ASSIGNED]: 'Assigned',
};
