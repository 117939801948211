"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToasterContextProvider = exports.useToasterContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const constants_1 = require("../../constants");
const TOAST_DURATION = 4000;
let id = 0;
const ToasterContext = (0, react_1.createContext)(null);
const useToasterContext = () => {
    const context = (0, react_1.useContext)(ToasterContext);
    if (!context) {
        throw new Error('useToasterContext must be used within <ToasterContextProvider />');
    }
    return context;
};
exports.useToasterContext = useToasterContext;
const ToasterContextProvider = ({ children, }) => {
    const [toasts, setToasts] = (0, react_1.useState)([]);
    const addToast = (toast) => {
        const toastId = id++;
        setToasts((currentToasts) => [...currentToasts, { ...toast, id: toastId }]);
        if (!toast.persistent) {
            setTimeout(() => {
                removeToast(toastId);
            }, TOAST_DURATION);
        }
    };
    const hideToast = (toastId) => {
        setToasts((currentToasts) => currentToasts.map((toast) => toast.id === toastId ? { ...toast, removed: true } : toast));
    };
    const removeToast = (toastId) => {
        hideToast(toastId);
        setTimeout(() => {
            setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== toastId));
        }, constants_1.theme.animation.duration * 1000);
    };
    const value = {
        addToast,
        removeToast,
        toasts,
    };
    return ((0, jsx_runtime_1.jsx)(ToasterContext.Provider, { value: value, children: children }));
};
exports.ToasterContextProvider = ToasterContextProvider;
