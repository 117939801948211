"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScheduleErrors = void 0;
const react_1 = require("react");
const schedule_1 = require("@bemlo/schedule");
const translate_1 = require("../../../../translate");
const useScheduleErrors = (shifts, wtdRuleSet) => {
    const t = (0, translate_1.useTranslations)();
    return (0, react_1.useMemo)(() => {
        if (!wtdRuleSet)
            return [];
        const violations = (0, schedule_1.getScheduleViolations)(shifts, wtdRuleSet);
        return violations.map((error) => ({
            shiftId: error.shiftId,
            error: t.$schedulingViolationType(error.violationType),
        }));
    }, [shifts, t, wtdRuleSet]);
};
exports.useScheduleErrors = useScheduleErrors;
