"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDialogContext = exports.DialogProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const DialogContext = (0, react_1.createContext)(null);
const DialogProvider = ({ isOpen, onClose, children, }) => {
    const value = (0, react_1.useMemo)(() => {
        return {
            isOpen,
            onClose,
        };
    }, [isOpen, onClose]);
    return ((0, jsx_runtime_1.jsx)(DialogContext.Provider, { value: value, children: children }));
};
exports.DialogProvider = DialogProvider;
const useDialogContext = () => {
    const context = (0, react_1.useContext)(DialogContext);
    if (context === null) {
        throw new Error('useDialogContext must be used within an DialogProvider');
    }
    return context;
};
exports.useDialogContext = useDialogContext;
