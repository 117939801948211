"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateMunicipalityFactory = translateMunicipalityFactory;
const enums_1 = require("@bemlo/enums");
function translateMunicipalityFactory(_locale) {
    /*
      Note: These translations are coming from the Municipality enum where the value is the name of
      the municipality. Implementation needs to be changed if we change the values, or need to support
      multiple languages for one municipality.
    */
    const translations = Object.assign({}, enums_1.Municipality);
    /*
      translations will contain both key and value as key:
      {
        STOCKHOLM: "Stockholm",
        Stockholm: "Stockholm",
      }
    */
    for (const key in enums_1.Municipality) {
        const value = translations[key];
        translations[value] = value;
    }
    return (municipality) => {
        return translations[municipality];
    };
}
