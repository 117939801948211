"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.howMuchExperience = void 0;
const enums_1 = require("@bemlo/enums");
exports.howMuchExperience = {
    [enums_1.HowMuchExperienceAlternative.STUDENT]: 'Student',
    [enums_1.HowMuchExperienceAlternative.NEW_GRADUATE]: 'New graduate',
    [enums_1.HowMuchExperienceAlternative.LESS_THAN_ONE]: 'Less than one year',
    [enums_1.HowMuchExperienceAlternative.ONE_TO_TWO]: '1-2 years',
    [enums_1.HowMuchExperienceAlternative.TWO]: '2 years',
    [enums_1.HowMuchExperienceAlternative.TWO_TO_THREE]: '2-3 years',
    [enums_1.HowMuchExperienceAlternative.THREE_TO_FIVE]: '3-5 years',
    [enums_1.HowMuchExperienceAlternative.FIVE_TO_TEN]: '5-10 years',
    [enums_1.HowMuchExperienceAlternative.MORE_THAN_TEN]: 'More than 10 years',
};
