"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tenderWaiverStatus = void 0;
const enums_1 = require("@bemlo/enums");
exports.tenderWaiverStatus = {
    [enums_1.TenderWaiverStatus.APPROVED]: 'Godkjent',
    [enums_1.TenderWaiverStatus.REJECTED]: 'Avvist',
    [enums_1.TenderWaiverStatus.PENDING]: 'Sendt',
    [enums_1.TenderWaiverStatus.USED]: 'Avsluttet',
};
