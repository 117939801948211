"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailabilityScheduleWeekOverviewHeader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const DayCell_1 = require("../atoms/DayCell");
const PeriodCell_1 = require("../atoms/PeriodCell");
const AvailabilityRow_1 = require("../AvailabilityRow");
const ShiftHoursCell_1 = require("../molecules/ShiftHoursCell");
const WeekDaysRow_1 = require("../molecules/WeekDaysRow");
const constants_2 = require("../utils/constants");
const get_grid_template_columns_1 = require("../utils/get-grid-template-columns");
const AvailabilityScheduleWeekOverviewHeader = ({ week, isOpen, toggleIsOpen, }) => {
    const t = (0, translate_1.useTranslations)();
    return ((0, jsx_runtime_1.jsxs)(AvailabilityRow_1.AvailabilityRowBase, { gridTemplateColumns: get_grid_template_columns_1.OVERVIEW_GRID_TEMPLATE_COLUMNS, styles: {
            backgroundColor: constants_1.Color.LIGHT_GREY,
            borderBottom: constants_1.theme.border.style.thinLight,
            color: week.tendered ? undefined : constants_1.Color.DARK_MEDIUM_GREY,
        }, children: [(0, jsx_runtime_1.jsx)(elements_1.Clickable, { disabled: !week.tendered, onClick: toggleIsOpen, children: (0, jsx_runtime_1.jsx)(elements_1.Icon, { name: isOpen ? 'ChevronDownIcon' : 'ChevronRightIcon', color: week.tendered ? constants_1.Color.DARK_GREY : constants_1.Color.DARK_MEDIUM_GREY, size: "sm" }) }), (0, jsx_runtime_1.jsx)(PeriodCell_1.PeriodCell, { startsAt: week.period.startsAt, endsAt: week.period.endsAt, selected: false }), week.tendered ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(elements_1.Badge, { styles: { width: 'fit-content' }, size: "sm", squared: true, color: "grey", children: t.common.percent_sign(week.scope.scope) }), (0, jsx_runtime_1.jsx)(elements_1.Badge, { styles: { width: 'fit-content' }, size: "sm", squared: true, color: "dark-blue", children: t.common.percent_sign(week.scope.scope - week.bookedScope) }), (0, jsx_runtime_1.jsx)(ShiftHoursCell_1.ShiftHoursCell, { availableShiftHours: constants_2.ALL_AVAILABILITY_SHIFT_HOURS, selectedShiftHours: week.scope.shiftHours, viewState: "view" })] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(elements_1.Div, {}), (0, jsx_runtime_1.jsx)(elements_1.Div, {}), (0, jsx_runtime_1.jsx)(elements_1.Div, {})] })), (0, jsx_runtime_1.jsx)(WeekDaysRow_1.WeekDaysContainer, { children: week.days.map((day) => ((0, jsx_runtime_1.jsx)(DayCell_1.OverviewDayCellHeader, { date: day.date, status: day.status }, day.date.toString()))) })] }));
};
exports.AvailabilityScheduleWeekOverviewHeader = AvailabilityScheduleWeekOverviewHeader;
