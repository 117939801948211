"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyState = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Flex_1 = require("./Flex");
const Icon_1 = require("./Icon");
const Paragraph_1 = require("./Paragraph");
const Text_1 = require("./Text");
const constants_1 = require("../constants");
const EmptyState = (props) => {
    return ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { col: true, gap: 3, items: "center", justify: "center", styles: { height: '100%', px: 3, py: 4, width: '100%', ...props.styles }, children: [props.icon && ((0, jsx_runtime_1.jsx)(Icon_1.Icon, { color: constants_1.Color.DARKEST_MEDIUM_GREY, name: props.icon, round: true, size: "xl" })), props.heading && ((0, jsx_runtime_1.jsx)(Text_1.Text, { bold: true, color: constants_1.Color.CINDER, size: 3, styles: { maxWidth: 480, ...props.headingStyles }, textAlign: "center", children: props.heading })), props.paragraph && ((0, jsx_runtime_1.jsx)(Paragraph_1.Paragraph, { mb: 0, styles: {
                    maxWidth: 480,
                    textAlign: 'center',
                    ...props.paragraphStyles,
                }, children: props.paragraph })), props.children] }));
};
exports.EmptyState = EmptyState;
