import { Locale } from '@bemlo/enums'
import { dayjsFactory } from '@bemlo/date'
import {
  translateCountryFactory,
  translateCurrentEmploymentStatusFactory,
  translateFileUploadTypeFactory,
  translateHowMuchExperienceFactory,
  translateHowMuchWorkFactory,
  translateJobTypeFactory,
  translateLicenseStatusFactory,
  translateMunicipalityFactory,
  translateProfessionFactory,
  translateProfessionShortFactory,
  translateSpecializationEducationFactory,
  translateSpecializationTitleFactory,
  translateWhichDepartmentToWorkInAlternativeFactory,
  translateWhichRegionAlternativeFactory,
  translateWorkFormFactory,
} from '@bemlo/i18n'

import {
  HowMuchExperienceAlternative,
  HowMuchWorkAlternative,
  LicenseStatus,
  Municipality,
  WhichDepartmentToWorkInAlternative,
  WhichRegionAlternative,
  WorkForm,
} from '../generated/graphql'

export function factories(locale: Locale) {
  return {
    $country: translateCountryFactory(locale),
    $currentEmploymentStatus: translateCurrentEmploymentStatusFactory(locale),
    $date: dayjsFactory(locale),
    $fileUploadType: translateFileUploadTypeFactory(locale),
    $howMuchExperience:
      translateHowMuchExperienceFactory<HowMuchExperienceAlternative>(locale),
    $jobType: translateJobTypeFactory(locale),
    $licenseStatus: translateLicenseStatusFactory<LicenseStatus>(locale),
    $municipality: translateMunicipalityFactory<Municipality>(locale),
    $profession: translateProfessionFactory(locale),
    $professionShort: translateProfessionShortFactory(locale),
    $specializationEducation: translateSpecializationEducationFactory(locale),
    $specializationTitle: translateSpecializationTitleFactory(locale),
    $whichRegionAlternative:
      translateWhichRegionAlternativeFactory<WhichRegionAlternative>(locale),
    $whichDepartmentToWorkInAlternative:
      translateWhichDepartmentToWorkInAlternativeFactory<WhichDepartmentToWorkInAlternative>(
        locale,
      ),
    $workForm: translateWorkFormFactory<WorkForm>(locale),
    $howMuchWork: translateHowMuchWorkFactory<HowMuchWorkAlternative>(locale),
  }
}
