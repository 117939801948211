"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDisclosureContext = exports.DisclosureContext = void 0;
const react_1 = require("react");
exports.DisclosureContext = (0, react_1.createContext)(null);
const useDisclosureContext = () => {
    const context = (0, react_1.useContext)(exports.DisclosureContext);
    if (!context) {
        throw new Error('useDisclosureContext must be used within the DisclosureContext.Provider');
    }
    return context;
};
exports.useDisclosureContext = useDisclosureContext;
