"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileUploadType = void 0;
const enums_1 = require("@bemlo/enums");
exports.fileUploadType = {
    [enums_1.FileUploadType.CV]: 'CV',
    [enums_1.FileUploadType.LICENSE]: 'Legitimation',
    [enums_1.FileUploadType.HOSP]: 'HOSP',
    [enums_1.FileUploadType.IVO]: 'IVO',
    [enums_1.FileUploadType.OTHER]: 'Annat dokument',
    [enums_1.FileUploadType.REFERENCE]: 'Referens',
    [enums_1.FileUploadType.SPECIALIZATION]: 'Specialisering',
};
