"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePayTypes = void 0;
const parsePayTypes = (payTypes) => {
    return {
        DAY: payTypes?.basePay ?? 0,
        EVENING: payTypes?.eveningsPay ?? payTypes?.basePay ?? 0,
        HOLIDAY: payTypes?.holidayPay ?? payTypes?.basePay ?? 0,
        NIGHT: payTypes?.nightsPay ?? payTypes?.basePay ?? 0,
        WEEKEND: payTypes?.weekendPay ?? payTypes?.basePay ?? 0,
        WEEKEND_NIGHT: payTypes?.weekendNightsPay ??
            payTypes?.nightsPay ??
            payTypes?.weekendPay ??
            payTypes?.basePay ??
            0,
    };
};
exports.parsePayTypes = parsePayTypes;
