"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToMarkdown = void 0;
/*
  More info about the Delta format https://quilljs.com/docs/delta/

  The thing that makes this a bit weird, is that *block* nodes' attributes apply to the text after
  the last newline. While *inline* attributes is on the same node. So something expressed in
  Markdown as:

  Some text
  # My heading
  My _italic text_

  in Delta format is expressed as

  [
    {
      "insert": "Some text\nMy heading"
    },
    {
      "attributes": {
        "header": 1
      },
      "insert": "\n"
    },
    {
      "insert": "My "
    },
    {
      "attributes": {
        "italic": true
      },
      "insert": "italic text"
    },
    {
      "insert": "\n"
    }
  ]
*/
/**
 * Used for calculating the numbers in an ordered list
 */
class Group {
    constructor(type) {
        this.count = 0;
        this.type = type;
    }
}
class Node {
    constructor(content) {
        this.open = '';
        this.children = [];
        this.close = '';
        if (typeof content === 'string') {
            this.open = content;
        }
        else if (content instanceof Node) {
            this.append(content);
        }
    }
    append(child) {
        this.children = [...this.children, child];
    }
    render() {
        return (this.open +
            this.children.map((child) => child.render()).join('') +
            this.close);
    }
}
class LineNode extends Node {
    constructor() {
        super(...arguments);
        this.open = '\n';
    }
}
class ItalicNode extends Node {
    constructor() {
        super(...arguments);
        this.open = '_';
        this.close = '_';
    }
}
class BoldNode extends Node {
    constructor() {
        super(...arguments);
        this.open = '**';
        this.close = '**';
    }
}
class LinkNode extends Node {
    constructor(childNode, url) {
        super(childNode);
        this.open = '[';
        this.close = `](${url})`;
    }
}
class HeaderNode extends Node {
    constructor(level) {
        super();
        this.open = '#'.repeat(level) + ' ';
    }
}
class ListNode extends Node {
    constructor(type, count) {
        super();
        switch (type) {
            case 'bullet':
                this.open = '- '; // - List item
                break;
            case 'ordered': {
                this.open = `${count}. `; // 1. List item
            }
        }
    }
}
class ToMarkdown extends Node {
    constructor(ops) {
        super();
        this.lineNode = new Node(); // First lineNode shouldn't have this.open = "\n"
        for (const op of ops) {
            this.parseOp(op);
        }
        /*
          During parsing, only appending lines when they are hitting on a new line. So need to append
          the last one here, because it wasn't appended yet.
        */
        this.append(this.lineNode);
    }
    parseOp(op) {
        const attributes = op.attributes;
        // Checking for BLOCK attributes
        if (attributes) {
            let blockNode;
            if (attributes.header) {
                // Header shouldn't be in group, so resetting group if stumbling upon it.
                this.group = undefined;
                blockNode = new HeaderNode(attributes.header);
            }
            else if (attributes.list) {
                const groupType = `list-${attributes.list}`;
                if (!this.group || this.group.type !== groupType) {
                    this.group = new Group(groupType);
                }
                this.group.count++;
                blockNode = new ListNode(attributes.list, this.group.count);
            }
            if (blockNode) {
                /*
                  Puts the block node between LineNode and it's children. E.g. if the block is a heading,
                  everyting on that line should be included in the heading.
                */
                blockNode.children = this.lineNode.children;
                this.lineNode.children = [blockNode];
                this.append(this.lineNode);
                // Always a new line after a block
                this.lineNode = new LineNode();
                return;
            }
        }
        const lines = op.insert.split('\n');
        for (let l = 0; l < lines.length; l++) {
            /*
              Make newline only if 'op.insert' contained newline, if lines.length == 1, there were no
              newline.
            */
            if (l > 0) {
                // A newline (without block attributes) means we shouldn't be in a group anymore
                if (this.group) {
                    // A group should end with an extra newline
                    this.append(new LineNode());
                    this.group = undefined;
                }
                this.append(this.lineNode);
                this.lineNode = new LineNode();
            }
            const line = lines[l];
            let currentNode = new Node(line);
            // checking for inline attributes
            if (attributes) {
                if (attributes.italic) {
                    currentNode = new ItalicNode(currentNode);
                }
                if (attributes.bold) {
                    currentNode = new BoldNode(currentNode);
                }
                if (attributes.link) {
                    currentNode = new LinkNode(currentNode, attributes.link);
                }
            }
            this.lineNode.append(currentNode);
        }
    }
}
exports.ToMarkdown = ToMarkdown;
