"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useListboxProps = exports.ListboxPropsContextProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ListboxPropsContext = (0, react_1.createContext)(null);
const ListboxPropsContextProvider = (props) => {
    return ((0, jsx_runtime_1.jsx)(ListboxPropsContext.Provider, { value: props, children: props.children }));
};
exports.ListboxPropsContextProvider = ListboxPropsContextProvider;
const useListboxProps = () => {
    const context = (0, react_1.useContext)(ListboxPropsContext);
    if (!context) {
        throw new Error('useListboxProps must be used within the ListboxPropsContext.Provider');
    }
    return context;
};
exports.useListboxProps = useListboxProps;
