"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftHoursAvailabilityCell = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const ShiftHoursAvailabilityCell = (props) => {
    const availability = props.availability;
    if (!availability) {
        return ((0, jsx_runtime_1.jsx)(elements_1.Clickable, { onClick: props.onMarkAsAvailable, styles: {
                borderRadius: constants_1.theme.border.radius.small,
                border: constants_1.theme.border.style.thin,
            } }));
    }
    if (availability.isAvailable) {
        return ((0, jsx_runtime_1.jsx)(elements_1.Clickable, { styles: {
                borderRadius: constants_1.theme.border.radius.small,
                background: constants_1.Color.LIGHTER_GREEN,
            }, onClick: () => props.onMarkAsUnavailable(availability.id), children: (0, jsx_runtime_1.jsx)(elements_1.Flex, { styles: { justifyContent: 'center' }, children: (0, jsx_runtime_1.jsx)(elements_1.Icon, { name: "CalendarIcon", color: constants_1.Color.DARKEST_GREEN, design: "solid" }) }) }));
    }
    return ((0, jsx_runtime_1.jsx)(elements_1.Clickable, { styles: {
            borderRadius: constants_1.theme.border.radius.small,
            background: constants_1.Color.LIGHTER_RED,
        }, onClick: () => props.onDelete(availability.id), children: (0, jsx_runtime_1.jsx)(elements_1.Flex, { styles: { justifyContent: 'center' }, children: (0, jsx_runtime_1.jsx)(elements_1.Icon, { name: "XIcon", color: constants_1.Color.DARK_RED, design: "solid" }) }) }));
};
exports.ShiftHoursAvailabilityCell = ShiftHoursAvailabilityCell;
