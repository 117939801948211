"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.comparatorStyles = exports.inputStyles = exports.inputFontSize = exports.dropdownItemsStyles = exports.dropdownItemStyles = exports.dropdownButtonStyles = exports.comboboxInputStyles = exports.buttonStyles = exports.DROPDOWN_BUTTON_STYLES = exports.DISABLED_STYLES = exports.BUTTON_SIZE_MAP = void 0;
const styled_system_1 = require("styled-system");
const color_1 = require("./color");
const theme_1 = require("./theme");
const utils_1 = require("../utils");
const create_styles_1 = require("../utils/create-styles");
exports.BUTTON_SIZE_MAP = {
    xxs: {
        fontSize: theme_1.theme.font.size[6],
        paddingX: theme_1.theme.padding.button.smallest.x,
        paddingY: theme_1.theme.padding.button.smallest.y,
    },
    xs: {
        fontSize: theme_1.theme.font.size[6],
        paddingX: theme_1.theme.padding.button.smaller.x,
        paddingY: theme_1.theme.padding.button.smaller.y,
    },
    sm: {
        fontSize: theme_1.theme.font.size[6],
        paddingX: theme_1.theme.padding.button.small.x,
        paddingY: theme_1.theme.padding.button.small.y,
    },
    md: {
        fontSize: theme_1.theme.font.size[5],
        paddingX: theme_1.theme.padding.button.medium.x,
        paddingY: theme_1.theme.padding.button.medium.y,
    },
    lg: {
        fontSize: theme_1.theme.font.size[5],
        paddingX: theme_1.theme.padding.button.large.x,
        paddingY: theme_1.theme.padding.button.large.y,
    },
    xl: {
        fontSize: theme_1.theme.font.size[3],
        paddingX: theme_1.theme.padding.button.larger.x,
        paddingY: theme_1.theme.padding.button.larger.y,
    },
    xxl: {
        fontSize: theme_1.theme.font.size[2],
        paddingX: theme_1.theme.padding.button.largest.x,
        paddingY: theme_1.theme.padding.button.largest.y,
    },
};
exports.DISABLED_STYLES = {
    background: color_1.Color.DARK_MEDIUM_GREY,
    color: color_1.Color.DARKER_GREY,
    cursor: 'not-allowed',
};
exports.DROPDOWN_BUTTON_STYLES = {
    blue: {
        backgroundColor: color_1.Color.BLUE,
        color: color_1.Color.WHITE,
        hoverBackgroundColor: color_1.Color.DARK_BLUE,
        invalidColor: color_1.Color.TRANSPARENT_BLUE,
    },
    white: {
        backgroundColor: color_1.Color.WHITE,
        border: true,
        color: color_1.Color.BLACK,
        hoverBackgroundColor: color_1.Color.LEGACY_GRAY,
        invalidColor: color_1.Color.DARK_GREY,
    },
    normal: {
        backgroundColor: color_1.Color.LIGHT_GREY,
        border: true,
        color: color_1.Color.BLACK,
        hoverBackgroundColor: color_1.Color.LEGACY_GRAY,
        invalidColor: color_1.Color.DARK_GREY,
    },
    none: {
        backgroundColor: color_1.Color.TRANSPARENT,
        color: color_1.Color.BLACK,
        hoverBackgroundColor: color_1.Color.LIGHT_GREY,
        invalidColor: color_1.Color.DARK_GREY,
        border: false,
    },
};
const buttonStyles = (props = {}) => {
    const dimensions = exports.BUTTON_SIZE_MAP[props.size ?? 'md'];
    return {
        ...(0, styled_system_1.borderRadius)(props),
        border: theme_1.theme.border.style.thin,
        borderRadius: theme_1.theme.border.radius.normal,
        boxShadow: theme_1.theme.boxShadow.normal,
        cursor: 'pointer',
        fontSize: dimensions.fontSize,
        fontFamily: 'inherit',
        outline: 'none',
        paddingBottom: dimensions.paddingY,
        paddingLeft: dimensions.paddingX,
        paddingRight: dimensions.paddingX,
        paddingTop: dimensions.paddingY,
        position: 'relative',
        userSelect: 'none',
        transitionDuration: '1s',
        transitionProperty: 'background-color',
        transitionTimingFunction: theme_1.theme.animation.easing,
        '&:disabled': exports.DISABLED_STYLES,
    };
};
exports.buttonStyles = buttonStyles;
const comboboxInputStyles = (props = {}) => {
    return {
        ...(0, exports.inputStyles)(props),
        width: '100%',
        /*
         * Simulates the CSS `outline` property, but using the border color instead.
         *  - `&:has(*:focus)` matches a `react-select` component with any focused descendant.
         *  - `[aria-activedescendant]` matches a Headless UI component with any focused descendant.
         */
        '&:focus, &:has(*:focus), [aria-activedescendant]': {
            borderColor: color_1.Color.LIGHT_BLUE,
        },
    };
};
exports.comboboxInputStyles = comboboxInputStyles;
const dropdownButtonStyles = (props = {}) => {
    const style = exports.DROPDOWN_BUTTON_STYLES[props.design || 'normal'];
    return {
        ...(0, exports.buttonStyles)(props),
        ...(props.design === 'none' && { boxShadow: 'none' }),
        appearance: 'none',
        backgroundColor: style.backgroundColor,
        ...(style.border ? {} : { border: '1px solid transparent' }),
        ...(props.invalid && { borderColor: color_1.Color.RED }),
        boxSizing: 'border-box',
        color: props.isPlaceholder ? style.invalidColor : style.color,
        display: 'flex',
        fontWeight: props.isPlaceholder
            ? theme_1.theme.font.weight.book
            : theme_1.theme.font.weight.legacy[500],
        lineHeight: theme_1.theme.lineHeight.normal,
        minWidth: props.compact || props.noText ? 0 : 224,
        paddingRight: props.noText ? 30 : 40,
        textAlign: 'left',
        '&:hover:not(:disabled)': {
            backgroundColor: style.hoverBackgroundColor,
        },
        ...(props.fullWidth
            ? { width: '100%' }
            : {
                width: props.noText ? 0 : undefined,
                [(0, utils_1.mediaBreakpointGte)('md')]: {
                    maxWidth: 640,
                },
            }),
    };
};
exports.dropdownButtonStyles = dropdownButtonStyles;
const dropdownItemStyles = (props = {}) => {
    return {
        ...(props.active ? { backgroundColor: color_1.Color.LIGHTEST_BLUE } : {}),
        color: props.disabled ? color_1.Color.DARKEST_MEDIUM_GREY : color_1.Color.BLACK,
        ...(props.isPlaceholder && { color: color_1.Color.DARKEST_MEDIUM_GREY }),
        cursor: props.disabled ? 'not-allowed' : 'pointer',
        ...(0, exports.inputFontSize)({}),
        margin: 0,
        outline: 'none',
        padding: `${theme_1.theme.spacing['2']}px ${theme_1.theme.spacing['2.5']}px`,
        userSelect: 'none',
        '&:hover, &[data-headlessui-state="active"]': props.disabled
            ? undefined
            : { backgroundColor: color_1.Color.LIGHTEST_BLUE },
    };
};
exports.dropdownItemStyles = dropdownItemStyles;
const dropdownItemsStyles = (props = {}) => {
    return {
        backgroundColor: color_1.Color.WHITE,
        border: theme_1.theme.border.style.thinMedium,
        borderRadius: theme_1.theme.border.radius.normal,
        boxShadow: theme_1.theme.boxShadow.normal,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        listStyle: 'none',
        margin: 0,
        padding: 0,
        maxHeight: 300,
        minWidth: 224,
        outline: 'none',
        overflow: 'auto',
        position: 'absolute',
        top: 4,
        width: '100%',
        zIndex: theme_1.theme.zIndex.select,
        ...(props.alignItems === 'right' ? { right: 0 } : { left: 0 }),
    };
};
exports.dropdownItemsStyles = dropdownItemsStyles;
const inputFontSize = (props) => {
    return {
        // For mobiles we should keep the font size 16px for iOS
        fontSize: theme_1.theme.font.size[5],
        // Set font size to smaller for medium and large screens:
        '@media (min-width: 768px)': {
            fontSize: theme_1.theme.font.size[5.5],
        },
        ...(props.size && { fontSize: theme_1.theme.font.size[props.size] }),
    };
};
exports.inputFontSize = inputFontSize;
const inputStyles = (props = {}) => {
    return {
        background: color_1.Color.LIGHT_GREY,
        backgroundImage: props.icon ? `url(${props.icon})` : undefined,
        backgroundPosition: 'right 8px center',
        backgroundRepeat: 'no-repeat',
        border: theme_1.theme.border.style.thinMedium,
        // `!important` is needed as `react-select` reorders the style declarations for some reason:
        ...(props.invalid && { borderColor: `${color_1.Color.RED} !important` }),
        borderRadius: theme_1.theme.border.radius.normal,
        // Ensure that the padding is included in the width/height:
        boxSizing: 'border-box',
        cursor: props.cursor,
        // Ensure that we don't use the browser's default font:
        fontFamily: 'inherit',
        ...(0, exports.inputFontSize)(props), // It does not s
        fontWeight: props.bold ? theme_1.theme.font.weight.bold : undefined,
        // Adjust down the line-height to match icon-only buttons:
        lineHeight: `${theme_1.theme.elements.icon.size.sm}px`,
        minWidth: typeof props.minWidth === 'number' ? props.minWidth : 224,
        ...(props.maxWidth && { maxWidth: props.maxWidth, minWidth: 'auto' }),
        outline: 'none',
        padding: `${theme_1.theme.spacing['2']}px ${theme_1.theme.spacing['2.5']}px`,
        // paddingBottom: theme.padding.input.y,
        // paddingLeft: theme.padding.input.x,
        // paddingRight: theme.padding.input.x,
        // paddingTop: theme.padding.input.y,
        width: props.fullWidth ? '100%' : undefined,
        '&:disabled': exports.DISABLED_STYLES,
        ...(0, create_styles_1.createStyles)(props.styles),
    };
};
exports.inputStyles = inputStyles;
// Box and Style props used to support Clickable and new Div element
const comparatorStyles = ({ design = 'light' }) => ({
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    paddingTop: theme_1.theme.spacing[1],
    paddingRight: theme_1.theme.spacing[2],
    paddingBottom: theme_1.theme.spacing[1],
    paddingLeft: theme_1.theme.spacing[2],
    color: design === 'light' ? color_1.Color.LIGHT_BLUE : color_1.Color.BLUE,
    fontWeight: design === 'light' ? theme_1.theme.font.weight.book : theme_1.theme.font.weight.bold,
    '&:not(:last-child)': {
        borderRight: theme_1.theme.border.style.thin,
    },
});
exports.comparatorStyles = comparatorStyles;
