"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputDetail = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const Flex_1 = require("../../../elements/Flex");
const useProfessionalView_1 = require("../hooks/useProfessionalView");
const InputDetail = ({ field, formatter = (value) => value, icon, label, permissionScope, placeholders, url, value, }) => {
    const { actor, isEditing, isMe, update } = (0, useProfessionalView_1.useProfessionalView)();
    if (!actor.can('read', permissionScope)) {
        return null;
    }
    if (isEditing(permissionScope)) {
        return ((0, jsx_runtime_1.jsx)(elements_1.Form.Input, { disabled: !actor.can('update', permissionScope), label: label, labelIcon: icon, onBlur: () => update(field)(formatter(value?.trim())), onChange: update(field), placeholder: placeholders.read, value: value }));
    }
    // Only render the row if we can show a value or an "Add a value..." placeholder:
    if (!value &&
        (!actor.can('update', permissionScope) || !placeholders.update)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { gap: 2, items: "center", styles: { overflowWrap: 'anywhere' }, children: [(0, jsx_runtime_1.jsx)(elements_1.Icon, { color: constants_1.Color.BLUE, name: icon, size: "sm" }), url && !isMe ? ((0, jsx_runtime_1.jsx)(elements_1.Link, { to: url, children: (0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, color: constants_1.Color.DARK_BLUE, size: 6, children: formatter(value) }) })) : ((0, jsx_runtime_1.jsx)(Flex_1.Flex, { gap: 1, children: (0, jsx_runtime_1.jsx)(elements_1.Text, { color: value ? constants_1.Color.BLACK : constants_1.Color.DARKEST_MEDIUM_GREY, size: 6, children: formatter(value) || `${placeholders.update}...` }) }))] }, icon));
};
exports.InputDetail = InputDetail;
