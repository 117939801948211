"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyLogo = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Icon_1 = require("./Icon");
const Row_1 = require("./Row");
const Text_1 = require("./Text");
const constants_1 = require("../constants");
const CompanyLogo = ({ displayName, logoUrl, showIcon, size, small, }) => {
    return logoUrl ? ((0, jsx_runtime_1.jsx)("img", { alt: displayName, height: size ?? (small ? 24 : 32), src: logoUrl })) : ((0, jsx_runtime_1.jsxs)(Row_1.Row, { alignItems: "center", gridGap: 2, lineHeight: 1.3, children: [showIcon && ((0, jsx_runtime_1.jsx)(Icon_1.Icon, { color: constants_1.Color.BLUE, name: "OfficeBuildingIcon", size: "sm" })), (0, jsx_runtime_1.jsx)(Text_1.Text, { bold: true, color: constants_1.Color.BLUE, my: 1, size: 6, children: displayName })] }));
};
exports.CompanyLogo = CompanyLogo;
