"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Raw = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const utils_1 = require("@bemlo/utils");
const Raw = ({ children }) => {
    const childrenWithLineBreaks = children
        .split(/\n/g)
        .map((paragraph, i) => [i > 0 && (0, jsx_runtime_1.jsx)("br", {}), paragraph.trim()].filter(utils_1.isTruthy));
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: childrenWithLineBreaks });
};
exports.Raw = Raw;
