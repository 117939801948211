"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComparatorInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ComparatorTrigger_1 = require("./ComparatorTrigger");
const PopoverCombobox_1 = require("../Menu/PopoverCombobox");
const ComparatorInput = ({ children, design = 'bold', singleSelect, ...props }) => {
    return ((0, jsx_runtime_1.jsx)(PopoverCombobox_1.PopoverCombobox, { trigger: (0, jsx_runtime_1.jsx)(ComparatorTrigger_1.ComparatorTrigger, { design: design, children: children }), type: singleSelect ? 'singleSelect' : 'multiSelect', ...props }));
};
exports.ComparatorInput = ComparatorInput;
