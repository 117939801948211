"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateDynamicShiftStatusFactory = void 0;
const locales_1 = require("../locales");
const translateDynamicShiftStatusFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('dynamicShiftStatus', locale);
    return (status) => {
        return t[status];
    };
};
exports.translateDynamicShiftStatusFactory = translateDynamicShiftStatusFactory;
