"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateSpecializationTitleFactory = exports.translateSpecializationEducationFactory = void 0;
const locales_1 = require("../locales");
const translateSpecializationEducationFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('specialization', locale);
    return (specialization) => {
        return t[specialization].education;
    };
};
exports.translateSpecializationEducationFactory = translateSpecializationEducationFactory;
const translateSpecializationTitleFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('specialization', locale);
    return (specialization, count = 1) => {
        // Note: casting to * is fine, because we have a fall back if real count doesn't exist
        return t[specialization].title[count] ?? t[specialization].title['*'];
    };
};
exports.translateSpecializationTitleFactory = translateSpecializationTitleFactory;
