"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Information = void 0;
const react_1 = require("react");
const jsx_runtime_1 = require("react/jsx-runtime");
const date_1 = require("@bemlo/date");
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const EditIcon_1 = require("../components/EditIcon");
const InputDetail_1 = require("../components/InputDetail");
const InputDetailDate_1 = require("../components/InputDetailDate");
const SectionHeading_1 = require("../components/SectionHeading");
const useProfessionalView_1 = require("../hooks/useProfessionalView");
const scopes_1 = require("../scopes");
const Information = () => {
    const { actor, data, isEditing, market } = (0, useProfessionalView_1.useProfessionalView)();
    const t = (0, translate_1.useTranslations)();
    const { email, hpr, personalIdentityNumber, phoneNumber, graduatedAt } = data;
    const emailScope = scopes_1.scopes.information.email();
    const phoneNumberScope = scopes_1.scopes.information.phoneNumber();
    const personalIdentityNumberScope = scopes_1.scopes.information.personalIdentityNumber();
    const hprScope = scopes_1.scopes.information.hpr();
    const graduatedAtScope = scopes_1.scopes.information.graduatedAt();
    const scope = scopes_1.scopes.information();
    if (!actor.can('read', scope)) {
        return null;
    }
    const items = [
        {
            field: 'email',
            icon: 'MailIcon',
            label: t.professional_view.information.email(),
            permissionScope: emailScope,
            placeholders: {
                read: t.professional_view.information.email_placeholder(),
                update: t.professional_view.information.add_an_email(),
            },
            url: email ? `mailto:${email}` : undefined,
            value: email,
        },
        {
            field: 'phoneNumber',
            icon: 'PhoneIcon',
            label: t.professional_view.information.phone_number(),
            permissionScope: phoneNumberScope,
            placeholders: {
                read: t.professional_view.information.phone_number_placeholder(),
                update: t.professional_view.information.add_a_phone_number(),
            },
            url: phoneNumber
                ? `tel:${phoneNumber.replace(/[^0-9+]/g, '')}`
                : undefined,
            value: phoneNumber,
        },
        market === enums_1.Market.SE && {
            field: 'personalIdentityNumber',
            icon: 'FingerPrintIcon',
            label: t.professional_view.information.personal_identity_number(),
            permissionScope: personalIdentityNumberScope,
            placeholders: {
                read: t.professional_view.information.personal_identity_number_placeholder(),
                update: t.professional_view.information.add_a_personal_identity_number(),
            },
            value: personalIdentityNumber,
        },
    ]
        .filter(utils_1.isTruthy)
        .filter((item) => actor.can('update', scope) || item.value);
    return ((0, jsx_runtime_1.jsxs)(elements_1.Paper, { styles: { flex: 'col', gap: 1, p: 3.5 }, children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { items: "start", justify: "between", styles: { mb: 1 }, children: [(0, jsx_runtime_1.jsx)(SectionHeading_1.SectionHeading, { children: t.professional_view.information.information() }), (0, jsx_runtime_1.jsx)(EditIcon_1.EditIcon, { permissionScope: scope })] }), (0, jsx_runtime_1.jsx)(elements_1.Flex, { col: true, gap: 3, children: items.length === 0 && !actor.can('update', scope) ? ((0, jsx_runtime_1.jsx)(elements_1.Div, { styles: { pb: 2, pt: 1 }, children: (0, jsx_runtime_1.jsx)(elements_1.Text, { color: constants_1.Color.DARK_GREY, size: 6, children: t.professional_view.information.no_information() }) })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: isEditing(scope) ? 3 : 2, children: [items.map((item) => ((0, react_1.createElement)(InputDetail_1.InputDetail, { ...item, key: item.field }))), (0, jsx_runtime_1.jsx)(InputDetailDate_1.InputDetailDate, { label: t.professional_view.information.authorized_since(), field: "graduatedAt", icon: "CalendarIcon", permissionScope: graduatedAtScope, placeholders: {
                                        read: t.professional_view.information.authorized_since_placeholder(),
                                        update: t.professional_view.information.add_authorization_year(),
                                    }, value: graduatedAt ? (0, date_1.fromUnixTime)(graduatedAt) : undefined })] }), actor.can('read', hprScope) && hpr && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(elements_1.HorizontalRule, {}), (0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 2.5, items: "center", children: [(0, jsx_runtime_1.jsx)(elements_1.Icon, { backgroundColor: constants_1.Color.LIGHTER_GREEN, color: constants_1.Color.GREEN, name: "CheckIcon", round: true, size: "lg" }), (0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 1, children: [(0, jsx_runtime_1.jsxs)(elements_1.Text, { bold: true, size: 6, children: [t.professional_view.base.authorized(), hpr.profession && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [" ", t.$profession(hpr.profession).toLowerCase()] }))] }), (0, jsx_runtime_1.jsxs)(elements_1.Text, { color: constants_1.Color.DARK_GREY, size: 6, children: [t.professional_view.information.hpr_number(), ' ', (0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, children: hpr.id })] })] })] })] }))] })) })] }));
};
exports.Information = Information;
