"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateCurrentEmploymentStatusFactory = void 0;
const locales_1 = require("../locales");
const translateCurrentEmploymentStatusFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('currentEmploymentStatus', locale);
    return (currentEmploymentStatus) => {
        return t[currentEmploymentStatus];
    };
};
exports.translateCurrentEmploymentStatusFactory = translateCurrentEmploymentStatusFactory;
