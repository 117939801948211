"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateOnCallTypeFactory = void 0;
const locales_1 = require("../locales");
const translateOnCallTypeFactory = (locale, length = 'long') => {
    if (length === 'long') {
        const t = (0, locales_1.getTranslation)('onCallType', locale);
        return (type) => {
            return t[type];
        };
    }
    if (length === 'short') {
        const t = (0, locales_1.getTranslation)('onCallTypeShort', locale);
        return (type) => {
            return t[type];
        };
    }
    const t = (0, locales_1.getTranslation)('onCallTypeAbbreviation', locale);
    return (type) => {
        return t[type];
    };
};
exports.translateOnCallTypeFactory = translateOnCallTypeFactory;
