"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Country = void 0;
var Country;
(function (Country) {
    Country["SWEDEN"] = "SWEDEN";
    Country["NORWAY"] = "NORWAY";
    Country["DENMARK"] = "DENMARK";
    Country["FINLAND"] = "FINLAND";
    Country["FRANCE"] = "FRANCE";
    Country["GERMANY"] = "GERMANY";
    Country["SPAIN"] = "SPAIN";
    Country["UNITED_KINGDOM"] = "UNITED_KINGDOM";
    Country["BELGIUM"] = "BELGIUM";
})(Country || (exports.Country = Country = {}));
