"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormatAcceptanceAvailabilities = void 0;
const date_1 = require("@bemlo/date");
const utils_1 = require("@bemlo/utils");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const constants_1 = require("../utils/constants");
const useFormatAcceptanceAvailabilities = (presentation) => {
    const t = (0, translate_1.useTranslations)();
    const weeks = (0, elements_1.useWeeksForRange)(presentation?.tender.startsAt ?? 0, presentation?.tender.endsAt ?? 0);
    return () => {
        if (!presentation) {
            return [];
        }
        const availabilitiesWeekMap = new Map();
        presentation.tender.tenderedAvailabilities.forEach((availability) => {
            const availabilityWeek = t.$date
                .unix(availability.period.startsAt)
                .isoWeek();
            availabilitiesWeekMap.set(availabilityWeek, availability);
        });
        return weeks
            .map((week) => {
            const availability = availabilitiesWeekMap.get(week.number);
            if (!availability || !availability.presentedAvailability) {
                const weekDaysInRange = week.days.filter((day) => day.withinRange);
                return {
                    key: week.key,
                    selected: false,
                    period: {
                        startsAt: weekDaysInRange[0]?.date.unix(),
                        endsAt: weekDaysInRange[weekDaysInRange.length - 1]?.date.unix(),
                    },
                    newScope: constants_1.EMPTY_SCOPE,
                    days: week.days.map((day) => ({
                        date: day.date,
                        includedInPeriod: day.withinRange,
                        selected: false,
                    })),
                };
            }
            const dayLookup = new Map(availability.days.map((day) => [
                date_1.dayjsEn.unix(day.date).startOf('day').unix(),
                day,
            ]));
            return {
                key: availability.id,
                remainingScope: availability.remainingScope,
                selected: !!availability.presentedAvailability,
                period: availability.period,
                newScope: availability.presentedAvailability.scope ?? constants_1.EMPTY_SCOPE,
                tenderedAvailability: {
                    id: availability.id,
                    scope: availability.scope,
                },
                presentedAvailability: availability.presentedAvailability,
                acceptedAvailability: availability.presentedAvailability.acceptedAvailability,
                days: week.days.map((day) => {
                    const availabilityDay = dayLookup.get(day.date.startOf('day').unix());
                    return {
                        date: day.date,
                        includedInPeriod: day.withinRange,
                        selected: !!availabilityDay?.presentedDay,
                        tenderedDayId: availabilityDay?.id,
                        presentedDayId: availabilityDay?.presentedDay?.id,
                        acceptedDayId: availabilityDay?.presentedDay?.acceptedDay?.id,
                    };
                }),
            };
        })
            .filter(utils_1.isTruthy);
    };
};
exports.useFormatAcceptanceAvailabilities = useFormatAcceptanceAvailabilities;
