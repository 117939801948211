"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VacanciesTitle = exports.useCellTheme = exports.useShiftPresets = exports.convertUIDraftShiftToShift = exports.useUIShiftsFromSlots = exports.Schedule = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Grid_1 = require("./components/Grid");
const Shift_1 = require("./components/Shift");
const useCellTheme_1 = require("./components/useCellTheme");
Object.defineProperty(exports, "useCellTheme", { enumerable: true, get: function () { return useCellTheme_1.useCellTheme; } });
const VacanciesTitle_1 = require("./components/VacanciesTitle");
Object.defineProperty(exports, "VacanciesTitle", { enumerable: true, get: function () { return VacanciesTitle_1.VacanciesTitle; } });
const WeekTitle_1 = require("./components/WeekTitle");
const consts_1 = require("./consts");
const Divider_1 = require("./rows/Divider");
const FixedVacancies_1 = require("./rows/FixedVacancies");
const Header_1 = require("./rows/Header");
const LoadMore_1 = require("./rows/LoadMore");
const NewShiftType_1 = require("./rows/NewShiftType");
const Presentation_1 = require("./rows/Presentation");
const Resource_1 = require("./rows/Resource");
const ScheduleResource_1 = require("./rows/ScheduleResource");
const ShiftDemand_1 = require("./rows/ShiftDemand");
const Vacancies_1 = require("./rows/Vacancies");
const useSchedule_1 = require("./useSchedule");
const useShiftPresets_1 = require("./useShiftPresets");
Object.defineProperty(exports, "useShiftPresets", { enumerable: true, get: function () { return useShiftPresets_1.useShiftPresets; } });
const useUIShiftsFromSlots_1 = require("./useUIShiftsFromSlots");
Object.defineProperty(exports, "useUIShiftsFromSlots", { enumerable: true, get: function () { return useUIShiftsFromSlots_1.useUIShiftsFromSlots; } });
const get_days_in_range_1 = require("./utils/get-days-in-range");
const ui_draft_shift_to_shift_1 = require("./utils/ui-draft-shift-to-shift");
Object.defineProperty(exports, "convertUIDraftShiftToShift", { enumerable: true, get: function () { return ui_draft_shift_to_shift_1.convertUIDraftShiftToShift; } });
const elements_1 = require("../../../elements");
const Schedule = (props) => {
    const [days] = (0, get_days_in_range_1.useDaysInRange)(props.startsAt, props.endsAt, props.country);
    return ((0, jsx_runtime_1.jsx)(useSchedule_1.ScheduleProvider, { startsAt: props.startsAt, endsAt: props.endsAt, zoom: props.zoom ?? 'week', headerColumnWidth: props.headerColumnWidth ?? consts_1.DEFAULT_HEADER_COLUMN_WIDTH, days: days, enableEditing: props.enableEditing, enableSelectShifts: props.enableSelectShifts, enableSelectDays: props.enableSelectDays, compactBookings: props.compactBookings, shiftPopover: props.shiftPopover, availabilityPopover: props.availabilityPopover, shifts: props.shifts, availability: props.availability, workingTimeDirectiveRuleSet: props.workingTimeDirectiveRuleSet, children: (0, jsx_runtime_1.jsxs)(Grid_1.Grid, { children: [(0, jsx_runtime_1.jsx)(Header_1.Header, { title: props.title }), (0, jsx_runtime_1.jsx)("tbody", { children: props.children })] }) }));
};
exports.Schedule = Schedule;
const Vertical = (props) => {
    const [, weeks] = (0, get_days_in_range_1.useDaysInRange)(props.startsAt, props.endsAt, props.country);
    return ((0, jsx_runtime_1.jsx)(elements_1.Div, { children: weeks.map((week) => ((0, jsx_runtime_1.jsx)(useSchedule_1.ScheduleProvider, { startsAt: props.startsAt, endsAt: props.endsAt, zoom: props.zoom ?? 'week', headerColumnWidth: props.headerColumnWidth ?? consts_1.DEFAULT_HEADER_COLUMN_WIDTH, days: week.days, enableEditing: props.enableEditing, enableSelectDays: props.enableSelectDays, enableSelectShifts: props.enableSelectShifts, compactBookings: props.compactBookings, shiftPopover: props.shiftPopover, availabilityPopover: props.availabilityPopover, shifts: props.shifts, availability: props.availability, workingTimeDirectiveRuleSet: props.workingTimeDirectiveRuleSet, children: (0, jsx_runtime_1.jsxs)(Grid_1.Grid, { children: [(0, jsx_runtime_1.jsx)(Header_1.Header, { title: (0, jsx_runtime_1.jsx)(WeekTitle_1.WeekTitle, { week: week.number }) }), (0, jsx_runtime_1.jsx)("tbody", { children: props.children })] }) }, week.key))) }));
};
Schedule.Vertical = Vertical;
Schedule.ScheduleResource = ScheduleResource_1.ScheduleResource;
Schedule.Divider = Divider_1.Divider;
Schedule.NewShiftType = NewShiftType_1.NewShiftType;
Schedule.Vacancies = Vacancies_1.Vacancies;
Schedule.ShiftDemands = ShiftDemand_1.ShiftDemands;
Schedule.FixedVacancies = FixedVacancies_1.FixedVacancies;
Schedule.Presentation = Presentation_1.Presentation;
Schedule.Shift = Shift_1.Shift;
Schedule.Resource = Resource_1.Resource;
Schedule.FetchMore = LoadMore_1.FetchMore;
