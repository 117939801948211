"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAcceptanceDayCellStatus = void 0;
const utils_1 = require("./utils");
const utils_2 = require("../presentation/utils");
const utils_3 = require("../tender/utils");
const constants_1 = require("../utils/constants");
const getAcceptanceDayCellStatus = (day, isWeekSelected, viewState) => {
    const dayTendered = (0, utils_3.isDayTendered)(day);
    const dayPresented = (0, utils_2.isDayPresented)(day);
    const dayAccepted = (0, utils_1.isDayAccepted)(day);
    const isDaySelectable = dayPresented && !isWeekSelected;
    if (viewState === 'view') {
        if (dayAccepted) {
            return constants_1.ACCEPTED_DAY_STYLE;
        }
        if (dayPresented) {
            return constants_1.REJECTED_DAY_STYLE;
        }
        if (dayTendered) {
            return constants_1.VIEW_TENDERED_DAY_STYLE;
        }
        return constants_1.DISABLED_DAY_STYLE;
    }
    if (dayPresented && day.selected) {
        return {
            ...constants_1.ACCEPTED_DAY_STYLE,
            isDaySelectable,
        };
    }
    if (dayPresented) {
        return {
            ...constants_1.REJECTED_DAY_STYLE,
            isDaySelectable,
        };
    }
    if (dayTendered) {
        return {
            ...constants_1.VIEW_TENDERED_DAY_STYLE,
            isDaySelectable,
        };
    }
    return constants_1.DISABLED_DAY_STYLE;
};
exports.getAcceptanceDayCellStatus = getAcceptanceDayCellStatus;
