"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeReportStatus = void 0;
var TimeReportStatus;
(function (TimeReportStatus) {
    TimeReportStatus["CREATED"] = "CREATED";
    TimeReportStatus["CLOCKED_IN"] = "CLOCKED_IN";
    TimeReportStatus["REPORTED"] = "REPORTED";
    TimeReportStatus["RETURNED"] = "RETURNED";
    TimeReportStatus["ATTESTED"] = "ATTESTED";
    TimeReportStatus["ARCHIVED"] = "ARCHIVED";
})(TimeReportStatus || (exports.TimeReportStatus = TimeReportStatus = {}));
