"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Municipality = exports.UnitedKingdomMunicipality = exports.SwedenMunicipality = exports.NorwayMunicipality = void 0;
const region_municipality_1 = require("./data/region-municipality");
exports.NorwayMunicipality = Object.assign({}, ...Object.values(region_municipality_1.NORWAY_REGION_MUNICIPALITY_HIERARCHY).map(({ municipalities }) => municipalities));
exports.SwedenMunicipality = Object.assign({}, ...Object.values(region_municipality_1.SWEDEN_REGION_MUNICIPALITY_HIERARCHY).map(({ municipalities }) => municipalities));
exports.UnitedKingdomMunicipality = Object.assign({}, ...Object.values(region_municipality_1.UNITED_KINGDOM_REGION_MUNICIPALITY_HIERARCHY).map(({ municipalities }) => municipalities));
exports.Municipality = {
    ...exports.NorwayMunicipality,
    ...exports.SwedenMunicipality,
    ...exports.UnitedKingdomMunicipality,
};
