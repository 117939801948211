"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInView = void 0;
const react_1 = require("react");
const useInView = () => {
    const ref = (0, react_1.useRef)(null);
    const [inView, setInView] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setInView(entry.isIntersecting);
        });
        const element = ref.current;
        if (element) {
            observer.observe(element);
            return () => {
                observer.unobserve(element);
            };
        }
    }, [ref.current]);
    return [ref, inView];
};
exports.useInView = useInView;
