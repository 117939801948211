"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.whichDepartmentToWorkInAlternative = void 0;
const enums_1 = require("@bemlo/enums");
const $ = enums_1.WhichDepartmentToWorkInAlternative;
const jobb = (preposition, title) => ({ preposition, title });
exports.whichDepartmentToWorkInAlternative = {
    [$.AKUTSJUKVARD]: jobb('på', 'Akuttmottak'),
    [$.AMBULANS]: jobb('i', 'Ambulanse'),
    [$.ASIH]: jobb('innen', 'Avansert hjemmesykepleie'),
    [$.BARN_OCH_UNGDOM]: jobb('på', 'Barn- og ungdomsmottak'),
    [$.BARNMEDICIN]: jobb('på', 'Barneavdeling'),
    [$.BARNONKOLOGEN]: jobb('på', 'Barnekreftavdeling'),
    [$.BAVA]: jobb('på', 'Akuttmottak, Barn'),
    [$.CENTER]: jobb('på', 'Mottak'),
    [$.DEMENS]: jobb('på', 'Demensbolig'),
    [$.DEPARTMENT]: jobb('på', 'Avdeling'),
    [$.DIALYSMOTTAGNING]: jobb('på', 'Dialysemottak'),
    [$.FORLOSSNING]: jobb('på', 'Fødestue'),
    [$.GASTROAVDELNING]: jobb('på', 'Gastroavdeling'),
    [$.GERIATRIK]: jobb('på', 'Geriatrisk avdeling'),
    [$.GYNEKOLOGDEPARTMENT]: jobb('på', 'Gynekologisk avdeling'),
    [$.HALSOCENTRAL]: jobb('på', 'Helsehus'),
    [$.HEMSJUKVARD]: jobb('i', 'Hjemmetjenesten'),
    [$.HIA]: jobb('på', 'Hjertemedisinsk Intensiv'),
    [$.HJARTAVDELNING]: jobb('på', 'Hjerteavdeling'),
    [$.INFEKTION]: jobb('på', 'Infeksjonsavdeling'),
    [$.IVA]: jobb('på', 'Intensivavdeling'),
    [$.JOURCENTRAL]: jobb('på', 'Legevakt'),
    [$.KIRURGI]: jobb('på', 'Kirurgisk avdeling'),
    [$.KVINNOKLINIK]: jobb('på', 'Gynekologisk mottak'),
    [$.LUNGAVDELNING]: jobb('på', 'Lungeavdeling'),
    [$.MAMMOGRAFI]: jobb('på', 'Mammografi'),
    [$.MAVA]: jobb('på', 'Medisinsk akuttmottak'),
    [$.MEDICIN]: jobb('på', 'Medisinsk avdeling'),
    [$.MUNICIPAL]: jobb('', 'Kommunalt'),
    [$.NARAKUT]: jobb('på', 'Lokalt akuttmottak'),
    [$.NEONATAL]: jobb('på', 'Nyfødtavdeling'),
    [$.NEUROLOGI]: jobb('på', 'Nevrologisk avdeling'),
    [$.NJURAVDELNING]: jobb('på', 'Nyreavdeling'),
    [$.ONH_MOTTAGNING]: jobb('ved', 'Øre-nese-hals'),
    [$.ONKOLOGEN]: jobb('på', 'Onkologisk avdeling'),
    [$.OPEN_FOR_ANYTHING]: jobb('i', 'Åpen for alt'),
    [$.OPERATION]: jobb('på', 'Operasjon'),
    [$.ORTOPEDEN]: jobb('ved', 'Ortopedi'),
    [$.POSTOPERATION]: jobb('på', 'Postoperasjon'),
    [$.PSYKIATRI]: jobb('innen', 'Psykiatri'),
    [$.RADIOLOGEN]: jobb('på', 'Radiologisk avdeling'),
    [$.REHABILITERING]: jobb('innen', 'Rehabilitering'),
    [$.REUMATOLOGI]: jobb('på', 'Reumatologisk avdeling'),
    [$.SABO]: jobb('på', 'Sykehjem'),
    [$.SJUKSTUGA]: jobb('på', 'Sykestue'),
    [$.SKOLSJUKVARD]: jobb('på', 'Skolehelsetjenesten'),
    [$.TELEFONRADGIVNING]: jobb('innen', 'Telefonrådgivning'),
    [$.THORAXKIRURGEN]: jobb('på', 'Thoraxkirurgisk avdeling'),
    [$.VACCINERING]: jobb('innen', 'Vaksinering'),
    [$.VARDCENTRAL]: jobb('på', 'Legekontor'),
};
