"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.availability_schedule = exports.jobs = exports.timesheets = exports.shifts = exports.availability = exports.unit_relation = exports.professional_view = exports.common = exports.chat_view = void 0;
const enums_1 = require("@bemlo/enums");
const i18n_1 = require("@bemlo/i18n");
const utils_1 = require("@bemlo/utils");
const list_1 = require("../list");
const LOCALE = enums_1.Locale.SV;
const $country = (0, i18n_1.translateCountryFactory)(LOCALE);
const $licenseStatus = (0, i18n_1.translateUsingBothKeyAndValue)(enums_1.LicenseStatus, {
    [enums_1.LicenseStatus.NO_LICENSE]: 'Saknar legitimation',
    [enums_1.LicenseStatus.HAS_APPLIED]: 'Har sökt legitimation',
    [enums_1.LicenseStatus.HAS_LICENSE]: 'Har legitimation',
});
const $list = (0, list_1.listFactory)('och');
const $market = (market) => $country((0, utils_1.countryForMarket)(market));
const $profession = (0, i18n_1.translateProfessionFactory)(LOCALE);
exports.chat_view = {
    conversation_other: () => 'Konversationer',
    internal: () => 'Intern',
    internal_conversation: () => 'Intern konversation',
    join: () => 'Gå med',
    new: () => 'Ny',
    new_conversation: () => 'Ny konversation',
    no_conversation_heading: (isBrowsable) => isBrowsable ? 'Ingen konversation vald' : 'Ingen konversation',
    no_conversation_paragraph: (isBrowsable, isCreatable) => ({
        'false,false': 'Det finns ingen konversation här ännu.',
        'false,true': 'Starta en ny konversation för att komma igång.',
        'true,false': 'Klicka på en konversation för att komma igång.',
        'true,true': 'Välj en befintlig konversation eller starta en ny.',
    })[`${isBrowsable},${isCreatable}`],
    no_subject: () => 'inget ämne',
    send: () => 'Skicka',
    start_conversation: () => 'Starta konversation',
    write_a_message: () => 'Skriv ett meddelande...',
};
exports.common = {
    accepted_scope: () => 'Accepterad omfattning',
    booked_scope: () => 'Bokad omfattning',
    close: () => 'Stäng',
    minutes: () => 'Minuter',
    percent_sign: (value) => `${value} %`,
    period: () => 'Period',
    presented_scope: () => 'Presenterad omfattning',
    remaining_scope: () => 'Återstående omfattning',
    scope: () => 'Omfattning',
    search: () => 'Sök',
    tendered_scope: () => 'Avropad omfattning',
    this_month: () => 'Denna månad',
    this_week: () => 'Denna vecka',
    time_of_day: () => 'Tid på dygnet',
    today: () => 'Idag',
    total: () => 'Totalt',
};
exports.professional_view = (() => {
    const base = {
        authorized: () => 'Auktoriserad',
        cancel: () => 'Avbryt',
        drivers_license: () => 'Körkort',
        last_updated: () => 'Senast uppdaterad',
        save: () => 'Spara',
    };
    const biography = {
        about_me: () => 'Om mig',
        about_the_candidate: () => 'Om kandidaten',
        and_n_more: (n) => `och ${n} till`,
        applied_for_license: () => 'Har ansökt om legitimation',
        biography: () => 'Biografi',
        country: () => 'Land',
        current_employment: () => 'Nuvarande anställning',
        currently_employed: () => 'För närvarande anställd',
        has_applied_for_license_in: (markets, hasAnyLicense) => `har ansökt ${hasAnyLicense ? 'om legitimation ' : ''}i ${$list(markets.map($market))}`,
        home_municipality: () => 'Hemkommun',
        journal_system_other: () => 'Journalsystem',
        journal_systems: () => 'Journalsystem',
        licensed: (profession) => profession
            ? `Legitimerad ${$profession(profession, 1).toLowerCase()}`
            : 'Har legitimation',
        licensed_in: (markets) => `legitimerad i ${$list(markets.map($market))}`,
        n_years_of_experience: (years) => `${years}+ års erfarenhet`,
        name: () => 'Namn',
        no_biography: () => 'ingen beskrivning',
        norwegian_license: () => 'Norsk legitimation',
        profession: () => 'Yrke',
        send_message: () => 'Meddelande',
        specialization: () => 'Specialisering',
        swedish_license: () => 'Svensk legitimation',
        write_a_biography: () => 'Skriv en kort biografi om dig själv...',
        years_of_experience: () => 'Antal års erfarenhet',
    };
    const candidate_type = (type) => {
        const translations = {
            [enums_1.CandidateType.BOOKED]: 'Bokad kandidat',
            [enums_1.CandidateType.MANAGED]: 'Inlagd',
            [enums_1.CandidateType.MIRRORED]: 'Kontakt',
            [enums_1.CandidateType.SOVEREIGN]: 'Profil',
            [enums_1.CandidateType.STATIC]: 'ATLAS',
            [enums_1.CandidateType.SYNCED]: 'CRM',
            [enums_1.CandidateType.EMPLOYED]: 'Anställd',
        };
        return translations[type];
    };
    const candidate_type_action = (type) => {
        const translations = {
            [enums_1.CandidateType.BOOKED]: 'bokad',
            [enums_1.CandidateType.MANAGED]: 'inlagd',
            [enums_1.CandidateType.MIRRORED]: 'upplåst',
            [enums_1.CandidateType.SOVEREIGN]: '',
            [enums_1.CandidateType.STATIC]: 'upplåst',
            [enums_1.CandidateType.SYNCED]: 'synkad',
            [enums_1.CandidateType.EMPLOYED]: 'tillagd',
        };
        return translations[type];
    };
    const contact_requests = {
        contact_request_other: () => 'Kontaktförfrågningar',
        n_new: (count) => `${count} ny${count === 1 ? '' : 'a'}!`,
    };
    const edit = {
        you_are_editing_professional: (name) => `Du redigerar ${name ?? 'din profil'}.`,
    };
    const file_uploads = {
        documents: () => 'Dokument',
        fetched_at: (date) => `Hämtad ${date}`,
        upload: () => 'Ladda upp...',
        upload_document: () => 'Nytt dokument',
        uploaded_at: (date) => `Uppladdad ${date}`,
    };
    const information = {
        add_a_personal_identity_number: () => 'Lägg till personnummer',
        add_a_phone_number: () => 'Lägg till telefonnummer',
        add_an_email: () => 'Lägg till e-postadress',
        add_authorization_year: () => 'Lägg till legitimeringsår',
        authorized_since: () => 'Legitimerad sedan',
        authorized_since_placeholder: () => 'Välj år...',
        email: () => 'E-post',
        email_placeholder: () => 'T.ex. namn@foretag.se',
        hpr_number: () => 'HPR-nummer',
        information: () => 'Information',
        no_information: () => 'Det finns ingen information att visa.',
        personal_identity_number: () => 'Personnummer',
        personal_identity_number_placeholder: () => 'YYYYMMDD-XXXX',
        phone_number: () => 'Telefonnummer',
        phone_number_placeholder: () => 'T.ex. +46 8 123 456 78',
    };
    const interests = {
        bemlo_job_matcher: () => 'Bemlo Job Matcher',
        candidate_interests: () => 'Kandidatens preferenser',
        country_other: () => 'Länder',
        department_other: () => 'Avdelningar',
        earliest_start_date: () => 'Tidigast startdatum',
        extent: () => 'Omfattning',
        interests: () => 'Intressen',
        job_type: (jobType) => {
            const translations = {
                [enums_1.JobType.IRREGULAR_PARTTIME]: 'Ströpass',
                [enums_1.JobType.JOUR]: 'Jourer',
                [enums_1.JobType.PERMANENT]: 'Tillsvidareanställningar',
                [enums_1.JobType.STAFFING]: 'Bemanningsjobb',
                [enums_1.JobType.TEMPORARY]: 'Vikariat',
            };
            return translations[jobType];
        },
        job_type_other: () => 'Typ av anställning',
        make_my_contact_information_visible_to_employers: () => 'Gör min kontaktinformation synlig för arbetsgivare',
        municipality_other: () => 'Kommuner',
        open_for_work_from: () => 'Öppen för arbete från',
        open_to_work_from: (date) => `Öppen för jobb fr.o.m. ${date}`,
        region_other: () => 'Regioner',
        subscribe_to_matching_jobs: () => 'Prenumerera på matchande jobb',
        we_will_notify_you_once_a_day: () => 'Vi kommer att skicka ut notifikationer en gång om dagen',
        what_jobs_are_you_interested_in: () => 'Vilka slags jobb är du intresserad av?',
        work_form_other: () => 'Anställningsformer',
        you_may_be_contacted_regarding_job_opportunities: () => 'Du kan kontaktas angående jobbmöjligheter',
    };
    const license_status = $licenseStatus;
    const profile_progress = {
        add_authorization: () => 'Lägg till legitimation',
        invite_a_reference: () => 'Bjud in referenser',
        maximize_your_chances: () => 'Maximera dina chanser att hitta ditt drömjobb.',
        upload_cv: () => 'Ladda upp CV',
        upload_license: () => 'Ladda upp legitimation',
        your_profile_is_n_percent_done: (percent) => `Din profil är ${percent}% färdig`,
        your_profile_looks_empty: () => 'Din profil ser tom ut',
    };
    const references = {
        add_reference: () => 'Lägg till referens',
        added: () => 'Tillagd',
        added_at: (when) => `Tillagd ${when}`,
        n_attachments: (attachments) => `${attachments} bilag${attachments === 1 ? 'a' : 'or'}`,
        n_questions_answered: (count) => `${count} ${count === 1 ? 'fråga besvarad' : 'frågor besvarade'}`,
        no_references: () => 'Det finns inga referenser att visa.',
        no_references_added: () => 'Inga referenser har lagts till än.',
        references: () => 'Referenser',
        reported_at: (when, isManaged) => `Rapporterades ${isManaged ? '' : 'av kandidaten '}${when}`,
        waiting_for_response: () => 'Väntar svar...',
    };
    const summary_section = {
        locked: () => 'Låst',
    };
    const vacancies = {
        based_on_the_candidates_profile: () => 'Baserat på kandidatens profil',
        based_on_your_profile: () => 'Baserat på din profil',
        jobs_for_you: () => 'Jobb för dig',
        matching_vacancies: () => 'Matchande jobb',
        n_percent_match: (percent) => `${percent}% match`,
        no_title: () => 'ingen titel',
        region_other: () => 'Regioner',
        see_n_matching_jobs: (count) => `Se ${count ?? 'alla'} matchande jobb`,
        show_more: () => 'Visa mer',
    };
    return {
        base,
        biography,
        candidate_type,
        candidate_type_action,
        contact_requests,
        edit,
        file_uploads,
        information,
        interests,
        license_status,
        profile_progress,
        references,
        summary_section,
        vacancies,
    };
})();
const unit_relation = (type) => {
    const translations = {
        [enums_1.UnitRelation.HOME_UNIT]: 'Hemenhet',
        [enums_1.UnitRelation.CROSS_UNIT]: 'Annan enhet',
        [enums_1.UnitRelation.CROSS_ORG]: 'Annan organisation',
    };
    return translations[type];
};
exports.unit_relation = unit_relation;
exports.availability = {
    available: () => 'Tillgänglig',
    blocked: () => 'Blockerad',
    non_preferred: () => 'Ej föredragen',
    scheduled: () => 'Schemalagd',
    unavailable: () => 'Otillgänglig',
    vacation: () => 'Semester',
};
exports.shifts = {
    absent: () => 'Frånvarande',
    accepted: () => 'Accepterad',
    accepting: () => 'Accepterar',
    add_many: () => 'Skapa många pass',
    add_many_description: (periodicity, startDate) => `Skapa pass ${periodicity === 1
        ? 'varje vecka'
        : periodicity === 2
            ? 'varannan vecka'
            : `var ${periodicity}e vecka`} från ${startDate}`,
    add_many_period_start: () => 'Periodens start',
    add_many_periodicity: () => 'Periodicitet',
    add_many_periodicity_placeholder: () => 'T.ex. 2 för varannan vecka',
    applied: () => 'Ansökt',
    archived: () => 'Arkiverad',
    available: () => 'Tillgänglig',
    blocked: () => 'Blockerad',
    book: () => 'Boka',
    booked: () => 'Bokad',
    cancelled: () => 'Avbokad',
    day: () => 'Dag',
    draft: () => 'Utkast',
    make_fixed: () => 'Gör fast',
    new: () => 'Ny',
    new_row: () => 'Ny rad',
    night: () => 'Natt',
    no_shifts: () => 'Inga pass',
    not_published: () => 'Inte publicerad',
    occupied: () => 'Upptagen',
    presented: () => 'Presenterad',
    published: () => 'Publicerad',
    reject: () => 'Avböj',
    rejected: () => 'Avböjd',
    rejecting: () => 'Avböjer',
    remove_fixed: () => 'Ta bort fast',
    replace: () => 'Ersätt',
    replacing: () => 'Ersätter',
    requires_meds_training: () => 'Requires meds training',
    row_n: (n) => `Rad ${n}`,
    selected: () => 'Vald',
    shift_one: () => 'Pass',
    shift_other: () => 'Pass',
    suggested: () => 'Föreslagen',
    template: () => 'Mall',
    tendered: () => 'Avropad',
    twilight: () => 'Kväll',
    unavailable: () => 'Ej tillgänglig',
    unselected: () => 'Ej vald',
    urgent: () => 'Akut',
    vacant_one: () => 'Vakant',
    vacant_other: () => 'Vakanta',
};
exports.timesheets = {
    attested: () => 'Attesterad',
    break_time: () => 'Rast',
    consultant_manager: () => 'Konsultchef',
    end_time: () => 'Sluttid',
    reported: () => 'Rapporterade',
    resource: () => 'Resurs',
    returned: () => 'Returnerade',
    start_time: () => 'Starttid',
    time_report_log: () => 'Tidsrapport-logg',
    unit_manager: () => 'Enhetschef',
    updated: () => 'Uppdaterad',
};
exports.jobs = {
    apply_by: () => 'Ansök senast',
    apply_directly_on_the_website: () => 'Ansök direkt på Bemlo',
    estimated_salary: () => 'Uppskattad lön',
    explore_similar_jobs: () => 'Utforska liknande jobb',
    full_time_description: () => 'Uppdraget är på 100%',
    gathered_best_jobs_for_you_description: () => 'Vi har samlat bemanningsjobb från 30+ bemanningsbolag, så att du enkelt kan hitta det som passar dig bäst.',
    irregular_part_time_description: () => 'Uppdraget handlar om enstaka pass då och då',
    job_location: (municipality) => `Bemanningsuppdrag i ${municipality}`,
    logo_of_employer: () => 'Arbetsgivarens logotyp',
    match: () => 'Match',
    new: () => 'Ny!',
    no_description: () => 'Ingen beskrivning',
    open_map: () => 'Öppna karta',
    published_at_date: (date) => `Publicerad ${date}`,
    regular_part_time_description: () => 'Uppdraget är på mellan 20-80%',
    the_job_is_located_in_municipality: (municipality) => `Uppdraget utförs i ${municipality}`,
    week_number: (week) => `Vecka ${week}`,
    your_workplace: () => 'Din arbetsplats',
};
exports.availability_schedule = {
    schema_error_scope_must_be_greater_than_or_equal_to: (scope) => `Omfattning måste vara större än eller lika med ${scope}.`,
    schema_error_scope_must_be_less_than_or_equal_to: (scope) => `Omfattning måste vara mindre än eller lika med ${scope}.`,
    schema_error_select_at_least_one_day: () => 'Välj åtminstone en dag.',
    schema_error_select_at_least_one_shift_hours_type: () => 'Välj åtminstone en skifttyp.',
};
