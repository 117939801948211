"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatRange = void 0;
const formatRange = (_option, dayjsClass, dayjsFactory) => {
    dayjsClass.prototype.formatRange = function (toDate) {
        const currentDate = dayjsFactory();
        const currentYear = currentDate.year();
        let format = 'll'; // E.g. 7 Jun 2023
        if (currentYear === this.year() && currentYear === toDate.year()) {
            format = this.$locale().custom.monthDay; // E.g. 7 Jun
        }
        return this.format(format) + ' – ' + toDate.format(format);
    };
};
exports.formatRange = formatRange;
