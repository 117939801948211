"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listFactory = void 0;
/**
 * @example
 * ```ts
 * const $list = listFactory('and')
 * $list(['foo', 'bar', 'baz']) // => 'foo, bar and baz'
 * ```
 */
const listFactory = (conjunction) => {
    return (items) => {
        return items
            .map((item, i) => {
            return `${i === 0 ? '' : i === items.length - 1 ? ` ${conjunction} ` : ', '}${item}`;
        })
            .join('');
    };
};
exports.listFactory = listFactory;
