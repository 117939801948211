"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Column = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Box_1 = require("./Box");
/**
 * @deprecated use <Div /> instead
 */
const Column = (props) => {
    return ((0, jsx_runtime_1.jsx)(Box_1.Box, { flexDirection: props.reverse ? 'column-reverse' : 'column', ...props, children: props.children }));
};
exports.Column = Column;
