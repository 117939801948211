"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useValidate = void 0;
const translate_1 = require("../translate");
const useValidate = (schema, required, value) => {
    const t = (0, translate_1.useTranslation)();
    const parsed = schema?.safeParse(value);
    if (!parsed || parsed.success) {
        if (!required) {
            return;
        }
        if ((Array.isArray(value) && value.length) || value === 0 || value) {
            return;
        }
        return t.required();
    }
    return parsed?.error.issues[0].message ?? t.invalid_value();
};
exports.useValidate = useValidate;
