"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toUnixTime = exports.fromUnixTime = void 0;
const fromUnixTime = (unixTime) => {
    return new Date(unixTime * 1000);
};
exports.fromUnixTime = fromUnixTime;
const toUnixTime = (date = new Date()) => {
    return Math.floor(date.getTime() / 1000);
};
exports.toUnixTime = toUnixTime;
