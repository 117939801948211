"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHolidays = void 0;
const date_1 = require("@bemlo/date");
const map_1 = require("./map");
const getHolidays = (country, startDate, endDate) => {
    const holidays = map_1.COUNTRY_HOLIDAYS_MAP[country];
    if (!holidays) {
        return [];
    }
    const util = holidays[0]();
    const data = holidays[1];
    // Get unique years
    const years = [startDate.year(), endDate.year()].filter((value, index, self) => self.indexOf(value) === index);
    return years.flatMap((year) => {
        const holidaysInYear = util.getHolidays(year);
        return holidaysInYear
            .filter((holiday) => data.all.includes(holiday.name))
            .map(({ date, name }) => ({
            name,
            date: (0, date_1.dayjsEn)(date),
            isPublic: data.public.includes(name),
        }))
            .filter(({ date }) => {
            const isAfterStartDate = date.isAfter(startDate, 'day') || date.isSame(startDate, 'day');
            const isBeforeEndDate = date.isBefore(endDate, 'day') || date.isSame(endDate, 'day');
            return isAfterStartDate && isBeforeEndDate;
        });
    });
};
exports.getHolidays = getHolidays;
