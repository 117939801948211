"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profession = void 0;
const enums_1 = require("@bemlo/enums");
const profession_1 = require("../en/profession");
exports.profession = {
    ...profession_1.profession,
    [enums_1.Profession.DOCTOR]: {
        short: 'LÄÄK',
        title: {
            '*': 'Lääkärit',
            '1': 'Lääkäri',
        },
    },
    [enums_1.Profession.NURSE]: {
        short: 'SH',
        title: {
            '*': 'Sairaanhoitajat',
            '1': 'Sairaanhoitaja',
        },
    },
};
