"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetHoursPerWeek = void 0;
const schedule_1 = require("@bemlo/schedule");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const useGetHoursPerWeek = (startDate, endDate) => {
    const t = (0, translate_1.useTranslations)();
    const weeks = (0, elements_1.useWeeksForRange)(t.$date.unix(startDate).startOf('week').unix(), t.$date.unix(endDate).endOf('week').unix());
    const getHoursPerWeek = (shifts, timeSpan) => {
        const startDate = t.$date.unix(timeSpan.startDate);
        const endDate = t.$date.unix(timeSpan.endDate);
        return weeks.map((week) => {
            if (week.days.every((day) => day.date.isBefore(startDate) || day.date.isAfter(endDate))) {
                return null;
            }
            const daysInWeek = week.days.map((day) => day.dateFormatted);
            return (0, schedule_1.shiftsToWorkHours)(shifts.filter(({ date }) => daysInWeek.includes(date)));
        });
    };
    return [weeks, getHoursPerWeek];
};
exports.useGetHoursPerWeek = useGetHoursPerWeek;
