"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areAllShiftsRequiredHoursApart = exports.areShiftsRequiredHoursApart = exports.hoursBetweenShifts = void 0;
const lodash_1 = require("lodash");
const shift_to_dayjs_1 = require("../../shift-to-dayjs");
const hoursBetweenShifts = (firstShift, secondShift) => secondShift.startDateTime.diff(firstShift.endDateTime, 'hour');
exports.hoursBetweenShifts = hoursBetweenShifts;
const areShiftsRequiredHoursApart = (first, second, minHours = 11) => {
    return ((0, exports.hoursBetweenShifts)((0, shift_to_dayjs_1.shiftToDayjs)(first), (0, shift_to_dayjs_1.shiftToDayjs)(second)) >= minHours);
};
exports.areShiftsRequiredHoursApart = areShiftsRequiredHoursApart;
const areAllShiftsRequiredHoursApart = (shifts, minHours = 11) => {
    const orderedShifts = (0, lodash_1.chain)(shifts)
        .map((shift) => ({
        ...shift,
        dayjs: (0, shift_to_dayjs_1.shiftToDayjs)(shift),
    }))
        // Sort by start time
        .orderBy((shift) => shift.dayjs.startDateTime.unix())
        .value();
    return orderedShifts.every((_, index) => {
        if (index === 0)
            return true;
        return ((0, exports.hoursBetweenShifts)(orderedShifts[index - 1].dayjs, orderedShifts[index].dayjs) >= minHours);
    });
};
exports.areAllShiftsRequiredHoursApart = areAllShiftsRequiredHoursApart;
