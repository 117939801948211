"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromDateTimeComponentsWithOnCall = exports.fromDateTimeComponents = void 0;
const date_1 = require("@bemlo/date");
const fromDateTimeComponents = (date, startTime, endTime, isOnCall) => {
    let startDateTime = (0, date_1.dayjsEn)(date).time(startTime);
    let endDateTime = (0, date_1.dayjsEn)(date).time(endTime);
    // If on call starts before shift start time, it starts the next day
    if (isOnCall && isOnCall.shiftStartTime > startTime) {
        startDateTime = startDateTime.add(1, 'day');
        endDateTime = endDateTime.add(1, 'day');
    }
    // If end is before start the shift ends the next day
    if (endDateTime.isBefore(startDateTime) ||
        endDateTime.isSame(startDateTime)) {
        endDateTime = endDateTime.add(1, 'day');
    }
    return { startDateTime, endDateTime };
};
exports.fromDateTimeComponents = fromDateTimeComponents;
const fromDateTimeComponentsWithOnCall = (date, startTime, endTime, onCallTime) => {
    const dateTime = fromDateTimeComponents(date, startTime, endTime);
    const onCallDateTime = onCallTime
        ? fromDateTimeComponents(date, onCallTime.startTime, onCallTime.endTime, {
            shiftStartTime: startTime,
        })
        : undefined;
    return {
        ...dateTime,
        onCallTime: onCallDateTime,
    };
};
exports.fromDateTimeComponentsWithOnCall = fromDateTimeComponentsWithOnCall;
