"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentPageSidebarItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../constants");
const Div_1 = require("../Div");
const Flex_1 = require("../Flex");
const FormattedText_1 = require("../FormattedText");
const HoverCard_1 = require("../HoverCard");
const Link_1 = require("../Link");
const Text_1 = require("../Text");
const FONT_SIZE = 14;
const ContentPageSidebarItem = (props) => {
    const textContent = props.children ?? ((0, jsx_runtime_1.jsx)(Text_1.Text, { noWrap: true, fontSize: FONT_SIZE, styles: {
            display: 'block',
            color: props.to ? constants_1.Color.BLUE : undefined,
        }, children: (0, jsx_runtime_1.jsx)(FormattedText_1.FormattedText, { children: props.text ?? '' }) }));
    const valueContent = props.hoverCardContent ? ((0, jsx_runtime_1.jsx)("strong", { children: textContent })) : (textContent);
    const itemContent = ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { styles: {
            width: '100%',
            alignItems: 'center',
            gap: 2,
            ...props.styles,
        }, children: [(0, jsx_runtime_1.jsx)(Div_1.Div, { styles: { flex: '0 0 40%' }, children: (0, jsx_runtime_1.jsx)(Text_1.Text, { fontSize: FONT_SIZE, color: props.labelColor ?? constants_1.Color.DARKEST_MEDIUM_GREY, children: props.label }) }), (0, jsx_runtime_1.jsx)(Div_1.Div, { styles: { flex: '1', overflow: 'hidden' }, children: props.to ? (0, jsx_runtime_1.jsx)(Link_1.Link, { to: props.to, children: valueContent }) : valueContent })] }));
    return props.hoverCardContent ? ((0, jsx_runtime_1.jsx)(HoverCard_1.HoverCard, { trigger: itemContent, children: props.hoverCardContent })) : (itemContent);
};
exports.ContentPageSidebarItem = ContentPageSidebarItem;
