"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Input_1 = require("./Input");
const NumberInput = (props) => {
    return ((0, jsx_runtime_1.jsx)(Input_1.Input, { ...props, onChange: (value) => {
            props.onChange?.(value ? Number(value) : undefined);
        }, type: "number", value: props.value === undefined ? undefined : String(props.value) }));
};
exports.NumberInput = NumberInput;
