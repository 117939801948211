import { z } from 'zod'

import { Market } from '@bemlo/enums'

import { Env, ENVIRONMENTS, getEnv } from '../utils/env'

type FirebaseConfig = {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId?: string
  appId?: string
  measurementId?: string
}

const FIREBASE_CONFIGS: Record<Env, FirebaseConfig> = {
  development: {
    apiKey: 'fake-api-key',
    authDomain: 'demo-bemlo.firebaseapp.com',
    projectId: 'demo-bemlo',
    storageBucket: 'demo-bemlo.appspot.com',
  },
  test: {
    apiKey: 'AIzaSyAwYLWiq-LbZdd_0LRx4v46G7i9O_u6fec',
    authDomain: 'bemlo-test-0e17.firebaseapp.com',
    projectId: 'bemlo-test-0e17',
    storageBucket: 'bemlo-test-0e17-uploads',
    messagingSenderId: '103837175079',
    appId: '1:103837175079:web:3a6200959c77a1fc89ad02',
  },
  stage: {
    apiKey: 'AIzaSyBL8a0gF46PVjz7_o552lF5-zHPKMGzbZg',
    authDomain: 'bemlo-stage.firebaseapp.com',
    projectId: 'bemlo-stage',
    storageBucket: 'bemlo-stage.appspot.com',
    messagingSenderId: '602515704632',
    appId: '1:602515704632:web:0dd3871abf933e866a0fcd',
    measurementId: 'G-J8EE3TP87B',
  },
  production: {
    apiKey: 'AIzaSyAGtuLm5OQvIdtvpw8_HF3x-Nc0w9qJCZc',
    authDomain: 'bemlo-production.firebaseapp.com',
    projectId: 'bemlo-production',
    storageBucket: 'bemlo-production.appspot.com',
    messagingSenderId: '1078363166505',
    appId: '1:1078363166505:web:3cf45ed1fd73884cb98938',
    measurementId: 'G-MV86E747EM',
  },
}

export const ClientConfig = z.object({
  ENV: z.enum(ENVIRONMENTS),
  MARKET: z.nativeEnum(Market),
  GRAPHQL_URL: z.string(),
  FIREBASE_CONFIG: z.object({
    apiKey: z.string(),
    authDomain: z.string(),
    projectId: z.string(),
    storageBucket: z.string(),
    messagingSenderId: z.string().optional(),
    appId: z.string().optional(),
    measurementId: z.string().optional(),
  }),
  CONNECT_FIREBASE_EMULATOR: z.string().optional(),
  GIT_COMMIT_SHA: z.string().optional(),
})

function buildClientConfig() {
  const result = ClientConfig.safeParse({
    ENV: process.env.GATSBY_ENV,
    MARKET: process.env.GATSBY_MARKET,
    GRAPHQL_URL: process.env.GATSBY_GRAPHQL_URL,
    FIREBASE_CONFIG: FIREBASE_CONFIGS[getEnv()],
    CONNECT_FIREBASE_EMULATOR: process.env.GATSBY_CONNECT_FIREBASE_EMULATOR,
    GIT_COMMIT_SHA: process.env.GATSBY_VERCEL_GIT_COMMIT_SHA, // Set by Vercel
  })

  if (!result.success) {
    console.error('Invalid client config:')
    console.error(result.error.toString())
    process?.exit(1)
  }

  return result.data
}

export const clientConfig = buildClientConfig()
