"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBreakpoint = void 0;
const react_1 = require("react");
const constants_1 = require("../constants");
const sortedBreakpoints = Object.entries(constants_1.breakpoints).sort(([_aKey, aValue], [_bKey, bValue]) => bValue - aValue);
const getCurrentBreakpoint = () => {
    if (typeof window === 'undefined') {
        return undefined;
    }
    for (const [name, value] of sortedBreakpoints) {
        if (value <= window.innerWidth) {
            return name;
        }
    }
};
const useBreakpoint = () => {
    const [current, setCurrent] = (0, react_1.useState)(getCurrentBreakpoint());
    (0, react_1.useEffect)(() => {
        const handler = () => {
            setCurrent(getCurrentBreakpoint());
        };
        window.addEventListener('resize', handler);
        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);
    return (0, react_1.useMemo)(() => ({
        current,
        eq: {
            sm: current === 'sm',
            md: current === 'md',
        },
        gte: {
            sm: current === 'sm' || current === 'md',
            md: current === 'md',
        },
        lt: {
            sm: false,
            md: current === 'sm',
        },
    }), [current]);
};
exports.useBreakpoint = useBreakpoint;
