"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoSSR = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const NoSSR = ({ children }) => {
    const [render, setRender] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        setRender(true);
    }, []);
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: render ? children : null });
};
exports.NoSSR = NoSSR;
