"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobType = void 0;
var JobType;
(function (JobType) {
    JobType["IRREGULAR_PARTTIME"] = "IRREGULAR_PARTTIME";
    JobType["JOUR"] = "JOUR";
    JobType["PERMANENT"] = "PERMANENT";
    JobType["STAFFING"] = "STAFFING";
    JobType["TEMPORARY"] = "TEMPORARY";
})(JobType || (exports.JobType = JobType = {}));
