"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftApplicationStatus = void 0;
var ShiftApplicationStatus;
(function (ShiftApplicationStatus) {
    ShiftApplicationStatus["ACCEPTED"] = "ACCEPTED";
    ShiftApplicationStatus["APPLIED"] = "APPLIED";
    ShiftApplicationStatus["WITHDRAWN"] = "WITHDRAWN";
    ShiftApplicationStatus["REJECTED"] = "REJECTED";
})(ShiftApplicationStatus || (exports.ShiftApplicationStatus = ShiftApplicationStatus = {}));
