"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverviewCalendarTenderTitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const OverviewCalendarRow_1 = require("./OverviewCalendarRow");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const OverviewCalendarTenderTitle = (props) => {
    const t = (0, translate_1.useTranslations)();
    return ((0, jsx_runtime_1.jsxs)(OverviewCalendarRow_1.OverviewCalendarRowTitle, { onClick: () => props.onClick(props.id), children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 1, styles: { flex: 1 }, children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { fontSize: constants_1.theme.font.size[6], bold: true, children: props.title }), (0, jsx_runtime_1.jsx)(elements_1.Text, { fontSize: constants_1.theme.font.size[6.5], display: "block", children: t.$date.unix(props.startDate).format('v. W') +
                            ' – ' +
                            t.$date.unix(props.endDate).format('v. W') })] }), (0, jsx_runtime_1.jsx)(elements_1.Div, { styles: { flex: 1 }, children: (0, jsx_runtime_1.jsx)(elements_1.EducationBadge, { profession: props.profession, size: "sm", specializations: props.specializations }) })] }));
};
exports.OverviewCalendarTenderTitle = OverviewCalendarTenderTitle;
