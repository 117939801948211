"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ui = void 0;
exports.ui = {
    break_n_min: (breakTime) => `Rast: ${breakTime} min`,
    cancel: () => 'Avbryt',
    confirm: () => 'Bekräfta',
    filter_ellipsis: () => 'Filtrera...',
    includes: () => 'inkluderar',
    includes_all_of: () => 'inkluderar alla av',
    includes_any_of: () => 'inkluderar någon av',
    includes_none_of: () => 'inkluderar ingen av',
    is: () => 'är',
    is_after: () => 'är från och med',
    is_any_of: () => 'är någon av',
    is_before: () => 'är till och med',
    is_not: () => 'är inte',
    is_not_part_of: () => 'är inte en del av',
    is_part_of: () => 'är en del av',
    n_shifts_available: (n) => `${n} ledig${n === 1 ? 't' : 'a'} pass`,
    no_alternatives: () => 'Inga alternativ',
    no_break: () => 'Ingen rast',
    not_includes: () => 'inkluderar inte',
    optional: () => 'frivilligt',
    reply: () => 'Svar',
    reply_from_x: (name) => `Svar från ${name}`,
    selected_one: () => 'Vald',
    shift_acknowledged_by_candidate: () => 'Passbokning bekräftad av kandidaten',
    week: (number) => `Vecka${number !== undefined ? ` ${number}` : ''}`,
};
