import { Profession } from '../generated/graphql'
import { getTranslation } from '../translations'

import { MenuItemArea } from './enums/menu-item-area'
import { features } from './features'
import { marketConfig } from './market-configs'
import { MarketMenuItem, MenuItemConfig } from './types'

export const menuItemsFromConfig = ({
  profession,
  menuItemArea,
}: {
  profession?: Profession
  menuItemArea?: MenuItemArea
} = {}): MarketMenuItem[] => {
  const t = getTranslation('menuItems')

  return marketConfig.features
    .flatMap((feature) => features()[feature]?.menuItems ?? [])
    .filter(byProfession(profession))
    .filter(byMenuItemArea(menuItemArea))
    .sort(byWeight)
    .map(({ key, url }) => ({ label: t[key], url }))
}

export const byProfession =
  (profession?: Profession) => (menuItem: MenuItemConfig) => {
    if (!profession) {
      return !menuItem.professions
    }
    return !menuItem.professions || menuItem.professions.includes(profession)
  }

export const byMenuItemArea =
  (area?: MenuItemArea) => (menuItem: MenuItemConfig) => {
    if (!area) {
      return !menuItem.areas
    }
    return !menuItem.areas || menuItem.areas.includes(area)
  }

export const byWeight = (
  { weight: weight1 = 9999 }: MenuItemConfig,
  { weight: weight2 = 9999 }: MenuItemConfig,
) => weight1 - weight2
