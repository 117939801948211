"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobType = void 0;
const enums_1 = require("@bemlo/enums");
exports.jobType = {
    [enums_1.JobType.IRREGULAR_PARTTIME]: 'Irregular part-time',
    [enums_1.JobType.JOUR]: 'On call',
    [enums_1.JobType.PERMANENT]: 'Permanent',
    [enums_1.JobType.STAFFING]: 'Staffing',
    [enums_1.JobType.TEMPORARY]: 'Temporary',
};
