"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dynamicShiftStatus = void 0;
const enums_1 = require("@bemlo/enums");
exports.dynamicShiftStatus = {
    [enums_1.DynamicShiftStatus.APPLICATION_NOT_CHOSEN]: 'Not chosen',
    [enums_1.DynamicShiftStatus.APPLIED]: 'Applied',
    [enums_1.DynamicShiftStatus.AVAILABLE]: 'Available',
    [enums_1.DynamicShiftStatus.BOOKED]: 'Booked',
    [enums_1.DynamicShiftStatus.CANCELED]: 'Cancelled',
    [enums_1.DynamicShiftStatus.OCCUPIED]: 'Occupied',
    [enums_1.DynamicShiftStatus.REJECTED]: 'Rejected',
    [enums_1.DynamicShiftStatus.SUGGESTED]: 'Suggested',
    [enums_1.DynamicShiftStatus.WITHDRAWN]: 'Withdrawn',
};
