"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.form = void 0;
exports.form = {
    at_least: () => 'at least',
    at_most: () => 'at most',
    character_one: () => 'character',
    character_other: () => 'characters',
    custom: () => 'Custom',
    equal_to: () => 'equal to',
    exactly: () => 'exactly',
    from: () => 'From',
    greater_than: () => 'greater than',
    invalid_date: () => 'Invalid date',
    invalid_email: () => 'Invalid email',
    invalid_format: () => 'Invalid format',
    invalid_price: () => 'Invalid price',
    invalid_value: () => 'Invalid value',
    less_than: () => 'less than',
    month: () => 'Month',
    more_than: () => 'more than',
    must_be: () => 'must be',
    must_be_a_number: () => 'Must be a valid number',
    must_contain: () => 'must contain',
    must_end_with: () => 'must end with',
    must_start_with: () => 'must start with',
    n_weeks: (n) => `${n} Weeks`,
    no_alternatives: () => 'No alternatives',
    optional: () => 'optional',
    or: () => 'or',
    required: () => 'Required',
    select_a_date: () => 'Select a date',
    select_a_value: () => 'Select a value',
    smaller_than: () => 'smaller than',
    the_date: () => 'Date',
    the_value: () => 'Value',
    the_value_must_be_a_multiple_of: (number) => `Value must be a multiple of ${number}`,
    the_value_must_be_finite: () => 'Value must be finite',
    to: () => 'To',
    value_one: () => 'value',
    value_other: () => 'values',
    week: () => 'Week',
    you_must_select: () => 'You must select',
};
