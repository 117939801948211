"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTime = exports.parseDateTime = exports.parseDate = void 0;
const enums_1 = require("@bemlo/enums");
const _1 = require(".");
const normalize_time_1 = require("./normalize-time");
const DEFAULT_LOCALE = enums_1.Locale.SV;
const parseDate = (date, locale = DEFAULT_LOCALE) => {
    return (0, _1.dayjsFactory)(locale)(date);
};
exports.parseDate = parseDate;
const parseDateTime = (date, time, locale = DEFAULT_LOCALE) => {
    const { hour, minute } = (0, exports.parseTime)(time);
    return (0, exports.parseDate)(date, locale).hour(hour).minute(minute);
};
exports.parseDateTime = parseDateTime;
const parseTime = (time) => {
    const [hours, minutes] = time.split(':');
    return (0, normalize_time_1.normalizeTime)({
        hour: parseInt(hours, 10) || 0,
        minute: parseInt(minutes ?? '0', 10) || 0,
    });
};
exports.parseTime = parseTime;
