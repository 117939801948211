"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addAvailabilities = exports.addAvailability = exports.removeAvailabilities = exports.removeAvailability = void 0;
const constants_1 = require("./constants");
const removeAvailability = (availability) => {
    return {
        ...availability,
        selected: false,
        newScope: {
            scope: 0,
            shiftHours: constants_1.EMPTY_ARRAY,
        },
        days: availability.days.map((day) => ({ ...day, selected: false })),
    };
};
exports.removeAvailability = removeAvailability;
const removeAvailabilities = (availabilities) => {
    return availabilities.map((availability) => (0, exports.removeAvailability)(availability));
};
exports.removeAvailabilities = removeAvailabilities;
const addAvailability = (availability, options) => {
    return {
        ...availability,
        selected: true,
        newScope: {
            scope: options.getDefaultScope(availability),
            shiftHours: options.getDefaultShiftHours(availability),
        },
        days: availability.days.map((day) => ({
            ...day,
            selected: options.shouldAddDay(day),
        })),
    };
};
exports.addAvailability = addAvailability;
const addAvailabilities = (availabilities, options) => {
    return availabilities.map((availability) => options.shouldAddAvailability(availability) && !availability.selected
        ? (0, exports.addAvailability)(availability, options)
        : availability);
};
exports.addAvailabilities = addAvailabilities;
