"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initials = void 0;
const initials = (name) => name
    .trim()
    // remove all non unicode letter characters except numbers and spaces
    .replace(/[^\p{Letter} 0-9]/gu, '')
    .split(/\s+/)
    .slice(0, 3)
    .map((word) => word.slice(0, 1)?.toUpperCase() ?? '')
    .join('');
exports.initials = initials;
