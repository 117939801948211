"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateCountryFactory = void 0;
const locales_1 = require("../locales");
const translateCountryFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('country', locale);
    return (country) => {
        return t[country];
    };
};
exports.translateCountryFactory = translateCountryFactory;
