"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobType = void 0;
const enums_1 = require("@bemlo/enums");
exports.jobType = {
    [enums_1.JobType.IRREGULAR_PARTTIME]: 'Ströpass',
    [enums_1.JobType.JOUR]: 'Jourer',
    [enums_1.JobType.PERMANENT]: 'Tillsvidareanställningar',
    [enums_1.JobType.STAFFING]: 'Bemanning',
    [enums_1.JobType.TEMPORARY]: 'Vikariat',
};
